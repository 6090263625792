import { FC } from 'react';
import cn from 'classnames';

import TotalValueLocked from '@modules/layout/components/TotalValueLocked';
import NetworkSelector from '@modules/networks/components/NetworkSelector';

import styles from './Subheader.module.scss';

const Subheader: FC<{ className?: string }> = ({ className }) => {
  return (
    <aside className={cn(styles.container, className)}>
      <TotalValueLocked small />
      <NetworkSelector />
    </aside>
  );
};

Subheader.defaultProps = { className: undefined };

export default Subheader;
