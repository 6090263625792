import { BigNumber } from '@ethersproject/bignumber';

import ContractFunctions from '@modules/common/types/ContractFunctions';

const getClusterPrice = (
  clusterContract: ContractFunctions<BigNumber>,
  clusterAddress: string,
): Promise<BigNumber> => clusterContract.getClusterPrice(clusterAddress);

export default getClusterPrice;
