import { BigNumber } from '@ethersproject/bignumber';

import ContractFunctions from '@modules/common/types/ContractFunctions';

const handlePoolWithdrawUnderlying = (
  underlyingAddress: string,
  poolContract: ContractFunctions<BigNumber>,
  poolId: number,
  amount: BigNumber,
  gasLimit: BigNumber,
): Promise<BigNumber> => {
  return poolContract.withdrawInOneUnderlying(
    poolId,
    amount,
    underlyingAddress,
    { gasLimit },
  );
};

export default handlePoolWithdrawUnderlying;
