import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import {
  CallEffect,
  PutEffect,
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { JsonRpcProvider } from '@ethersproject/providers';
import { ContractInterface, Contract } from '@ethersproject/contracts';
import { parseUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';

import {
  setIsLoadingDisassemble,
  setErrorDisassemble,
  setDisassemble,
} from '@modules/clusters/slices/commissionSlice';
import getClusterGasPriceDisassemble from '@modules/clusters/actions/getClusterGasPriceDisassemble';
import getClusterBalanceOf from '@modules/common/actions/getTokenBalanceOf';
import getProviderFeeData from '@modules/clusters/helpers/getProviderFeeData';
import ProviderFeeData from '@modules/clusters/types/ProviderFeeData';
import { ClusterList } from '@configs/clusters';

const getClusterUserBalance = (
  clusterAddress: string,
  clusterABI: ContractInterface,
  provider: JsonRpcProvider,
  account: string,
): Promise<BigNumber> | null => {
  const clusterContract = new Contract(
    clusterAddress,
    clusterABI,
    account ? provider.getSigner(account) : provider,
  );

  return getClusterBalanceOf(clusterContract, account);
};

const getMethodGasPrice = (
  clusterAddress: string,
  clusterABI: ContractInterface,
  provider: JsonRpcProvider,
  amount: BigNumber,
  account?: string,
): Promise<BigNumber> => {
  const clusterContract = new Contract(
    clusterAddress,
    clusterABI,
    account ? provider.getSigner(account) : provider,
  );

  return getClusterGasPriceDisassemble(clusterContract, amount);
};

function* getClusterCommissionDisassembleWorker({
  payload,
}: PayloadAction<{
  clusterDecimals: number;
  clusterAddress: string;
  clusters: ClusterList;
  library: JsonRpcProvider;
  account: string;
}>): Generator<
  | CallEffect<Promise<BigNumber> | null>
  | CallEffect<ProviderFeeData>
  | CallEffect<BigNumber>
  | PutEffect<AnyAction>,
  void,
  never
> {
  try {
    yield put(setIsLoadingDisassemble(true));
    yield put(setErrorDisassemble(null));

    const clusterUserBalance: BigNumber | null = yield call(
      getClusterUserBalance,
      payload.clusterAddress,
      payload.clusters[payload.clusterAddress].tokenABI,
      payload.library,
      payload.account,
    );

    if (Number(clusterUserBalance)) {
      const providerFeeData: ProviderFeeData = yield call(
        getProviderFeeData,
        payload.library,
      );

      const methodGasPrice: BigNumber = yield call(
        getMethodGasPrice,
        payload.clusterAddress,
        payload.clusters[payload.clusterAddress].tokenABI,
        payload.library,
        clusterUserBalance,
        payload.account,
      );

      const commissionValue =
        providerFeeData.maxFeePerGas && providerFeeData.maxPriorityFeePerGas
          ? providerFeeData.maxFeePerGas
              .add(providerFeeData.maxPriorityFeePerGas)
              .mul(methodGasPrice)
          : BigNumber.from(parseUnits('0.01', payload.clusterDecimals));

      yield put(setDisassemble(commissionValue));
    }
  } catch (error: unknown) {
    yield put(setErrorDisassemble(error));
  } finally {
    yield put(setIsLoadingDisassemble(false));
  }
}

function* getClusterCommissionDisassembleSaga(): Generator {
  yield takeLatest(
    'CLUSTERS_GET_CLUSTER_COMMISSION_DISASSEMBLE_REQUESTED',
    getClusterCommissionDisassembleWorker,
  );
}

export default getClusterCommissionDisassembleSaga;
