import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { RootState } from '@utils/store';

interface InitialState {
  isLoading: boolean;
  isSuccess: boolean;
  error: unknown | null;
}

const initialState: InitialState = {
  isLoading: false,
  isSuccess: false,
  error: null,
};

const reducers = {
  setIsLoading(state: InitialState, action: PayloadAction<boolean>) {
    state.isLoading = action.payload;
  },
  setIsSuccess(state: InitialState, action: PayloadAction<boolean>) {
    state.isSuccess = action.payload;
  },
  setError(state: InitialState, action: PayloadAction<unknown | null>) {
    state.error = action.payload;
  },
};

const dhvSwapTransaction = createSlice({
  name: 'dhvSwapTransaction',
  initialState,
  reducers,
});

export const isLoadingSelector = (state: RootState): boolean =>
  state.dhvSwapTransaction.isLoading;
export const isSuccessSelector = (state: RootState): boolean =>
  state.dhvSwapTransaction.isSuccess;
export const errorSelector = (state: RootState): unknown | null =>
  state.dhvSwapTransaction.error;

export const { setIsLoading, setIsSuccess, setError } =
  dhvSwapTransaction.actions;

export default dhvSwapTransaction.reducer;
