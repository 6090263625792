import ETHEREUM_ICON from '@assets/images/networks/ethereum.svg';
import BSC_ICON from '@assets/images/networks/bsc.svg';
import POLYGON_ICON from '@assets/images/networks/polygon.svg';
import XDAI_ICON from '@assets/images/networks/xdai.svg';

interface ExplorersInterface {
  name: string;
  url: string;
  standard: string;
}

interface NetworkInterface {
  name: string;
  chain: string;
  network: string;
  icon?: string;
  rpc: string[];
  faucets?: string[];
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  infoURL: string;
  shortName: string;
  chainId: number;
  networkId: number;
  slip44?: number;
  ens?: {
    registry: string;
  };
  explorers: ExplorersInterface[];
}

// network list based at the https://chainid.network/chains.json
const NETWORKS_ETHEREUM: NetworkInterface = {
  name: 'Ethereum',
  chain: 'ETH',
  network: 'mainnet',
  icon: ETHEREUM_ICON,
  rpc: [
    `https://mainnet.infura.io/v3/${
      process.env.REACT_APP_INFURA_API_KEY || ''
    }`,
    `wss://mainnet.infura.io/ws/v3/${
      process.env.REACT_APP_INFURA_API_KEY || ''
    }`,
    'https://api.mycryptoapi.com/eth',
    'https://cloudflare-eth.com',
  ],
  faucets: [],
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  infoURL: 'https://ethereum.org',
  shortName: 'eth',
  chainId: 1,
  networkId: 1,
  slip44: 60,
  ens: {
    registry: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  },
  explorers: [
    {
      name: 'etherscan',
      url: 'https://etherscan.io',
      standard: 'EIP3091',
    },
  ],
};

const NETWORKS_BINANCE: NetworkInterface = {
  name: 'BNB Chain',
  chain: 'BSC',
  network: 'mainnet',
  icon: BSC_ICON,
  rpc: [
    'https://bsc-dataseed1.binance.org',
    'https://bsc-dataseed2.binance.org',
    'https://bsc-dataseed3.binance.org',
    'https://bsc-dataseed4.binance.org',
    'https://bsc-dataseed1.defibit.io',
    'https://bsc-dataseed2.defibit.io',
    'https://bsc-dataseed3.defibit.io',
    'https://bsc-dataseed4.defibit.io',
    'https://bsc-dataseed1.ninicoin.io',
    'https://bsc-dataseed2.ninicoin.io',
    'https://bsc-dataseed3.ninicoin.io',
    'https://bsc-dataseed4.ninicoin.io',
    'wss://bsc-ws-node.nariox.org',
  ],
  faucets: ['https://free-online-app.com/faucet-for-eth-evm-chains/'],
  nativeCurrency: {
    name: 'Binance Chain Native Token',
    symbol: 'BNB',
    decimals: 18,
  },
  infoURL: 'https://www.binance.org',
  shortName: 'bnb',
  chainId: 56,
  networkId: 56,
  slip44: 714,
  explorers: [
    {
      name: 'bscscan',
      url: 'https://bscscan.com',
      standard: 'EIP3091',
    },
  ],
};

const NETWORKS_POLYGON: NetworkInterface = {
  name: 'Polygon',
  chain: 'Matic(Polygon)',
  network: 'mainnet',
  icon: POLYGON_ICON,
  rpc: [
    'https://polygon-rpc.com/',
    'https://rpc-mainnet.matic.network',
    'wss://ws-mainnet.matic.network',
    'https://rpc-mainnet.matic.quiknode.pro',
    'https://matic-mainnet.chainstacklabs.com',
  ],
  faucets: [],
  nativeCurrency: {
    name: 'Matic',
    symbol: 'MATIC',
    decimals: 18,
  },
  infoURL: 'https://matic.network/',
  shortName: 'matic',
  chainId: 137,
  networkId: 137,
  explorers: [
    {
      name: 'polygonscan',
      url: 'https://polygonscan.com',
      standard: 'EIP3091',
    },
  ],
};

const NETWORKS_XDAI: NetworkInterface = {
  name: 'Gnosis Chain',
  chain: 'XDAI',
  network: 'mainnet',
  icon: XDAI_ICON,
  rpc: [
    'https://rpc.gnosischain.com/',
    'wss://rpc.gnosischain.com/wss',
    'https://rpc.ankr.com/gnosis',
    'https://gnosis-mainnet.public.blastapi.io/',
    'https://gnosischain-rpc.gateway.pokt.network/',
  ],
  faucets: [
    'https://free-online-app.com/faucet-for-eth-evm-chains/',
    'https://xdai-app.herokuapp.com/faucet',
  ],
  nativeCurrency: {
    name: 'xDAI',
    symbol: 'XDAI',
    decimals: 18,
  },
  infoURL: 'https://forum.poa.network/c/xdai-chain',
  shortName: 'xdai',
  chainId: 100,
  networkId: 100,
  explorers: [
    {
      name: 'blockscout',
      url: 'https://blockscout.com/poa/xdai',
      standard: 'EIP3091',
    },
  ],
};

const NETWORKS_RINKEBY: NetworkInterface = {
  name: 'Rinkeby',
  chain: 'ETH',
  network: 'rinkeby',
  rpc: [
    `https://rinkeby.infura.io/v3/${
      process.env.REACT_APP_INFURA_API_KEY || ''
    }`,
    `wss://rinkeby.infura.io/ws/v3/${
      process.env.REACT_APP_INFURA_API_KEY || ''
    }`,
  ],
  faucets: ['https://faucet.rinkeby.io'],
  nativeCurrency: {
    name: 'Rinkeby Ether',
    symbol: 'RIN',
    decimals: 18,
  },
  infoURL: 'https://www.rinkeby.io',
  shortName: 'rin',
  chainId: 4,
  networkId: 4,
  ens: {
    registry: '0xe7410170f87102df0055eb195163a03b7f2bff4a',
  },
  explorers: [
    {
      name: 'etherscan-rinkeby',
      url: 'https://rinkeby.etherscan.io',
      standard: 'EIP3091',
    },
  ],
};

const NETWORKS_CHAPEL: NetworkInterface = {
  name: 'Binance Smart Chain Testnet',
  chain: 'BSC',
  network: 'Chapel',
  icon: BSC_ICON,
  rpc: [
    'https://data-seed-prebsc-1-s1.binance.org:8545',
    'https://data-seed-prebsc-2-s1.binance.org:8545',
    'https://data-seed-prebsc-1-s2.binance.org:8545',
    'https://data-seed-prebsc-2-s2.binance.org:8545',
    'https://data-seed-prebsc-1-s3.binance.org:8545',
    'https://data-seed-prebsc-2-s3.binance.org:8545',
  ],
  faucets: ['https://testnet.binance.org/faucet-smart'],
  nativeCurrency: {
    name: 'Binance Chain Native Token',
    symbol: 'tBNB',
    decimals: 18,
  },
  infoURL: 'https://testnet.binance.org/',
  shortName: 'bnbt',
  chainId: 97,
  networkId: 97,
  explorers: [
    {
      name: 'bscscan-testnet',
      url: 'https://testnet.bscscan.com',
      standard: 'EIP3091',
    },
  ],
};

const NETWORKS_MUMBAI: NetworkInterface = {
  name: 'Mumbai',
  chain: 'Matic(Polygon)',
  network: 'testnet',
  icon: POLYGON_ICON,
  rpc: [
    'https://rpc-mumbai.matic.today',
    'https://matic-mumbai.chainstacklabs.com',
    'https://rpc-mumbai.maticvigil.com',
    'https://matic-testnet-archive-rpc.bwarelabs.com',
    'wss://rpc-mumbai.matic.today',
    'wss://ws-matic-mumbai.chainstacklabs.com',
    'wss://rpc-mumbai.maticvigil.com/ws',
    'wss://matic-testnet-archive-ws.bwarelabs.com',
  ],
  faucets: ['https://faucet.matic.network/'],
  nativeCurrency: { name: 'Matic', symbol: 'tMATIC', decimals: 18 },
  infoURL: 'https://matic.network/',
  shortName: 'maticmum',
  chainId: 80001,
  networkId: 80001,
  explorers: [
    {
      name: 'polygonscan',
      url: 'https://mumbai.polygonscan.com/',
      standard: 'EIP3091',
    },
  ],
};

const NETWORKS: NetworkInterface[] =
  process.env.REACT_APP_NETWORK_ENVIRONMENT === 'mainnet'
    ? [NETWORKS_ETHEREUM, NETWORKS_BINANCE, NETWORKS_POLYGON, NETWORKS_XDAI]
    : [NETWORKS_RINKEBY, NETWORKS_CHAPEL, NETWORKS_MUMBAI];

export default NETWORKS;
