import * as Sentry from '@sentry/react';
import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import {
  CallEffect,
  PutEffect,
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { JsonRpcProvider } from '@ethersproject/providers';
import {
  ContractTransaction,
  ContractInterface,
  ContractReceipt,
  Contract,
} from '@ethersproject/contracts';
import { BigNumber } from '@ethersproject/bignumber';

import {
  setConfirmedItem,
  setPendingItem,
  setSuccessItem,
  setErrorItem,
  setValueItem,
} from '@modules/pools/slices/stakeSlice';
import getPoolGasPriceDepositUnderlying from '@modules/pools/actions/getPoolGasPriceDepositUnderlying';
import getPoolGasPriceDeposit from '@modules/pools/actions/getPoolGasPriceDeposit';
import handlePoolDepositUnderlying from '@modules/pools/actions/handlePoolDepositUnderlying';
import handlePoolDeposit from '@modules/pools/actions/handlePoolDeposit';

const getGasPrice = (
  poolAddress: string,
  poolABI: ContractInterface,
  poolId: number,
  amountList: BigNumber[],
  provider: JsonRpcProvider,
  account: string,
): Promise<BigNumber> => {
  const poolContract = new Contract(
    poolAddress,
    poolABI,
    provider.getSigner(account),
  );

  return amountList.length > 1
    ? getPoolGasPriceDepositUnderlying(poolContract, poolId, amountList)
    : getPoolGasPriceDeposit(poolContract, poolId, amountList[0]);
};

const stakePool = (
  poolAddress: string,
  poolABI: ContractInterface,
  poolId: number,
  amountList: BigNumber[],
  gasLimit: BigNumber,
  provider: JsonRpcProvider,
  account: string,
): Promise<BigNumber> => {
  const poolContract = new Contract(
    poolAddress,
    poolABI,
    provider.getSigner(account),
  );

  return amountList.length > 1
    ? handlePoolDepositUnderlying(poolContract, poolId, amountList, gasLimit)
    : handlePoolDeposit(poolContract, poolId, amountList[0], gasLimit);
};

function* stakePoolWorker({
  payload,
}: PayloadAction<{
  poolAddress: string;
  poolABI: ContractInterface;
  poolId: number;
  amountList: BigNumber[];
  library: JsonRpcProvider;
  account: string;
}>): Generator<
  | CallEffect<ContractReceipt>
  | CallEffect<BigNumber>
  | CallEffect<unknown>
  | PutEffect<AnyAction>,
  void,
  never
> {
  try {
    yield put(
      setPendingItem({
        poolAddress: payload.poolAddress,
        poolId: payload.poolId,
        isPending: true,
      }),
    );
    yield put(
      setErrorItem({
        poolAddress: payload.poolAddress,
        poolId: payload.poolId,
        error: null,
      }),
    );

    const gasLimit: BigNumber = yield call(
      getGasPrice,
      payload.poolAddress,
      payload.poolABI,
      payload.poolId,
      payload.amountList,
      payload.library,
      payload.account,
    );

    const { wait }: ContractTransaction = yield call(
      stakePool,
      payload.poolAddress,
      payload.poolABI,
      payload.poolId,
      payload.amountList,
      gasLimit.mul(120).div(100),
      payload.library,
      payload.account,
    );

    yield put(
      setConfirmedItem({
        poolAddress: payload.poolAddress,
        poolId: payload.poolId,
        isConfirmed: true,
      }),
    );
    yield put(
      setPendingItem({
        poolAddress: payload.poolAddress,
        poolId: payload.poolId,
        isPending: false,
      }),
    );

    yield call(wait, 1);

    yield put(
      setValueItem({
        poolAddress: payload.poolAddress,
        poolId: payload.poolId,
        value: payload.amountList,
      }),
    );
    yield put(
      setSuccessItem({
        poolAddress: payload.poolAddress,
        poolId: payload.poolId,
        isSuccess: true,
      }),
    );
    yield put(
      setConfirmedItem({
        poolAddress: payload.poolAddress,
        poolId: payload.poolId,
        isConfirmed: false,
      }),
    );
  } catch (error: unknown) {
    yield put(
      setConfirmedItem({
        poolAddress: payload.poolAddress,
        poolId: payload.poolId,
        isConfirmed: false,
      }),
    );
    yield put(
      setPendingItem({
        poolAddress: payload.poolAddress,
        poolId: payload.poolId,
        isPending: false,
      }),
    );
    yield put(
      setSuccessItem({
        poolAddress: payload.poolAddress,
        poolId: payload.poolId,
        isSuccess: false,
      }),
    );
    yield put(
      setErrorItem({
        poolAddress: payload.poolAddress,
        poolId: payload.poolId,
        error,
      }),
    );
    Sentry.captureException(
      `Stake pool error,
      ' pool address ${payload.poolAddress}',
      ' pool id ${payload.poolId}',
      ' amount ${payload.amountList
        .map((amount) => Number(amount))
        .toString()}',
      ' account ${payload.account}'`,
    );
  }
}

function* stakePoolSaga(): Generator {
  yield takeLatest('POOLS_STAKE_POOL_REQUESTED', stakePoolWorker);
}

export default stakePoolSaga;
