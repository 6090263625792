import { BigNumber } from '@ethersproject/bignumber';

const maxValueWithCommission = (
  commissionValue?: BigNumber | null,
  maxValue?: BigNumber | null,
): BigNumber | null => {
  return maxValue && commissionValue ? maxValue.sub(commissionValue) : null;
};

export default maxValueWithCommission;
