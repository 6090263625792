import { all } from 'redux-saga/effects';

// Cluster sagas:
import getClusterCommissionDisassembleSaga from '@modules/clusters/sagas/getClusterCommissionDisassembleSaga';
import getClusterCommissionAssembleSaga from '@modules/clusters/sagas/getClusterCommissionAssembleSaga';
import resetDisassembleClusterSaga from '@modules/clusters/sagas/resetDisassembleClusterSaga';
import resetAssembleClusterSaga from '@modules/clusters/sagas/resetAssembleClusterSaga';
import disassembleClusterSaga from '@modules/clusters/sagas/disassembleClusterSaga';
import getClusterAmountSaga from '@modules/clusters/sagas/getClusterAmountSaga';
import assembleClusterSaga from '@modules/clusters/sagas/assembleClusterSaga';
import setErrorClusterSaga from '@modules/clusters/sagas/setErrorClusterSaga';
import getClusterListSaga from '@modules/clusters/sagas/getClusterListSaga';
import getClusterSaga from '@modules/clusters/sagas/getClusterSaga';
// Connection sagas:
import inProgressSaga from '@modules/connections/sagas/inProgressSaga';
import isLoadingSaga from '@modules/connections/sagas/isLoadingSaga';
import connectedSaga from '@modules/connections/sagas/connectedSaga';
// DHV sagas:
import setErrorTokenPricesSaga from '@modules/dhv/sagas/setErrorTokenPricesSaga';
import setErrorChartDataSaga from '@modules/dhv/sagas/setErrorChartDataSaga';
import setAllowanceErrorSaga from '@modules/dhv/sagas/setErrorAllowance';
import getChartDataSaga from '@modules/dhv/sagas/getChartDataSaga';
import getDHVSwapBalancesSaga from '@modules/dhv/sagas/getDHVSwapBalancesSaga';
import getAllowanceSaga from '@modules/dhv/sagas/getAllowanceSaga';
import approveTokenSaga from '@modules/dhv/sagas/approveTokenSaga';
import getAmountOutSaga from '@modules/dhv/sagas/getAmountOutSaga';
import getAmountInSaga from '@modules/dhv/sagas/getAmountsInSaga';
import dhvSwapSaga from '@modules/dhv/sagas/dhvSwapSaga';
// Layout sagas:
import setErrorTvlSaga from '@modules/layout/sagas/setErrorTvlSaga';
import getTvlSaga from '@modules/layout/sagas/getTvlSaga';
// Main sagas:
import setErrorProductsSaga from '@modules/main/sagas/setErrorProductsSaga';
import getProductsSaga from '@modules/main/sagas/getProductsSaga';
// Pool sagas:
import setErrorPoolsStatsSaga from '@modules/pools/sagas/setErrorPoolsStatsSaga';
import setErrorPoolsTvlSaga from '@modules/pools/sagas/setErrorPoolsTvlSaga';
import setErrorPoolSaga from '@modules/pools/sagas/setErrorPoolSaga';
import getPoolStatsSaga from '@modules/pools/sagas/getPoolStatsSaga';
import getPoolsTvlSaga from '@modules/pools/sagas/getPoolsTvlSaga';
import getPoolListSaga from '@modules/pools/sagas/getPoolListSaga';
import getPoolSaga from '@modules/pools/sagas/getPoolSaga';
import resetUnstakePoolSaga from '@modules/pools/sagas/resetUnstakePoolSaga';
import resetRewardPoolSaga from '@modules/pools/sagas/resetRewardPoolSaga';
import resetStakePoolSaga from '@modules/pools/sagas/resetStakePoolSaga';
import unstakePoolSaga from '@modules/pools/sagas/unstakePoolSaga';
import approvePoolSaga from '@modules/pools/sagas/approvePoolSaga';
import rewardPoolSaga from '@modules/pools/sagas/rewardPoolSaga';
import stakePoolSaga from '@modules/pools/sagas/stakePoolSaga';
// Prices sagas:
import setErrorCurrencySaga from '@modules/prices/sagas/setErrorCurrencySaga';
import getTokenPricesSaga from '@modules/prices/sagas/getTokensPricesSaga';
import getCurrencySaga from '@modules/prices/sagas/getCurrencySaga';
// Underlying sagas:
import setErrorUnderlyingSaga from '@modules/underlying/sagas/setErrorUnderlyingSaga';
import getUnderlyingListSaga from '@modules/underlying/sagas/getUnderlyingListSaga';

function* rootSaga(): Generator {
  yield all([
    // Cluster sagas:
    getClusterCommissionDisassembleSaga(),
    getClusterCommissionAssembleSaga(),
    resetDisassembleClusterSaga(),
    resetAssembleClusterSaga(),
    disassembleClusterSaga(),
    getClusterAmountSaga(),
    assembleClusterSaga(),
    setErrorClusterSaga(),
    getClusterListSaga(),
    getClusterSaga(),
    // Connection sagas:
    inProgressSaga(),
    isLoadingSaga(),
    connectedSaga(),
    // DHV sagas:
    setErrorTokenPricesSaga(),
    setErrorChartDataSaga(),
    setAllowanceErrorSaga(),
    getChartDataSaga(),
    getDHVSwapBalancesSaga(),
    getAllowanceSaga(),
    approveTokenSaga(),
    getAmountOutSaga(),
    getAmountInSaga(),
    dhvSwapSaga(),
    // Layout sagas:
    setErrorTvlSaga(),
    getTvlSaga(),
    // Main sagas:
    setErrorProductsSaga(),
    getProductsSaga(),
    // Pool sagas:
    setErrorPoolsStatsSaga(),
    setErrorPoolsTvlSaga(),
    setErrorPoolSaga(),
    getPoolStatsSaga(),
    getPoolsTvlSaga(),
    getPoolListSaga(),
    getPoolSaga(),
    resetUnstakePoolSaga(),
    resetRewardPoolSaga(),
    resetStakePoolSaga(),
    unstakePoolSaga(),
    approvePoolSaga(),
    rewardPoolSaga(),
    stakePoolSaga(),
    // Prices sagas:
    setErrorCurrencySaga(),
    getTokenPricesSaga(),
    getCurrencySaga(),
    // Underlying sagas:
    setErrorUnderlyingSaga(),
    getUnderlyingListSaga(),
  ]);
}

export default rootSaga;
