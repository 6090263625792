import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { RootState } from '@utils/store';

interface InitialState {
  inProgress: string;
  isLoading: boolean;
  connected: string;
}

const initialState: InitialState = {
  inProgress: '',
  isLoading: false,
  connected: '',
};

const reducers = {
  setInProgress(state: InitialState, action: PayloadAction<string>) {
    state.inProgress = action.payload;
  },
  setIsLoading(state: InitialState, action: PayloadAction<boolean>) {
    state.isLoading = action.payload;
  },
  setConnected(state: InitialState, action: PayloadAction<string>) {
    state.connected = action.payload;
  },
};

const connectionsSlice = createSlice({
  name: 'connections',
  initialState,
  reducers,
});

export const inProgressSelector = (state: RootState): string =>
  state.connections.inProgress;
export const isLoadingSelector = (state: RootState): boolean =>
  state.connections.isLoading;
export const connectedSelector = (state: RootState): string =>
  state.connections.connected;

export const { setInProgress, setIsLoading, setConnected } =
  connectionsSlice.actions;

export default connectionsSlice.reducer;
