import { FC, useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { formatUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import { Form } from 'antd';

import FormSubmit from '@modules/common/components/FormSubmit';
import checkMaxValue from '@modules/common/helpers/checkMaxValue';
import trackGTMActions from '@modules/common/helpers/trackGTMActions';
import UnderlyingFormItem from '@modules/pools/types/UnderlyingFormItem';
import constants from '@modules/common/constants';
import POOLS, { Pool } from '@configs/pools';

import styles from './PoolStakeFormSubmit.module.scss';

const INPUT_NAME = 'value';

const { Item, useForm } = Form;

const PoolStakeFormSubmit: FC<{
  poolAddress: string;
  poolId: number;
  underlyingList: UnderlyingFormItem[];
  inputValueList: BigNumber[];
  allowance: (BigNumber | null)[];
  isDisabled: Pool['closed'];
  isLoading: boolean;
}> = ({
  poolAddress,
  poolId,
  underlyingList,
  inputValueList,
  allowance,
  isDisabled,
  isLoading,
}) => {
  const { t } = useTranslation();

  const [form] = useForm();

  const dispatch = useDispatch();

  const { chainId, account, library } = useWeb3React<Web3Provider>();

  const isMinInputValueBigger: boolean = useMemo(() => {
    let bigger = true;

    inputValueList.forEach((item, index) => {
      if (
        !checkMaxValue(
          constants.INPUT_MINIMAL_STEP,
          underlyingList[index].decimals,
          item,
        )
      ) {
        bigger = false;
      }
    });

    return bigger;
  }, [underlyingList, inputValueList]);

  const isAllowanceSmaller: boolean = useMemo(() => {
    let smaller = false;

    allowance.forEach((item, index) => {
      if (
        item &&
        inputValueList[index].gt(BigNumber.from('0')) &&
        item.lt(inputValueList[index])
      ) {
        smaller = true;
      }
    });

    return smaller;
  }, [inputValueList, allowance]);

  const initialValues = useMemo(() => {
    const state: { [key: string]: string } = {};

    underlyingList?.forEach((item) => {
      state[`${INPUT_NAME}_${item.symbol}`] = '0';
    });

    return state;
  }, [underlyingList]);

  useEffect(() => {
    underlyingList?.forEach((item, index) => {
      form.setFieldsValue({
        [`${INPUT_NAME}_${item.symbol}`]: formatUnits(
          inputValueList[index],
          item.decimals,
        ),
      });
    });
  }, [form, underlyingList, inputValueList]);

  const onFinish = useCallback(() => {
    if (
      account &&
      library &&
      chainId &&
      POOLS[chainId] &&
      POOLS[chainId][poolAddress] &&
      POOLS[chainId][poolAddress][poolId]
    ) {
      trackGTMActions(
        'Stake',
        POOLS[chainId][poolAddress][poolId].symbol || '',
        POOLS[chainId][poolAddress][poolId].type || 1,
        chainId,
        account,
        'intent',
      );

      dispatch({
        type: 'POOLS_STAKE_POOL_REQUESTED',
        payload: {
          poolAddress,
          poolABI: POOLS[chainId][poolAddress][poolId].poolABI,
          poolId,
          amountList: inputValueList,
          library,
          account,
        },
      });
    }

    form.resetFields();
  }, [
    form,
    dispatch,
    account,
    library,
    chainId,
    poolId,
    poolAddress,
    inputValueList,
  ]);

  return (
    <Form
      initialValues={initialValues}
      onFinish={onFinish}
      form={form}
      name="stakePoolSubmit"
    >
      {underlyingList?.map((item) => (
        <Item hidden name={`${INPUT_NAME}_${item.symbol}`} key={item.symbol}>
          <input hidden type="text" />
        </Item>
      ))}
      <div className={styles['form-btn']}>
        <FormSubmit
          isDisabled={isMinInputValueBigger || isAllowanceSmaller || isDisabled}
          isLoading={isLoading}
          name={t('POOLS.POOL_STAKE_FORM_SUBMIT.BUTTON_TITLE')}
        />
      </div>
    </Form>
  );
};

export default PoolStakeFormSubmit;
