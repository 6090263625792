import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import {
  CallEffect,
  PutEffect,
  AllEffect,
  takeLatest,
  call,
  put,
  all,
} from 'redux-saga/effects';
import { JsonRpcProvider } from '@ethersproject/providers';
import { Contract } from '@ethersproject/contracts';
import { BigNumber } from '@ethersproject/bignumber';

import {
  setIsLoading,
  setPreError,
  setError,
  setData,
} from '@modules/dhv/slices/dhvSwapBalancesSlice';
import getTokenBalanceOf from '@modules/common/actions/getTokenBalanceOf';
import getTokenDecimals from '@modules/common/actions/getTokenDecimals';
import { Swap } from '@configs/swap';

const getNativeTokenBalance = (
  provider: JsonRpcProvider,
  account: string,
): Promise<BigNumber> => {
  return provider.getBalance(account);
};

function* getDHVSwapBalancesWorker({
  payload,
}: PayloadAction<{
  transactionFee: Swap['transactionFee'];
  dhvAddress: Swap['dhvAddress'];
  tokenAbi: Swap['tokenAbi'];
  library: JsonRpcProvider;
  account: string;
}>): Generator<
  | AllEffect<CallEffect<BigNumber> | CallEffect<number>>
  | CallEffect<BigNumber>
  | PutEffect<AnyAction>,
  void,
  never
> {
  try {
    yield put(setIsLoading(true));
    yield put(setPreError(null));
    yield put(setError(null));

    const tokenContract = new Contract(
      payload.dhvAddress,
      payload.tokenAbi,
      payload.library.getSigner(payload.account),
    );

    const result: [BigNumber, BigNumber, number] = yield all([
      call(getNativeTokenBalance, payload.library, payload.account),
      call(getTokenBalanceOf, tokenContract, payload.account),
      call(getTokenDecimals, tokenContract),
    ]);

    yield put(
      setData({
        transactionFee: result[0].mul(payload.transactionFee).div(100),
        balanceNative: result[0],
        balanceDHV: result[1],
        decimalsDHV: result[2],
      }),
    );
  } catch (error: unknown) {
    yield put(setPreError(error));
  } finally {
    yield put(setIsLoading(false));
  }
}

function* getDHVSwapBalancesSaga(): Generator {
  yield takeLatest('DHV_GET_SWAP_BALANCES', getDHVSwapBalancesWorker);
}

export default getDHVSwapBalancesSaga;
