import { FC } from 'react';
import { IPoweredBy } from '@configs/pools';
import styles from '@modules/pools/components/PoweredBy/PoweredBy.module.scss';

interface PoweredProps {
  info: IPoweredBy | undefined;
}

const PoweredBy: FC<PoweredProps> = ({ info }) => {
  if (!info) return null;

  const { name, icon, link } = info;
  return (
    <div className={styles.container}>
      <img className={styles.icon} src={icon} alt={name} />
      <span className={styles.text}>
        Powered by{' '}
        <a
          className={styles.text__link}
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          {name}
        </a>
      </span>
    </div>
  );
};

export default PoweredBy;
