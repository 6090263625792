import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';

const getPoolGasLimitUnStakeUnderlying = (
  underlyingAddress: string,
  poolContract: Contract,
  poolId: number,
  amount: BigNumber,
): Promise<BigNumber> => {
  return poolContract.estimateGas.withdrawInOneUnderlying(
    poolId,
    amount,
    underlyingAddress,
  );
};

export default getPoolGasLimitUnStakeUnderlying;
