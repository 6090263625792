import { BigNumber } from '@ethersproject/bignumber';

import ContractFunctions from '@modules/common/types/ContractFunctions';

const handleTokenApprove = (
  tokenContract: ContractFunctions<BigNumber>,
  poolAddress: string,
  amount: BigNumber,
  gasLimit: BigNumber,
): Promise<BigNumber> => {
  return tokenContract.approve(poolAddress, amount, { gasLimit });
};

export default handleTokenApprove;
