import { BigNumber } from '@ethersproject/bignumber';

import ContractFunctions from '@modules/common/types/ContractFunctions';

const getPendingRewards = (
  poolContract: ContractFunctions<[BigNumber]>,
  poolId: number,
  account?: string | null,
): Promise<[BigNumber]> | null => {
  return account ? poolContract.pendingRewards(poolId, account) : null;
};

export default getPendingRewards;
