import { createContext } from 'react';
import WalletConnectProvider from '@walletconnect/web3-provider';

export type WalletConnectContextType = {
  handleChangeProvider: (value: WalletConnectProvider | null) => void;
  provider: WalletConnectProvider | null;
};

export const WalletConnectContext = createContext<WalletConnectContextType>({
  handleChangeProvider: () => {},
  provider: null,
});
