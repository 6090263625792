import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BigNumber } from '@ethersproject/bignumber';

import type { RootState } from '@utils/store';

interface Data {
  transactionFee: BigNumber;
  balanceNative: BigNumber;
  balanceDHV: BigNumber;
  decimalsDHV: number;
}

interface InitialState {
  isLoading: boolean;
  preError: unknown | null;
  error: unknown | null;
  data: Data | null;
}

const initialState: InitialState = {
  isLoading: false,
  preError: null,
  error: null,
  data: null,
};

const reducers = {
  setIsLoading(state: InitialState, action: PayloadAction<boolean>) {
    state.isLoading = action.payload;
  },
  setPreError(state: InitialState, action: PayloadAction<unknown | null>) {
    state.preError = action.payload;
  },
  setError(state: InitialState, action: PayloadAction<unknown | null>) {
    state.error = action.payload;
  },
  setData(state: InitialState, action: PayloadAction<Data>) {
    state.data = action.payload;
  },
};

const dhvSwapBalances = createSlice({
  name: 'dhvSwapBalances',
  initialState,
  reducers,
});

export const isLoadingSelector = (state: RootState): boolean =>
  state.dhvSwapBalances.isLoading;
export const preErrorSelector = (state: RootState): unknown | null =>
  state.dhvSwapBalances.preError;
export const errorSelector = (state: RootState): unknown | null =>
  state.dhvSwapBalances.error;
export const dataSelector = (state: RootState): Data | null =>
  state.dhvSwapBalances.data;

export const { setIsLoading, setPreError, setError, setData } =
  dhvSwapBalances.actions;

export default dhvSwapBalances.reducer;
