import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { formatUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import cn from 'classnames';

import formatValuePrice from '@modules/common/helpers/formatValuePrice';
import formatValueToken from '@modules/common/helpers/formatValueToken';
import getTokenPrice from '@modules/common/helpers/getTokenPrice';
import useMediaQuery from '@modules/layout/hooks/useMediaQuery';
import StoredCluster from '@modules/clusters/types/StoredCluster';
import CLUSTERS from '@configs/clusters';
import { useTypedSelector } from '@utils/store';
import { getCurrencySelector } from '@modules/prices/slices/currencySlice';
import { listSelector } from '@modules/pools/slices/poolsSlice';

import styles from '@modules/clusters/components/ClusterBalances/ClusterBalances.module.scss';

const ClusterBalances: FC<{
  clusterAddress: string;
  className: string;
  cluster: StoredCluster;
}> = ({ clusterAddress, className, cluster }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { chainId, account } = useWeb3React();

  const currency = useTypedSelector(getCurrencySelector);
  const pools = useTypedSelector(listSelector);

  const isMobile = useMediaQuery(991);

  const clusterStaked = useMemo(() => {
    const stakedAmount = Object.values(pools || {})
      ?.map((poolsData) => Object.values(poolsData))
      .flat()
      .find((pool) => pool.symbol === cluster.symbol)?.userPoolAmount;

    return stakedAmount || BigNumber.from(0);
  }, [cluster, pools]);

  return chainId ? (
    <dl className={cn(styles.container, className)}>
      <dt className={styles.title}>
        {t('CLUSTERS.CLUSTER_BALANCES.TOTAL_VALUE')}
      </dt>
      <dd className={styles.description}>
        {cluster.totalSupply &&
        cluster.price &&
        currency &&
        chainId &&
        currency[chainId]
          ? formatValuePrice(
              language,
              getTokenPrice(
                cluster.totalSupply.mul(cluster.price),
                cluster.decimals * 2,
                currency[chainId].price,
              ),
            )
          : '-'}
      </dd>

      <dt className={cn(styles.title, styles['title--flex'])}>
        <img
          className={styles.icon}
          height="16"
          width="15"
          src={CLUSTERS[chainId][clusterAddress].tokenIcon}
          alt={cluster.symbol}
        />{' '}
        {t('CLUSTERS.CLUSTER_BALANCES.PRICE')}
      </dt>
      <dd className={styles.description}>
        {cluster.price && currency && chainId && currency[chainId]
          ? formatValuePrice(
              language,
              getTokenPrice(
                cluster.price,
                cluster.decimals,
                currency[chainId].price,
              ),
            )
          : '-'}
      </dd>

      <dt className={styles.title}>
        {isMobile
          ? t('CLUSTERS.CLUSTER_BALANCES.USER_BALANCE_MOBILE')
          : t('CLUSTERS.CLUSTER_BALANCES.USER_BALANCE')}
      </dt>
      <dd className={styles.description}>
        {cluster.userBalance &&
        cluster.price &&
        clusterStaked &&
        currency &&
        account &&
        chainId &&
        currency[chainId]
          ? formatValuePrice(
              language,
              getTokenPrice(
                cluster.userBalance.add(clusterStaked).mul(cluster.price),
                cluster.decimals * 2,
                currency[chainId].price,
              ),
            )
          : '-'}
      </dd>

      <dt className={styles.title}>
        {t('CLUSTERS.CLUSTER_BALANCES.AVAILABLE')}
      </dt>
      <dd className={styles.description}>
        {cluster.userBalance && account
          ? `${formatValueToken(
              language,
              Number(formatUnits(cluster.userBalance, cluster.decimals)),
            )} ${cluster.symbol}`
          : '-'}
      </dd>

      <dt className={styles.title}>{t('CLUSTERS.CLUSTER_BALANCES.STAKED')}</dt>
      <dd className={styles.description}>
        {clusterStaked && account
          ? `${formatValueToken(
              language,
              Number(formatUnits(clusterStaked, cluster.decimals)),
            )} ${cluster.symbol}`
          : '-'}
      </dd>
    </dl>
  ) : null;
};

export default ClusterBalances;
