import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ERROR_ICON from '@modules/portfolio/assets/error_logo.svg';

import styles from './PortfolioError.module.scss';

const PortfolioError: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.error}>
      <div className={styles.icon}>
        <img src={ERROR_ICON} alt={t('PORTFOLIO.PORTFOLIO_ERROR.TEXT')} />
      </div>
      <p>{t('PORTFOLIO.PORTFOLIO_ERROR.TEXT')}</p>
    </div>
  );
};

export default PortfolioError;
