const formatValuePrice = (locales: string, value: number): string => {
  const formatter = new Intl.NumberFormat(locales, {
    currency: 'USD',
    style: 'currency',
  });

  const updatedValue = Math.trunc(value * 10 ** 2) / 10 ** 2;

  return formatter.format(updatedValue < 0.01 ? 0 : updatedValue);
};

export default formatValuePrice;
