import { ContractInterface } from '@ethersproject/contracts';

import IERC20 from '@abis/IERC20.json';

export interface Token {
  tokenStandart: string;
  tokenAddress?: string;
  dedcimals: number;
  tokenABI: ContractInterface;
  symbol: string;
}

interface TokenByChain {
  [key: number]: Token;
}

const DHV: Token = {
  tokenStandart: 'ERC20',
  dedcimals: 18,
  tokenABI: IERC20,
  symbol: 'DHV',
};

const DHV_LIST: TokenByChain = {
  1: {
    tokenAddress: '0x62Dc4817588d53a056cBbD18231d91ffCcd34b2A',
    ...DHV,
  },
  4: {
    tokenAddress: '0xED377aCA060a0b1Dab10911C8DFf98D24fcF4118',
    ...DHV,
  },
  56: {
    tokenAddress: '0x58759dd469ae5631c42cf8a473992335575b58d7',
    ...DHV,
  },
  100: {
    tokenAddress: '0xFbdd194376de19a88118e84E279b977f165d01b8',
    ...DHV,
  },
  137: {
    tokenAddress: '0x5fCB9de282Af6122ce3518CDe28B7089c9F97b26',
    ...DHV,
  },
  80001: {
    tokenAddress: '0x19B30Ab83641745Bd445c8dB21A520f2971257e0',
    ...DHV,
  },
};

export default DHV_LIST;
