import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BigNumber } from '@ethersproject/bignumber';

import getInitialObject from '@modules/common/helpers/getInitialObject';
import ErrorList from '@modules/common/types/ErrorList';
import type { RootState } from '@utils/store';

interface Amount {
  amountFromEth: BigNumber;
  ethFromAmount: BigNumber;
}

interface AmountList {
  [key: string]: Amount | null;
}

interface InitialState {
  clusterAmountList: AmountList;
  errorList: ErrorList;
}

const initialState: InitialState = {
  clusterAmountList: getInitialObject(),
  errorList: getInitialObject(),
};

const reducers = {
  setClusterAmountItem(
    state: InitialState,
    action: PayloadAction<{
      clusterAddress: string;
      amountFromEth: BigNumber;
      ethFromAmount: BigNumber;
    }>,
  ) {
    state.clusterAmountList[action.payload.clusterAddress] = {
      amountFromEth: action.payload.amountFromEth,
      ethFromAmount: action.payload.ethFromAmount,
    };
  },
  setErrorItem(
    state: InitialState,
    action: PayloadAction<{ clusterAddress: string; error: unknown | null }>,
  ) {
    state.errorList[action.payload.clusterAddress] = action.payload.error;
  },
};

const amountsSlice = createSlice({
  name: 'amounts',
  initialState,
  reducers,
});

export const clusterAmountListSelector = (state: RootState): AmountList =>
  state.amounts.clusterAmountList;
export const errorListSelector = (state: RootState): ErrorList =>
  state.amounts.errorList;

export const { setClusterAmountItem, setErrorItem } = amountsSlice.actions;

export default amountsSlice.reducer;
