import { FC, useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import TagManager from 'react-gtm-module';
import { useIntercom } from 'react-use-intercom';
import cn from 'classnames';

import NotificationBar from '@modules/layout/components/NotificationBar';
import SocialLinks from '@modules/layout/components/SocialLinks';
import Disclaimer from '@modules/layout/components/Disclaimer';
import Subheader from '@modules/layout/components/Subheader';
import Sidebar from '@modules/layout/components/Sidebar';
import Header from '@modules/layout/components/Header';
import Footer from '@modules/layout/components/Footer';
import { preErrorSelector } from '@modules/layout/slices/tvlSlice';
import constants from '@modules/common/constants';
import pages, { Page } from '@utils/pages';
import { useTypedSelector } from '@utils/store';

import styles from './Layout.module.scss';

const Layout: FC = ({ children }) => {
  const location = useLocation();
  const [isOpened, setIsOpened] = useState(false);
  const [attempt, setAttempt] = useState(0);

  const { t } = useTranslation();

  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const { boot } = useIntercom();

  const preError = useTypedSelector(preErrorSelector);

  const handleOpened = useCallback(() => setIsOpened(!isOpened), [isOpened]);

  const getTvl = useCallback(() => {
    dispatch({ type: 'LAYOUT_GET_TVL' });
  }, [dispatch]);

  useEffect(() => {
    getTvl();

    const reGetTvl = setInterval(() => getTvl(), constants.GET_ITEMS_INTERVAL);

    return () => clearInterval(reGetTvl);
  }, [getTvl]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'changePage',
        eventAction: location.pathname,
      },
    });
  }, [location]);

  useEffect(() => {
    if (preError && attempt < constants.GET_ITEMS_ATTEMPTS) {
      getTvl();

      setAttempt(attempt + 1);
    }

    if (preError && attempt >= constants.GET_ITEMS_ATTEMPTS) {
      dispatch({
        type: 'LAYOUT_SET_ERROR_TVL',
        payload: { error: preError },
      });
    }
  }, [preError, getTvl, dispatch, attempt]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const page = pages.find(({ path }: Page) => pathname === path);

    if (page?.title) {
      document.title = `DeHive | ${t(page.title)}`;
    }
  }, [pathname, t]);

  useEffect(() => {
    boot({
      createdAt: Math.round(new Date().getTime() / 1000).toString(),
    });
  }, [boot]);

  return (
    <main className={cn(styles.container, { [styles['is-opened']]: isOpened })}>
      <Header className={styles.header} onClick={handleOpened} />

      <Subheader className={styles.subheader} />

      <Sidebar className={styles.sidebar} onClick={handleOpened} />

      <NotificationBar className={styles.notifications} />

      {children}

      <SocialLinks className={styles.socialLinks} />

      <Disclaimer className={styles.disclaimer} />

      <Footer className={styles.footer} />
    </main>
  );
};

export default Layout;
