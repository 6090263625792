import { FC, useCallback } from 'react';
import { Web3Provider } from '@ethersproject/providers';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { formatUnits } from '@ethersproject/units';
import cn from 'classnames';

import PoolStake from '@modules/pools/components/PoolStake';
import PortfolioMore from '@modules/portfolio/components/PortfolioMore';
import ValueChanges from '@modules/common/components/ValueChanges';
import { listSelector as listSelectorUnderlying } from '@modules/underlying/slices/underlyingSlice';
import { listSelector as listSelectorPools } from '@modules/pools/slices/poolsSlice';
import { getCurrencySelector } from '@modules/prices/slices/currencySlice';
import { getPrices } from '@modules/prices/slices/tokenPricesSlice';
import formatValuePrice from '@modules/common/helpers/formatValuePrice';
import formatValueToken from '@modules/common/helpers/formatValueToken';
import getTokenPrice from '@modules/common/helpers/getTokenPrice';
import getPoolName from '@modules/pools/helpers/getPoolName';
import StoredAssetList from '@modules/portfolio/types/StoredAssetList';
import { useTypedSelector } from '@utils/store';
import POOLS from '@configs/pools';

import styles from './PortfolioAssets.module.scss';

const POOL_PRICE_DAY_AGO = 0.5;

const PortfolioAssetsRow: FC<{
  poolAddress: string;
  poolId: number;
  list: StoredAssetList;
}> = ({ poolAddress, poolId, list }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const underlyingList = useTypedSelector(listSelectorUnderlying);
  const currency = useTypedSelector(getCurrencySelector);
  const prices = useTypedSelector(getPrices);
  const pools = useTypedSelector(listSelectorPools);

  const { chainId } = useWeb3React<Web3Provider>();

  const getPrice = useCallback(
    (address: string, pid: number) => {
      let price = 0;

      if (chainId && list) {
        const { clusterPrice, symbol, decimals } = list[address][Number(pid)];

        if (clusterPrice && currency) {
          price =
            Number(formatUnits(clusterPrice, decimals)) *
            currency[chainId].price;
        }

        if (prices && !clusterPrice) {
          price =
            prices[
              POOLS[chainId][address][pid].asset ||
                POOLS[chainId][address][pid].symbol ||
                symbol
            ];
        }
      }
      return price;
    },
    [currency, chainId, prices, list],
  );

  return (
    <tr className={styles.bodyRow}>
      <td className={styles.bodyCell}>
        {chainId &&
          POOLS[chainId] &&
          POOLS[chainId][poolAddress] &&
          POOLS[chainId][poolAddress][poolId]?.icon && (
            <div
              className={cn(styles.bodyIcon, {
                [styles['bodyIcon--withSustrate']]:
                  POOLS[chainId][poolAddress][poolId].type !== 2 ||
                  POOLS[chainId][poolAddress][poolId].type !== 3,
              })}
            >
              <img
                className={styles.bodyIconImage}
                src={
                  POOLS[chainId][poolAddress][poolId].tokenIcon ||
                  POOLS[chainId][poolAddress][poolId].icon
                }
                alt={list[poolAddress][poolId].symbol}
              />
            </div>
          )}
      </td>

      <td className={cn(styles.bodyCell, styles['bodyCell--bold'])}>
        {list[poolAddress][poolId].balanceOf &&
        list[poolAddress][poolId].userPoolAmount &&
        getPrice(poolAddress, poolId)
          ? formatValuePrice(
              language,
              getTokenPrice(
                list[poolAddress][poolId].balanceOf.add(
                  list[poolAddress][poolId].userPoolAmount,
                ),
                list[poolAddress][poolId].decimals,
                getPrice(poolAddress, poolId),
              ),
            )
          : '-'}
      </td>

      <td className={styles.bodyCell}>
        {list[poolAddress][poolId].balanceOf
          ? formatValueToken(
              language,
              Number(
                formatUnits(
                  list[poolAddress][poolId].balanceOf,
                  list[poolAddress][poolId].decimals,
                ),
              ),
            )
          : '-'}{' '}
        {pools &&
          pools[poolAddress] &&
          pools[poolAddress][poolId] &&
          getPoolName(
            poolAddress,
            underlyingList,
            poolId,
            pools[poolAddress][poolId],
            chainId,
          )}{' '}
        {chainId &&
          POOLS[chainId] &&
          POOLS[chainId][poolAddress] &&
          POOLS[chainId][poolAddress][poolId]?.type === 1 &&
          t('PORTFOLIO.PORTFOLIO_ASSETS.POOL_POSTFIX')}
      </td>

      <td className={styles.bodyCell}>
        {list[poolAddress][poolId].userPoolAmount
          ? formatValueToken(
              language,
              Number(
                formatUnits(
                  list[poolAddress][poolId].userPoolAmount,
                  list[poolAddress][poolId].decimals,
                ),
              ),
            )
          : '-'}{' '}
        {pools &&
          pools[poolAddress] &&
          pools[poolAddress][poolId] &&
          getPoolName(
            poolAddress,
            underlyingList,
            poolId,
            pools[poolAddress][poolId],
            chainId,
          )}{' '}
        {chainId &&
          POOLS[chainId] &&
          POOLS[chainId][poolAddress] &&
          POOLS[chainId][poolAddress][poolId]?.type === 1 &&
          t('PORTFOLIO.PORTFOLIO_ASSETS.POOL_POSTFIX')}
      </td>

      <td className={styles.bodyCell} style={{ display: 'none' }}>
        {getPrice(poolAddress, poolId) && POOL_PRICE_DAY_AGO ? (
          <ValueChanges
            valuePrevious={POOL_PRICE_DAY_AGO}
            valueCurrent={getPrice(poolAddress, poolId)}
          />
        ) : (
          '-'
        )}
      </td>

      <td className={styles.bodyCell}>
        {pools &&
          chainId &&
          Object.keys(pools)?.map((addressPool: string) =>
            Object.keys(pools[addressPool])
              .filter(
                (pidPool: string) =>
                  poolAddress === addressPool &&
                  list[poolAddress][poolId].symbol ===
                    pools[addressPool][Number(pidPool)].symbol,
              )
              .map((pidPool: string) => (
                <PoolStake
                  isClosed={
                    POOLS[chainId] &&
                    POOLS[chainId][addressPool] &&
                    POOLS[chainId][addressPool][Number(pidPool)]?.closed
                  }
                  className={styles.stake}
                  buttonSize="medium"
                  underlying={
                    POOLS[chainId][addressPool][Number(pidPool)].type === 4
                      ? POOLS[chainId][addressPool][Number(pidPool)].underlying
                      : undefined
                  }
                  poolAddress={addressPool}
                  poolId={Number(pidPool)}
                  pool={pools[addressPool][Number(pidPool)]}
                  key={Number(pidPool)}
                />
              )),
          )}

        <PortfolioMore poolGroupAddress={poolAddress} poolId={poolId} />
      </td>
    </tr>
  );
};

const PortfolioAssets: FC<{ list: StoredAssetList | null }> = ({ list }) => {
  const { t } = useTranslation();

  const { chainId } = useWeb3React<Web3Provider>();

  return list && chainId ? (
    <table className={styles.container}>
      <thead>
        <tr>
          <th className={styles.headCell}>
            {t('PORTFOLIO.PORTFOLIO_ASSETS.LOGO')}
          </th>
          <th className={styles.headCell}>
            {t('PORTFOLIO.PORTFOLIO_ASSETS.USD_VALUE')}
          </th>
          <th className={styles.headCell}>
            {t('PORTFOLIO.PORTFOLIO_ASSETS.BALANCE')}
          </th>
          <th className={styles.headCell}>
            {t('PORTFOLIO.PORTFOLIO_ASSETS.STAKED')}
          </th>
          <th className={styles.headCell} style={{ display: 'none' }}>
            {t('PORTFOLIO.PORTFOLIO_ASSETS.24H_CHANGE')}
          </th>
          <th className={styles.headCell}>
            {t('PORTFOLIO.PORTFOLIO_ASSETS.MORE')}
          </th>
        </tr>
      </thead>

      <tbody>
        {Object.keys(list)?.map((address: string) => {
          return Object.keys(list[address])
            ?.filter(
              (pid) =>
                (POOLS[chainId] &&
                  POOLS[chainId][address] &&
                  POOLS[chainId][address][Number(pid)]?.type === 0) ||
                false,
            )
            ?.map((pid: string) => (
              <PortfolioAssetsRow
                poolAddress={address}
                poolId={Number(pid)}
                list={list}
                key={`${address}_${pid}`}
              />
            ));
        })}

        {Object.keys(list)?.map((address: string) => {
          return Object.keys(list[address])
            ?.filter(
              (pid) =>
                (POOLS[chainId] &&
                  POOLS[chainId][address] &&
                  POOLS[chainId][address][Number(pid)]?.type === 1) ||
                false,
            )
            ?.map((pid: string) => (
              <PortfolioAssetsRow
                poolAddress={address}
                poolId={Number(pid)}
                list={list}
                key={`${address}_${pid}`}
              />
            ));
        })}

        {Object.keys(list)?.map((address: string) => {
          return Object.keys(list[address])
            ?.filter(
              (pid) =>
                (POOLS[chainId] &&
                  POOLS[chainId][address] &&
                  POOLS[chainId][address][Number(pid)]?.type === 2) ||
                false,
            )
            ?.map((pid: string) => (
              <PortfolioAssetsRow
                poolAddress={address}
                poolId={Number(pid)}
                list={list}
                key={`${address}_${pid}`}
              />
            ));
        })}

        {Object.keys(list)?.map((address: string) => {
          return Object.keys(list[address])
            ?.filter((pid) => {
              if (
                POOLS[chainId] &&
                POOLS[chainId][address] &&
                POOLS[chainId][address][Number(pid)]
              ) {
                const { symbol, type } = POOLS[chainId][address][Number(pid)];

                return symbol && symbol.includes('DHV') && type === 4;
              }

              return false;
            })
            ?.map((pid: string) => (
              <PortfolioAssetsRow
                poolAddress={address}
                poolId={Number(pid)}
                list={list}
                key={`${address}_${pid}`}
              />
            ));
        })}

        {Object.keys(list)?.map((address: string) => {
          return Object.keys(list[address])
            ?.filter((pid) => {
              if (
                POOLS[chainId] &&
                POOLS[chainId][address] &&
                POOLS[chainId][address][Number(pid)]
              ) {
                const { symbol, type } = POOLS[chainId][address][Number(pid)];

                return symbol && symbol.includes('DHV') && type === 3;
              }

              return false;
            })
            ?.map((pid: string) => (
              <PortfolioAssetsRow
                poolAddress={address}
                poolId={Number(pid)}
                list={list}
                key={`${address}_${pid}`}
              />
            ));
        })}
      </tbody>
    </table>
  ) : null;
};

export default PortfolioAssets;
