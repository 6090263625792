import { FC, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';

import FormSubmit from '@modules/common/components/FormSubmit';
import FormInput from '@modules/common/components/FormInput';
import { loadingListSelector } from '@modules/pools/slices/approveSlice';
import { listSelector } from '@modules/underlying/slices/underlyingSlice';
import formatValueToken from '@modules/common/helpers/formatValueToken';
import checkMaxValue from '@modules/common/helpers/checkMaxValue';
import constants from '@modules/common/constants';
import { useTypedSelector } from '@utils/store';
import POOLS, { Pool } from '@configs/pools';
import ENABLED_ICON from '@modules/pools/assets/enabled_icon.svg';
import cn from 'classnames';

import styles from './PoolStakeFormEnable.module.scss';

const INPUT_NAME = 'value';

const { useForm } = Form;

const PoolStakeFormEnable: FC<{
  userTotalLockedDHV?: BigNumber | null;
  userPoolAmountDHV?: BigNumber | null;
  сlusterRateAccuracy?: BigNumber;
  clusterRate?: BigNumber;
  underlyingIndex: number;
  tokenAddress: string;
  poolAddress: string;
  poolName: string;
  poolId: number;
  userBalance?: BigNumber | null;
  allowance: BigNumber | null;
  decimals: number;
  symbol: string;
  onInputChange: (index: number, value: BigNumber) => void;
  formEnable?: FormInstance;
  className?: string;
  isDisabled: Pool['closed'];
  isLoading: boolean;
  isSingle: boolean;
}> = ({
  userTotalLockedDHV,
  userPoolAmountDHV,
  сlusterRateAccuracy,
  clusterRate,
  underlyingIndex,
  tokenAddress,
  poolAddress,
  poolName,
  poolId,
  userBalance,
  allowance,
  decimals,
  symbol,
  onInputChange,
  formEnable,
  className,
  isDisabled,
  isLoading,
  isSingle,
}) => {
  const [inputValue, setInputValue] = useState<BigNumber>(BigNumber.from('0'));

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const underlyingList = useTypedSelector(listSelector);
  const loadingList = useTypedSelector(loadingListSelector);

  const [form] = useForm();

  const dispatch = useDispatch();

  const { chainId, account, library } = useWeb3React<Web3Provider>();

  const isMinValueBigger: boolean = useMemo(() => {
    return checkMaxValue(constants.INPUT_MINIMAL_STEP, decimals, userBalance);
  }, [decimals, userBalance]);

  const isAllowanceSmaller: boolean = useMemo(() => {
    return !!(
      allowance?.gt(BigNumber.from('0')) &&
      inputValue.lte(allowance || BigNumber.from('0'))
    );
  }, [inputValue, allowance]);

  const additionalValidation = useCallback(
    (value: string) => {
      if (
        userPoolAmountDHV &&
        userTotalLockedDHV &&
        clusterRate &&
        сlusterRateAccuracy &&
        decimals &&
        Number(value) > 0 &&
        clusterRate.gt(BigNumber.from('0'))
      ) {
        const currentValue = clusterRate
          .div(сlusterRateAccuracy)
          .mul(parseUnits('1', decimals))
          .mul(parseUnits(value, decimals))
          .div(parseUnits('1', decimals));
        const minValue = userPoolAmountDHV.sub(userTotalLockedDHV);

        if (minValue.lt(currentValue)) {
          throw new Error(
            t('POOLS.POOL_STAKE_FORM_ENABLE.ERROR_TITLE', {
              current: formatValueToken(
                language,
                Number(formatUnits(currentValue, decimals)),
              ),
              min: formatValueToken(
                language,
                Number(formatUnits(minValue, decimals)),
              ),
            }),
          );
        }
      }
    },
    [
      userTotalLockedDHV,
      userPoolAmountDHV,
      сlusterRateAccuracy,
      clusterRate,
      decimals,
      language,
      t,
    ],
  );

  const onFinish = useCallback(
    (args: { [key: string]: string }) => {
      if (
        account &&
        library &&
        chainId &&
        POOLS[chainId] &&
        POOLS[chainId][poolAddress] &&
        POOLS[chainId][poolAddress][poolId]
      ) {
        dispatch({
          type: 'POOLS_APPROVE_UNDERLYING_REQUESTED',
          payload: {
            poolTokenAddress: tokenAddress,
            poolTokenABI: POOLS[chainId][poolAddress][poolId].tokenABI,
            poolAddress,
            amount: parseUnits(args[INPUT_NAME], decimals),
            library,
            account,
          },
        });
      }
    },
    [
      dispatch,
      account,
      library,
      chainId,
      tokenAddress,
      poolAddress,
      decimals,
      poolId,
    ],
  );

  const displayiedSymbol = useMemo(() => {
    if (
      underlyingList &&
      chainId &&
      POOLS[chainId] &&
      POOLS[chainId][poolAddress] &&
      (POOLS[chainId][poolAddress][poolId]?.type === 2 ||
        POOLS[chainId][poolAddress][poolId]?.type === 3) &&
      POOLS[chainId][poolAddress][poolId]?.underlying
    ) {
      let result = '';

      POOLS[chainId][poolAddress][poolId].underlying?.forEach(
        ({ address }, index: number) => {
          if (
            index !==
            (POOLS[chainId][poolAddress][poolId].underlying?.length || 0) - 1
          ) {
            result += `${underlyingList[address]?.symbol || ''}/`;
          } else {
            result += underlyingList[address]?.symbol || '';
          }
        },
      );

      return result;
    }

    return symbol;
  }, [underlyingList, poolAddress, chainId, poolId, symbol]);

  return (
    <Form
      onFieldsChange={(changedFieldList) => {
        changedFieldList.forEach((item) => {
          const field = item as { name: string[]; value: string };

          if (field.name[0] === INPUT_NAME) {
            setInputValue(parseUnits(field.value || '0', decimals));

            onInputChange(
              underlyingIndex,
              parseUnits(field.value || '0', decimals),
            );
            form.setFieldsValue({ [INPUT_NAME]: field.value || '0' });
          }
        });
      }}
      initialValues={{ [INPUT_NAME]: '0' }}
      onFinish={onFinish}
      form={formEnable || form}
      name={`stakePoolEnable_${tokenAddress}`}
      className={cn(styles.form, className)}
    >
      <dl
        className={cn(
          styles['form-balance'],
          !isSingle && styles['form-balance--multy-pool'],
        )}
      >
        <dt>
          {t('POOLS.POOL_STAKE_FORM_ENABLE.BALANCE_TITLE', {
            name:
              chainId &&
              POOLS[chainId] &&
              POOLS[chainId][poolAddress] &&
              POOLS[chainId][poolAddress][poolId]?.type === 4
                ? displayiedSymbol
                : poolName,
          })}
        </dt>
        <dd>
          {userBalance
            ? formatValueToken(
                language,
                Number(formatUnits(userBalance, decimals)),
              )
            : '-'}{' '}
          {displayiedSymbol}
        </dd>
      </dl>

      <div className={styles.row}>
        <FormInput
          minValueForValidation={constants.INPUT_MINIMAL_STEP}
          additionalValidation={clusterRate && additionalValidation}
          tokenSymbol={!isSingle ? symbol : undefined}
          isDisabled={
            isLoading ||
            isDisabled ||
            isMinValueBigger ||
            !!loadingList[tokenAddress]
          }
          className={styles.input}
          inputName={INPUT_NAME}
          maxValue={userBalance}
          decimals={decimals}
          minValue="0"
          isShort={!isSingle}
          form={formEnable || form}
        />

        {!isSingle && isAllowanceSmaller && (
          <div className={styles.check}>
            <img
              className={styles.checkIcon}
              height="15"
              width="15"
              src={ENABLED_ICON}
              alt=""
            />

            <span className={styles.checkText}>
              {t('POOLS.POOL_STAKE_FORM_ENABLE.ENABLED_TEXT')}
            </span>
          </div>
        )}

        {!isSingle && !isAllowanceSmaller && (
          <FormSubmit
            isDisabled={isMinValueBigger || !!loadingList[tokenAddress]}
            isLoading={isLoading || isDisabled || !!loadingList[tokenAddress]}
            className={styles.button}
            name={t('POOLS.POOL_STAKE_FORM_ENABLE.BUTTON_TITLE')}
            size="sbig"
          />
        )}
      </div>
    </Form>
  );
};

PoolStakeFormEnable.defaultProps = {
  userTotalLockedDHV: undefined,
  userPoolAmountDHV: undefined,
  сlusterRateAccuracy: undefined,
  clusterRate: undefined,
  userBalance: undefined,
  formEnable: undefined,
  className: undefined,
};

export default PoolStakeFormEnable;
