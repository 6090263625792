import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import {
  CallEffect,
  PutEffect,
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { JsonRpcProvider } from '@ethersproject/providers';
import { ContractInterface, Contract } from '@ethersproject/contracts';
import { BigNumber } from '@ethersproject/bignumber';

import {
  setIsLoading,
  setListItem,
  setPreError,
  setError,
} from '@modules/clusters/slices/clustersSlice';
import getTokenName from '@modules/common/actions/getTokenName';
import getTokenSymbol from '@modules/common/actions/getTokenSymbol';
import getTokenDecimals from '@modules/common/actions/getTokenDecimals';
import getClusterUnderlyingList from '@modules/clusters/actions/getClusterUnderlyingList';
import getClusterUnderlyingInCluster from '@modules/clusters/actions/getClusterUnderlyingInCluster';
import getClusterTotalSupply from '@modules/clusters/actions/getClusterTotalSupply';
import getClusterBalanceOf from '@modules/common/actions/getTokenBalanceOf';
import { ClusterList } from '@configs/clusters';
import { ControllerData } from '@configs/controllers';
import getClusterPrice from '@modules/clusters/actions/getClusterPrice';

type ReturnedCluster = [
  string,
  string,
  number,
  string[],
  BigNumber[],
  BigNumber,
  BigNumber | null,
  BigNumber,
];

const getCluster = (
  clusterAddress: string,
  clusterABI: ContractInterface,
  provider: JsonRpcProvider,
  controller: ControllerData,
  account?: string,
): Promise<ReturnedCluster> => {
  const clusterContract = new Contract(
    clusterAddress,
    clusterABI,
    account ? provider.getSigner(account) : provider,
  );

  const clusterController = new Contract(
    controller.controllerAddress,
    controller.controllerABI,
    provider,
  );

  return Promise.all([
    getTokenName(clusterContract),
    getTokenSymbol(clusterContract),
    getTokenDecimals(clusterContract),
    getClusterUnderlyingList(clusterContract),
    getClusterUnderlyingInCluster(clusterContract),
    getClusterTotalSupply(clusterContract),
    account ? getClusterBalanceOf(clusterContract, account) : null,
    getClusterPrice(clusterController, clusterAddress),
  ]);
};

function* getClusterWorker({
  payload,
}: PayloadAction<{
  clusterAddress: string;
  clusters: ClusterList;
  controller: ControllerData;
  library: JsonRpcProvider;
  account?: string;
}>): Generator<
  CallEffect<ReturnedCluster> | PutEffect<AnyAction>,
  void,
  never
> {
  try {
    yield put(setIsLoading(true));
    yield put(setPreError(null));
    yield put(setError(null));

    const returnedCluster: ReturnedCluster = yield call(
      getCluster,
      payload.clusterAddress,
      payload.clusters[payload.clusterAddress].tokenABI,
      payload.library,
      payload.controller,
      payload.account,
    );

    const clusterResult = {
      name: returnedCluster[0],
      symbol: returnedCluster[1],
      decimals: returnedCluster[2],
      underlyingList: returnedCluster[3].map((item: string, index: number) => {
        return {
          address: item,
          share: returnedCluster[4][index],
        };
      }),
      totalSupply: returnedCluster[5],
      userBalance: returnedCluster[6],
      price: returnedCluster[7],
    };

    yield put(
      setListItem({
        clusterAddress: payload.clusterAddress,
        cluster: clusterResult,
      }),
    );
  } catch (error: unknown) {
    yield put(setPreError(error));
  } finally {
    yield put(setIsLoading(false));
  }
}

function* getClusterSaga(): Generator {
  yield takeLatest('CLUSTERS_GET_CLUSTER_REQUESTED', getClusterWorker);
}

export default getClusterSaga;
