import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';

import { setInProgress } from '@modules/connections/slices/connectionsSlice';

function* setInProgressWorker({ payload }: PayloadAction<string>) {
  yield put(setInProgress(payload));
}

function* inProgressSaga(): Generator {
  yield takeLatest('CONNECTIONS_IN_PROGRESS_REQUESTED', setInProgressWorker);
}

export default inProgressSaga;
