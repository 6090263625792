import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import styles from './PortfolioEmpty.module.scss';

const PortfolioEmpty: FC<{ icon: string }> = ({ icon }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.empty}>
      <div className={styles.icon}>
        <img src={icon} alt={t('PORTFOLIO.PORTFOLIO_EMPTY.TEXT_1')} />
      </div>
      <div>
        <p>{t('PORTFOLIO.PORTFOLIO_EMPTY.TEXT_1')}</p>
        <p>
          <Trans
            i18nKey="PORTFOLIO.PORTFOLIO_EMPTY.TEXT_2"
            components={[
              <NavLink exact to="/stables" key={0}>
                {' '}
              </NavLink>,
            ]}
          />
        </p>
      </div>
    </div>
  );
};

export default PortfolioEmpty;
