import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import StoredProducts from '@modules/main/types/StoredProducts';
import type { RootState } from '@utils/store';

interface InitialState {
  isLoading: boolean;
  preError: unknown | null;
  error: unknown | null;
  list: StoredProducts | null;
}

const initialState: InitialState = {
  isLoading: false,
  preError: null,
  error: null,
  list: null,
};

const reducers = {
  setIsLoading(state: InitialState, action: PayloadAction<boolean>) {
    state.isLoading = action.payload;
  },
  setPreError(state: InitialState, action: PayloadAction<unknown | null>) {
    state.preError = action.payload;
  },
  setError(state: InitialState, action: PayloadAction<unknown | null>) {
    state.error = action.payload;
  },
  setList(state: InitialState, action: PayloadAction<StoredProducts>) {
    state.list = action.payload;
  },
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers,
});

export const isLoadingSelector = (state: RootState): boolean =>
  state.products.isLoading;
export const preErrorSelector = (state: RootState): unknown | null =>
  state.products.preError;
export const errorSelector = (state: RootState): unknown | null =>
  state.products.error;
export const listSelector = (state: RootState): StoredProducts | null =>
  state.products.list;

export const { setIsLoading, setPreError, setError, setList } =
  productsSlice.actions;

export default productsSlice.reducer;
