import {
  call,
  CallEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import { AnyAction, PayloadAction } from '@reduxjs/toolkit';

import {
  setPoolsTvl,
  setError,
  setIsLoading,
  setPreError,
} from '@modules/pools/slices/poolsTvlSlices';
import StoredPoolsTvl from '@modules/pools/types/poolsTvl';

const API = process.env.REACT_APP_API || 'http://localhost:1234/api';

const getPoolStats = (
  pools: string[],
  chainId: number,
): Promise<AxiosResponse<string>[]> => {
  return Promise.all(
    pools.map((symbol) =>
      axios.get<string>(
        `${API}/staking/tvl?stakingSymbol=${symbol}&network=${chainId}`,
      ),
    ),
  );
};

function* getPoolsTvlWorker({
  payload,
}: PayloadAction<{
  pools: string[];
  chainId: number;
}>): Generator<
  CallEffect<AxiosResponse<string>[]> | PutEffect<AnyAction>,
  void,
  never
> {
  try {
    yield put(setIsLoading(true));
    yield put(setPreError(null));
    yield put(setError(null));

    const response: AxiosResponse<string>[] = yield call(
      getPoolStats,
      payload.pools,
      payload.chainId,
    );

    const poolsTvl: StoredPoolsTvl = {};

    response.forEach(({ data }: { data: string }, index) => {
      poolsTvl[payload.pools[index]] = Number(data);
    });

    yield put(setPoolsTvl(poolsTvl));
  } catch (error: unknown) {
    yield put(setPreError(error));
  } finally {
    yield put(setIsLoading(false));
  }
}

function* getPoolsTvlSaga(): Generator {
  yield takeLatest('POOLS_GET_POOLS_TLV', getPoolsTvlWorker);
}

export default getPoolsTvlSaga;
