import ContractFunctions from '@modules/common/types/ContractFunctions';
import { BigNumber } from '@ethersproject/bignumber';

const getClusterYieldBalance = (
  poolContract: ContractFunctions<BigNumber>,
  poolId: number,
): Promise<BigNumber> => {
  return poolContract.yieldBalance(poolId);
};

export default getClusterYieldBalance;
