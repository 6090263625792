import { BigNumber } from '@ethersproject/bignumber';

import ContractFunctions from '@modules/common/types/ContractFunctions';

const handlePoolDepositUnderlying = (
  poolContract: ContractFunctions<BigNumber>,
  poolId: number,
  amountList: BigNumber[],
  gasLimit: BigNumber,
): Promise<BigNumber> => {
  return poolContract.depositInUnderlying(poolId, amountList, { gasLimit });
};

export default handlePoolDepositUnderlying;
