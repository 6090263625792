import { BigNumber } from '@ethersproject/bignumber';

import ContractFunctions from '@modules/common/types/ContractFunctions';

const getTokenAllowance = (
  tokenContract: ContractFunctions<BigNumber>,
  poolAddress: string,
  account: string,
): Promise<BigNumber> => {
  return tokenContract.allowance(account, poolAddress);
};

export default getTokenAllowance;
