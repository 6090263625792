import { useWeb3React } from '@web3-react/core';
import { UAuthConnector } from '@uauth/web3-react';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import NETWORKS from '@configs/networks';
import Connector from '@modules/connections/types/Connector';
import icon from '@modules/connections/assets/unstopableDomains_logo.png';

const injected = new InjectedConnector({
  supportedChainIds: NETWORKS.map(({ chainId }) => chainId),
});

const walletconnect = new WalletConnectConnector({
  infuraId: process.env.REACT_APP_INFURA_ID,
  qrcode: true,
});

const uauth = new UAuthConnector({
  clientID: process.env.REACT_APP_UD_CLIENT_ID,
  clientSecret: process.env.REACT_APP_UD_CLIENT_SECRET,
  redirectUri: process.env.REACT_APP_UD_REDIRECT_URL,
  fallbackIssuer: process.env.REACT_APP_FALLBACK_ISSUER,
  shouldLoginWithRedirect: false,
  scope: 'openid wallet',

  connectors: { injected, walletconnect },
});

const UAuthConnect = (): (() => Promise<void>) => {
  const { activate } = useWeb3React();

  const connectAUTH = uauth;

  return () => activate(connectAUTH);
};

const Disconnect = (): (() => void) => {
  const { deactivate } = useWeb3React();

  return () => deactivate();
};

const connector: Connector = {
  type: 'uAuth',
  name: 'Unstoppable',
  icon,
  connect: UAuthConnect,
  disconnect: Disconnect,
};

export default connector;
