import { notification } from 'antd';

const errorOpenModalStake = (): void => {
  notification.error({
    message: 'Stake pool',
    description: 'Here is not available a pool that you try to stake.',
  });
};

export default errorOpenModalStake;
