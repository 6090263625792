import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import StoredPool from '@modules/pools/types/StoredPool';

import styles from './PoolUnstakeFormPrompt.module.scss';

const PoolUnstakeFormPrompt: FC<{
  name: StoredPool['name'];
}> = ({ name }) => {
  const { t } = useTranslation();

  return (
    <p className={styles.text}>
      {t('POOLS.POOL_UNSTAKE_FORM_PROMPT.TEXT', { name })}
    </p>
  );
};

export default PoolUnstakeFormPrompt;
