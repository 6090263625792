import { FC, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { Tooltip, notification } from 'antd';
import cn from 'classnames';

import { connectedSelector } from '@modules/connections/slices/connectionsSlice';
import injectedConnector from '@modules/connections/connectors/Injected';
import useMediaQuery from '@modules/layout/hooks/useMediaQuery';
import { useTypedSelector } from '@utils/store';
import LOADING_IMAGE from '@modules/common/assets/loading.svg';
import ADD_TOKEN from '@modules/common/assets/add_token.svg';

import styles from '@modules/common/components/AddToken/AddToken.module.scss';

const AddToken: FC<{
  tooltipClassName?: string;
  tokenStandart: string;
  tokenDecimals: number;
  tokenAddress: string;
  tokenSymbol: string;
  tokenIcon: string;
  className?: string;
}> = ({
  tooltipClassName,
  tokenStandart,
  tokenDecimals,
  tokenAddress,
  tokenSymbol,
  tokenIcon,
  className,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const { chainId, account } = useWeb3React();

  const connected = useTypedSelector(connectedSelector);

  const isMobile = useMediaQuery(991);

  const handleAddToken = useCallback(
    (
      type: string,
      decimals: number,
      address: string,
      symbol: string,
      image: string,
    ) => {
      if (!(chainId && account && connected) && !window?.ethereum) {
        notification.error({
          message: t('COMMON.ADD_TOKEN.ADD_ERROR'),
          description: t('COMMON.ADD_TOKEN.NOT_CONNECTED'),
        });
      }

      if (
        chainId &&
        account &&
        connected &&
        connected !== injectedConnector.type
      ) {
        notification.error({
          message: t('COMMON.ADD_TOKEN.ADD_ERROR'),
          description: t('COMMON.ADD_TOKEN.WRONG_WALLET'),
        });
      }

      if (
        (!(chainId && account && connected) && window?.ethereum) ||
        (chainId && account && connected === injectedConnector.type)
      ) {
        setIsLoading(true);

        window?.ethereum
          .request({
            method: 'wallet_watchAsset',
            params: {
              type,
              options: { address, symbol, decimals, image },
            },
          })
          .catch((error: Error) => {
            notification.error({
              message: t('COMMON.ADD_TOKEN.ADD_ERROR'),
              description: error.message || error,
            });
          })
          .finally(() => setIsLoading(false));
      }
    },
    [t, chainId, account, connected],
  );

  return (
    <button
      className={cn(
        styles.container,
        { [styles['is-loading']]: isLoading },
        className,
      )}
      disabled={isLoading}
      onClick={() => {
        handleAddToken(
          tokenStandart,
          tokenDecimals,
          tokenAddress,
          tokenSymbol,
          tokenIcon,
        );
      }}
      type="button"
    >
      <Tooltip
        overlayClassName={tooltipClassName}
        placement="topLeft"
        trigger={isMobile ? 'click' : 'hover'}
        title={t('COMMON.ADD_TOKEN.ADD_TOOLTIP', { symbol: tokenSymbol })}
      >
        <img
          className={styles.icon}
          height="30"
          width="28"
          src={ADD_TOKEN}
          alt={t('COMMON.ADD_TOKEN.ADD_TOOLTIP', { symbol: tokenSymbol })}
        />
      </Tooltip>

      {isLoading && (
        <img
          className={styles.loading}
          height="26"
          width="26"
          src={LOADING_IMAGE}
          alt=""
        />
      )}
    </button>
  );
};

AddToken.defaultProps = { tooltipClassName: undefined, className: undefined };

export default AddToken;
