import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import moment from 'moment';
import { Tooltip } from 'antd';
import cn from 'classnames';

import {
  isLoadingSelector,
  listSelector,
} from '@modules/underlying/slices/underlyingSlice';
import getTokenScanLink from '@modules/clusters/helpers/getTokenScanLink';
import useMediaQuery from '@modules/layout/hooks/useMediaQuery';
import StoredPool from '@modules/pools/types/StoredPool';
import { useTypedSelector } from '@utils/store';
import LOADING_IMAGE from '@modules/common/assets/loading.svg';
import UNDERLYING_LIST from '@configs/underlying';
import POOLS, { TimerRewards, Pool } from '@configs/pools';

import styles from '@modules/pools/components/PoolCardRewardList/PoolCardRewardList.module.scss';

const PoolCardRewardList: FC<{
  pendingRewards: string[];
  timerRewards: TimerRewards['list'];
  timestamp: TimerRewards['timestamp'];
  isClosed?: Pool['closed'];
  poolTokenAddress: StoredPool['tokenAddress'];
  poolSymbol: StoredPool['symbol'];
  poolType: Pool['type'];
  poolId: number;
}> = ({
  pendingRewards,
  timerRewards,
  timestamp,
  isClosed,
  poolTokenAddress,
  poolSymbol,
  poolType,
  poolId,
}) => {
  const { t } = useTranslation();

  const underlyingList = useTypedSelector(listSelector);
  const isLoading = useTypedSelector(isLoadingSelector);

  const { chainId } = useWeb3React();

  const isMobile = useMediaQuery(991);
  const isTablet = useMediaQuery(1599);

  const displayiedPoolSymbol: string = useMemo(() => {
    let symbol = '';

    if (underlyingList && pendingRewards && poolType === 3) {
      pendingRewards.forEach((address: string, index: number) => {
        if (index !== pendingRewards.length - 1) {
          symbol += `${underlyingList[address]?.symbol || ''}/`;
        } else {
          symbol += underlyingList[address]?.symbol || '';
        }
      });
    }

    return symbol;
  }, [underlyingList, pendingRewards, poolType]);

  return (
    <aside
      className={cn(styles.container, {
        [styles['is-loading']]: isLoading,
        [styles['is-closed']]: isClosed,
      })}
    >
      <h4 className={styles.title}>{t('POOLS.POOL_CARD_REWARD_LIST.TITLE')}</h4>

      <ul className={styles.list}>
        {pendingRewards?.map(
          (address: string) =>
            chainId &&
            POOLS[chainId] &&
            POOLS[chainId][address] &&
            POOLS[chainId][address][poolId]?.icon &&
            !POOLS[chainId][address][poolId]?.tokenIcon && (
              <li className={styles.listItem} key={address}>
                <Tooltip
                  overlayClassName={styles.listItemTooltip}
                  placement="topLeft"
                  trigger={isMobile ? 'click' : 'hover'}
                  title={poolSymbol}
                >
                  <img
                    className={styles.listItemImg}
                    height="18"
                    width="18"
                    src={POOLS[chainId][address][poolId].icon}
                    alt={poolSymbol}
                  />
                </Tooltip>
              </li>
            ),
        )}

        {pendingRewards?.map(
          (address: string) =>
            chainId &&
            POOLS[chainId] &&
            POOLS[chainId][address] &&
            POOLS[chainId][address][poolId]?.tokenIcon && (
              <li className={styles.listItem} key={address}>
                <Tooltip
                  overlayClassName={styles.listItemTooltip}
                  placement="topLeft"
                  trigger={isMobile ? 'click' : 'hover'}
                  title={poolSymbol}
                >
                  <img
                    className={styles.listItemImg}
                    height="18"
                    width="18"
                    src={POOLS[chainId][address][poolId].tokenIcon}
                    alt={poolSymbol}
                  />
                </Tooltip>
              </li>
            ),
        )}

        {poolType === 3 && underlyingList && pendingRewards && (
          <li className={styles.listItem}>
            <Tooltip
              overlayClassName={styles.listItemTooltip}
              placement="topLeft"
              trigger={isMobile ? 'click' : 'hover'}
              title={`${displayiedPoolSymbol} LP token`}
            >
              <a
                className={cn(
                  styles.listItemLink,
                  styles['listItemLink--double'],
                )}
                target="_blank"
                href={
                  chainId ? getTokenScanLink(chainId, poolTokenAddress) : ''
                }
                rel="noreferrer"
              >
                {pendingRewards.map(
                  (address: string) =>
                    UNDERLYING_LIST[underlyingList[address]?.symbol]?.icon && (
                      <span className={styles.listItemLinkItem} key={address}>
                        <img
                          className={cn(styles.listItemImg, {
                            [styles['is-finished']]:
                              timerRewards.includes(address) &&
                              timestamp > 0 &&
                              timestamp < moment().unix(),
                          })}
                          height="18"
                          width="18"
                          src={
                            UNDERLYING_LIST[underlyingList[address].symbol].icon
                          }
                          alt={underlyingList[address].symbol}
                        />
                      </span>
                    ),
                )}
              </a>
            </Tooltip>
          </li>
        )}

        {poolType !== 3 &&
          underlyingList &&
          pendingRewards?.map(
            (address: string) =>
              UNDERLYING_LIST[underlyingList[address]?.symbol]?.icon &&
              !(
                timerRewards.includes(address) &&
                timestamp > 0 &&
                timestamp < moment().unix()
              ) && (
                <li className={styles.listItem} key={address}>
                  <a
                    className={styles.listItemLink}
                    target="_blank"
                    href={chainId ? getTokenScanLink(chainId, address) : ''}
                    rel="noreferrer"
                  >
                    <Tooltip
                      overlayClassName={styles.listItemTooltip}
                      placement="topLeft"
                      trigger={isMobile ? 'click' : 'hover'}
                      title={underlyingList[address].symbol}
                    >
                      <img
                        className={cn(styles.listItemImg, {
                          [styles['is-finished']]:
                            timerRewards.includes(address) &&
                            timestamp > 0 &&
                            timestamp < moment().unix(),
                        })}
                        height="18"
                        width="18"
                        src={
                          UNDERLYING_LIST[underlyingList[address].symbol].icon
                        }
                        alt={underlyingList[address].symbol}
                      />
                    </Tooltip>
                  </a>
                </li>
              ),
          )}

        {poolType !== 3 &&
          underlyingList &&
          pendingRewards?.map(
            (address: string) =>
              UNDERLYING_LIST[underlyingList[address]?.symbol]?.icon &&
              timerRewards.includes(address) &&
              timestamp > 0 &&
              timestamp < moment().unix() && (
                <li className={styles.listItem} key={address}>
                  <a
                    className={styles.listItemLink}
                    target="_blank"
                    href={chainId ? getTokenScanLink(chainId, address) : ''}
                    rel="noreferrer"
                  >
                    <Tooltip
                      overlayClassName={styles.listItemTooltip}
                      placement="topLeft"
                      trigger={isMobile ? 'click' : 'hover'}
                      title={underlyingList[address].symbol}
                    >
                      <img
                        className={cn(styles.listItemImg, {
                          [styles['is-finished']]:
                            timerRewards.includes(address) &&
                            timestamp > 0 &&
                            timestamp < moment().unix(),
                        })}
                        height="18"
                        width="18"
                        src={
                          UNDERLYING_LIST[underlyingList[address].symbol].icon
                        }
                        alt={underlyingList[address].symbol}
                      />
                    </Tooltip>
                  </a>
                </li>
              ),
          )}

        {((isMobile && pendingRewards.length > 5) ||
          (!isMobile && isTablet && pendingRewards.length > 3)) && (
          <li>
            <span className={styles.listCounter}>
              {isMobile && `+${pendingRewards.length - 5}`}
              {!isMobile && isTablet && `+${pendingRewards.length - 3}`}
            </span>
          </li>
        )}
      </ul>

      {isLoading && (
        <img
          className={styles.loading}
          height="18"
          width="18"
          src={LOADING_IMAGE}
          alt=""
        />
      )}
    </aside>
  );
};

PoolCardRewardList.defaultProps = { isClosed: undefined };

export default PoolCardRewardList;
