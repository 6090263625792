import { FC, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import { Form } from 'antd';

import PoolUnstakeForm from '@modules/pools/components/PoolUnstakeForm';
import ActionModal from '@modules/common/components/ActionModal';
import StoredPool from '@modules/pools/types/StoredPool';
import { listSelector as listSelectorClusters } from '@modules/clusters/slices/clustersSlice';
import { getCurrencySelector } from '@modules/prices/slices/currencySlice';
import {
  confirmedListSelector,
  pendingListSelector,
  successListSelector,
  valueListSelector,
} from '@modules/pools/slices/unstakeSlice';
import { listSelector } from '@modules/underlying/slices/underlyingSlice';
import { getPrices } from '@modules/prices/slices/tokenPricesSlice';
import formatValueToken from '@modules/common/helpers/formatValueToken';
import formatValuePrice from '@modules/common/helpers/formatValuePrice';
import getTokenPrice from '@modules/common/helpers/getTokenPrice';
import trackGTMActions from '@modules/common/helpers/trackGTMActions';
import useMediaQuery from '@modules/layout/hooks/useMediaQuery';
import getPoolSymbol from '@modules/pools/helpers/getPoolSymbol';
import getPoolName from '@modules/pools/helpers/getPoolName';
import { useTypedSelector } from '@utils/store';
import constants from '@modules/pools/constants';
import UNSTAKE_ICON from '@modules/pools/assets/unstake_icon.svg';
import POOLS, { PoolUnderlying } from '@configs/pools';

const SELECT_NAME = 'select';
const INPUT_NAME = 'value';

const { useForm } = Form;

const defineModalWidth = (isMultyPool: boolean, isMobile: boolean): number => {
  if (isMultyPool && isMobile) {
    return 290;
  }

  if (isMultyPool && !isMobile) {
    return 586;
  }

  if (isMobile) {
    return 290;
  }

  return 455;
};

const getDefaultTitle = (poolType: number): string => {
  if (poolType === 1) {
    return 'POOLS.POOL_UNSTAKE.DEFAULT_TITLE_CLUSTER';
  }

  if (poolType === 4) {
    return 'POOLS.POOL_UNSTAKE.DEFAULT_TITLE_STABLE';
  }

  return 'POOLS.POOL_UNSTAKE.DEFAULT_TITLE';
};

const PoolUnstake: FC<{
  className?: string;
  underlying?: PoolUnderlying[];
  poolAddress: string;
  poolId: number;
  pool: StoredPool;
}> = ({ poolAddress, className, underlying, poolId, pool }) => {
  const [amountIndex, setAmountIndex] = useState<number>(0);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const isMobile = useMediaQuery(991);

  const underlyingList = useTypedSelector(listSelector);
  const confirmedList = useTypedSelector(confirmedListSelector);
  const pendingList = useTypedSelector(pendingListSelector);
  const successList = useTypedSelector(successListSelector);
  const valueList = useTypedSelector(valueListSelector);
  const priceList = useTypedSelector(getPrices);
  const clusters = useTypedSelector(listSelectorClusters);
  const currency = useTypedSelector(getCurrencySelector);

  const [form] = useForm();

  const dispatch = useDispatch();

  const { chainId, account, library } = useWeb3React<Web3Provider>();

  const successValue: string = useMemo(() => {
    let value = 0;

    valueList[poolAddress][poolId]?.forEach((item) => {
      if (item) {
        value += Number(formatUnits(item, pool.decimals));
      }
    });

    return formatValueToken(language, value);
  }, [poolAddress, poolId, language, valueList, pool]);

  const successPrice: string = useMemo(() => {
    let value = 0;

    valueList[poolAddress][poolId]?.forEach((item) => {
      if (item && priceList && chainId) {
        if (
          POOLS[chainId] &&
          POOLS[chainId][poolAddress] &&
          priceList[
            POOLS[chainId][poolAddress][poolId].asset ||
              POOLS[chainId][poolAddress][poolId].symbol ||
              pool.symbol
          ]
        ) {
          value += getTokenPrice(
            item,
            pool.decimals,
            priceList[
              POOLS[chainId][poolAddress][poolId].asset ||
                POOLS[chainId][poolAddress][poolId].symbol ||
                pool.symbol
            ],
          );
        } else if (clusters && chainId) {
          const cluster = Object.values(clusters).find(
            (storedCluster) => storedCluster.symbol === pool.symbol,
          );
          if (cluster && currency) {
            value += getTokenPrice(
              item,
              pool.decimals,
              getTokenPrice(
                cluster.price,
                cluster.decimals,
                currency[chainId].price,
              ),
            );
          }
        }
      }
    });

    return formatValuePrice(language, value);
  }, [
    valueList,
    poolAddress,
    poolId,
    language,
    priceList,
    chainId,
    pool.symbol,
    pool.decimals,
    clusters,
    currency,
  ]);

  const onClose = () => {
    form.resetFields();
  };

  const onUnstake = (args: { [key: string]: string }) => {
    if (
      account &&
      library &&
      chainId &&
      POOLS[chainId]?.[poolAddress]?.[poolId]
    ) {
      trackGTMActions(
        'UnStake',
        pool.symbol,
        pool.type || 1,
        chainId,
        account,
        'intent',
      );

      if (args[SELECT_NAME]) {
        setAmountIndex(Number(args[SELECT_NAME]));
      }

      dispatch({
        type: 'POOLS_UNSTAKE_POOL_REQUESTED',
        payload: {
          underlyingAddress:
            underlying && underlying[Number(args[SELECT_NAME])].address,
          poolDecimals: pool.decimals,
          poolAddress,
          poolABI: POOLS[chainId][poolAddress][poolId].poolABI,
          poolId,
          amount: underlying
            ? pool.userPoolAmount
                ?.mul(BigNumber.from(args[INPUT_NAME]))
                .div(BigNumber.from('100'))
            : parseUnits(args[INPUT_NAME], pool.decimals),
          library,
          account,
        },
      });
    }

    form.resetFields();
  };

  useEffect(() => {
    if (successList[poolAddress][poolId]) {
      trackGTMActions(
        'UnStake',
        pool.symbol,
        pool.type || 1,
        chainId,
        account,
        'fact',
      );
    }
  }, [
    account,
    chainId,
    pool.symbol,
    pool.type,
    poolAddress,
    poolId,
    successList,
  ]);

  return (
    <ActionModal
      dispatchObject={{
        type: 'POOLS_RESET_UNSTAKE_POOL_REQUESTED',
        payload: { poolAddress, poolId },
      }}
      defaultTitle={t(getDefaultTitle(pool.type || 0), {
        name: getPoolName(poolAddress, underlyingList, poolId, pool, chainId),
      })}
      successTitle={
        pool.type === 4
          ? `${t('POOLS.POOL_UNSTAKE.SUCCESS_FULL_INFO_TITLE', {
              poolName: getPoolName(
                poolAddress,
                underlyingList,
                poolId,
                pool,
                chainId,
              ),
              successTokenName:
                underlying &&
                underlyingList &&
                underlyingList[underlying[amountIndex].address]?.symbol,
            })}`
          : t('POOLS.POOL_UNSTAKE.SUCCESS_TITLE', {
              name: getPoolName(
                poolAddress,
                underlyingList,
                poolId,
                pool,
                chainId,
              ),
            })
      }
      successValue={pool.type === 4 ? undefined : successValue}
      successPrice={successPrice}
      successSymbol={getPoolSymbol(
        poolAddress,
        underlyingList,
        pool.symbol,
        poolId,
        chainId,
      )}
      buttonClassName={className}
      buttonTitle={t('POOLS.POOL_UNSTAKE.BUTTON_TITLE')}
      buttonColor="pink"
      isConfirmed={confirmedList[poolAddress][poolId]}
      isDisabled={!pool.userPoolAmount?.gt(BigNumber.from('0'))}
      isPending={pendingList[poolAddress][poolId]}
      isSuccess={successList[poolAddress][poolId]}
      modalAddress={`${poolId}_${poolAddress}`}
      modalPath={constants.MODAL_PATH_UNSTAKE}
      modalIcon={UNSTAKE_ICON}
      isEllipse
      modalWidth={defineModalWidth(pool.type === 4, isMobile)}
      platformIcon={
        (chainId && POOLS[chainId]?.[poolAddress]?.[poolId]?.platformIcon) ||
        undefined
      }
      isUnstakeModal
      onClose={onClose}
    >
      <PoolUnstakeForm
        userPoolAmount={pool.userPoolAmount}
        poolDecimals={pool.decimals}
        poolSymbol={
          (chainId && POOLS[chainId]?.[poolAddress]?.[poolId]?.asset) ||
          (chainId && POOLS[chainId]?.[poolAddress]?.[poolId]?.symbol) ||
          pool.symbol
        }
        poolName={getPoolName(
          poolAddress,
          underlyingList,
          poolId,
          pool,
          chainId,
        )}
        poolType={
          (chainId && POOLS[chainId]?.[poolAddress]?.[poolId]?.type) || 0
        }
        underlying={underlying}
        isLoading={
          !!(
            confirmedList[poolAddress][poolId] ||
            pendingList[poolAddress][poolId]
          )
        }
        selectName={SELECT_NAME}
        inputName={INPUT_NAME}
        onFinish={onUnstake}
        form={form}
      />
    </ActionModal>
  );
};

PoolUnstake.defaultProps = { underlying: undefined, className: undefined };

export default PoolUnstake;
