import { FC, useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import qs from 'query-string';
import { Modal } from 'antd';
import cn from 'classnames';

import Button from '@modules/common/components/Button';
import useMediaQuery from '@modules/layout/hooks/useMediaQuery';
import ActionModalDispatch from '@modules/common/types/ActionModalDispatch';
import LOADING_IMAGE from '@modules/common/assets/loading.svg';
import CHECK_MODAL from '@modules/common/assets/check_modal.svg';
import { Pool } from '@configs/pools';

import styles from './ActionModal.module.scss';

const ActionModal: FC<{
  dispatchObject: ActionModalDispatch;
  defaultTitle: string;
  successTitle: string;
  successValue?: string;
  successPrice?: string;
  successSymbol?: string;
  successSubTitle?: string;
  buttonClassName?: string;
  buttonTitle: string;
  buttonColor?: 'gradient' | 'polygon' | 'pink' | 'azure' | 'blue';
  buttonSize?: 'big' | 'small' | 'medium';
  isConfirmed: boolean | null;
  isDisabled?: boolean;
  isPending: boolean | null;
  isSuccess: boolean | null;
  modalAddress: string;
  modalPath: string;
  modalIcon: string;
  isIconGrey?: boolean;
  isEllipse?: boolean;
  modalWidth?: number;
  joinLeaveTheme?: boolean;
  platformIcon?: Pool['platformIcon'];
  isUnstakeModal?: boolean;
  onClose?: () => void;
}> = ({
  dispatchObject,
  defaultTitle,
  successTitle,
  successValue,
  successPrice,
  successSymbol,
  successSubTitle,
  buttonClassName,
  buttonTitle,
  buttonColor,
  buttonSize,
  isConfirmed,
  isDisabled,
  isPending,
  isSuccess,
  modalAddress,
  modalPath,
  modalIcon,
  isIconGrey,
  isEllipse,
  children,
  modalWidth,
  joinLeaveTheme,
  platformIcon,
  isUnstakeModal,
  onClose,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const { search } = useLocation<{ search: string }>();
  const history = useHistory();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { account } = useWeb3React();

  const isMobile = useMediaQuery(991);

  const handleClose = () => {
    history.push({ search: '' });

    setIsVisible(false);

    if (onClose) {
      onClose();
    }

    if (isSuccess) {
      dispatch(dispatchObject);
    }
  };

  const handleOpen = () => {
    history.push({ search: `${modalPath}=${modalAddress}` });
  };

  const handleOk = () => {
    dispatch(dispatchObject);
  };

  useEffect(() => {
    const searchList = qs.parse(search);

    if (searchList[modalPath] === modalAddress) {
      setIsVisible(true);
    }
  }, [modalAddress, modalPath, search]);

  return (
    <>
      <Button
        className={buttonClassName}
        disabled={isDisabled || !account}
        onClick={handleOpen}
        color={buttonColor}
        size={buttonSize}
        text={buttonTitle}
      />

      <Modal
        destroyOnClose
        bodyStyle={
          isMobile ? { padding: '35px 20px' } : { padding: '50px 35px' }
        }
        onCancel={handleClose}
        visible={isVisible}
        footer={false}
        width={modalWidth}
      >
        <div className={styles.modal}>
          <figure
            className={cn(
              styles['modal-figure'],
              !isUnstakeModal && !joinLeaveTheme && styles.closedBox,
              isUnstakeModal && !joinLeaveTheme && styles.openBox,
              {
                [styles['modal-ellipse']]:
                  (isEllipse && !isConfirmed && !isPending) || isSuccess,
              },
            )}
          >
            {isConfirmed || isPending ? (
              <img
                height="100"
                width="100"
                className={styles.loader}
                src={LOADING_IMAGE}
                alt={t('COMMON.ACTION_MODAL.PENDING_TITLE')}
              />
            ) : (
              <img
                className={cn(
                  styles.box,
                  isIconGrey && styles.grey,
                  joinLeaveTheme && styles.joinLeaveFigure,
                  joinLeaveTheme && isSuccess && styles.joinLeaveSuccess,
                )}
                src={modalIcon}
                alt={defaultTitle}
              />
            )}

            {isSuccess && (
              <img
                className={cn(
                  styles.checkIcon,
                  joinLeaveTheme && styles.joinLeaveCheck,
                )}
                src={CHECK_MODAL}
                alt={successTitle}
              />
            )}

            {platformIcon && !isConfirmed && !isPending && !isSuccess && (
              <img
                className={styles.stableIcon}
                src={platformIcon}
                alt="Stable pool icon"
              />
            )}
          </figure>

          {!(isSuccess && successValue) && (
            <h1
              className={cn(
                styles['modal-title'],
                isSuccess &&
                  joinLeaveTheme &&
                  isMobile &&
                  styles['modal-title--success'],
              )}
            >
              {!isConfirmed && !isPending && !isSuccess && defaultTitle}

              {isConfirmed && t('COMMON.ACTION_MODAL.CONFIRMED_TITLE')}

              {isPending && t('COMMON.ACTION_MODAL.PENDING_TITLE')}

              {isSuccess && successTitle}
            </h1>
          )}

          {isSuccess && successSubTitle && (
            <p className={styles['modal-pending']}>{successSubTitle}</p>
          )}

          {isSuccess && successValue && (
            <dl className={styles['modal-balance']}>
              <dt>{successTitle}</dt>
              <dd>
                {`${successValue} ${successSymbol || ''}`}{' '}
                <span>({successPrice})</span>
              </dd>
            </dl>
          )}

          {isSuccess && !successValue && successPrice && (
            <p className={styles['modal-successPrice']}>({successPrice})</p>
          )}

          {isPending && (
            <p className={styles['modal-pending']}>
              {t('COMMON.ACTION_MODAL.PENDING_TEXT')}
            </p>
          )}

          {!isConfirmed && !isPending && !isSuccess && children}

          {(isConfirmed || isPending) && (
            <Button
              onClick={handleClose}
              color="azure"
              text={t('COMMON.ACTION_MODAL.BUTTON_CANCEL')}
              className={styles['modal-btn']}
            />
          )}

          {isSuccess && (
            <Button
              onClick={handleOk}
              text={t('COMMON.ACTION_MODAL.BUTTON_OK')}
              className={styles['modal-btn']}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

ActionModal.defaultProps = {
  buttonClassName: undefined,
  successSubTitle: undefined,
  successSymbol: undefined,
  successValue: undefined,
  successPrice: undefined,
  buttonColor: 'gradient',
  buttonSize: 'big',
  isDisabled: undefined,
  isIconGrey: undefined,
  isEllipse: false,
  joinLeaveTheme: false,
  modalWidth: 455,
  platformIcon: undefined,
  isUnstakeModal: false,
  onClose: undefined,
};

export default ActionModal;
