import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { RootState } from '@utils/store';
import StoredChartData from '@modules/dhv/types/ChartData';

interface InitialState {
  isLoading: boolean;
  preError: unknown | null;
  error: unknown | null;
  chartData: StoredChartData[] | null;
}

const initialState: InitialState = {
  isLoading: false,
  preError: null,
  error: null,
  chartData: null,
};

const reducers = {
  setIsLoading(state: InitialState, action: PayloadAction<boolean>) {
    state.isLoading = action.payload;
  },
  setPreError(state: InitialState, action: PayloadAction<unknown | null>) {
    state.preError = action.payload;
  },
  setError(state: InitialState, action: PayloadAction<unknown | null>) {
    state.error = action.payload;
  },
  setChartData(
    state: InitialState,
    action: PayloadAction<StoredChartData[] | null>,
  ) {
    state.chartData = action.payload;
  },
};

const dhvChartPricesSlice = createSlice({
  name: 'dhvChartPrices',
  initialState,
  reducers,
});

export const isLoadingSelector = (state: RootState): boolean =>
  state.dhvChartPrices.isLoading;
export const preErrorSelector = (state: RootState): unknown | null =>
  state.dhvChartPrices.preError;
export const errorSelector = (state: RootState): unknown | null =>
  state.dhvChartPrices.error;
export const getDhvChart = (state: RootState): StoredChartData[] | null =>
  state.dhvChartPrices.chartData;

export const { setIsLoading, setPreError, setError, setChartData } =
  dhvChartPricesSlice.actions;

export default dhvChartPricesSlice.reducer;
