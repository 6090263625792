import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { Form } from 'antd';

import ClusterModalForm from '@modules/clusters/components/ClusterModalForm';
import ActionModal from '@modules/common/components/ActionModal';
import StoredCluster from '@modules/clusters/types/StoredCluster';
import { clusterAmountListSelector } from '@modules/clusters/slices/amountsSlice';
import { disassembleSelector } from '@modules/clusters/slices/commissionSlice';
import {
  confirmedListSelector,
  pendingListSelector,
  successListSelector,
} from '@modules/clusters/slices/disassembleSlice';
import getNetworkDecimals from '@modules/common/helpers/getNetworkDecimals';
import getNetworkSymbol from '@modules/common/helpers/getNetworkSymbol';
import trackGTMActions from '@modules/common/helpers/trackGTMActions';
import useMediaQuery from '@modules/layout/hooks/useMediaQuery';
import { useTypedSelector } from '@utils/store';
import constants from '@modules/clusters/constants';
import CLUSTERS from '@configs/clusters';

const { useForm } = Form;

const INPUT_NAME = 'disassembleAmount';

const ClusterDisassemble: FC<{
  clusterAddress: string;
  className: string;
  cluster: StoredCluster;
}> = ({ clusterAddress, className, cluster }) => {
  const { t } = useTranslation();

  const [disassembleClusterForm] = useForm();

  const dispatch = useDispatch();

  const { chainId, account, library } = useWeb3React<Web3Provider>();

  const clusterAmountList = useTypedSelector(clusterAmountListSelector);
  const commissionValue = useTypedSelector(disassembleSelector);
  const confirmedList = useTypedSelector(confirmedListSelector);
  const pendingList = useTypedSelector(pendingListSelector);
  const successList = useTypedSelector(successListSelector);

  const isMobile = useMediaQuery(991);

  const onClose = () => {
    disassembleClusterForm.resetFields();
  };

  const onDisassemble = (args: { [key: string]: string }) => {
    if (chainId && library && account) {
      trackGTMActions(
        'Cluster Disassemble',
        cluster.symbol,
        'cluster',
        chainId,
        account,
        'intent',
      );
      dispatch({
        type: 'CLUSTERS_DISASSEMBLE_CLUSTER_REQUESTED',
        payload: {
          clusterDecimals: cluster.decimals,
          clusterAddress,
          clusters: CLUSTERS[chainId],
          library,
          account,
          amount: args[INPUT_NAME],
        },
      });

      disassembleClusterForm.resetFields();
    }
  };

  useEffect(() => {
    if (successList[clusterAddress]) {
      trackGTMActions(
        'Cluster Disassemble',
        cluster.symbol,
        'cluster',
        chainId,
        account,
        'fact',
      );
    }
  }, [account, chainId, cluster.symbol, clusterAddress, successList]);

  return chainId ? (
    <ActionModal
      dispatchObject={{
        type: 'CLUSTERS_RESET_DISASSEMBLE_CLUSTER_REQUESTED',
        payload: { clusterAddress },
      }}
      defaultTitle={t('CLUSTERS.CLUSTER_DISASSEMBLE.DEFAULT_TITLE', {
        name: CLUSTERS[chainId][clusterAddress].name || cluster.name,
      })}
      successTitle={t('CLUSTERS.CLUSTER_DISASSEMBLE.SUCCESS_TITLE', {
        name: CLUSTERS[chainId][clusterAddress].name || cluster.name,
      })}
      buttonClassName={className}
      buttonTitle={t(
        isMobile
          ? 'CLUSTERS.CLUSTER_DISASSEMBLE.BUTTON_TITLE_MOBILE'
          : 'CLUSTERS.CLUSTER_DISASSEMBLE.BUTTON_TITLE',
      )}
      buttonColor="pink"
      isConfirmed={confirmedList[clusterAddress]}
      isPending={pendingList[clusterAddress]}
      isSuccess={successList[clusterAddress]}
      modalAddress={clusterAddress}
      modalPath={constants.MODAL_PATH_DISASSEMBLE}
      modalIcon={CLUSTERS[chainId][clusterAddress].icon}
      isIconGrey
      modalWidth={isMobile ? 290 : 455}
      joinLeaveTheme
      onClose={onClose}
    >
      <ClusterModalForm
        returnedWithComission
        returnedDecimals={getNetworkDecimals(chainId)}
        commissionValue={commissionValue}
        returnedSymbol={getNetworkSymbol(chainId)}
        clusterAmount={clusterAmountList[clusterAddress]?.ethFromAmount}
        inputDecimals={cluster.decimals}
        inputSymbol={cluster.symbol}
        submitName={t('CLUSTERS.CLUSTER_DISASSEMBLE.SUBMIT_NAME', {
          name: CLUSTERS[chainId][clusterAddress].name || cluster.name,
        })}
        isLoading={
          !!(confirmedList[clusterAddress] || pendingList[clusterAddress])
        }
        inputName={INPUT_NAME}
        formName="disassembleCluster"
        onFinish={onDisassemble}
        maxValue={cluster.userBalance}
        price={cluster.price}
        name={CLUSTERS[chainId][clusterAddress].name || cluster.name}
        form={disassembleClusterForm}
      />
    </ActionModal>
  ) : null;
};

export default ClusterDisassemble;
