import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import {
  CallEffect,
  PutEffect,
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { JsonRpcProvider } from '@ethersproject/providers';
import { Contract } from '@ethersproject/contracts';
import { BigNumber } from '@ethersproject/bignumber';

import {
  setIsLoading,
  setPreError,
  setData,
} from '@modules/dhv/slices/dhvSwapValuesSlice';
import getAmountsIn from '@modules/dhv/actions/getAmountsIn';
import { Swap } from '@configs/swap';

function* getAmountInWorker({
  payload,
}: PayloadAction<{
  transactionFee: Swap['transactionFee'];
  routerAddress: Swap['swapRouter'];
  routerABI: Swap['swapAbi'];
  isReverse: boolean;
  amountOut: BigNumber;
  swapPath: Swap['swapPath'];
  slippage: Swap['slippage'];
  library: JsonRpcProvider;
}>): Generator<CallEffect<unknown> | PutEffect<AnyAction>, void, never> {
  try {
    yield put(setIsLoading(true));

    const routerContract = new Contract(
      payload.routerAddress,
      payload.routerABI,
      payload.library,
    );

    const response: BigNumber[] = yield call(
      getAmountsIn,
      routerContract,
      payload.amountOut,
      payload.isReverse ? [...payload.swapPath].reverse() : payload.swapPath,
    );

    yield put(
      setData({
        minimumReceive: response[response.length - 1].sub(
          response[response.length - 1].mul(payload.slippage).div(100),
        ),
        networkFee: payload.isReverse
          ? response[response.length - 1].mul(payload.transactionFee).div(100)
          : response[0].mul(payload.transactionFee).div(100),
        amountOut: response[response.length - 1],
        amountIn: response[0],
      }),
    );
  } catch (error: unknown) {
    yield put(setPreError(error));
  } finally {
    yield put(setIsLoading(false));
  }
}

function* getAmountInSaga(): Generator {
  yield takeLatest('SWAP_GET_AMOUNT_IN', getAmountInWorker);
}

export default getAmountInSaga;
