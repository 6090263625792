import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { PutEffect, takeLatest, put } from 'redux-saga/effects';

import { setPreError, setError } from '@modules/pools/slices/poolsSlice';

function* setErrorPoolWorker({
  payload,
}: PayloadAction<{
  error: unknown;
}>): Generator<PutEffect<AnyAction>, void, never> {
  yield put(setPreError(null));
  yield put(setError(payload.error));
}

function* setErrorPoolSaga(): Generator {
  yield takeLatest('POOLS_SET_ERROR_POOL_REQUESTED', setErrorPoolWorker);
}

export default setErrorPoolSaga;
