import { FC, useMemo, useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import qs from 'query-string';
import cn from 'classnames';

import ErrorBlock from '@modules/common/components/ErrorBlock';
import NotFound from '@modules/common/components/NotFound';
import PoolCard from '@modules/pools/components/PoolCard';
import {
  isLoadingSelector,
  preErrorSelector,
  errorSelector,
  listSelector,
} from '@modules/pools/slices/poolsSlice';
import { preErrorSelector as preErrorSelectorPoolsTvl } from '@modules/pools/slices/poolsTvlSlices';
import { preErrorSelector as preErrorSelectorPoolsStats } from '@modules/pools/slices/poolsStatsSlice';
import { preErrorSelector as preErrorSelectorUnderlying } from '@modules/underlying/slices/underlyingSlice';
import { successListSelector as successListSelectorUnstake } from '@modules/pools/slices/unstakeSlice';
import { successListSelector as successListSelectorApprove } from '@modules/pools/slices/approveSlice';
import { successListSelector as successListSelectorStake } from '@modules/pools/slices/stakeSlice';
import { successListSelector as successListSelectorReward } from '@modules/pools/slices/rewardSlice';
import errorOpenModalUnstake from '@modules/pools/helpers/errorOpenModalUnstake';
import errorOpenModalReward from '@modules/pools/helpers/errorOpenModalReward';
import errorOpenModalStake from '@modules/pools/helpers/errorOpenModalStake';
import { useTypedSelector } from '@utils/store';
import UnderlyingItem from '@modules/underlying/types/UnderlyingItem';
import constantsCommon from '@modules/common/constants';
import constantsPools from '@modules/pools/constants';
import LOADING_IMAGE from '@modules/common/assets/loading.svg';
import CONTROLLERS from '@configs/controllers';
import POOLS, { PoolUnderlying, PoolList } from '@configs/pools';

import styles from '@modules/pools/components/StableList/StableList.module.scss';

const POOLS_TYPE = 4;
const HELP_LINK =
  'https://intercom.help/dehive/en/articles/5919915-what-is-stables';

const StableList: FC = () => {
  const [attemptPools, setAttemptPools] = useState<number>(0);
  const [attemptPoolsTvl, setAttemptPoolsTvl] = useState<number>(0);
  const [attemptPoolStats, setAttemptPoolStats] = useState<number>(0);
  const [attemptUnderlying, setAttemptUnderlying] = useState<number>(0);
  const [balanceAttempts, setBalanceAttempts] = useState<number>(0);

  const { t } = useTranslation();

  const { search } = useLocation<{ search: string }>();

  const dispatch = useDispatch();

  const { chainId, account, library } = useWeb3React<Web3Provider>();

  const isLoading = useTypedSelector(isLoadingSelector);
  const preError = useTypedSelector(preErrorSelector);
  const error = useTypedSelector(errorSelector);
  const pools = useTypedSelector(listSelector);
  const successListUnstake = useTypedSelector(successListSelectorUnstake);
  const successListApprove = useTypedSelector(successListSelectorApprove);
  const successListStake = useTypedSelector(successListSelectorStake);
  const successListReward = useTypedSelector(successListSelectorReward);
  const preErrorPoolsStats = useTypedSelector(preErrorSelectorPoolsStats);
  const preErrorPoolsTvl = useTypedSelector(preErrorSelectorPoolsTvl);
  const preErrorUnderlying = useTypedSelector(preErrorSelectorUnderlying);

  const getTokenPrices = useCallback(() => {
    dispatch({
      type: 'DHV_GET_TOKENS_PRICES_REQUESTED',
    });
  }, [dispatch]);

  useEffect(() => {
    getTokenPrices();

    const reGetTokenPrices = setInterval(
      () => getTokenPrices(),
      constantsCommon.GET_ITEMS_INTERVAL,
    );
    return clearInterval(reGetTokenPrices);
  }, [getTokenPrices]);

  useEffect(() => {
    if (preError && balanceAttempts < constantsCommon.GET_ITEMS_ATTEMPTS) {
      getTokenPrices();
      setBalanceAttempts(balanceAttempts + 1);
    }
    if (preError && balanceAttempts >= constantsCommon.GET_ITEMS_ATTEMPTS) {
      dispatch({
        type: 'DHV_SET_ERROR_TOKENS_PRICES_REQUESTED',
        payload: { error: preError },
      });
    }
  }, [balanceAttempts, dispatch, getTokenPrices, preError]);

  const getPoolsTvl = useCallback(() => {
    if (chainId) {
      const poolsSymbols = Object.values(POOLS[chainId])
        .map((element) => Object.values(element))
        .flat()
        .filter(({ type }) => type === POOLS_TYPE)
        .map(({ symbol }) => symbol);
      dispatch({
        type: 'POOLS_GET_POOLS_TLV',
        payload: { pools: poolsSymbols, chainId },
      });
    }
  }, [dispatch, chainId]);

  const getPoolsStats = useCallback(() => {
    if (POOLS && chainId) {
      dispatch({
        type: 'POOLS_GET_POOL_STATS',
        payload: {
          pools: Object.values(POOLS[chainId])
            .map((element) => Object.values(element))
            .flat()
            .filter(({ symbol }) => symbol)
            .map(({ symbol }) => symbol),
          chainId,
        },
      });
    }
  }, [dispatch, chainId]);

  const getPoolList = useCallback(() => {
    const stableList: PoolList = {};

    if (library && chainId && POOLS[chainId]) {
      Object.keys(POOLS[chainId])?.map((address: string) => {
        return Object.keys(POOLS[chainId][address])?.map((pid: string) => {
          if (POOLS[chainId][address][Number(pid)].type === POOLS_TYPE) {
            if (stableList[address]) {
              stableList[address][Number(pid)] =
                POOLS[chainId][address][Number(pid)];
            } else {
              stableList[address] = {
                [Number(pid)]: POOLS[chainId][address][Number(pid)],
              };
            }
          }

          return pid;
        });
      });
    }

    if (stableList && library && chainId) {
      dispatch({
        type: 'POOLS_GET_POOL_LIST_REQUESTED',
        payload: {
          pools: stableList,
          library,
          controller: CONTROLLERS[chainId],
          account,
        },
      });
    }
  }, [dispatch, chainId, library, account]);

  const getUnderlyingList = useCallback(() => {
    if (chainId && library && POOLS[chainId]) {
      const list: UnderlyingItem[] = [];

      Object.keys(POOLS[chainId]).forEach((address: string) => {
        Object.keys(POOLS[chainId][address]).forEach((pid: string) => {
          if (POOLS[chainId][address][Number(pid)].type === POOLS_TYPE) {
            const preList: string[] = Array.from(
              new Set<string>([
                ...(POOLS[chainId][address][Number(pid)].pendingRewards || []),
                ...(POOLS[chainId][address][Number(pid)].underlying?.map(
                  (item: PoolUnderlying) => {
                    return item.address;
                  },
                ) || []),
              ]),
            );

            preList?.forEach((item: string) => {
              list.push({
                underlyingABI: POOLS[chainId][address][Number(pid)].tokenABI,
                address: item,
              });
            });
          }
        });
      });

      dispatch({
        type: 'UNDERLYING_GET_UNDERLYING_LIST_REQUESTED',
        payload: { underlyingList: list, library, account },
      });
    }
  }, [dispatch, chainId, account, library]);

  useEffect(() => {
    getPoolsTvl();

    const reGetPoolsTvl = setInterval(
      () => getPoolsTvl(),
      constantsCommon.GET_ITEMS_INTERVAL,
    );

    return () => clearInterval(reGetPoolsTvl);
  }, [getPoolsTvl]);

  useEffect(() => {
    if (
      preErrorPoolsTvl &&
      attemptPoolsTvl < constantsCommon.GET_ITEMS_ATTEMPTS
    ) {
      getPoolsTvl();

      setAttemptPoolsTvl(attemptPoolsTvl + 1);
    }

    if (
      preErrorPoolsTvl &&
      attemptPoolsTvl >= constantsCommon.GET_ITEMS_ATTEMPTS
    ) {
      dispatch({
        type: 'POOLS_SET_ERROR_POOLS_TVL',
        payload: { error: preErrorPoolsTvl },
      });
    }
  }, [preErrorPoolsTvl, getPoolsTvl, dispatch, attemptPoolsTvl]);

  useEffect(() => {
    getPoolsStats();

    const reGetPoolsStats = setInterval(
      () => getPoolsStats(),
      constantsCommon.GET_ITEMS_INTERVAL,
    );

    return () => clearInterval(reGetPoolsStats);
  }, [getPoolsStats]);

  useEffect(() => {
    if (
      preErrorPoolsStats &&
      attemptPoolStats < constantsCommon.GET_ITEMS_ATTEMPTS
    ) {
      getPoolsStats();

      setAttemptPoolStats(attemptPoolStats + 1);
    }

    if (
      preErrorPoolsStats &&
      attemptPoolStats >= constantsCommon.GET_ITEMS_ATTEMPTS
    ) {
      dispatch({
        type: 'POOLS_SET_ERROR_POOLS_STATS',
        payload: { error: preErrorPoolsStats },
      });
    }
  }, [preErrorPoolsStats, getPoolsStats, dispatch, attemptPoolStats]);

  useEffect(() => {
    getPoolList();

    const reGetPoolList = setInterval(
      () => getPoolList(),
      constantsCommon.GET_ITEMS_INTERVAL,
    );

    return () => clearInterval(reGetPoolList);
  }, [getPoolList]);

  useEffect(() => {
    if (
      successListUnstake ||
      successListApprove ||
      successListStake ||
      successListReward
    ) {
      getPoolList();
    }
  }, [
    successListUnstake,
    getPoolList,
    successListApprove,
    successListStake,
    successListReward,
  ]);

  useEffect(() => {
    if (preError && attemptPools < constantsCommon.GET_ITEMS_ATTEMPTS) {
      getPoolList();

      setAttemptPools(attemptPools + 1);
    }

    if (preError && attemptPools >= constantsCommon.GET_ITEMS_ATTEMPTS) {
      dispatch({
        type: 'POOLS_SET_ERROR_POOL_REQUESTED',
        payload: { error: preError },
      });
    }
  }, [preError, getPoolList, dispatch, attemptPools]);

  useEffect(() => {
    getUnderlyingList();

    const reGetUnderlyingList = setInterval(
      () => getUnderlyingList(),
      constantsCommon.GET_ITEMS_INTERVAL,
    );

    return () => clearInterval(reGetUnderlyingList);
  }, [getUnderlyingList]);

  useEffect(() => {
    if (
      preErrorUnderlying &&
      attemptUnderlying < constantsCommon.GET_ITEMS_ATTEMPTS
    ) {
      getUnderlyingList();

      setAttemptUnderlying(attemptUnderlying + 1);
    }

    if (
      preErrorUnderlying &&
      attemptUnderlying >= constantsCommon.GET_ITEMS_ATTEMPTS
    ) {
      dispatch({
        type: 'UNDERLYING_SET_ERROR_UNDERLYING_REQUESTED',
        payload: { error: preErrorUnderlying },
      });
    }
  }, [preErrorUnderlying, getUnderlyingList, dispatch, attemptUnderlying]);

  useEffect(() => {
    const searchList = qs.parse(search);

    if (
      chainId &&
      POOLS[chainId] &&
      searchList[constantsPools.MODAL_PATH_UNSTAKE]
    ) {
      let availablePool = false;

      Object.keys(POOLS[chainId])?.forEach((address: string) => {
        Object.keys(POOLS[chainId][address])?.forEach((pid: string) => {
          if (
            POOLS[chainId][address][Number(pid)].type === POOLS_TYPE &&
            searchList[constantsPools.MODAL_PATH_UNSTAKE] ===
              `${pid}_${address}`
          ) {
            availablePool = true;
          }
        });
      });

      if (!availablePool) {
        errorOpenModalUnstake();
      }
    }

    if (
      chainId &&
      POOLS[chainId] &&
      searchList[constantsPools.MODAL_PATH_STAKE]
    ) {
      let availablePool = false;

      Object.keys(POOLS[chainId])?.forEach((address: string) => {
        Object.keys(POOLS[chainId][address])?.forEach((pid: string) => {
          if (
            POOLS[chainId][address][Number(pid)].type === POOLS_TYPE &&
            searchList[constantsPools.MODAL_PATH_STAKE] === `${pid}_${address}`
          ) {
            availablePool = true;
          }
        });
      });

      if (!availablePool) {
        errorOpenModalStake();
      }
    }

    if (
      chainId &&
      POOLS[chainId] &&
      searchList[constantsPools.MODAL_PATH_REWARD]
    ) {
      let availablePool = false;

      Object.keys(POOLS[chainId])?.forEach((address: string) => {
        Object.keys(POOLS[chainId][address])?.forEach((pid: string) => {
          if (
            POOLS[chainId][address][Number(pid)].type === POOLS_TYPE &&
            searchList[constantsPools.MODAL_PATH_REWARD] === `${pid}_${address}`
          ) {
            availablePool = true;
          }
        });
      });

      if (!availablePool) {
        errorOpenModalReward();
      }
    }
  }, [chainId, search]);

  const isPoolAvailable = useMemo(() => {
    if (chainId && POOLS[chainId]) {
      let availablePools = false;

      Object.keys(POOLS[chainId])?.forEach((address: string) => {
        Object.keys(POOLS[chainId][address])?.forEach((pid: string) => {
          if (POOLS[chainId][address][Number(pid)].type === POOLS_TYPE) {
            availablePools = true;
          }
        });
      });

      return availablePools;
    }

    return false;
  }, [chainId]);

  const isAvailablePoolStored = useMemo(() => {
    if (pools) {
      let availablePools = false;

      Object.keys(pools)?.forEach((address: string) => {
        Object.keys(pools[address])?.forEach((pid: string) => {
          if (pools[address][Number(pid)].type === POOLS_TYPE) {
            availablePools = true;
          }
        });
      });

      return availablePools;
    }

    return false;
  }, [pools]);

  return (
    <article className={styles.container}>
      <h1 className={styles.title}>{t('POOLS.STABLE_LIST.PAGE_TITLE')}</h1>

      <p className={styles.description}>
        <Trans
          i18nKey="POOLS.STABLE_LIST.PAGE_DESCRIPTION"
          components={[
            <a
              className={styles.descriptionLink}
              target="_blank"
              href={HELP_LINK}
              rel="noreferrer"
              key={0}
            >
              {' '}
            </a>,
          ]}
        />
      </p>

      {isPoolAvailable ? (
        <section
          className={cn(styles.list, { [styles['is-loading']]: isLoading })}
        >
          {pools &&
            Object.keys(pools)?.map((address: string) => {
              return Object.keys(pools[address])?.map((pid: string) => {
                if (
                  pools[address][Number(pid)].type === POOLS_TYPE &&
                  chainId &&
                  POOLS[chainId] &&
                  POOLS[chainId][address] &&
                  !POOLS[chainId][address][Number(pid)]?.closed
                ) {
                  return (
                    <PoolCard
                      poolGroupAddress={address}
                      className={styles.listItem}
                      poolId={Number(pid)}
                      pool={pools[address][Number(pid)]}
                      key={`${address}_${pid}`}
                    />
                  );
                }

                return null;
              });
            })}

          {pools &&
            Object.keys(pools)?.map((address: string) => {
              return Object.keys(pools[address])?.map((pid: string) => {
                if (
                  pools[address][Number(pid)].type === POOLS_TYPE &&
                  chainId &&
                  POOLS[chainId] &&
                  POOLS[chainId][address] &&
                  POOLS[chainId][address][Number(pid)]?.closed
                ) {
                  return (
                    <PoolCard
                      poolGroupAddress={address}
                      className={styles.listItem}
                      poolId={Number(pid)}
                      pool={pools[address][Number(pid)]}
                      key={`${address}_${pid}`}
                    />
                  );
                }

                return null;
              });
            })}

          {isLoading && (
            <img
              className={styles.loading}
              height="100"
              width="100"
              src={LOADING_IMAGE}
              alt=""
            />
          )}
        </section>
      ) : (
        <NotFound title={t('POOLS.NOT_FOUND.BLOCK_TITLE')} />
      )}

      {isPoolAvailable && !isAvailablePoolStored && error && (
        <ErrorBlock title={t('POOLS.ERROR_BLOCK.BLOCK_TITLE')} />
      )}
    </article>
  );
};

export default StableList;
