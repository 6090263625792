import i18next from 'i18next';
import languagedetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import locales from '../locales';

i18next
  .use(languagedetector)
  .use(initReactI18next) // passes i18next down to react-i18next
  .init({
    resources: locales,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    supportedLngs: Object.keys(locales),
  })
  .catch((error) => {
    throw error;
  });

export default i18next;
