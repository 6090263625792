import Portfolio from '@modules/portfolio/components/Portfolio';
import ICON from '@modules/portfolio/assets/pages/portfolio_logo.svg';

const page = {
  path: '/portfolio',
  menu: 1,
  icon: ICON,
  order: 6,
  title: 'PORTFOLIO.PORTFOLIO.NAV_LINK_NAME',
  component: Portfolio,
};

export default page;
