import { FC } from 'react';
import cn from 'classnames';

import TELEGRAM_ICON from '@modules/layout/assets/socials/telegram.svg';
import DISCORD_ICON from '@modules/layout/assets/socials/discord.svg';
import MEDIUM_ICON from '@modules/layout/assets/socials/medium.svg';
import TWITTER_ICON from '@modules/layout/assets/socials/twitter.svg';
import YOUTUBE_ICON from '@modules/layout/assets/socials/youtube.svg';
import REDDIT_ICON from '@modules/layout/assets/socials/reddit.svg';
import GITHUB_ICON from '@modules/layout/assets/socials/github.svg';

import styles from './SocialLinks.module.scss';

const socials = [
  { title: 'Telegram', icon: TELEGRAM_ICON, link: 'https://t.me/DeHive_chat' },
  {
    title: 'Discord',
    icon: DISCORD_ICON,
    link: 'https://discord.gg/RPFC5vw5HV',
  },
  { title: 'Medium', icon: MEDIUM_ICON, link: 'https://medium.com/dehive/' },
  {
    title: 'Twitter',
    icon: TWITTER_ICON,
    link: 'https://twitter.com/dehive_finance',
  },
  {
    title: 'YouTube',
    icon: YOUTUBE_ICON,
    link: 'https://www.youtube.com/channel/UCpiI8m7aalpZ2mzavUqt4RA',
  },
  {
    title: 'Reddit',
    icon: REDDIT_ICON,
    link: 'https://www.reddit.com/user/DeHive_Finance/',
  },
  {
    title: 'GitHub',
    icon: GITHUB_ICON,
    link: 'https://github.com/DeHive-finance',
  },
];

const SocialLinks: FC<{ className?: string }> = ({ className }) => {
  return (
    <aside className={cn(styles.container, className)}>
      <ul className={styles.list}>
        {socials.map(({ title, icon, link }) => (
          <li className={styles.listItem} key={title}>
            <a
              className={styles.listItemLink}
              target="_blank"
              title={title}
              href={link}
              rel="noreferrer"
            >
              <img className={styles.listItemIcon} src={icon} alt={title} />
            </a>
          </li>
        ))}
      </ul>
    </aside>
  );
};

SocialLinks.defaultProps = { className: undefined };

export default SocialLinks;
