import { FC, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { formatUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';

import FormSubmit from '@modules/common/components/FormSubmit';
import { listSelector } from '@modules/underlying/slices/underlyingSlice';
import { getPrices } from '@modules/prices/slices/tokenPricesSlice';
import { getCurrencySelector } from '@modules/prices/slices/currencySlice';
import formatValueToken from '@modules/common/helpers/formatValueToken';
import formatValuePrice from '@modules/common/helpers/formatValuePrice';
import getTokenPrice from '@modules/common/helpers/getTokenPrice';
import { useTypedSelector } from '@utils/store';
import UNDERLYING_LIST from '@configs/underlying';
import POOLS, { Pool } from '@configs/pools';

import styles from './PoolRewardForm.module.scss';

const { Item } = Form;

const PoolRewardForm: FC<{
  pendingRewards?: BigNumber[] | null;
  yieldBalance?: BigNumber | null;
  poolDecimals: number;
  poolAddress: string;
  poolSymbol: string;
  poolAsset: Pool['asset'];
  poolName: string;
  poolId: number;
  clusterPrice?: BigNumber | null;
  isLoading: boolean;
  inputName: string;
  onFinish: (values: { [key: string]: string }) => void;
  form: FormInstance;
}> = ({
  pendingRewards,
  yieldBalance,
  poolDecimals,
  poolAddress,
  poolSymbol,
  poolAsset,
  poolName,
  poolId,
  clusterPrice,
  isLoading,
  inputName,
  onFinish,
  form,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const underlyingList = useTypedSelector(listSelector);
  const currency = useTypedSelector(getCurrencySelector);
  const prices = useTypedSelector(getPrices);

  const { chainId } = useWeb3React();

  const totalValue = useMemo(() => {
    let value = BigNumber.from('0');

    if (yieldBalance) {
      value = value.add(yieldBalance);
    }

    if (pendingRewards) {
      pendingRewards.forEach((item) => {
        value = value.add(item);
      });
    }

    return value;
  }, [yieldBalance, pendingRewards]);

  const rewardPrice = useMemo(() => {
    if (clusterPrice && currency && chainId) {
      return (
        Number(formatUnits(clusterPrice, poolDecimals)) *
        currency[chainId].price
      );
    }

    if (prices && prices[poolAsset || poolSymbol]) {
      return prices[poolAsset || poolSymbol];
    }

    return null;
  }, [
    prices,
    poolSymbol,
    poolAsset,
    clusterPrice,
    poolDecimals,
    chainId,
    currency,
  ]);

  const totalPrice = useMemo(() => {
    let price = 0;

    if (prices && yieldBalance && rewardPrice) {
      price += getTokenPrice(yieldBalance, poolDecimals, rewardPrice);
    }

    if (
      chainId &&
      pendingRewards &&
      POOLS[chainId] &&
      POOLS[chainId][poolAddress] &&
      POOLS[chainId][poolAddress][poolId]
    ) {
      POOLS[chainId][poolAddress][poolId].pendingRewards?.forEach(
        (address, index: number) => {
          if (
            pendingRewards[index] &&
            underlyingList &&
            prices &&
            prices[underlyingList[address]?.symbol]
          ) {
            price += getTokenPrice(
              pendingRewards[index],
              poolDecimals,
              prices[underlyingList[address]?.symbol],
            );
          }
        },
      );
    }

    return price;
  }, [
    prices,
    yieldBalance,
    rewardPrice,
    chainId,
    pendingRewards,
    poolAddress,
    poolId,
    poolDecimals,
    underlyingList,
  ]);

  useEffect(() => {
    form.setFieldsValue({ [inputName]: formatUnits(totalValue, poolDecimals) });
  }, [form, inputName, totalValue, poolDecimals]);

  return (
    <Form
      initialValues={{ [inputName]: '0' }}
      onFinish={onFinish}
      form={form}
      name="rewardPool"
      className={styles.poolRewardForm}
    >
      <Item hidden name={inputName}>
        <input hidden type="text" />
      </Item>

      <ul className={styles.rewardsList}>
        {yieldBalance?.gt(BigNumber.from('0')) && (
          <li className={styles.item}>
            {chainId &&
              POOLS[chainId] &&
              POOLS[chainId][poolAddress] &&
              POOLS[chainId][poolAddress][poolId] && (
                <div className={styles.icon}>
                  <img
                    src={
                      POOLS[chainId][poolAddress][poolId].tokenIcon ||
                      POOLS[chainId][poolAddress][poolId].icon
                    }
                    alt={poolName}
                  />
                </div>
              )}

            <b className={styles.balance}>
              {yieldBalance && poolDecimals
                ? formatValueToken(
                    language,
                    Number(formatUnits(yieldBalance, poolDecimals)),
                  )
                : '-'}
            </b>

            <span className={styles.rewardPrice}>
              (
              {rewardPrice && yieldBalance && poolDecimals
                ? formatValuePrice(
                    language,
                    getTokenPrice(yieldBalance, poolDecimals, rewardPrice),
                  )
                : '-'}
              )
            </span>
          </li>
        )}

        {chainId &&
          pendingRewards &&
          POOLS[chainId] &&
          POOLS[chainId][poolAddress] &&
          POOLS[chainId][poolAddress][poolId] &&
          POOLS[chainId][poolAddress][poolId].pendingRewards?.map(
            (address, index: number) =>
              pendingRewards[index]?.gt(BigNumber.from('0')) && (
                <li key={address} className={styles.item}>
                  {underlyingList &&
                    underlyingList[address]?.symbol &&
                    UNDERLYING_LIST[underlyingList[address]?.symbol]?.icon && (
                      <div className={styles.icon}>
                        <img
                          src={
                            UNDERLYING_LIST[underlyingList[address].symbol].icon
                          }
                          alt={poolName}
                        />
                      </div>
                    )}

                  <b className={styles.balance}>
                    {pendingRewards[index] &&
                    underlyingList &&
                    underlyingList[address]?.decimals
                      ? formatValueToken(
                          language,
                          Number(
                            formatUnits(
                              pendingRewards[index],
                              underlyingList[address].decimals,
                            ),
                          ),
                        )
                      : '-'}
                  </b>

                  <span className={styles.rewardPrice}>
                    (
                    {pendingRewards[index] &&
                    underlyingList &&
                    prices &&
                    prices[underlyingList[address]?.symbol]
                      ? formatValuePrice(
                          language,
                          getTokenPrice(
                            pendingRewards[index],
                            poolDecimals,
                            prices[underlyingList[address]?.symbol],
                          ),
                        )
                      : '-'}
                    )
                  </span>
                </li>
              ),
          )}
      </ul>

      <dl className={styles.totalReward}>
        <dt>{t('POOLS.POOLS_REWARD_FORM.TOTAL')}</dt>
        <dd>{formatValuePrice(language, totalPrice)}</dd>
      </dl>

      <FormSubmit
        isDisabled={!totalValue.gt(BigNumber.from('0'))}
        isLoading={isLoading}
        name={t('POOLS.POOLS_REWARD_FORM.CLAIM', {
          value: formatValuePrice(language, totalPrice),
        })}
      />
    </Form>
  );
};

PoolRewardForm.defaultProps = {
  pendingRewards: undefined,
  yieldBalance: undefined,
  clusterPrice: undefined,
};

export default PoolRewardForm;
