import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import {
  CallEffect,
  PutEffect,
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { JsonRpcProvider } from '@ethersproject/providers';
import { ContractInterface, Contract } from '@ethersproject/contracts';
import { BigNumber } from '@ethersproject/bignumber';

import {
  setIsLoading,
  setPreError,
  setError,
  setList,
} from '@modules/underlying/slices/underlyingSlice';
import getTokenName from '@modules/common/actions/getTokenName';
import getTokenSymbol from '@modules/common/actions/getTokenSymbol';
import getTokenDecimals from '@modules/common/actions/getTokenDecimals';
import getTokenBalanceOf from '@modules/common/actions/getTokenBalanceOf';
import StoredUnderlyingList from '@modules/underlying/types/StoredUnderlyingList';
import UnderlyingItem from '@modules/underlying/types/UnderlyingItem';

type ReturnedUnderlying = [string, string, string, number, BigNumber | null];

const getUnderlying = (
  underlyingAddress: string,
  underlyingABI: ContractInterface,
  provider: JsonRpcProvider,
  account?: string,
): Promise<ReturnedUnderlying> => {
  const underlyingContract = new Contract(
    underlyingAddress,
    underlyingABI,
    account ? provider.getSigner(account) : provider,
  );

  return Promise.all([
    underlyingAddress,
    getTokenName(underlyingContract),
    getTokenSymbol(underlyingContract),
    getTokenDecimals(underlyingContract),
    account ? getTokenBalanceOf(underlyingContract, account) : null,
  ]);
};

const getUnderlyingList = (
  underlyingList: UnderlyingItem[],
  provider: JsonRpcProvider,
  account?: string,
) => {
  const getUnderlyingListInfo: Promise<ReturnedUnderlying>[] = [];

  underlyingList.forEach(({ address, underlyingABI }) => {
    getUnderlyingListInfo.push(
      getUnderlying(address, underlyingABI, provider, account),
    );
  });

  return Promise.all(getUnderlyingListInfo);
};

function* getUnderlyingListWorker({
  payload,
}: PayloadAction<{
  underlyingList: UnderlyingItem[];
  library: JsonRpcProvider;
  account?: string;
}>): Generator<
  CallEffect<ReturnedUnderlying[]> | PutEffect<AnyAction>,
  void,
  never
> {
  try {
    yield put(setIsLoading(true));
    yield put(setPreError(null));
    yield put(setError(null));

    const returnedUnderlyingList: ReturnedUnderlying[] = yield call(
      getUnderlyingList,
      payload.underlyingList,
      payload.library,
      payload.account,
    );

    const underlyingListResult: StoredUnderlyingList = {};

    returnedUnderlyingList.map((value: ReturnedUnderlying) => {
      underlyingListResult[value[0]] = {
        name: value[1],
        symbol: value[2],
        decimals: value[3],
        userBalance: value[4],
      };

      return value;
    });

    yield put(setList(underlyingListResult));
  } catch (error) {
    yield put(setPreError(error));
  } finally {
    yield put(setIsLoading(false));
  }
}

function* getUnderlyingListSaga(): Generator {
  yield takeLatest(
    'UNDERLYING_GET_UNDERLYING_LIST_REQUESTED',
    getUnderlyingListWorker,
  );
}

export default getUnderlyingListSaga;
