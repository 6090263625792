import TagManager from 'react-gtm-module';
import NETWORKS from '@configs/networks';

const trackGTMActions = (
  event: string,
  clusterName: string,
  clusterType: string | number,
  chainId: number | undefined,
  wallet: string | null | undefined,
  status: string,
): void => {
  const eventAction = NETWORKS.find(
    (netInterface) => netInterface.chainId === chainId,
  )?.name;

  TagManager.dataLayer({
    dataLayer: {
      event: 'event',
      eventCategory: `${clusterType} | ${clusterName} | ${status}`,
      eventAction: `${
        eventAction && chainId
          ? `(${chainId}) ${eventAction} |
           ${window.location.pathname} |
            ${event}`
          : `${chainId || ''}| ${window.location.pathname} | ${event}`
      }`,
      eventLabel: wallet,
    },
  });
};

export default trackGTMActions;
