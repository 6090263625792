// Cluster slices:
import disassembleSlice from '@modules/clusters/slices/disassembleSlice';
import commissionSlice from '@modules/clusters/slices/commissionSlice';
import assembleSlice from '@modules/clusters/slices/assembleSlice';
import clustersSlice from '@modules/clusters/slices/clustersSlice';
import amountsSlice from '@modules/clusters/slices/amountsSlice';
// Connection slices:
import connectionsSlice from '@modules/connections/slices/connectionsSlice';
// DHV slices:
import dhvSwapTransactionSlice from '@modules/dhv/slices/dhvSwapTransactionSlice';
import dhvSwapAllowanceSlice from '@modules/dhv/slices/dhvSwapAllowanceSlice';
import dhvSwapBalancesSlice from '@modules/dhv/slices/dhvSwapBalancesSlice';
import dhvSwapValuesSlice from '@modules/dhv/slices/dhvSwapValuesSlice';
import dhvChartPricesSlice from '@modules/dhv/slices/dhvChartPricesSlice';
// Layout slices:
import tvlSlice from '@modules/layout/slices/tvlSlice';
// Main slices:
import productsSlice from '@modules/main/slices/productsSlice';
// Pool slices:
import poolsStatsSlice from '@modules/pools/slices/poolsStatsSlice';
import poolsTvlSlice from '@modules/pools/slices/poolsTvlSlices';
import approveSlice from '@modules/pools/slices/approveSlice';
import unstakeSlice from '@modules/pools/slices/unstakeSlice';
import rewardSlice from '@modules/pools/slices/rewardSlice';
import stakeSlice from '@modules/pools/slices/stakeSlice';
import poolsSlice from '@modules/pools/slices/poolsSlice';
// Prices slices:
import tokensPricesSlice from '@modules/prices/slices/tokenPricesSlice';
import currencySlice from '@modules/prices/slices/currencySlice';
// Underlying slices:
import underlyingSlice from '@modules/underlying/slices/underlyingSlice';

const rootReducer = {
  // Cluster slices:
  disassemble: disassembleSlice,
  commission: commissionSlice,
  assemble: assembleSlice,
  clusters: clustersSlice,
  amounts: amountsSlice,
  // Connection slices:
  connections: connectionsSlice,
  // DHV slices:
  dhvSwapTransaction: dhvSwapTransactionSlice,
  dhvSwapAllowance: dhvSwapAllowanceSlice,
  dhvSwapBalances: dhvSwapBalancesSlice,
  dhvSwapValues: dhvSwapValuesSlice,
  dhvChartPrices: dhvChartPricesSlice,
  // Layout slices:
  tvl: tvlSlice,
  // Main slices:
  products: productsSlice,
  // Pool slices:
  poolsStats: poolsStatsSlice,
  poolsTvl: poolsTvlSlice,
  stakeApprove: approveSlice,
  unstake: unstakeSlice,
  reward: rewardSlice,
  stake: stakeSlice,
  pools: poolsSlice,
  // Prices slices:
  tokensPrices: tokensPricesSlice,
  currency: currencySlice,
  // Underlying slices:
  underlying: underlyingSlice,
};

export default rootReducer;
