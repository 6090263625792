import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { formatUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import cn from 'classnames';

import { getCurrencySelector } from '@modules/prices/slices/currencySlice';
import { listSelector } from '@modules/underlying/slices/underlyingSlice';
import { getPrices } from '@modules/prices/slices/tokenPricesSlice';
import formatValuePrice from '@modules/common/helpers/formatValuePrice';
import getTokenPrice from '@modules/common/helpers/getTokenPrice';
import StoredPool from '@modules/pools/types/StoredPool';
import { useTypedSelector } from '@utils/store';
import { Pool } from '@configs/pools';

import styles from '@modules/pools/components/PoolCardRewardValue/PoolCardRewardValue.module.scss';

const PoolCardRewardValue: FC<{
  storedPendingRewards?: StoredPool['pendingRewards'];
  pendingRewards?: Pool['pendingRewards'];
  userPoolAmount: StoredPool['userPoolAmount'];
  yieldBalance: StoredPool['yieldBalance'];
  clusterPrice: StoredPool['clusterPrice'];
  poolDecimals: StoredPool['decimals'];
  poolSymbol: StoredPool['symbol'];
  poolAsset: Pool['asset'];
}> = ({
  storedPendingRewards,
  pendingRewards,
  userPoolAmount,
  yieldBalance,
  clusterPrice,
  poolDecimals,
  poolSymbol,
  poolAsset,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const underlyingList = useTypedSelector(listSelector);
  const currency = useTypedSelector(getCurrencySelector);
  const prices = useTypedSelector(getPrices);

  const { chainId, account } = useWeb3React();

  const totalPrice = useMemo(() => {
    let yieldPrice = 0;
    let price = 0;

    if (clusterPrice && currency && chainId) {
      yieldPrice =
        Number(formatUnits(clusterPrice, poolDecimals)) *
        currency[chainId].price;
    }

    if (prices && prices[poolAsset || poolSymbol]) {
      yieldPrice = prices[poolAsset || poolSymbol];
    }

    if (prices && yieldBalance && yieldPrice) {
      price += getTokenPrice(yieldBalance, poolDecimals, yieldPrice);
    }

    pendingRewards?.forEach((address, index: number) => {
      if (
        storedPendingRewards &&
        storedPendingRewards[index] &&
        underlyingList &&
        prices &&
        prices[underlyingList[address]?.symbol]
      ) {
        price += getTokenPrice(
          storedPendingRewards[index],
          underlyingList[address]?.decimals,
          prices[underlyingList[address]?.symbol],
        );
      }
    });

    return price;
  }, [
    storedPendingRewards,
    pendingRewards,
    underlyingList,
    yieldBalance,
    clusterPrice,
    poolDecimals,
    poolSymbol,
    poolAsset,
    currency,
    prices,
    chainId,
  ]);

  return (
    <dl className={styles.container}>
      <dt
        className={cn(styles.title, {
          [styles['is-empty']]:
            !userPoolAmount || userPoolAmount.lte(BigNumber.from('0')),
        })}
      >
        {t('POOLS.POOL_CARD_REWARD_VALUE.TITLE')}
      </dt>

      <dd
        className={cn(styles.value, {
          [styles['is-empty']]:
            !userPoolAmount || userPoolAmount.lte(BigNumber.from('0')),
        })}
      >
        {account ? formatValuePrice(language, totalPrice) : '-'}
      </dd>
    </dl>
  );
};

PoolCardRewardValue.defaultProps = {
  storedPendingRewards: undefined,
  pendingRewards: undefined,
};

export default PoolCardRewardValue;
