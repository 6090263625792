import {
  call,
  CallEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import { AnyAction } from '@reduxjs/toolkit';

import {
  setCurrency,
  setError,
  setIsLoading,
  setPreError,
} from '@modules/prices/slices/currencySlice';
import StoredCurrencyList from '@modules/prices/types/StoredCurrencyList';
import ResponseData from '@modules/prices/types/ResponseData';
import NETWORKS from '@configs/networks';

const API = process.env.REACT_APP_API || 'http://localhost:1234/api';

const getCurrency = (): Promise<AxiosResponse<ResponseData[]>> => {
  return axios.get<ResponseData[]>(`${API}/prices/currency`);
};

function* getCurrencyWorker(): Generator<
  CallEffect<AxiosResponse<ResponseData[]>> | PutEffect<AnyAction>,
  void,
  never
> {
  try {
    yield put(setIsLoading(true));
    yield put(setPreError(null));
    yield put(setError(null));

    const { data }: { data: ResponseData[] } = yield call(getCurrency);

    const storedCurrencyList: StoredCurrencyList = {};

    data.forEach(({ symbol, priceUSD }) => {
      const network = NETWORKS.find(
        (netInterface) =>
          netInterface.nativeCurrency.symbol ===
          (symbol === 'BSC' ? 'BNB' : symbol),
      );

      if (network) {
        storedCurrencyList[network.chainId] = {
          symbol: symbol === 'BSC' ? 'BNB' : symbol,
          price: Number(priceUSD),
        };
      }
    });

    yield put(setCurrency(storedCurrencyList));
  } catch (error: unknown) {
    yield put(setPreError(error));
  } finally {
    yield put(setIsLoading(false));
  }
}

function* getCurrencySaga(): Generator {
  yield takeLatest('PRICES_GET_CURRENCY', getCurrencyWorker);
}

export default getCurrencySaga;
