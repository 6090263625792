import { notification } from 'antd';

import ConnectorsDisconnects from '@modules/connections/types/ConnectorsDisconnects';

const onDisconnect = (
  disconnections: ConnectorsDisconnects,
  connectorType: string,
): void => {
  const disconnect = disconnections[connectorType];

  if (disconnect) {
    localStorage.removeItem(
      `settings-connection-network-${
        process.env.REACT_APP_NETWORK_ENVIRONMENT || 'test'
      }`,
    );
    localStorage.removeItem(
      `settings-connection-name-${
        process.env.REACT_APP_NETWORK_ENVIRONMENT || 'test'
      }`,
    );

    disconnect()?.catch((err: Error) => {
      notification.error({
        message: 'Disconnection error',
        description: err.message || err,
      });
    });
  }
};

export default onDisconnect;
