import TagManager from 'react-gtm-module';
import NETWORKS from '@configs/networks';

const trackGTMActionsSwap = (
  purchasedToken: string,
  where: string,
  chainId: number | undefined,
  wallet: string | undefined | null,
  status: string,
): void => {
  const eventAction = NETWORKS.find(
    (netInterface) => netInterface.chainId === chainId,
  )?.name;

  TagManager.dataLayer({
    dataLayer: {
      event: 'event',
      eventCategory: `Swap | ${purchasedToken} | ${status}`,
      eventAction: `${
        eventAction && chainId
          ? `(${chainId}) ${eventAction} |
           ${window.location.pathname} |
            Buy ${where}`
          : `${chainId || ''} | ${window.location.pathname} | Buy ${where}`
      }`,
      eventLabel: wallet,
    },
  });
};

export default trackGTMActionsSwap;
