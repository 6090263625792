import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { poolsApyAprSelector } from '@modules/pools/slices/poolsStatsSlice';
import formatValuePercentage from '@modules/common/helpers/formatValuePercentage';
import { useTypedSelector } from '@utils/store';

import styles from '@modules/clusters/components/ClusterCardStats/ClusterCardStats.module.scss';

const ClusterCardStats: FC<{ symbol: string }> = ({ symbol }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const clusterStats = useTypedSelector(poolsApyAprSelector);

  return (
    <dl className={styles.container}>
      <dt className={styles.title}>{t('CLUSTERS.CLUSTER_CARD_STATS.TITLE')}</dt>
      <dd className={styles.value}>
        {clusterStats && clusterStats[symbol] && clusterStats[symbol]?.apy
          ? formatValuePercentage(language, clusterStats[symbol].apy)
          : '-%'}
      </dd>
    </dl>
  );
};

export default ClusterCardStats;
