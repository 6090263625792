import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@utils/store';
import StoredPoolsTvl from '@modules/pools/types/poolsTvl';

interface InitialState {
  isLoading: boolean;
  preError: unknown | null;
  error: unknown | null;
  list: StoredPoolsTvl | null;
}

const initialState: InitialState = {
  isLoading: false,
  preError: null,
  error: null,
  list: null,
};

const reducers = {
  setIsLoading(state: InitialState, action: PayloadAction<boolean>) {
    state.isLoading = action.payload;
  },
  setPreError(state: InitialState, action: PayloadAction<unknown | null>) {
    state.preError = action.payload;
  },
  setError(state: InitialState, action: PayloadAction<unknown | null>) {
    state.error = action.payload;
  },
  setPoolsTvl(state: InitialState, action: PayloadAction<StoredPoolsTvl>) {
    state.list = {
      ...state.list,
      ...action.payload,
    };
  },
};

const poolsTvlSlices = createSlice({
  name: 'poolsTvl',
  initialState,
  reducers,
});

export const isLoadingSelector = (state: RootState): boolean =>
  state.poolsTvl.isLoading;
export const preErrorSelector = (state: RootState): unknown | null =>
  state.poolsTvl.preError;
export const errorSelector = (state: RootState): unknown | null =>
  state.poolsTvl.error;
export const getPoolsTvl = (state: RootState): StoredPoolsTvl | null =>
  state.poolsTvl.list;

export const { setIsLoading, setPreError, setError, setPoolsTvl } =
  poolsTvlSlices.actions;

export default poolsTvlSlices.reducer;
