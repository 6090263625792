import ContractFunctions from '@modules/common/types/ContractFunctions';
import { BigNumber } from '@ethersproject/bignumber';

const getClusterRate = (
  poolContract: ContractFunctions<BigNumber>,
  clusterAddress: string,
): Promise<BigNumber> => {
  return poolContract.clusterRate(clusterAddress);
};

export default getClusterRate;
