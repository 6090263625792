import { JsonRpcProvider } from '@ethersproject/providers';
import { ContractInterface, Contract } from '@ethersproject/contracts';
import { BigNumber } from '@ethersproject/bignumber';

import getTokenBalanceOf from '@modules/common/actions/getTokenBalanceOf';
import getTokenDecimals from '@modules/common/actions/getTokenDecimals';
import getTokenSymbol from '@modules/common/actions/getTokenSymbol';
import getTokenName from '@modules/common/actions/getTokenName';
import constantsPools from '@modules/pools/constants';

const getPoolAsset = (
  poolTokenAddress: string,
  poolTokenABI: ContractInterface,
  provider: JsonRpcProvider,
  account?: string,
): Promise<[string, string, number, BigNumber | null]> => {
  const tokenContract = new Contract(poolTokenAddress, poolTokenABI, provider);

  if (poolTokenAddress === constantsPools.NOMO_POOL.address)
    return Promise.resolve(constantsPools.NOMO_POOL.assets);

  return Promise.all([
    getTokenName(tokenContract),
    getTokenSymbol(tokenContract),
    getTokenDecimals(tokenContract),
    account ? getTokenBalanceOf(tokenContract, account) : null,
  ]);
};

export default getPoolAsset;
