import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './PageNotFound.module.scss';

const PageNotFound: FC = () => {
  const { t } = useTranslation();

  return (
    <article className={styles.container}>
      <h1 className={styles.title}>{t('STATIC.PAGE_NOT_FOUND.PAGE_TITLE')}</h1>

      <h2 className={styles.subtitle}>
        {t('STATIC.PAGE_NOT_FOUND.PAGE_DESCRIPTION')}
      </h2>
    </article>
  );
};

export default PageNotFound;
