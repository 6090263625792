import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { BigNumber } from '@ethersproject/bignumber';
import { Form, notification } from 'antd';

import ClusterModalForm from '@modules/clusters/components/ClusterModalForm';
import ActionModal from '@modules/common/components/ActionModal';
import StoredCluster from '@modules/clusters/types/StoredCluster';
import { clusterAmountListSelector } from '@modules/clusters/slices/amountsSlice';
import {
  isLoadingAssembleSelector,
  assembleSelector,
} from '@modules/clusters/slices/commissionSlice';
import {
  confirmedListSelector,
  pendingListSelector,
  successListSelector,
} from '@modules/clusters/slices/assembleSlice';
import getNetworkDecimals from '@modules/common/helpers/getNetworkDecimals';
import getNetworkSymbol from '@modules/common/helpers/getNetworkSymbol';
import trackGTMActions from '@modules/common/helpers/trackGTMActions';
import useMediaQuery from '@modules/layout/hooks/useMediaQuery';
import { useTypedSelector } from '@utils/store';
import constants from '@modules/clusters/constants';
import CONTROLLERS from '@configs/controllers';
import CLUSTERS from '@configs/clusters';

const { useForm } = Form;

const INPUT_NAME = 'assembleAmount';

const ClusterAssemble: FC<{
  clusterAddress: string;
  className: string;
  cluster: StoredCluster;
}> = ({ clusterAddress, className, cluster }) => {
  const [userBalance, setUserBalance] = useState<BigNumber | null | undefined>(
    null,
  );

  const { t } = useTranslation();

  const [assembleClusterForm] = useForm();

  const dispatch = useDispatch();

  const { chainId, account, library } = useWeb3React<Web3Provider>();

  const isLoadingCommission = useTypedSelector(isLoadingAssembleSelector);
  const clusterAmountList = useTypedSelector(clusterAmountListSelector);
  const commissionValue = useTypedSelector(assembleSelector);
  const confirmedList = useTypedSelector(confirmedListSelector);
  const pendingList = useTypedSelector(pendingListSelector);
  const successList = useTypedSelector(successListSelector);

  const isMobile = useMediaQuery(991);

  const onClose = () => {
    assembleClusterForm.resetFields();
  };

  const onAssemble = (args: { [key: string]: string }) => {
    if (chainId && library && account) {
      trackGTMActions(
        'Cluster Assemble',
        cluster.symbol,
        'cluster',
        chainId,
        account,
        'intent',
      );
      dispatch({
        type: 'CLUSTERS_ASSEMBLE_CLUSTER_REQUESTED',
        payload: {
          controllerAddress: CONTROLLERS[chainId].controllerAddress,
          networkDecimals: getNetworkDecimals(chainId),
          clusterDecimals: cluster.decimals,
          clusterAddress,
          controllerABI: CONTROLLERS[chainId].controllerABI,
          clusters: CLUSTERS[chainId],
          library,
          account,
          amount: args[INPUT_NAME],
        },
      });

      assembleClusterForm.resetFields();
    }
  };

  useEffect(() => {
    if (successList[clusterAddress]) {
      trackGTMActions(
        'Cluster Assemble',
        cluster.symbol,
        'cluster',
        chainId,
        account,
        'fact',
      );
    }
  }, [successList, clusterAddress, cluster.symbol, chainId, account]);

  useEffect(() => {
    if (cluster && chainId && account && library) {
      library
        .getBalance(account)
        .then((result: BigNumber) => setUserBalance(result))
        .catch((err: Error) => {
          notification.error({
            message: t('CLUSTERS.CLUSTER_ASSEMBLE.ERROR_USER_BALANCE'),
            description: err.message || err,
          });
        });
    }
  }, [cluster, chainId, account, library, t]);

  return chainId ? (
    <ActionModal
      dispatchObject={{
        type: 'CLUSTERS_RESET_ASSEMBLE_CLUSTER_REQUESTED',
        payload: { clusterAddress },
      }}
      defaultTitle={t('CLUSTERS.CLUSTER_ASSEMBLE.DEFAULT_TITLE', {
        name: CLUSTERS[chainId][clusterAddress].name || cluster.name,
      })}
      successTitle={t('CLUSTERS.CLUSTER_ASSEMBLE.SUCCESS_TITLE', {
        name: CLUSTERS[chainId][clusterAddress].name || cluster.name,
      })}
      buttonClassName={className}
      buttonTitle={t(
        isMobile
          ? 'CLUSTERS.CLUSTER_ASSEMBLE.BUTTON_TITLE_MOBILE'
          : 'CLUSTERS.CLUSTER_ASSEMBLE.BUTTON_TITLE',
      )}
      isConfirmed={confirmedList[clusterAddress]}
      isPending={pendingList[clusterAddress]}
      isSuccess={successList[clusterAddress]}
      modalAddress={clusterAddress}
      modalPath={constants.MODAL_PATH_ASSEMBLE}
      modalIcon={CLUSTERS[chainId][clusterAddress].icon}
      modalWidth={isMobile ? 290 : 455}
      joinLeaveTheme
      onClose={onClose}
    >
      <ClusterModalForm
        returnedDecimals={cluster.decimals}
        commissionValue={commissionValue}
        returnedSymbol={cluster.symbol}
        clusterAmount={clusterAmountList[clusterAddress]?.amountFromEth}
        inputDecimals={getNetworkDecimals(chainId)}
        inputSymbol={getNetworkSymbol(chainId)}
        submitName={t('CLUSTERS.CLUSTER_ASSEMBLE.SUBMIT_NAME', {
          name: CLUSTERS[chainId][clusterAddress].name || cluster.name,
        })}
        isAssemble
        isDisabled={isLoadingCommission}
        isLoading={
          !!(confirmedList[clusterAddress] || pendingList[clusterAddress])
        }
        inputName={INPUT_NAME}
        formName="assembleCluster"
        onFinish={onAssemble}
        maxValue={userBalance}
        price={cluster.price}
        name={CLUSTERS[chainId][clusterAddress].name || cluster.name}
        form={assembleClusterForm}
      />
    </ActionModal>
  ) : null;
};

export default ClusterAssemble;
