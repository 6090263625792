import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';

import styles from '@modules/info/assets/styles/info.module.scss';

const PrivacyPolicy: FC = () => {
  const { t } = useTranslation();

  return (
    <article className={styles.container}>
      <hgroup>
        <h1 className={styles.title}>{t('INFO.PRIVACY_POLICY.PAGE_TITLE')}</h1>
      </hgroup>
      <aside>
        <ul className={styles.created}>
          <li className={styles.createdVersion}>
            {t('INFO.PRIVACY_POLICY.PAGE_VERSION')} 1.0.1.2022
          </li>
          <li>
            {t('INFO.PRIVACY_POLICY.PAGE_LAST_UPDATED')}{' '}
            <time dateTime="2021-10-07">October 7, 2021</time>
          </li>
        </ul>
      </aside>

      <section className={styles.section}>
        <p className={styles.mb}>
          <Trans
            i18nKey="INFO.PRIVACY_POLICY.DOCUMENT_DESCRIBE"
            values={{
              link: 'https://dehive.finance/',
              abbrLLC: 'LLC',
            }}
            tOptions={{ interpolation: { escapeValue: false } }}
            components={[
              <abbr key={0} title={`${t('INFO.ABBR_DECRYPTION.LLC')}`} />,
              <a
                key={1}
                className={styles.primaryLink}
                href="https://dehive.finance/"
              >
                {' '}
              </a>,
            ]}
          />
        </p>
        <p>
          <Trans i18nKey="INFO.PRIVACY_POLICY.COMMITTED_TO_SAFEGUARDING" />
        </p>
      </section>

      <address className={styles.section}>
        <h3 className={styles.subtitle}>
          {t('INFO.PRIVACY_POLICY.PAGE_SUB_TITLE')}
        </h3>

        <p>{t('INFO.PRIVACY_POLICY.ADDRESS.ADDRESS_REGISTERED')}</p>
        <p>
          <Trans
            i18nKey="INFO.PRIVACY_POLICY.ADDRESS.CONTACT_EMAIL"
            values={{ link: 'dehive_foundation@ukr.net' }}
            components={[
              <a
                key={0}
                className={styles.primaryLink}
                href="mailto:dehive_foundation@ukr.net"
              >
                {' '}
              </a>,
            ]}
          />
        </p>
      </address>
      <nav className={styles.section}>
        <h3 className={styles.numTitle}>
          {t('INFO.PRIVACY_POLICY.NAV.TABLE_OF_CONTENTS')}
        </h3>

        <ol className={cn(styles.numList, styles.mb)}>
          <li className={styles.numItem}>
            <a
              className={styles.defaultLink}
              href="#information-we-collect-from-you"
            >
              {t('INFO.PRIVACY_POLICY.NAV.INFORMATION_WE_COLLECT')}
            </a>
          </li>
          <li className={styles.numItem}>
            <a
              className={styles.defaultLink}
              href="#legal-basis-for-processing"
            >
              {t('INFO.PRIVACY_POLICY.NAV.LEGAL_BASIS_FOR_PROCESSING')}
            </a>
          </li>
          <li className={styles.numItem}>
            <a
              className={styles.defaultLink}
              href="#third-party-access-to-information"
            >
              {t('INFO.PRIVACY_POLICY.NAV.THIRD_PARTY_ACCESS_INFORMATION')}
            </a>
          </li>
          <li className={styles.numItem}>
            <a className={styles.defaultLink} href="#your-rights">
              {t('INFO.PRIVACY_POLICY.NAV.YOUR_RIGHTS')}
            </a>
          </li>
          <li className={styles.numItem}>
            <a className={styles.defaultLink} href="#security-of-information">
              {t('INFO.PRIVACY_POLICY.NAV.SECURITY_OF_INFORMATION')}
            </a>
          </li>
          <li className={styles.numItem}>
            <a className={styles.defaultLink} href="#third-party-services">
              {t('INFO.PRIVACY_POLICY.NAV.THIRD_PARTY_SERVICES')}
            </a>
          </li>
          <li className={styles.numItem}>
            <a className={styles.defaultLink} href="#changes-to-this-notice">
              {t('INFO.PRIVACY_POLICY.NAV.CHANGES_TO_THIS_NOTICE')}
            </a>
          </li>
        </ol>
      </nav>
      <section
        id="information-we-collect-from-you"
        className={cn(styles.section, styles['section--anchor'])}
      >
        <h3 className={styles.subtitle}>
          {t('INFO.PRIVACY_POLICY.INFO_WE_COLLECT.TITLE')}
        </h3>

        <h4 className={styles.subSubtitle}>
          {t('INFO.PRIVACY_POLICY.INFO_WE_COLLECT.SUBSCRIPTION_INFORMATION')}
        </h4>

        <p>{t('INFO.PRIVACY_POLICY.INFO_WE_COLLECT.SUBSCRIBE_FOR_OUR_NEWS')}</p>
        <p>
          <Trans
            i18nKey="INFO.PRIVACY_POLICY.INFO_WE_COLLECT.WE_USE_INFORMATION"
            values={{ abbrDHV: 'DHV' }}
            components={[
              <abbr key={0} title="DeHive">
                {' '}
              </abbr>,
            ]}
          />
        </p>
        <p className={styles.mb}>
          {t('INFO.PRIVACY_POLICY.INFO_WE_COLLECT.WE_KEEP_YOUR_SUBSCRIPTION')}
        </p>

        <h4 className={styles.subSubtitle}>
          {t('INFO.PRIVACY_POLICY.INFO_WE_COLLECT.SUPPORT_REQUEST_INFORMATION')}
        </h4>

        <p>{t('INFO.PRIVACY_POLICY.INFO_WE_COLLECT.REQUEST_SUPPORT')}</p>
        <p>{t('INFO.PRIVACY_POLICY.INFO_WE_COLLECT.INFORMATION_SOLELY')}</p>
        <p>{t('INFO.PRIVACY_POLICY.INFO_WE_COLLECT.STORE_YOUR_SUPPORT')}</p>
      </section>

      <section className={styles.section}>
        <h3 className={styles.subtitle}>
          {t('INFO.PRIVACY_POLICY.INFO_WE_COLLECT.IP_LOGS_AND_TRAFFIC_DATA')}
        </h3>

        <p>
          <Trans
            i18nKey="INFO.PRIVACY_POLICY.INFO_WE_COLLECT.DURING_YOUR_USE"
            values={{ abbrIP: 'IP' }}
            components={[
              <abbr key={0} title={t('INFO.ABBR_DECRYPTION.IP')}>
                {' '}
              </abbr>,
            ]}
          />
        </p>
        <p>{t('INFO.PRIVACY_POLICY.INFO_WE_COLLECT.WE_USE_THIS_DATA')}</p>
        <p className={styles.mb}>
          <Trans
            i18nKey="INFO.PRIVACY_POLICY.INFO_WE_COLLECT.WE_WILL_STORE_LOGS"
            values={{ abbrIP: 'IP' }}
            components={[
              <abbr key={0} title={t('INFO.ABBR_DECRYPTION.IP')}>
                {' '}
              </abbr>,
            ]}
          />
        </p>
        <p>
          <Trans
            i18nKey="INFO.PRIVACY_POLICY.INFO_WE_COLLECT.WE_ALSO_COLLECT_INFO"
            components={[
              <a key={0} href="#analytics" className={styles.defaultLink}>
                {' '}
              </a>,
            ]}
          />
        </p>
      </section>

      <section className={styles.section}>
        <h3 className={styles.subtitle}>
          {t('INFO.PRIVACY_POLICY.INFO_WE_COLLECT.COOKIES')}
        </h3>

        <p className={styles.mb}>
          <Trans i18nKey="INFO.PRIVACY_POLICY.INFO_WE_COLLECT.COOKIE_IS" />
        </p>
        <p>
          <Trans i18nKey="INFO.PRIVACY_POLICY.INFO_WE_COLLECT.USE_COOKIES" />
        </p>
      </section>

      <section
        id="legal-basis-for-processing"
        className={cn(styles.section, styles['section--anchor'])}
      >
        <h3 className={styles.subtitle}>
          {t('INFO.PRIVACY_POLICY.LEGAL_BASIS.TITLE')}
        </h3>

        <h4 className={styles.subSubtitle}>
          {t('INFO.PRIVACY_POLICY.LEGAL_BASIS.YOUR_CONSENT')}
        </h4>

        <p className={styles.mb}>
          {t('INFO.PRIVACY_POLICY.LEGAL_BASIS.WE_PROCESS')}
        </p>
        <p className={styles.mb}>
          {t('INFO.PRIVACY_POLICY.LEGAL_BASIS.STATISTIC_COOKIES')}
        </p>
        <p className={styles.mb}>
          {t('INFO.PRIVACY_POLICY.LEGAL_BASIS.YOU_CAN_WITHDRAW')}
        </p>
      </section>

      <section className={styles.section}>
        <h3 className={styles.subtitle}>
          {t('INFO.PRIVACY_POLICY.LEGAL_BASIS.LEGITIMATE_INTERESTS')}
        </h3>

        <p>
          <Trans
            i18nKey="INFO.PRIVACY_POLICY.LEGAL_BASIS.LEGITIMATE_INTERESTS_DESC"
            values={{ abbrIP: 'IP' }}
            components={[
              <abbr key={0} title={t('INFO.ABBR_DECRYPTION.IP')}>
                {' '}
              </abbr>,
            ]}
          />
        </p>
      </section>

      <section
        id="third-party-access-to-information"
        className={cn(styles.section, styles['section--anchor'])}
      >
        <h3 className={styles.subtitle}>
          {t('INFO.PRIVACY_POLICY.THIRD_PARTY.ACCESS_TO_INFORMATION_TITLE')}
        </h3>

        <h4 className={styles.subSubtitle}>
          {t('INFO.PRIVACY_POLICY.THIRD_PARTY.SERVICE_PROVIDERS')}
        </h4>
        <p className={styles.mb}>
          {t('INFO.PRIVACY_POLICY.THIRD_PARTY.FOLLOWING_CATEGORIES')}
        </p>
        <ol className={styles.numList}>
          <li className={styles.numItem}>
            <p>{t('INFO.PRIVACY_POLICY.THIRD_PARTY.CLIENT_RELATIONSHIP')}</p>
          </li>
          <li className={styles.numItem}>
            <p>
              <Trans
                i18nKey="INFO.PRIVACY_POLICY.THIRD_PARTY.PROVIDER_OF_LOAD"
                values={{ abbrDNS: 'DNS' }}
                components={[
                  <abbr key={0} title={t('INFO.ABBR_DECRYPTION.DNS')}>
                    {' '}
                  </abbr>,
                ]}
              />
            </p>
          </li>
          <li className={styles.numItem}>
            <p>{t('INFO.PRIVACY_POLICY.THIRD_PARTY.PROVIDERS_OF_PLATFORM')}</p>
          </li>
        </ol>
      </section>

      <section className={styles.section}>
        <h3 className={styles.subtitle}>
          {t('INFO.PRIVACY_POLICY.THIRD_PARTY.OUR_CONTACTORS')}
        </h3>
        <p className={styles.mb}>
          {t('INFO.PRIVACY_POLICY.THIRD_PARTY.FOLLOWING_CONTRACTORS')}
        </p>
        <ol className={cn(styles.numList, styles.mb)}>
          <li className={styles.numItem}>
            <p>{t('INFO.PRIVACY_POLICY.THIRD_PARTY.CONTRACTORS_OF_LEGAL')}</p>
          </li>
          <li className={styles.numItem}>
            <p>
              {t('INFO.PRIVACY_POLICY.THIRD_PARTY.CONTRACTORS_AND_SUPPORT')}
            </p>
          </li>
        </ol>
        <p className={styles.mb}>
          <Trans
            i18nKey="INFO.PRIVACY_POLICY.THIRD_PARTY.INVOLVEMENT_OF_PROVIDERS"
            values={{ abbrSCC: 'SCC' }}
            components={[
              <abbr key={0} title={t('INFO.ABBR_DECRYPTION.SCC')}>
                {' '}
              </abbr>,
            ]}
          />
        </p>
        <p>
          <Trans i18nKey="INFO.PRIVACY_POLICY.THIRD_PARTY.CONTRACTORS_NOTICE" />
        </p>
      </section>

      <section
        id="analytics"
        className={cn(styles.section, styles['section--anchor'])}
      >
        <h3 className={styles.subtitle}>
          {t('INFO.PRIVACY_POLICY.THIRD_PARTY.ANALYTICS')}
        </h3>

        <p>{t('INFO.PRIVACY_POLICY.THIRD_PARTY.ANALYTIC_SERVICES')}</p>
      </section>

      <section className={styles.section}>
        <h3 className={styles.subtitle}>
          {t('INFO.PRIVACY_POLICY.THIRD_PARTY.OTHER_DISCLOSURES')}
        </h3>

        <p className={styles.mb}>
          {t('INFO.PRIVACY_POLICY.THIRD_PARTY.ADDITION_TO_DISCLOSURES')}
        </p>
        <ol className={cn(styles.numList, styles.mb)}>
          <li className={styles.numItem}>
            <p>
              <Trans i18nKey="INFO.PRIVACY_POLICY.THIRD_PARTY.REQUIRED_LAW" />
            </p>
          </li>
          <li className={styles.numItem}>
            <p>
              <Trans i18nKey="INFO.PRIVACY_POLICY.THIRD_PARTY.WE_SELL" />
            </p>
          </li>
        </ol>
        <p>{t('INFO.PRIVACY_POLICY.THIRD_PARTY.EXCEPT_AS_PROVIDED')}</p>
      </section>

      <section
        id="your-rights"
        className={cn(styles.section, styles['section--anchor'])}
      >
        <h3 className={styles.subtitle}>
          {t('INFO.PRIVACY_POLICY.YOUR_RIGHTS.TITLE')}
        </h3>

        <p className={styles.mb}>
          <Trans i18nKey="INFO.PRIVACY_POLICY.YOUR_RIGHTS.MAINTAIN_CONTROL" />
        </p>
        <ol className={cn(styles.numList, styles.mb)}>
          <li className={styles.numItem}>
            <p>
              <Trans
                i18nKey="INFO.PRIVACY_POLICY.YOUR_RIGHTS.OBJECT_PROCESSING"
                components={[<strong key={0} />]}
              />
            </p>
          </li>
          <li className={styles.numItem}>
            <p>
              <Trans
                i18nKey="INFO.PRIVACY_POLICY.YOUR_RIGHTS.ACCESS_INFORMATION"
                components={[<strong key={0} />]}
              />
            </p>
          </li>
          <li className={styles.numItem}>
            <p>
              <Trans
                i18nKey="INFO.PRIVACY_POLICY.YOUR_RIGHTS.VERIFY_INFORMATION"
                components={[<strong key={0} />]}
              />
            </p>
          </li>
          <li className={styles.numItem}>
            <p>
              <Trans
                i18nKey="INFO.PRIVACY_POLICY.YOUR_RIGHTS.RESTRICT_PROCESSING"
                components={[<strong key={0} />]}
              />
            </p>
          </li>
          <li className={styles.numItem}>
            <p>
              <Trans
                i18nKey="INFO.PRIVACY_POLICY.YOUR_RIGHTS.ASK_US"
                components={[<strong key={0} />]}
              />
            </p>
          </li>
          <li className={styles.numItem}>
            <p>
              <Trans
                i18nKey="INFO.PRIVACY_POLICY.YOUR_RIGHTS.ASK_US_TO_TRANSFER"
                components={[<strong key={0} />]}
              />
            </p>
          </li>
        </ol>
        <p>
          <Trans
            i18nKey="INFO.PRIVACY_POLICY.YOUR_RIGHTS.YOU_CAN_COMPLETE_REQUEST"
            values={{ link: 'dehive_foundation@ukr.net' }}
            components={[
              <a
                key={0}
                className={styles.primaryLink}
                href="mailto:dehive_foundation@ukr.net"
              >
                {' '}
              </a>,
            ]}
          />
        </p>
        <p>{t('INFO.PRIVACY_POLICY.YOUR_RIGHTS.PERSONAL_INFORMATION')}</p>
      </section>
      <section
        id="security-of-information"
        className={cn(styles.section, styles['section--anchor'])}
      >
        <h3 className={styles.subtitle}>
          {t('INFO.PRIVACY_POLICY.SECURITY_OF_INFO.TITLE')}
        </h3>

        <p className={styles.mb}>
          {t('INFO.PRIVACY_POLICY.SECURITY_OF_INFO.TAKE_NECESSARY')}
        </p>
        <p>{t('INFO.PRIVACY_POLICY.SECURITY_OF_INFO.PLEASE_NOTE')}</p>
      </section>
      <section
        id="third-party-services"
        className={cn(styles.section, styles['section--anchor'])}
      >
        <h3 className={styles.subtitle}>
          {t('INFO.PRIVACY_POLICY.THIRD_PARTY_SERVICES.TITLE')}
        </h3>

        <p className={styles.mb}>
          {t('INFO.PRIVACY_POLICY.THIRD_PARTY_SERVICES.OUR_PLATFORM')}
        </p>
        <p>{t('INFO.PRIVACY_POLICY.THIRD_PARTY_SERVICES.WE_ENCOURAGE')}</p>
      </section>
      <section
        id="changes-to-this-notice"
        className={cn(styles.section, styles['section--anchor'])}
      >
        <h3 className={styles.subtitle}>
          {t('INFO.PRIVACY_POLICY.CHANGES_TO_THIS_NOTICE.TITLE')}
        </h3>

        <p>
          <Trans i18nKey="INFO.PRIVACY_POLICY.CHANGES_TO_THIS_NOTICE.DESC" />
        </p>
      </section>
    </article>
  );
};

export default PrivacyPolicy;
