import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';

import ButtonLink from '@modules/common/components/ButtonLink';

import styles from '@modules/info/assets/styles/info.module.scss';

interface NavLinks {
  hash: string;
  text: string;
}

const NAV_LINKS: NavLinks[] = [
  {
    hash: '#general-provisions',
    text: 'INFO.TERMS_OF_USE.NAV.GENERAL_PROVISIONS',
  },
  {
    hash: '#services',
    text: 'INFO.TERMS_OF_USE.NAV.SERVICES',
  },
  {
    hash: '#information-services',
    text: 'INFO.TERMS_OF_USE.NAV.INFORMATION_SERVICES',
  },
  {
    hash: '#user-conduct',
    text: 'INFO.TERMS_OF_USE.NAV.USER_CONDUCT',
  },
  {
    hash: '#intellectual-property-rights',
    text: 'INFO.TERMS_OF_USE.NAV.INTELLECTUAL_PROPERTY_RIGHTS',
  },
  {
    hash: '#links-from-the-platform',
    text: 'INFO.TERMS_OF_USE.NAV.LINKS_FROM_THE_PLATFORM',
  },
  {
    hash: '#confidentiality-and-privacy-policy',
    text: 'INFO.TERMS_OF_USE.NAV.CONFIDENTIALITY_AND_PRIVACY_POLICY',
  },
  {
    hash: '#disclaimers-and-limitations-of-liability',
    text: 'INFO.TERMS_OF_USE.NAV.DISCLAIMERS_AND_LIMITATIONS',
  },
  {
    hash: '#announcements',
    text: 'INFO.TERMS_OF_USE.NAV.ANNOUNCEMENTS',
  },
  {
    hash: '#applicable-law-and-dispute-resolution',
    text: 'INFO.TERMS_OF_USE.NAV.APPLICABLE_LAW',
  },
  {
    hash: '#final-provisions',
    text: 'INFO.TERMS_OF_USE.NAV.FINAL_PROVISIONS',
  },
  {
    hash: '#risk-warning',
    text: 'INFO.TERMS_OF_USE.NAV.RISK_WARNING',
  },
  {
    hash: '#risk-of-extreme-volatility',
    text: 'INFO.TERMS_OF_USE.NAV.RISK_OF_EXTREME_VOLATILITY',
  },
  {
    hash: '#risk-of-uninsured-losses',
    text: 'INFO.TERMS_OF_USE.NAV.RISK_OF_UNINSURED_LOSSES',
  },
  {
    hash: '#risk-of-lack-of-awareness',
    text: 'INFO.TERMS_OF_USE.NAV.RISK_OF_LACK_OF_AWARENESS',
  },
  {
    hash: '#risk-of-weaknesses',
    text: 'INFO.TERMS_OF_USE.NAV.RISK_OF_WEAKNESSES',
  },
];

const TermsOfUse: FC = () => {
  const { t } = useTranslation();

  return (
    <article className={styles.container}>
      <hgroup>
        <h1 className={styles.title}>{t('INFO.TERMS_OF_USE.PAGE_TITLE')}</h1>
      </hgroup>
      <aside>
        <ul className={styles.created}>
          <li className={styles.createdVersion}>
            {t('INFO.TERMS_OF_USE.PAGE_VERSION')} 1.0.1.2022
          </li>
          <li>
            {t('INFO.TERMS_OF_USE.PAGE_LAST_UPDATED')}{' '}
            <time dateTime="2021-10-07">October 7, 2021</time>
          </li>
        </ul>
      </aside>

      <p className={styles.mb}>
        <Trans
          i18nKey="INFO.TERMS_OF_USE.PAGE_DESCRIPTION"
          values={{ link: 'https://dehive.finance/', abbrLLC: 'LLC' }}
          tOptions={{ interpolation: { escapeValue: false } }}
          components={[
            <abbr key={0} title={`${t('INFO.ABBR_DECRYPTION.LLC')}`} />,
            <a
              key={1}
              className={styles.primaryLink}
              href="https://dehive.finance/"
            >
              {' '}
            </a>,
            <span key={2} />,
          ]}
        />
      </p>

      <blockquote>
        <Trans
          i18nKey="INFO.TERMS_OF_USE.PAGE_NOTICE"
          components={[<strong key={0} />]}
        />
      </blockquote>

      <section className={styles.section}>
        <p>
          <Trans
            i18nKey="INFO.TERMS_OF_USE.IF_NOT_AGREE"
            components={[<strong key={0} />]}
          />
        </p>
      </section>

      <section className={styles.section}>
        <ul className={styles.nav}>
          {NAV_LINKS.map(({ hash, text }) => (
            <li key={hash}>
              <ButtonLink
                className={styles.navLink}
                color="lightBlue"
                size="little"
                text={t(text)}
                path="/terms-of-use"
                hash={hash}
              />
            </li>
          ))}
        </ul>
      </section>

      <section
        id="general-provisions"
        className={cn(styles.section, styles['section--anchor'])}
      >
        <h3 className={styles.subtitle}>
          {t('INFO.TERMS_OF_USE.GENERAL_PROVISIONS.TITLE')}
        </h3>

        <ol className={styles.numList}>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.GENERAL_PROVISIONS.LEGALLY')}
          </li>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.GENERAL_PROVISIONS.ACCESSING_OR_USING')}
          </li>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.GENERAL_PROVISIONS.COMPANY_MAY_CHANGE')}
          </li>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.GENERAL_PROVISIONS.COMPANY_WILL_NOTIFY')}
          </li>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.GENERAL_PROVISIONS.REVIEW_CURRENT_VERSION')}
          </li>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.GENERAL_PROVISIONS.REVISED_PROVISIONS')}
          </li>
        </ol>
      </section>

      <section
        id="services"
        className={cn(styles.section, styles['section--anchor'])}
      >
        <h3 className={styles.subtitle}>
          {t('INFO.TERMS_OF_USE.SERVICES.TITLE')}
        </h3>
        <ol className={styles.numList}>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.SERVICES.PLATFORM_PROVIDES')}
            <ol className={styles.subNumList}>
              <li className={styles.subNumItem}>
                {t('INFO.TERMS_OF_USE.SERVICES.PLATFORM_ACTIONS')}
              </li>
              <li className={styles.subNumItem}>
                {t('INFO.TERMS_OF_USE.SERVICES.BECOME_HOLDER')}
              </li>
              <li className={styles.subNumItem}>
                {t('INFO.TERMS_OF_USE.SERVICES.ACCESS_OTHER_PROTOCOL')}
              </li>
            </ol>
          </li>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.SERVICES.CONNECT_YOUR_WALLET')}
          </li>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.SERVICES.ELIGIBLE_TO_USE')}
          </li>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.SERVICES.DISABLE_THE_ACCESS')}
          </li>
        </ol>
      </section>

      <section
        id="information-services"
        className={cn(styles.section, styles['section--anchor'])}
      >
        <h3 className={styles.subtitle}>
          {t('INFO.TERMS_OF_USE.INFORMATION_SERVICES.TITLE')}
        </h3>
        <ol className={styles.numList}>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.INFORMATION_SERVICES.PRODUCE_AND_DISPLAY')}
          </li>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.INFORMATION_SERVICES.CONTENT_DOES_NOT')}
          </li>
        </ol>
      </section>

      <section
        id="user-conduct"
        className={cn(styles.section, styles['section--anchor'])}
      >
        <h3 className={styles.subtitle}>
          {t('INFO.TERMS_OF_USE.USER_CONDUCT.TITLE')}
        </h3>

        <ol className={styles.numList}>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.USER_CONDUCT.DURING_USING')}
            <ol className={styles.subNumList}>
              <li className={styles.subNumItem}>
                {t('INFO.TERMS_OF_USE.USER_CONDUCT.NOT_VIOLATE_OR_ASSIST')}
              </li>
              <li className={styles.subNumItem}>
                {t('INFO.TERMS_OF_USE.USER_CONDUCT.NOT_PROVIDE_FALSE_INFO')}
              </li>
              <li className={styles.subNumItem}>
                {t('INFO.TERMS_OF_USE.USER_CONDUCT.NOT_VIOLATE_PROPERTY')}
              </li>
              <li className={styles.subNumItem}>
                <Trans
                  i18nKey="INFO.TERMS_OF_USE.USER_CONDUCT.NOT_USE_FOR_DAMAGE"
                  values={{ abbrDDoS: 'DDoS' }}
                  components={[
                    <abbr
                      key={0}
                      title={`${t('INFO.ABBR_DECRYPTION.DDOS')}`}
                    />,
                  ]}
                />
              </li>
              <li className={styles.subNumItem}>
                {t('INFO.TERMS_OF_USE.USER_CONDUCT.NOT_IMPERSONATE')}
              </li>
              <li className={styles.subNumItem}>
                {t('INFO.TERMS_OF_USE.USER_CONDUCT.KEEP_MEANS_OF_ACCESS')}
              </li>
              <li className={styles.subNumItem}>
                {t('INFO.TERMS_OF_USE.USER_CONDUCT.NOT_GIVE_ANY_THIRD_PERSON')}
              </li>
              <li className={styles.subNumItem}>
                {t('INFO.TERMS_OF_USE.USER_CONDUCT.NOT_VIOLATE_ANY_LAWS')}
              </li>
              <li className={styles.subNumItem}>
                {t('INFO.TERMS_OF_USE.USER_CONDUCT.NOT_VIOLATE_THESE_TERMS')}
              </li>
            </ol>
          </li>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.USER_CONDUCT.YOU_ARE_SOLELY_LIABLE')}
          </li>
        </ol>
      </section>

      <section
        id="intellectual-property-rights"
        className={cn(styles.section, styles['section--anchor'])}
      >
        <h3 className={styles.subtitle}>
          {t('INFO.TERMS_OF_USE.INTELLECTUAL_RIGHTS.TITLE')}
        </h3>

        <ol className={styles.numList}>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.INTELLECTUAL_RIGHTS.ENTIRE_CONTENTS')}
          </li>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.INTELLECTUAL_RIGHTS.THESE_TERMS_PERMIT')}
            <ol className={styles.subNumList}>
              <li className={styles.subNumItem}>
                {t('INFO.TERMS_OF_USE.INTELLECTUAL_RIGHTS.REPRODUCE')}
              </li>
              <li className={styles.subNumItem}>
                {t('INFO.TERMS_OF_USE.INTELLECTUAL_RIGHTS.YOU_MUST_NOT_DELETE')}
              </li>
              <li className={styles.subNumItem}>
                {t('INFO.TERMS_OF_USE.INTELLECTUAL_RIGHTS.NO_RIGHT_TITLE')}
              </li>
            </ol>
          </li>
        </ol>
      </section>

      <section
        id="links-from-the-platform"
        className={cn(styles.section, styles['section--anchor'])}
      >
        <h3 className={styles.subtitle}>
          {t('INFO.TERMS_OF_USE.PLATFORM_LINKS.TITLE')}
        </h3>

        <p>{t('INFO.TERMS_OF_USE.PLATFORM_LINKS.TO_ANY_PLATFORMS')}</p>
      </section>

      <section
        id="confidentiality-and-privacy-policy"
        className={cn(styles.section, styles['section--anchor'])}
      >
        <h3 className={styles.subtitle}>
          {t('INFO.TERMS_OF_USE.CONFIDENTIALITY_PRIVACY_POLICY.TITLE')}
        </h3>
        <ol className={styles.numList}>
          <li className={styles.numItem}>
            {t(
              'INFO.TERMS_OF_USE.CONFIDENTIALITY_PRIVACY_POLICY.PERSONAL_DATA',
            )}
          </li>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.CONFIDENTIALITY_PRIVACY_POLICY.OBLIGED')}
          </li>
        </ol>
      </section>

      <section
        id="disclaimers-and-limitations-of-liability"
        className={cn(styles.section, styles['section--anchor'])}
      >
        <h3 className={styles.subtitle}>
          {t('INFO.TERMS_OF_USE.DISCLAIMERS.TITLE')}
        </h3>
        <ol className={styles.numList}>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.DISCLAIMERS.NON_PERFORMANCE')}
          </li>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.DISCLAIMERS.PROVIDED_WITHOUT')}
          </li>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.DISCLAIMERS.YOUR_USE_OF_THE_PLATFORM')}
          </li>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.DISCLAIMERS.COMPANY_DOES_NOT_GUARANTEE')}
          </li>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.DISCLAIMERS.COMPANY_SHALL')}
          </li>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.DISCLAIMERS.EVENT_SHALL_THE_COMPANY')}
          </li>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.DISCLAIMERS.SHALL_NOT_BE_LIABLE')}
          </li>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.DISCLAIMERS.COMPANY_ITS')}
          </li>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.DISCLAIMERS.EXTENT_PERMITTED')}
          </li>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.DISCLAIMERS.IN_CASE')}
          </li>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.DISCLAIMERS.IF_ANY_PROVISION')}
          </li>
        </ol>
      </section>

      <section
        id="announcements"
        className={cn(styles.section, styles['section--anchor'])}
      >
        <h3 className={styles.subtitle}>
          {t('INFO.TERMS_OF_USE.ANNOUNCEMENTS.TITLE')}
        </h3>

        <p>{t('INFO.TERMS_OF_USE.ANNOUNCEMENTS.DESCRIPTION')}</p>
      </section>

      <section
        id="applicable-law-and-dispute-resolution"
        className={cn(styles.section, styles['section--anchor'])}
      >
        <h3 className={styles.subtitle}>
          {t('INFO.TERMS_OF_USE.APPLICABLE_LAW.TITLE')}
        </h3>
        <ol className={styles.numList}>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.APPLICABLE_LAW.IF_ANY_RELATIONS')}
          </li>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.APPLICABLE_LAW.COMPANY_SHALL_ENDEAVOUR')}
          </li>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.APPLICABLE_LAW.PARTIES_FAIL')}
          </li>
        </ol>
      </section>

      <section
        id="final-provisions"
        className={cn(styles.section, styles['section--anchor'])}
      >
        <h3 className={styles.subtitle}>
          {t('INFO.TERMS_OF_USE.FINAL_PROVISIONS.TITLE')}
        </h3>
        <ol className={styles.numList}>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.FINAL_PROVISIONS.THESE_TERMS_SHALL')}
          </li>
          <li className={styles.numItem}>
            {t('INFO.TERMS_OF_USE.FINAL_PROVISIONS.ANY_OTHER_NOTICES')}
          </li>
          <li className={styles.numItem}>
            <Trans
              i18nKey="INFO.TERMS_OF_USE.FINAL_PROVISIONS.FOR_CONTACT"
              values={{ link: 'dehive_foundation@ukr.net' }}
              components={[
                <a
                  key={0}
                  className={styles.primaryLink}
                  href="mailto:dehive_foundation@ukr.net"
                >
                  {' '}
                </a>,
              ]}
            />
          </li>
        </ol>
      </section>

      <section
        id="risk-warning"
        className={cn(styles.section, styles['section--anchor'])}
      >
        <h3 className={styles.subtitle}>
          {t('INFO.TERMS_OF_USE.RISK_WARNING.TITLE')}
        </h3>

        <p>
          <Trans
            i18nKey="INFO.TERMS_OF_USE.RISK_WARNING.DESCRIPTION"
            values={{ abbrBTC: 'BTC' }}
            components={[
              <abbr key={0} title={`${t('INFO.ABBR_DECRYPTION.BTC')}`} />,
            ]}
          />
        </p>
      </section>

      <section
        id="risk-of-extreme-volatility"
        className={cn(styles.section, styles['section--anchor'])}
      >
        <h3 className={styles.subtitle}>
          {t('INFO.TERMS_OF_USE.RISK_OF_EXTREME.TITLE')}
        </h3>

        <p>{t('INFO.TERMS_OF_USE.RISK_OF_EXTREME.DIGITAL_TOKENS_HAVE')}</p>
        <p>{t('INFO.TERMS_OF_USE.RISK_OF_EXTREME.TRADING_OF_DIGITAL')}</p>
      </section>

      <section
        id="risk-of-uninsured-losses"
        className={cn(styles.section, styles['section--anchor'])}
      >
        <h3 className={styles.subtitle}>
          {t('INFO.TERMS_OF_USE.RISK_OF_UNINSURED_LOSSES.TITLE')}
        </h3>

        <p>{t('INFO.TERMS_OF_USE.RISK_OF_UNINSURED_LOSSES.DESCRIPTION')}</p>
      </section>

      <section
        id="risk-of-lack-of-awareness"
        className={cn(styles.section, styles['section--anchor'])}
      >
        <h3 className={styles.subtitle}>
          {t('INFO.TERMS_OF_USE.RISK_OF_LACK_OF_AWARENESS.TITLE')}
        </h3>

        <p>{t('INFO.TERMS_OF_USE.RISK_OF_LACK_OF_AWARENESS.DESCRIPTION')}</p>
      </section>

      <section
        id="risk-of-weaknesses"
        className={cn(styles.section, styles['section--anchor'])}
      >
        <h3 className={styles.subtitle}>
          {t('INFO.TERMS_OF_USE.RISK_OF_WEAKNESSES.TITLE')}
        </h3>

        <p>
          {t('INFO.TERMS_OF_USE.RISK_OF_WEAKNESSES.ADVANCES_IN_CRYPTOGRAPHY')}
        </p>
        <p>{t('INFO.TERMS_OF_USE.RISK_OF_WEAKNESSES.YOU_ACKNOWLEDGE_THAT')}</p>
        <p>{t('INFO.TERMS_OF_USE.RISK_OF_WEAKNESSES.YOU_EXPRESSLY_AGREE')}</p>
      </section>
    </article>
  );
};

export default TermsOfUse;
