// Network underlying icons
import BNB from '@assets/images/underlying/BNB.png';
import ETH from '@assets/images/underlying/ETH.png';
import MATIC from '@assets/images/underlying/MATIC.png';
import xDAI from '@assets/images/underlying/xDAI.png';
// DBSC underlying icons
import ALPACA from '@assets/images/underlying/ALPACA.png';
import BANANA from '@assets/images/underlying/BANANA.png';
import CAKE from '@assets/images/underlying/CAKE.png';
import DODO from '@assets/images/underlying/DODO.png';
import EPS from '@assets/images/underlying/EPS.png';
import MDX from '@assets/images/underlying/MDX.png';
import WBNB from '@assets/images/underlying/WBNB.png';
import XVS from '@assets/images/underlying/XVS.png';
// DECR underlying icons
import ONEINCH from '@assets/images/underlying/1INCH.png';
import AAVE from '@assets/images/underlying/AAVE.png';
import COMP from '@assets/images/underlying/COMP.png';
import CRV from '@assets/images/underlying/CRV.png';
import LINK from '@assets/images/underlying/LINK.png';
import SNX from '@assets/images/underlying/SNX.png';
import SUSHI from '@assets/images/underlying/SUSHI.png';
import UNI from '@assets/images/underlying/UNI.png';
// DGAME underlying icons
import DG from '@assets/images/underlying/DG.png';
import FEAR from '@assets/images/underlying/FEAR.png';
import GHST from '@assets/images/underlying/GHST.png';
import KABY from '@assets/images/underlying/KABY.png';
import MANA from '@assets/images/underlying/MANA.png';
import MUST from '@assets/images/underlying/MUST.png';
import SNK from '@assets/images/underlying/SNK.png';
// DPOL underlying icons
import ADDY from '@assets/images/underlying/ADDY.png';
import BIFI from '@assets/images/underlying/BIFI.png';
import DFYN from '@assets/images/underlying/DFYN.png';
import FISH from '@assets/images/underlying/FISH.png';
import QI from '@assets/images/underlying/QI.png';
import QUICK from '@assets/images/underlying/QUICK.png';
import WMATIC from '@assets/images/underlying/WMATIC.png';
// DXIN underlying icons
import HAUS from '@assets/images/underlying/HAUS.png';
import DXD from '@assets/images/underlying/DXD.png';
import RAID from '@assets/images/underlying/RAID.png';
import BRIGHT from '@assets/images/underlying/BRIGHT.png';
import RICE from '@assets/images/underlying/RICE.png';
import DATA from '@assets/images/underlying/DATA.png';
import FOX from '@assets/images/underlying/FOX.png';
import STAKE from '@assets/images/underlying/STAKE.png';
// DXDC underlying icons
import AGVE from '@assets/images/underlying/AGVE.png';
import DHV from '@assets/images/underlying/DHV.png';
import DIP from '@assets/images/underlying/DIP.png';
import ELK from '@assets/images/underlying/ELK.png';
import GNO from '@assets/images/underlying/GNO.png';
import HNY from '@assets/images/underlying/HNY.png';
import MIVA from '@assets/images/underlying/MIVA.png';
import SYMM from '@assets/images/underlying/SYMM.png';
// Curve.fi underlying icons
import DAI from '@assets/images/underlying/DAI.png';
import USDC from '@assets/images/underlying/USDC.png';
import USDT from '@assets/images/underlying/USDT.png';
import WBTC from '@assets/images/underlying/WBTC.png';
import WETH from '@assets/images/underlying/WETH.png';
import EURT from '@assets/images/underlying/EURT.png';
import AVAX from '@assets/images/underlying/AVAX.png';
import MAI from '@assets/images/underlying/MAI.png';
import TWT from '@assets/images/underlying/TWT.png';
import BUSD from '@assets/images/underlying/BUSD.png';

interface UnderlyingList {
  [key: string]: {
    description?: string;
    icon?: string;
    color?: string;
  };
}

const UNDERLYING_LIST: UnderlyingList = {
  // Network underlying icons
  BNB: {
    icon: BNB,
  },
  ETH: {
    icon: ETH,
  },
  MATIC: {
    icon: MATIC,
  },
  RIN: {
    icon: ETH,
  },
  tBNB: {
    icon: BNB,
  },
  tMATIC: {
    icon: MATIC,
  },
  XDAI: {
    icon: xDAI,
  },
  // DBSC underlying
  ALPACA: {
    description:
      // eslint-disable-next-line max-len
      'Alpaca Finance is the first leveraged yield farming protocol on BSC. Alpaca protocol will allow users to open a leveraged yield farming position by borrowing from internal deposit vaults.',
    icon: ALPACA,
  },
  BANANA: {
    description:
      // eslint-disable-next-line max-len
      'ApeSwap is an AMM-based DEX on Polygon and BSC that offers staking and yield farming opportunities to its users.',
    icon: BANANA,
  },
  Cake: {
    description:
      // eslint-disable-next-line max-len
      'PancakeSwap is a decentralized exchange built on top of the Binance Smart Chain. It is the biggest AMM-based exchange in BSC that allows users to trade, earn, and win tokens after connecting their wallet.',
    icon: CAKE,
  },
  DODO: {
    description:
      // eslint-disable-next-line max-len
      'DODO is a decentralized trading protocol for Web3 and a DEX running on BSC and Ethereum. It offers high liquidity to trade any asset across multiple chains.',
    icon: DODO,
  },
  EPS: {
    description:
      // eslint-disable-next-line max-len
      'Ellipsis Finance is an authorized fork of Curve that allows users to swap and stake stablecoins, as well as trade, earn, and stake its native token for fees and rewards.',
    icon: EPS,
  },
  MDX: {
    description:
      // eslint-disable-next-line max-len
      'Mdex is an AMM-based DEX that works through fund pools. It implements a dual-chain DEX model based on the Huobi Eco Chain and Ethereum and supports the dual mining mechanism of liquidity and transactions.',
    icon: MDX,
  },
  WBNB: {
    description:
      // eslint-disable-next-line max-len
      'Wrapped BNB is a wrapped version of the BNB native tokens on the BEP-20 standard on the Binance Smart Chain. Not to be confused with BNB Native Token.',
    icon: WBNB,
  },
  XVS: {
    description:
      // eslint-disable-next-line max-len
      'Venus Protocol is a decentralized marketplace for lenders and borrowers and a stablecoin protocol that operates on BSC. It is forked from Compound and MarkerDAO.',
    icon: XVS,
  },
  // DECR underlying
  '1INCH': {
    description:
      // eslint-disable-next-line max-len
      '1inch is a decentralized exchange aggregator that helps users discover the best trade prices for tokens by aggregating across different pools and suggesting the most efficient way to trade crypto.',
    icon: ONEINCH,
  },
  AAVE: {
    description:
      // eslint-disable-next-line max-len
      'Aave is a decentralized non-custodial liquidity market protocol where users can lend and borrow cryptocurrency across 20 different assets as collateral.',
    icon: AAVE,
  },
  COMP: {
    description:
      // eslint-disable-next-line max-len
      'Compound is an algorithmic, autonomous interest rate protocol built for developers and DeFi enthusiasts to borrow and lend crypto in an open-source community-governed way.',
    icon: COMP,
  },
  CRV: {
    description:
      // eslint-disable-next-line max-len
      'Curve is a decentralized exchange for stablecoins that uses an automated market maker to manage liquidity. Its native token fuels the Curve Finance ecosystem, including the DAO, StableSwap, and Curve Crypto Pools.',
    icon: CRV,
  },
  LINK: {
    description:
      // eslint-disable-next-line max-len
      'Chainlink is a framework for building Decentralized Oracle Networks that bring tamper-proof real-world data onto blockchain networks, enabling the creation of hybrid smart contracts.',
    icon: LINK,
  },
  SNX: {
    description:
      // eslint-disable-next-line max-len
      'Synthetix is a decentralized synthetic asset issuance protocol that enables derivatives trading in DeFi, allowing anyone, anywhere, to gain on-chain exposure to a vast range of assets.',
    icon: SNX,
  },
  SUSHI: {
    description:
      // eslint-disable-next-line max-len
      'Sushi is a decentralized community-driven protocol that uses an automated market maker to manage liquidity. It supplies users with DeFi tools for staking, yield farming, and liquidity providing.',
    icon: SUSHI,
  },
  UNI: {
    description:
      // eslint-disable-next-line max-len
      'Uniswap is a decentralized exchange that enables swapping ERC-20 tokens for other ERC-20 tokens without the need for an orderbook. It uses an automated market maker to manage liquidity.',
    icon: UNI,
  },
  // DGAME underlying
  $DG: {
    description:
      // eslint-disable-next-line max-len
      'Decentral Games is a DAO-governed metaverse casino powered by DG, where users earn rewards for playing games, LPs earn tokens for providing liquidity, and holders earn DG for participating in the treasury governance.',
    icon: DG,
  },
  FEAR: {
    description:
      // eslint-disable-next-line max-len
      'Fear is a Play2Earn NFT horror game project founded by the creators of the famous Whack It series. It will allow players to mint their own unique and rare NFTs while playing a horror action game on the blockchain.',
    icon: FEAR,
  },
  GHST: {
    description:
      // eslint-disable-next-line max-len
      'Cometh is a DeFi-powered Blockchain game with yield generating NFT, which allows users to explore the galaxy on the spaceships and earn tokens in reward for playing.',
    icon: GHST,
  },
  KABY: {
    description:
      // eslint-disable-next-line max-len
      'Kaby Arena is a Free-to-Play turn-based NFT game that employs the Play2Earn model to reward players with tokens and equipment through dynamic gameplay and participation in PvP tournaments.',
    icon: KABY,
  },
  MANA: {
    description:
      // eslint-disable-next-line max-len
      'Decentraland is a decentralized VR world that is powered by the Ethereum blockchain, where you can purchase land, build it, monetize, and immerse into the applications and content built by other users.',
    icon: MANA,
  },
  MUST: {
    icon: MUST,
  },
  SNK: {
    description:
      // eslint-disable-next-line max-len
      'Snook is an online, multiplayer, "Snake"-like NFT Play2Earn game. The player\'s snook character in the game is a minted NFT that is gradually developed as the player advances in the game.',
    icon: SNK,
  },
  // DPOL underlying
  ADDY: {
    description:
      // eslint-disable-next-line max-len
      'Adamant is a yield optimizer vault that consists of many farmers pooling their time and resources to collectively earn DeFi yields. Currently, it has over 100 vaults for Quickswap on the Polygon network.',
    icon: ADDY,
  },
  BIFI: {
    description:
      // eslint-disable-next-line max-len
      'Beefy Finance is a DeFi yield optimizer platform that allows its users to earn compound interest on their holdings through a set of automatic investment strategies based on other AMM projects and liquidity pools.',
    icon: BIFI,
  },
  DFYN: {
    description:
      // eslint-disable-next-line max-len
      'Dfyn is a multi-chain AMM DEX currently functional on the Polygon network. Dfyn nodes on various chains act as liquidity entry and exit points into the cross-chain liquidity super mesh that is enabled by Router Protocol.',
    icon: DFYN,
  },
  FISH: {
    description:
      // eslint-disable-next-line max-len
      'Polycat Finance is an AMM-based decentralized exchange with a deflationary token model enabling value-oriented, economically sustainable hybrid yield farming on Polygon.',
    icon: FISH,
  },
  QI: {
    description:
      // eslint-disable-next-line max-len
      'BENQI is a decentralized non-custodial liquidity market protocol built on Avalanche that enables users to effortlessly lend, borrow, and earn interest with their digital assets.',
    icon: QI,
  },
  QUICK: {
    description:
      // eslint-disable-next-line max-len
      'Quickswap is a decentralized exchange on the Polygon Network. It is based on the Uniswap V2 AMM protocol and allows users on Polygon to trade tokens through a liquidity pool.',
    icon: QUICK,
  },
  WMATIC: {
    description:
      // eslint-disable-next-line max-len
      'Wrapped Matic is a cryptocurrency that is operated on the Polygon network and is pegged to the value of the MATIC token.',
    icon: WMATIC,
  },
  // DXIN underlying
  HAUS: {
    description:
      // eslint-disable-next-line max-len
      'HausDAO is the community of contributors working together directly to design, build, and communicate the actual product. They have already raised over $50M collectively.',
    icon: HAUS,
  },
  DXD: {
    description:
      // eslint-disable-next-line max-len
      'DXdao is a collective that builds and governs DeFi products and services. Its current product suite features a prediction market, a governance-enabled AMM, a front-running resistant DEX, and a Layer 2 payment portal.',
    icon: DXD,
  },
  RAID: {
    description:
      // eslint-disable-next-line max-len
      'RaidGuild is a decentralized collective, a premier design and development agency of the Web3 ecosystem, including DAO, DeFi, dApps, and everything in between.',
    icon: RAID,
  },
  BRIGHT: {
    description:
      // eslint-disable-next-line max-len
      'Bright is a DAO powered by 1Hive Gardens and Aragon technologies. BrightID is a nonintrusive, decentralized, open-source technology seeking to reform identity verification by introducing a unique digital ID.',
    icon: BRIGHT,
  },
  RICE: {
    description:
      // eslint-disable-next-line max-len
      'DAOSquare is a DAO incubator for Web3, helping new projects with token economy solutions, technology innovating, community operating, and more.',
    icon: RICE,
  },
  DATA: {
    description:
      // eslint-disable-next-line max-len
      'Streamr is a decentralized real‑time data network that allows users to create, share and consume data streams on an open, scalable P2P protocol with powerful tools for monetization.',
    icon: DATA,
  },
  FOX: {
    description:
      // eslint-disable-next-line max-len
      'Shapeshift is a secure and non-custodial platform and wallet that allows users to send, receive, buy, trade, and store their crypto. It supports 750+ tokens across 11 blockchains and is developed by a DAO.',
    icon: FOX,
  },
  STAKE: {
    description:
      // eslint-disable-next-line max-len
      'xDai is an Ethereum sidechain and a Layer 2 scaling solution designed to be more efficient than Ethereum, with higher transaction throughput and much lower transaction fees.',
    icon: STAKE,
  },
  // DXDC underlying
  AGVE: {
    description:
      // eslint-disable-next-line max-len
      'Agave is a decentralized non-custodial money market protocol where users can participate by borrowing or lending money through the application.',
    icon: AGVE,
  },
  DHV: {
    description:
      // eslint-disable-next-line max-len
      'DeHive is a multi-chain asset management protocol that provides smart asset management of tokenized portfolios. In other words, it creates crypto indexes to maximize user profit.',
    icon: DHV,
  },
  DIP: {
    description:
      // eslint-disable-next-line max-len
      'Etherisc is a decentralized protocol developed to collectively build insurance products that will enable lower operational costs, provide greater transparency, and democratize access to reinsurance investments.',
    icon: DIP,
  },
  ELK: {
    description:
      // eslint-disable-next-line max-len
      'Elk Finance enables fast, safe, cheap, and trustless cross-chain value exchange across 14 blockchains. It offers impermanent loss protection, dynamic farming, and the first multi-chain native stablecoin.',
    icon: ELK,
  },
  GNO: {
    description:
      // eslint-disable-next-line max-len
      'Gnosis is a suite of products and a whole ecosystem building new market mechanisms for decentralized finance. Their products allow users to securely create, trade, and hold digital assets on Ethereum.',
    icon: GNO,
  },
  HNY: {
    description:
      // eslint-disable-next-line max-len
      '1Hive is a community building dApps and protocols, which uses Honey as a governance token. The community was founded by the team of Honeyswap, a fork of Uniswap on the xDAI chain.',
    icon: HNY,
  },
  MIVA: {
    description:
      // eslint-disable-next-line max-len
      'Minerva Wallet is a digital wallet for sovereign data, money, and even identities that users can create in the app and use for a growing number of DeFi tools.',
    icon: MIVA,
  },
  SYMM: {
    description:
      // eslint-disable-next-line max-len
      'Symmetric is a DAO-governed AMM and a DEX running on the Celo and xDai networks. It offers low fees, customizable pools, and a unique risk fund.',
    icon: SYMM,
  },
  // Curve.fi underlying
  DAI: {
    icon: DAI,
    color: '#ffeed3',
  },
  USDC: {
    icon: USDC,
    color: '#cfe6ff',
  },
  USDT: {
    icon: USDT,
    color: '#d0f5eb',
  },
  WBTC: {
    icon: WBTC,
    color: '#bfc5cb',
  },
  WETH: {
    icon: WETH,
    color: '#ebd3de',
  },
  WXDAI: {
    icon: xDAI,
  },
  EURT: {
    icon: EURT,
  },
  AVAX: {
    icon: AVAX,
  },
  MAI: {
    icon: MAI,
  },
  miMATIC: {
    icon: MAI,
  },
  TWT: {
    icon: TWT,
  },
  BUSDT: {
    icon: USDT,
  },
  BUSDC: {
    icon: USDC,
  },
  BUSD: {
    icon: BUSD,
  },
};

export default UNDERLYING_LIST;
