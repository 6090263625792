import PageNotFound from '@modules/static/components/PageNotFound';

const page = {
  path: '*',
  order: 999,
  title: 'STATIC.PAGE_NOT_FOUND.NAV_LINK_NAME',
  component: PageNotFound,
};

export default page;
