import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useTranslation } from 'react-i18next';
import { formatUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import AddToken from '@modules/common/components/AddToken';
import formatValueToken from '@modules/common/helpers/formatValueToken';
import formatValuePrice from '@modules/common/helpers/formatValuePrice';
import getTokenPrice from '@modules/common/helpers/getTokenPrice';
import UNDERLYING_LIST from '@configs/underlying';
import DHV_LIST from '@configs/dhv';
import constant from '@modules/common/constants';
import { useTypedSelector } from '@utils/store';
import {
  listSelector,
  isLoadingSelector as isLoadingSelectorPools,
} from '@modules/pools/slices/poolsSlice';
import {
  getPrices,
  preErrorSelector,
  isLoadingSelector as isLoadingSelectorPrice,
} from '@modules/prices/slices/tokenPricesSlice';
import {
  dataSelector,
  isLoadingSelector as isLoadingSelectorDhvSwap,
} from '@modules/dhv/slices/dhvSwapBalancesSlice';
import POOLS from '@configs/pools';
import LOADING_IMAGE from '@modules/common/assets/loading.svg';

import styles from './DHVBalance.module.scss';

const DHV_SYMBOL = 'DHV';

const DHVBalance: FC<{ className: string }> = ({ className }) => {
  const dispatch = useDispatch();

  const data = useTypedSelector(dataSelector);
  const poolList = useTypedSelector(listSelector);
  const tokenPrices = useTypedSelector(getPrices);
  const preError = useTypedSelector(preErrorSelector);
  const isLoadingPools = useTypedSelector(isLoadingSelectorPools);
  const isLoadingPrice = useTypedSelector(isLoadingSelectorPrice);
  const isLoadingDhvSwap = useTypedSelector(isLoadingSelectorDhvSwap);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { chainId, account } = useWeb3React();

  const [balanceAttempts, setBalanceAttempts] = useState(0);

  const getTokenPrices = useCallback(() => {
    dispatch({
      type: 'DHV_GET_TOKENS_PRICES_REQUESTED',
    });
  }, [dispatch]);

  useEffect(() => {
    getTokenPrices();

    const reGetTokenPrices = setInterval(
      () => getTokenPrices(),
      constant.GET_ITEMS_INTERVAL,
    );
    return clearInterval(reGetTokenPrices);
  }, [getTokenPrices]);

  useEffect(() => {
    if (preError && balanceAttempts < constant.GET_ITEMS_ATTEMPTS) {
      getTokenPrices();
      setBalanceAttempts(balanceAttempts + 1);
    }
    if (preError && balanceAttempts >= constant.GET_ITEMS_ATTEMPTS) {
      dispatch({
        type: 'DHV_SET_ERROR_TOKENS_PRICES_REQUESTED',
        payload: { error: preError },
      });
    }
  }, [balanceAttempts, dispatch, getTokenPrices, preError]);

  const dhvStaked = useMemo(() => {
    let stakedBalance = BigNumber.from(0);
    let stakedDecimals = 0;

    if (poolList && chainId) {
      Object.keys(poolList).forEach((address) =>
        Object.keys(poolList[address]).forEach((pid) => {
          const { type, userPoolAmount, decimals } =
            poolList[address][Number(pid)];

          if (
            POOLS[chainId] &&
            POOLS[chainId][address] &&
            POOLS[chainId][address][Number(pid)] &&
            type === 0 &&
            userPoolAmount
          ) {
            stakedBalance = stakedBalance.add(userPoolAmount);
            stakedDecimals = decimals;
          }
        }),
      );
    }
    return { stakedBalance, stakedDecimals };
  }, [poolList, chainId]);

  return (
    <div
      className={cn(
        styles.container,
        {
          [styles['is-loading']]:
            isLoadingPools || isLoadingPrice || isLoadingDhvSwap,
        },
        className,
      )}
    >
      <div className={styles.title}>
        <h3 className={styles.titleText}>{t('DHV.DHV_BALANCE.BLOCK_TITLE')}</h3>

        {chainId && DHV_LIST[chainId] && (
          <AddToken
            tooltipClassName={styles.titleAddTokenTooltip}
            tokenStandart={DHV_LIST[chainId].tokenStandart}
            tokenDecimals={DHV_LIST[chainId].dedcimals}
            tokenAddress={DHV_LIST[chainId].tokenAddress || ''}
            tokenSymbol={DHV_LIST[chainId].symbol}
            tokenIcon={UNDERLYING_LIST[DHV_LIST[chainId].symbol]?.icon || ''}
            className={styles.titleAddToken}
          />
        )}
      </div>

      <dl className={styles.list}>
        <dt className={styles.listTitle}>
          {t('DHV.DHV_BALANCE.DHV_IN_WALLET_TITLE')}
        </dt>

        <dd className={styles.listValue}>
          <span className={styles.listValueCount}>
            {data && account
              ? formatValueToken(
                  language,
                  Number(formatUnits(data.balanceDHV, data.decimalsDHV)),
                )
              : '-'}
          </span>

          {chainId &&
            DHV_LIST[chainId] &&
            UNDERLYING_LIST[DHV_LIST[chainId].symbol]?.icon && (
              <img
                className={styles.listValueIcon}
                src={UNDERLYING_LIST[DHV_LIST[chainId].symbol].icon}
                alt={DHV_LIST[chainId].symbol}
              />
            )}

          <small className={styles.listValuePrice}>
            {data && tokenPrices && tokenPrices[DHV_SYMBOL] && account
              ? formatValuePrice(
                  language,
                  getTokenPrice(
                    data.balanceDHV,
                    data.decimalsDHV,
                    tokenPrices[DHV_SYMBOL],
                  ),
                )
              : '-'}
          </small>
        </dd>

        <dt className={styles.listTitle}>
          {t('DHV.DHV_BALANCE.DHV_STAKED_TITLE')}
        </dt>

        <dd className={styles.listValue}>
          <span className={styles.listValueCount}>
            {dhvStaked && account
              ? formatValueToken(
                  language,
                  Number(
                    formatUnits(
                      dhvStaked.stakedBalance,
                      dhvStaked.stakedDecimals,
                    ),
                  ),
                )
              : '-'}
          </span>

          {chainId &&
            DHV_LIST[chainId] &&
            UNDERLYING_LIST[DHV_LIST[chainId].symbol]?.icon && (
              <img
                className={styles.listValueIcon}
                src={UNDERLYING_LIST[DHV_LIST[chainId].symbol].icon}
                alt={DHV_LIST[chainId].symbol}
              />
            )}

          <small className={styles.listValuePrice}>
            {dhvStaked && tokenPrices && tokenPrices[DHV_SYMBOL] && account
              ? formatValuePrice(
                  language,
                  getTokenPrice(
                    dhvStaked.stakedBalance,
                    dhvStaked.stakedDecimals,
                    tokenPrices[DHV_SYMBOL],
                  ),
                )
              : '-'}
          </small>
        </dd>
      </dl>

      {(isLoadingPools || isLoadingPrice || isLoadingDhvSwap) && (
        <img
          className={styles.loading}
          height="100"
          width="100"
          src={LOADING_IMAGE}
          alt="Loading"
        />
      )}
    </div>
  );
};

export default DHVBalance;
