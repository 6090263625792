import { FC } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import LOGO from '@modules/layout/assets/logo.png';
import LOGO_TITLE from '@modules/layout/assets/logo_title.svg';

import styles from './Logo.module.scss';

const Logo: FC<{ className?: string }> = ({ className }) => {
  return (
    <Link to="/" className={cn(styles.container, className)}>
      <img className={styles.image} src={LOGO} alt="DeHive logo" />
      <img className={styles.title} src={LOGO_TITLE} alt="DeHive logo title" />
    </Link>
  );
};

Logo.defaultProps = { className: undefined };

export default Logo;
