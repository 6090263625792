import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import useMediaQuery from '@modules/layout/hooks/useMediaQuery';

import Button from '@modules/common/components/Button';
import styles from './UnderlyingDetails.module.scss';

const UnderlyingDetails: FC<{
  description: string | undefined;
  symbol: string;
  name: string;
  icon: string | undefined;
}> = ({ description, symbol, name, icon }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const { t } = useTranslation();

  const isMobile = useMediaQuery(992);

  return (
    <>
      <Button
        onClick={() => setIsVisible(true)}
        text={t('CLUSTERS.UNDERLYING_DETAILS.BUTTON_OPEN')}
        size="medium"
        color="blue"
      />

      <Modal
        onCancel={() => setIsVisible(false)}
        visible={isVisible}
        footer={false}
        width={isMobile ? 290 : 455}
      >
        <div className={styles.modal}>
          {icon && (
            <div className={styles['modal-icon']}>
              <img src={icon} alt={symbol} />
            </div>
          )}

          <h1 className={styles['modal-title']}>{name}</h1>

          <h2 className={styles['modal-symbol']}>{symbol}</h2>

          <p className={styles['modal-description']}>{description}</p>

          <Button
            onClick={() => setIsVisible(false)}
            text={t('CLUSTERS.UNDERLYING_DETAILS.BUTTON_CLOSE')}
            className={styles['modal-btn']}
          />
        </div>
      </Modal>
    </>
  );
};

export default UnderlyingDetails;
