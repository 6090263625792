import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import formatValuePercentage from '@modules/common/helpers/formatValuePercentage';
import ARROW_DOWN from '@modules/common/assets/arrow_down.svg';
import ARROW_UP from '@modules/common/assets/arrow_up.svg';

import styles from '@modules/common/components/ValueChanges/ValueChanges.module.scss';

const getPercentChanges = (
  currentPrice: number,
  previousPrice: number,
): number => {
  if (currentPrice > previousPrice) {
    return 100 - (100 / currentPrice) * previousPrice;
  }

  if (previousPrice > currentPrice) {
    return 100 - (100 / previousPrice) * currentPrice;
  }

  return 0;
};

const ValueChanges: FC<{
  valuePrevious: number;
  valueCurrent: number;
  textBefore?: string;
  textAfter?: string;
}> = ({ valuePrevious, valueCurrent, textBefore, textAfter }) => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <div
      className={cn(styles.container, {
        [styles['container--down']]: valuePrevious > valueCurrent,
        [styles['container--up']]: valueCurrent > valuePrevious,
      })}
    >
      {textBefore && <span className={styles.text}>{textBefore}</span>}

      {valueCurrent > valuePrevious && (
        <img
          className={styles.arrow}
          height="6"
          width="8"
          src={ARROW_UP}
          alt=""
        />
      )}

      {valuePrevious > valueCurrent && (
        <img
          className={styles.arrow}
          height="6"
          width="8"
          src={ARROW_DOWN}
          alt=""
        />
      )}

      <span className={styles.text}>
        {formatValuePercentage(
          language,
          getPercentChanges(valueCurrent, valuePrevious),
        )}

        {textAfter}
      </span>
    </div>
  );
};

ValueChanges.defaultProps = {
  textBefore: undefined,
  textAfter: undefined,
};

export default ValueChanges;
