import { JsonRpcProvider } from '@ethersproject/providers';

import ProviderFeeData from '@modules/clusters/types/ProviderFeeData';

const getProviderFeeData = (
  provider: JsonRpcProvider,
): Promise<ProviderFeeData> => {
  return provider.getFeeData();
};

export default getProviderFeeData;
