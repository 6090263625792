import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { PutEffect, takeLatest, put } from 'redux-saga/effects';

import { setPreError, setError } from '@modules/prices/slices/tokenPricesSlice';

function* setErrorTokenPriceWorker({
  payload,
}: PayloadAction<{
  error: unknown;
}>): Generator<PutEffect<AnyAction>, void, never> {
  yield put(setPreError(null));
  yield put(setError(payload.error));
}

function* setErrorTokenPricesSaga(): Generator {
  yield takeLatest(
    'DHV_SET_ERROR_TOKENS_PRICES_REQUESTED',
    setErrorTokenPriceWorker,
  );
}

export default setErrorTokenPricesSaga;
