import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import getInitialObject from '@modules/common/helpers/getInitialObject';
import BooleanList from '@modules/common/types/BooleanList';
import ErrorList from '@modules/common/types/ErrorList';
import type { RootState } from '@utils/store';

interface InitialState {
  loadingList: BooleanList;
  successList: BooleanList;
  errorList: ErrorList;
}

const initialState: InitialState = {
  loadingList: getInitialObject(),
  successList: getInitialObject(),
  errorList: getInitialObject(),
};

const reducers = {
  setLoadingItem(
    state: InitialState,
    action: PayloadAction<{ tokenAddress: string; isLoading: boolean }>,
  ) {
    state.loadingList[action.payload.tokenAddress] = action.payload.isLoading;
  },
  setSuccessItem(
    state: InitialState,
    action: PayloadAction<{ tokenAddress: string; isSuccess: boolean }>,
  ) {
    state.successList[action.payload.tokenAddress] = action.payload.isSuccess;
  },
  setErrorItem(
    state: InitialState,
    action: PayloadAction<{ tokenAddress: string; error: unknown | null }>,
  ) {
    state.errorList[action.payload.tokenAddress] = action.payload.error;
  },
};

const approveSlice = createSlice({
  name: 'stakeApprove',
  initialState,
  reducers,
});

export const loadingListSelector = (state: RootState): BooleanList =>
  state.stakeApprove.loadingList;
export const successListSelector = (state: RootState): BooleanList =>
  state.stakeApprove.successList;
export const errorListSelector = (state: RootState): ErrorList =>
  state.stakeApprove.errorList;

export const { setLoadingItem, setSuccessItem, setErrorItem } =
  approveSlice.actions;

export default approveSlice.reducer;
