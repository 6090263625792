import { FC, useState, useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import { BigNumber } from '@ethersproject/bignumber';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';

import ClusterModalEstimate from '@modules/clusters/components/ClusterModalEstimate';
import ClusterModalBalance from '@modules/clusters/components/ClusterModalBalance';
import ClusterModalPrompt from '@modules/clusters/components/ClusterModalPrompt';
import ClusterModalFee from '@modules/clusters/components/ClusterModalFee';
import FormSubmit from '@modules/common/components/FormSubmit';
import FormInput from '@modules/common/components/FormInput';
import maxValueWithCommission from '@modules/common/helpers/maxValueWithCommission';
import checkMaxValue from '@modules/common/helpers/checkMaxValue';
import constants from '@modules/common/constants';
import StoredCluster from '@modules/clusters/types/StoredCluster';
import { Cluster } from '@configs/clusters';

import styles from './ClusterModalForm.module.scss';

const ClusterModalForm: FC<{
  returnedWithComission?: boolean;
  returnedDecimals: number;
  commissionValue: BigNumber | null;
  returnedSymbol: string;
  clusterAmount?: BigNumber;
  inputDecimals: number;
  inputSymbol: string;
  submitName: string;
  isAssemble?: boolean;
  isDisabled?: boolean;
  isLoading: boolean;
  inputName: string;
  formName: string;
  onFinish: ((values: { [key: string]: string }) => void) | undefined;
  maxValue?: BigNumber | null;
  price: BigNumber | null;
  name: StoredCluster['name'] | Cluster['name'];
  form: FormInstance;
}> = ({
  returnedWithComission,
  returnedDecimals,
  commissionValue,
  returnedSymbol,
  clusterAmount,
  inputDecimals,
  inputSymbol,
  submitName,
  isAssemble,
  isDisabled,
  isLoading,
  inputName,
  formName,
  onFinish,
  maxValue,
  price,
  name,
  form,
}) => {
  const [inputValue, setInputValue] = useState<string | null>(null);

  const { account } = useWeb3React();

  const isMinValueBigger: boolean = useMemo(() => {
    return checkMaxValue(
      constants.INPUT_MINIMAL_STEP,
      inputDecimals,
      returnedWithComission
        ? maxValue
        : maxValueWithCommission(commissionValue, maxValue),
    );
  }, [returnedWithComission, commissionValue, inputDecimals, maxValue]);

  return (
    <Form
      onFieldsChange={(changedFields) => {
        changedFields.map((item) => {
          const field = item as { name: string[]; value: string };

          if (field.name[0] === inputName) {
            setInputValue(field.value);
          }

          return item;
        });
      }}
      initialValues={{ [inputName]: '0' }}
      className={styles.container}
      onFinish={onFinish}
      form={form}
      name={formName}
    >
      {!isAssemble && (
        <ClusterModalPrompt className={styles.prompt} symbol={inputSymbol} />
      )}

      <ClusterModalBalance
        className={styles.balance}
        decimals={inputDecimals}
        balance={maxValue}
        symbol={inputSymbol}
        name={name}
      />

      <FormInput
        isDisabled={isDisabled || isLoading || !account || isMinValueBigger}
        className={styles.input}
        inputName={inputName}
        maxValue={
          returnedWithComission
            ? maxValue
            : maxValueWithCommission(commissionValue, maxValue)
        }
        decimals={inputDecimals}
        form={form}
      />

      <FormSubmit
        isDisabled={isDisabled || isMinValueBigger}
        isLoading={isLoading}
        className={styles.submit}
        color={!isAssemble ? 'polygon' : undefined}
        name={submitName}
        size="large"
      />

      {returnedWithComission && (
        <ClusterModalFee
          commissionValue={commissionValue}
          className={styles.fee}
          decimals={returnedDecimals}
          symbol={returnedSymbol}
        />
      )}

      <ClusterModalEstimate
        commissionValue={returnedWithComission ? commissionValue : null}
        clusterAmount={clusterAmount}
        inputValue={inputValue}
        isAssemble={isAssemble}
        decimals={returnedDecimals}
        symbol={returnedSymbol}
        price={price}
      />
    </Form>
  );
};

ClusterModalForm.defaultProps = {
  returnedWithComission: false,
  clusterAmount: undefined,
  isAssemble: false,
  isDisabled: false,
  maxValue: undefined,
};

export default ClusterModalForm;
