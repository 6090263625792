import { FC } from 'react';
import { useWeb3React } from '@web3-react/core';
import cn from 'classnames';

import StoredPool from '@modules/pools/types/StoredPool';
import POOLS from '@configs/pools';

import styles from '@modules/pools/components/PoolCardIcon/PoolCardIcon.module.scss';

const PoolCardIcon: FC<{
  poolGroupAddress: string;
  poolSymbol: StoredPool['symbol'];
  className: string;
  poolId: number;
}> = ({ poolGroupAddress, poolSymbol, className, poolId }) => {
  const { chainId } = useWeb3React();

  return chainId &&
    POOLS[chainId] &&
    POOLS[chainId][poolGroupAddress] &&
    POOLS[chainId][poolGroupAddress][poolId]?.icon ? (
    <div
      className={cn(styles.container, className, {
        [styles['container--solo']]:
          POOLS[chainId][poolGroupAddress][poolId].type === 0,
        [styles['container--cluster']]:
          POOLS[chainId][poolGroupAddress][poolId].type === 1,
        [styles['container--lp']]:
          POOLS[chainId][poolGroupAddress][poolId].type === 2,
        [styles['container--impulse']]:
          POOLS[chainId][poolGroupAddress][poolId].type === 3,
        [styles['container--stable']]:
          POOLS[chainId][poolGroupAddress][poolId].type === 4,
      })}
    >
      <img
        className={styles.icon}
        src={POOLS[chainId][poolGroupAddress][poolId].icon}
        alt={poolSymbol}
      />
    </div>
  ) : null;
};

export default PoolCardIcon;
