import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import {
  CallEffect,
  PutEffect,
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { JsonRpcProvider } from '@ethersproject/providers';
import { ContractInterface, Contract } from '@ethersproject/contracts';
import { parseUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';

import {
  setClusterAmountItem,
  setErrorItem,
} from '@modules/clusters/slices/amountsSlice';
import getClusterAmountFromEth from '@modules/clusters/actions/getClusterAmountFromEth';
import getUnderlyingInfo from '@modules/clusters/actions/getUnderlyingInfo';

type ReturnedClusterAmount = [BigNumber, BigNumber];

const getClusterAmount = (
  controllerAddress: string,
  clusterDecimals: number,
  clusterAddress: string,
  controllerABI: ContractInterface,
  provider: JsonRpcProvider,
): Promise<ReturnedClusterAmount> => {
  const controllerContract = new Contract(
    controllerAddress,
    controllerABI,
    provider,
  );

  return Promise.all([
    getClusterAmountFromEth(
      controllerContract,
      clusterDecimals,
      clusterAddress,
      '1',
    ),
    getUnderlyingInfo(
      controllerContract,
      clusterAddress,
      parseUnits('1', clusterDecimals),
    ),
  ]);
};

function* getClusterAmountWorker({
  payload,
}: PayloadAction<{
  controllerAddress: string;
  clusterDecimals: number;
  clusterAddress: string;
  controllerABI: ContractInterface;
  library: JsonRpcProvider;
}>): Generator<
  CallEffect<ReturnedClusterAmount> | PutEffect<AnyAction>,
  void,
  never
> {
  try {
    yield put(
      setErrorItem({
        clusterAddress: payload.clusterAddress,
        error: null,
      }),
    );

    const result = yield call(
      getClusterAmount,
      payload.controllerAddress,
      payload.clusterDecimals,
      payload.clusterAddress,
      payload.controllerABI,
      payload.library,
    );

    yield put(
      setClusterAmountItem({
        clusterAddress: payload.clusterAddress,
        amountFromEth: result[0],
        ethFromAmount: result[1][3],
      }),
    );
  } catch (error: unknown) {
    yield put(
      setErrorItem({
        clusterAddress: payload.clusterAddress,
        error,
      }),
    );
  }
}

function* getClusterAmountSaga(): Generator {
  yield takeLatest(
    'CLUSTERS_GET_CLUSTER_AMOUNT_REQUESTED',
    getClusterAmountWorker,
  );
}

export default getClusterAmountSaga;
