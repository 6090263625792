const formatValueToken = (locales: string, value: number): string => {
  const formatter = new Intl.NumberFormat(locales, {
    maximumFractionDigits: 6,
    style: 'decimal',
  });

  const updatedValue = Math.trunc(value * 10 ** 6) / 10 ** 6;

  return formatter.format(updatedValue < 0.000001 ? 0 : updatedValue);
};

export default formatValueToken;
