import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';

import { setIsLoading } from '@modules/connections/slices/connectionsSlice';

function* setIsLoadingWorker({ payload }: PayloadAction<boolean>) {
  yield put(setIsLoading(payload));
}

function* isLoadingSaga(): Generator {
  yield takeLatest('CONNECTIONS_IS_LOADING_REQUESTED', setIsLoadingWorker);
}

export default isLoadingSaga;
