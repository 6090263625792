import { parseUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';

const checkMaxValue = (
  value: string,
  decimals: number | undefined,
  maxValue?: BigNumber | null,
): boolean => {
  return !!(
    value &&
    maxValue &&
    decimals &&
    BigNumber.from(parseUnits(value, decimals)).gt(maxValue)
  );
};

export default checkMaxValue;
