import { FC, useMemo, useState, useEffect, useCallback } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import qs from 'query-string';
import cn from 'classnames';

import UnderlyingTable from '@modules/clusters/components/UnderlyingTable';
import ClusterInfo from '@modules/clusters/components/ClusterInfo';
import ErrorBlock from '@modules/common/components/ErrorBlock';
import NotFound from '@modules/common/components/NotFound';
import AddToken from '@modules/common/components/AddToken';
import PoolCard from '@modules/pools/components/PoolCard';
import {
  isLoadingSelector as isLoadingSelectorUnderlying,
  preErrorSelector as preErrorSelectorUnderlying,
  listSelector as listSelectorUnderlying,
} from '@modules/underlying/slices/underlyingSlice';
import {
  isLoadingSelector as isLoadingSelectorClusters,
  preErrorSelector as preErrorSelectorClusters,
  errorSelector as errorSelectorClusters,
  listSelector as listSelectorClusters,
} from '@modules/clusters/slices/clustersSlice';
import {
  isLoadingSelector as isLoadingSelectorPools,
  preErrorSelector as preErrorSelectorPools,
  listSelector as listSelectorPools,
} from '@modules/pools/slices/poolsSlice';
import { preErrorSelector as preErrorSelectorPoolsStats } from '@modules/pools/slices/poolsStatsSlice';
import { successListSelector as successListSelectorDisassemble } from '@modules/clusters/slices/disassembleSlice';
import { successListSelector as successListSelectorAssemble } from '@modules/clusters/slices/assembleSlice';
import { preErrorSelector as pricesPreError } from '@modules/prices/slices/tokenPricesSlice';
import { preErrorSelector as currencyPreError } from '@modules/prices/slices/currencySlice';
import { preErrorSelector as preErrorSelectorPoolsTvl } from '@modules/pools/slices/poolsTvlSlices';
import { successListSelector as successListSelectorUnstake } from '@modules/pools/slices/unstakeSlice';
import { successListSelector as successListSelectorApprove } from '@modules/pools/slices/approveSlice';
import { successListSelector as successListSelectorStake } from '@modules/pools/slices/stakeSlice';
import { successListSelector as successListSelectorReward } from '@modules/pools/slices/rewardSlice';
import errorOpenModalDisassemble from '@modules/clusters/helpers/errorOpenModalDisassemble';
import errorOpenModalAssemble from '@modules/clusters/helpers/errorOpenModalAssemble';
import errorOpenModalUnstake from '@modules/pools/helpers/errorOpenModalUnstake';
import errorOpenModalReward from '@modules/pools/helpers/errorOpenModalReward';
import errorOpenModalStake from '@modules/pools/helpers/errorOpenModalStake';
import { useTypedSelector } from '@utils/store';
import StoredClusterUnderlying from '@modules/clusters/types/StoredClusterUnderlying';
import UnderlyingItem from '@modules/underlying/types/UnderlyingItem';
import constantsCluster from '@modules/clusters/constants';
import constantsCommon from '@modules/common/constants';
import constantsPools from '@modules/pools/constants';
import LOADING_IMAGE from '@modules/common/assets/loading.svg';
import CONTROLLERS from '@configs/controllers';
import CLUSTERS from '@configs/clusters';
import POOLS from '@configs/pools';

import styles from '@modules/clusters/components/ClusterDetails/ClusterDetails.module.scss';

const ClusterDetails: FC = () => {
  const [attemptUnderlying, setAttemptUnderlying] = useState<number>(0);
  const [attemptCluster, setAttemptCluster] = useState<number>(0);
  const [attemptPool, setAttemptPool] = useState<number>(0);
  const [balanceAttempts, setBalanceAttempts] = useState(0);
  const [currencyAttempts, setCurrencyAttempts] = useState(0);
  const [attemptPoolsTvl, setAttemptPoolsTvl] = useState<number>(0);
  const [attemptPoolStats, setAttemptPoolStats] = useState<number>(0);

  const { t } = useTranslation();

  const { search } = useLocation<{ search: string }>();
  const { path } = useParams<{ path: string }>();

  const dispatch = useDispatch();

  const { chainId, account, library } = useWeb3React<Web3Provider>();

  const successListDisassemble = useTypedSelector(
    successListSelectorDisassemble,
  );
  const successListAssemble = useTypedSelector(successListSelectorAssemble);
  const isLoadingUnderlying = useTypedSelector(isLoadingSelectorUnderlying);
  const preErrorUnderlying = useTypedSelector(preErrorSelectorUnderlying);
  const underlyingList = useTypedSelector(listSelectorUnderlying);
  const isLoadingClusters = useTypedSelector(isLoadingSelectorClusters);
  const preErrorClusters = useTypedSelector(preErrorSelectorClusters);
  const errorClusters = useTypedSelector(errorSelectorClusters);
  const clusters = useTypedSelector(listSelectorClusters);
  const isLoadingPools = useTypedSelector(isLoadingSelectorPools);
  const preErrorPools = useTypedSelector(preErrorSelectorPools);
  const pools = useTypedSelector(listSelectorPools);
  const preErrorPoolsStats = useTypedSelector(preErrorSelectorPoolsStats);
  const preErrorPoolsTvl = useTypedSelector(preErrorSelectorPoolsTvl);
  const preErrorPrices = useTypedSelector(pricesPreError);
  const preErrorCurrency = useTypedSelector(currencyPreError);
  const successListUnstake = useTypedSelector(successListSelectorUnstake);
  const successListApprove = useTypedSelector(successListSelectorApprove);
  const successListStake = useTypedSelector(successListSelectorStake);
  const successListReward = useTypedSelector(successListSelectorReward);

  const token: string | null = useMemo(() => {
    let value: string | null = null;

    if (chainId && CLUSTERS[chainId]) {
      Object.keys(CLUSTERS[chainId]).forEach((address: string) => {
        if (path === CLUSTERS[chainId][address].path) {
          value = address;
        }
      });
    }

    return value;
  }, [chainId, path]);

  const getUnderlyingList = useCallback(() => {
    if (
      clusters &&
      chainId &&
      library &&
      token &&
      clusters[token] &&
      POOLS[chainId] &&
      CLUSTERS[chainId] &&
      CLUSTERS[chainId][token]
    ) {
      const list: UnderlyingItem[] = [];

      clusters[token].underlyingList.forEach(
        ({ address }: StoredClusterUnderlying) => {
          list.push({
            underlyingABI: CLUSTERS[chainId][token].underlyingABI,
            address,
          });
        },
      );

      Object.values(POOLS[chainId])
        .map((pool) => Object.values(pool))
        .flat()
        .forEach(({ symbol, pendingRewards, tokenABI }) => {
          if (symbol === clusters[token].symbol) {
            pendingRewards?.forEach((address) => {
              list.push({
                underlyingABI: tokenABI,
                address,
              });
            });
          }
        });

      dispatch({
        type: 'UNDERLYING_GET_UNDERLYING_LIST_REQUESTED',
        payload: { underlyingList: list, library },
      });
    }
  }, [dispatch, clusters, chainId, library, token]);

  const getCluster = useCallback(() => {
    if (
      chainId &&
      library &&
      token &&
      CLUSTERS[chainId] &&
      CLUSTERS[chainId][token]
    ) {
      dispatch({
        type: 'CLUSTERS_GET_CLUSTER_REQUESTED',
        payload: {
          clusterAddress: token,
          clusters: CLUSTERS[chainId],
          controller: CONTROLLERS[chainId],
          library,
          account,
        },
      });
    }
  }, [dispatch, chainId, library, account, token]);

  const getCurrencyPrice = useCallback(() => {
    dispatch({ type: 'PRICES_GET_CURRENCY' });
  }, [dispatch]);

  const getTokenPrices = useCallback(() => {
    dispatch({ type: 'DHV_GET_TOKENS_PRICES_REQUESTED' });
  }, [dispatch]);

  const getPoolsStats = useCallback(() => {
    if (clusters && token && clusters[token]?.symbol && chainId) {
      dispatch({
        type: 'POOLS_GET_POOL_STATS',
        payload: {
          pools: Object.values(POOLS[chainId])
            .map((pool) => Object.values(pool))
            .flat()
            .map(({ symbol }) => symbol)
            .filter((symbol) => symbol === clusters[token].symbol),
          chainId,
        },
      });
    }
  }, [dispatch, clusters, token, chainId]);

  const getPoolsTvl = useCallback(() => {
    if (clusters && token && clusters[token]?.symbol && chainId) {
      dispatch({
        type: 'POOLS_GET_POOLS_TLV',
        payload: {
          pools: Object.values(POOLS[chainId])
            .map((pool) => Object.values(pool))
            .flat()
            .map(({ symbol }) => symbol)
            .filter((symbol) => symbol === clusters[token].symbol),
          chainId,
        },
      });
    }
  }, [dispatch, clusters, token, chainId]);

  const getPool = useCallback(() => {
    if (
      chainId &&
      library &&
      token &&
      POOLS[chainId] &&
      CLUSTERS[chainId] &&
      CLUSTERS[chainId][token] &&
      CLUSTERS[chainId][token].poolAddress
    ) {
      dispatch({
        type: 'POOLS_GET_POOL_REQUESTED',
        payload: {
          poolAddress: CLUSTERS[chainId][token].poolAddress,
          poolId: CLUSTERS[chainId][token].poolId,
          pools: POOLS[chainId],
          controller: CONTROLLERS[chainId],
          library,
          account,
        },
      });
    }
  }, [dispatch, chainId, library, account, token]);

  useEffect(() => {
    getUnderlyingList();

    const reGetUnderlyingList = setInterval(
      () => getUnderlyingList(),
      constantsCommon.GET_ITEMS_INTERVAL,
    );

    return () => clearInterval(reGetUnderlyingList);
  }, [getUnderlyingList]);

  useEffect(() => {
    getCluster();

    const reGetCluster = setInterval(
      () => getCluster(),
      constantsCommon.GET_ITEMS_INTERVAL,
    );

    return () => clearInterval(reGetCluster);
  }, [getCluster]);

  useEffect(() => {
    if (token && successListAssemble[token]) {
      getCluster();
    }
  }, [successListAssemble, getCluster, token]);

  useEffect(() => {
    if (token && successListDisassemble[token]) {
      getCluster();
    }
  }, [successListDisassemble, getCluster, token]);

  useEffect(() => {
    getCurrencyPrice();

    const reGetCurrencyPrice = setInterval(
      () => getCurrencyPrice(),
      constantsCommon.GET_ITEMS_INTERVAL,
    );

    return clearInterval(reGetCurrencyPrice);
  }, [getCurrencyPrice]);

  useEffect(() => {
    getTokenPrices();

    const reGetTokenPrices = setInterval(
      () => getTokenPrices(),
      constantsCommon.GET_ITEMS_INTERVAL,
    );

    return clearInterval(reGetTokenPrices);
  }, [getTokenPrices]);

  useEffect(() => {
    getPoolsStats();

    const reGetPoolsStats = setInterval(
      () => getPoolsStats(),
      constantsCommon.GET_ITEMS_INTERVAL,
    );

    return () => clearInterval(reGetPoolsStats);
  }, [getPoolsStats]);

  useEffect(() => {
    getPoolsTvl();

    const reGetPoolsTvl = setInterval(
      () => getPoolsTvl(),
      constantsCommon.GET_ITEMS_INTERVAL,
    );

    return () => clearInterval(reGetPoolsTvl);
  }, [getPoolsTvl]);

  useEffect(() => {
    getPool();

    const reGetPool = setInterval(
      () => getPool(),
      constantsCommon.GET_ITEMS_INTERVAL,
    );

    return () => clearInterval(reGetPool);
  }, [getPool]);

  useEffect(() => {
    if (
      successListUnstake ||
      successListApprove ||
      successListStake ||
      successListReward
    ) {
      getPool();
    }
  }, [
    successListUnstake,
    getPool,
    successListApprove,
    successListStake,
    successListReward,
  ]);

  useEffect(() => {
    if (
      preErrorClusters &&
      attemptCluster < constantsCommon.GET_ITEMS_ATTEMPTS
    ) {
      getCluster();

      setAttemptCluster(attemptCluster + 1);
    }

    if (
      preErrorClusters &&
      attemptCluster >= constantsCommon.GET_ITEMS_ATTEMPTS
    ) {
      dispatch({
        type: 'CLUSTERS_SET_ERROR_CLUSTER_REQUESTED',
        payload: { error: preErrorClusters },
      });
    }
  }, [preErrorClusters, getCluster, dispatch, attemptCluster]);

  useEffect(() => {
    if (clusters && chainId && account && library && token && clusters[token]) {
      dispatch({
        type: 'CLUSTERS_GET_CLUSTER_COMMISSION_DISASSEMBLE_REQUESTED',
        payload: {
          clusterDecimals: clusters[token].decimals,
          clusterAddress: token,
          clusters: CLUSTERS[chainId],
          library,
          account,
        },
      });

      dispatch({
        type: 'CLUSTERS_GET_CLUSTER_COMMISSION_ASSEMBLE_REQUESTED',
        payload: {
          clusterDecimals: clusters[token].decimals,
          clusterAddress: token,
          clusters: CLUSTERS[chainId],
          library,
          account,
        },
      });
    }
  }, [dispatch, clusters, chainId, account, library, token]);

  useEffect(() => {
    if (
      preErrorCurrency &&
      currencyAttempts < constantsCommon.GET_ITEMS_ATTEMPTS
    ) {
      getCurrencyPrice();
      setCurrencyAttempts(currencyAttempts + 1);
    }
    if (
      preErrorCurrency &&
      currencyAttempts >= constantsCommon.GET_ITEMS_ATTEMPTS
    ) {
      dispatch({
        type: 'PRICE_SET_ERROR_CURRENCY_PRICE',
        payload: { error: preErrorCurrency },
      });
    }
  }, [currencyAttempts, dispatch, getCurrencyPrice, preErrorCurrency]);

  useEffect(() => {
    if (
      preErrorPrices &&
      balanceAttempts < constantsCommon.GET_ITEMS_ATTEMPTS
    ) {
      getTokenPrices();
      setBalanceAttempts(balanceAttempts + 1);
    }
    if (
      preErrorPrices &&
      balanceAttempts >= constantsCommon.GET_ITEMS_ATTEMPTS
    ) {
      dispatch({
        type: 'DHV_SET_ERROR_TOKENS_PRICES_REQUESTED',
        payload: { error: preErrorPrices },
      });
    }
  }, [balanceAttempts, dispatch, getTokenPrices, preErrorPrices]);

  useEffect(() => {
    if (
      preErrorPoolsStats &&
      attemptPoolStats < constantsCommon.GET_ITEMS_ATTEMPTS
    ) {
      getPoolsStats();

      setAttemptPoolStats(attemptPoolStats + 1);
    }

    if (
      preErrorPoolsStats &&
      attemptPoolStats >= constantsCommon.GET_ITEMS_ATTEMPTS
    ) {
      dispatch({
        type: 'POOLS_SET_ERROR_POOLS_STATS',
        payload: { error: preErrorPoolsStats },
      });
    }
  }, [preErrorPoolsStats, getPoolsStats, dispatch, attemptPoolStats]);

  useEffect(() => {
    if (
      preErrorPoolsTvl &&
      attemptPoolsTvl < constantsCommon.GET_ITEMS_ATTEMPTS
    ) {
      getPoolsTvl();

      setAttemptPoolsTvl(attemptPoolsTvl + 1);
    }

    if (
      preErrorPoolsTvl &&
      attemptPoolsTvl >= constantsCommon.GET_ITEMS_ATTEMPTS
    ) {
      dispatch({
        type: 'POOLS_SET_ERROR_POOLS_TVL',
        payload: { error: preErrorPoolsTvl },
      });
    }
  }, [preErrorPoolsTvl, getPoolsTvl, dispatch, attemptPoolsTvl]);

  useEffect(() => {
    if (preErrorPools && attemptPool < constantsCommon.GET_ITEMS_ATTEMPTS) {
      getPool();

      setAttemptPool(attemptPool + 1);
    }

    if (preErrorPools && attemptPool >= constantsCommon.GET_ITEMS_ATTEMPTS) {
      dispatch({
        type: 'POOLS_SET_ERROR_POOL_REQUESTED',
        payload: { error: preErrorPools },
      });
    }
  }, [preErrorPools, getPool, dispatch, attemptPool]);

  useEffect(() => {
    if (
      preErrorUnderlying &&
      attemptUnderlying < constantsCommon.GET_ITEMS_ATTEMPTS
    ) {
      getUnderlyingList();

      setAttemptUnderlying(attemptUnderlying + 1);
    }

    if (
      preErrorUnderlying &&
      attemptUnderlying >= constantsCommon.GET_ITEMS_ATTEMPTS
    ) {
      dispatch({
        type: 'UNDERLYING_SET_ERROR_UNDERLYING_REQUESTED',
        payload: { error: preErrorUnderlying },
      });
    }
  }, [preErrorUnderlying, getUnderlyingList, dispatch, attemptUnderlying]);

  useEffect(() => {
    if (
      clusters &&
      chainId &&
      library &&
      token &&
      CONTROLLERS[chainId] &&
      clusters[token]
    ) {
      dispatch({
        type: 'CLUSTERS_GET_CLUSTER_AMOUNT_REQUESTED',
        payload: {
          controllerAddress: CONTROLLERS[chainId].controllerAddress,
          clusterDecimals: clusters[token].decimals,
          clusterAddress: token,
          controllerABI: CONTROLLERS[chainId].controllerABI,
          library,
        },
      });
    }
  }, [dispatch, clusters, chainId, library, token]);

  useEffect(() => {
    const searchList = qs.parse(search);

    if (
      searchList[constantsCluster.MODAL_PATH_DISASSEMBLE] &&
      searchList[constantsCluster.MODAL_PATH_DISASSEMBLE] !== token
    ) {
      errorOpenModalDisassemble();
    }

    if (
      searchList[constantsCluster.MODAL_PATH_ASSEMBLE] &&
      searchList[constantsCluster.MODAL_PATH_ASSEMBLE] !== token
    ) {
      errorOpenModalAssemble();
    }

    if (
      chainId &&
      POOLS[chainId] &&
      searchList[constantsPools.MODAL_PATH_UNSTAKE]
    ) {
      let availablePool = false;

      Object.keys(POOLS[chainId])?.forEach((address: string) => {
        Object.keys(POOLS[chainId][address])?.forEach((pid: string) => {
          if (
            searchList[constantsPools.MODAL_PATH_UNSTAKE] ===
            `${pid}_${address}`
          ) {
            availablePool = true;
          }
        });
      });

      if (!availablePool) {
        errorOpenModalUnstake();
      }
    }

    if (
      chainId &&
      POOLS[chainId] &&
      searchList[constantsPools.MODAL_PATH_STAKE]
    ) {
      let availablePool = false;

      Object.keys(POOLS[chainId])?.forEach((address: string) => {
        Object.keys(POOLS[chainId][address])?.forEach((pid: string) => {
          if (
            searchList[constantsPools.MODAL_PATH_STAKE] === `${pid}_${address}`
          ) {
            availablePool = true;
          }
        });
      });

      if (!availablePool) {
        errorOpenModalStake();
      }
    }

    if (
      chainId &&
      POOLS[chainId] &&
      searchList[constantsPools.MODAL_PATH_REWARD]
    ) {
      let availablePool = false;

      Object.keys(POOLS[chainId])?.forEach((address: string) => {
        Object.keys(POOLS[chainId][address])?.forEach((pid: string) => {
          if (
            searchList[constantsPools.MODAL_PATH_REWARD] === `${pid}_${address}`
          ) {
            availablePool = true;
          }
        });
      });

      if (!availablePool) {
        errorOpenModalReward();
      }
    }
  }, [chainId, search, token]);

  return chainId &&
    !(token && CLUSTERS[chainId] && CLUSTERS[chainId][token]) ? (
    <Redirect to="/clusters" />
  ) : (
    <article className={styles.container}>
      {token && chainId && CLUSTERS[chainId] && CLUSTERS[chainId][token] && (
        <section
          className={cn(styles.info, {
            [styles['is-loading']]: isLoadingClusters,
          })}
        >
          {clusters && clusters[token] && (
            <div className={styles.title}>
              <h1 className={styles.titleText}>
                {CLUSTERS[chainId][token].name || clusters[token].name}
              </h1>

              <AddToken
                tooltipClassName={styles.titleAddTokenTooltip}
                tokenStandart={CLUSTERS[chainId][token].tokenStandart}
                tokenDecimals={clusters[token].decimals}
                tokenAddress={token}
                tokenSymbol={clusters[token].symbol}
                tokenIcon={
                  window.location.origin + CLUSTERS[chainId][token].tokenIcon
                }
                className={styles.titleAddToken}
              />
            </div>
          )}

          {clusters && clusters[token] && (
            <ClusterInfo clusterAddress={token} cluster={clusters[token]} />
          )}

          {isLoadingClusters && (
            <img
              className={styles.loading}
              height="100"
              width="100"
              src={LOADING_IMAGE}
              alt=""
            />
          )}
        </section>
      )}

      {token &&
        chainId &&
        CLUSTERS[chainId] &&
        CLUSTERS[chainId][token] &&
        CLUSTERS[chainId][token].poolId !== undefined &&
        CLUSTERS[chainId][token].poolAddress !== undefined && (
          <section
            className={cn(styles.pool, {
              [styles['is-loading']]: isLoadingPools,
            })}
          >
            {pools &&
              pools[CLUSTERS[chainId][token].poolAddress || ''] &&
              pools[CLUSTERS[chainId][token].poolAddress || ''][
                CLUSTERS[chainId][token].poolId || 0
              ] && (
                <PoolCard
                  poolGroupAddress={CLUSTERS[chainId][token].poolAddress || ''}
                  poolId={CLUSTERS[chainId][token].poolId || 0}
                  pool={
                    pools[CLUSTERS[chainId][token].poolAddress || ''][
                      CLUSTERS[chainId][token].poolId || 0
                    ]
                  }
                />
              )}

            {isLoadingPools && (
              <img
                className={styles.loading}
                height="100"
                width="100"
                src={LOADING_IMAGE}
                alt=""
              />
            )}
          </section>
        )}

      {token && chainId && CLUSTERS[chainId] && CLUSTERS[chainId][token] && (
        <section
          className={cn(styles.underlying, {
            [styles['is-loading']]: isLoadingUnderlying,
          })}
        >
          {clusters && clusters[token] && underlyingList && (
            <UnderlyingTable
              clusterUnderlyingList={clusters[token].underlyingList}
              clusterUserBalance={clusters[token].userBalance}
              clusterDecimals={clusters[token].decimals}
              clusterAddress={token}
              stakedUserBalance={
                pools &&
                pools[CLUSTERS[chainId][token].poolAddress || ''] &&
                pools[CLUSTERS[chainId][token].poolAddress || ''][
                  CLUSTERS[chainId][token].poolId || 0
                ]?.userPoolAmount
              }
              stakedDecimals={
                pools && pools[CLUSTERS[chainId][token].poolAddress || '']
                  ? pools[CLUSTERS[chainId][token].poolAddress || ''][
                      CLUSTERS[chainId][token].poolId || 0
                    ]?.decimals
                  : 0
              }
              underlyingList={underlyingList}
            />
          )}

          {isLoadingUnderlying && (
            <img
              className={styles.loading}
              height="100"
              width="100"
              src={LOADING_IMAGE}
              alt=""
            />
          )}
        </section>
      )}

      {chainId && !(token && CLUSTERS[chainId] && CLUSTERS[chainId][token]) && (
        <NotFound title={t('CLUSTERS.NOT_FOUND.BLOCK_TITLE')} />
      )}

      {chainId && CLUSTERS[chainId] && !clusters && errorClusters && (
        <ErrorBlock title={t('CLUSTERS.ERROR_BLOCK.BLOCK_TITLE')} />
      )}
    </article>
  );
};

export default ClusterDetails;
