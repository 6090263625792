import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';

const getPoolGasPriceDepositUnderlying = (
  poolContract: Contract,
  poolId: number,
  amountList: BigNumber[],
): Promise<BigNumber> => {
  return poolContract.estimateGas.depositInUnderlying(poolId, amountList);
};

export default getPoolGasPriceDepositUnderlying;
