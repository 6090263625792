import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';

const getClusterGasPriceAssemble = (
  clusterContract: Contract,
  clusterAmount: BigNumber,
  ethAmount: BigNumber,
): Promise<BigNumber> => {
  return clusterContract.estimateGas.assemble(clusterAmount, true, {
    value: ethAmount,
  });
};

export default getClusterGasPriceAssemble;
