import { notification } from 'antd';

const errorOpenModalReward = (): void => {
  notification.error({
    message: 'Rewards pool',
    description: 'Here is not available a pool that you try to claim.',
  });
};

export default errorOpenModalReward;
