import { BigNumber } from '@ethersproject/bignumber';

import ContractFunctions from '@modules/common/types/ContractFunctions';

const getTokenBalanceOf = (
  tokenContract: ContractFunctions<BigNumber>,
  account: string,
): Promise<BigNumber> => {
  return tokenContract.balanceOf(account);
};

export default getTokenBalanceOf;
