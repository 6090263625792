import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BigNumber } from '@ethersproject/bignumber';

import type { RootState } from '@utils/store';

interface InitialState {
  isLoading: boolean;
  isSuccess: boolean;
  preError: unknown | null;
  error: unknown | null;
  value: BigNumber | null;
}

const initialState: InitialState = {
  isLoading: false,
  isSuccess: false,
  preError: null,
  error: null,
  value: null,
};

const reducers = {
  setIsLoading(state: InitialState, action: PayloadAction<boolean>) {
    state.isLoading = action.payload;
  },
  setIsSuccess(state: InitialState, action: PayloadAction<boolean>) {
    state.isSuccess = action.payload;
  },
  setPreError(state: InitialState, action: PayloadAction<unknown | null>) {
    state.preError = action.payload;
  },
  setError(state: InitialState, action: PayloadAction<unknown | null>) {
    state.error = action.payload;
  },
  setValue(state: InitialState, action: PayloadAction<BigNumber>) {
    state.value = action.payload;
  },
};

const dhvSwapAllowance = createSlice({
  name: 'dhvSwapAllowance',
  initialState,
  reducers,
});

export const isLoadingSelector = (state: RootState): boolean =>
  state.dhvSwapAllowance.isLoading;
export const isSuccessSelector = (state: RootState): boolean =>
  state.dhvSwapAllowance.isSuccess;
export const preErrorSelector = (state: RootState): unknown | null =>
  state.dhvSwapAllowance.preError;
export const errorSelector = (state: RootState): unknown | null =>
  state.dhvSwapAllowance.error;
export const valueSelector = (state: RootState): BigNumber | null =>
  state.dhvSwapAllowance.value;

export const { setIsLoading, setIsSuccess, setPreError, setError, setValue } =
  dhvSwapAllowance.actions;

export default dhvSwapAllowance.reducer;
