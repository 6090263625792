import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { RootState } from '@utils/store';
import StoredClusterList from '@modules/clusters/types/StoredClusterList';
import StoredCluster from '@modules/clusters/types/StoredCluster';

interface InitialState {
  isLoading: boolean;
  preError: unknown | null;
  error: unknown | null;
  list: StoredClusterList | null;
}

const initialState: InitialState = {
  isLoading: false,
  preError: null,
  error: null,
  list: null,
};

const reducers = {
  setIsLoading(state: InitialState, action: PayloadAction<boolean>) {
    state.isLoading = action.payload;
  },
  setListItem(
    state: InitialState,
    action: PayloadAction<{ clusterAddress: string; cluster: StoredCluster }>,
  ) {
    if (state.list) {
      state.list[action.payload.clusterAddress] = action.payload.cluster;
    } else {
      state.list = {
        [action.payload.clusterAddress]: action.payload.cluster,
      };
    }
  },
  setPreError(state: InitialState, action: PayloadAction<unknown | null>) {
    state.preError = action.payload;
  },
  setError(state: InitialState, action: PayloadAction<unknown | null>) {
    state.error = action.payload;
  },
  setList(state: InitialState, action: PayloadAction<StoredClusterList>) {
    state.list = action.payload;
  },
};

const clustersSlice = createSlice({
  name: 'clusters',
  initialState,
  reducers,
});

export const isLoadingSelector = (state: RootState): boolean =>
  state.clusters.isLoading;
export const preErrorSelector = (state: RootState): unknown | null =>
  state.clusters.preError;
export const errorSelector = (state: RootState): unknown | null =>
  state.clusters.error;
export const listSelector = (state: RootState): StoredClusterList | null =>
  state.clusters.list;

export const { setIsLoading, setListItem, setPreError, setError, setList } =
  clustersSlice.actions;

export default clustersSlice.reducer;
