import * as Sentry from '@sentry/react';
import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import {
  CallEffect,
  PutEffect,
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { JsonRpcProvider } from '@ethersproject/providers';
import {
  ContractTransaction,
  ContractInterface,
  ContractReceipt,
  Contract,
} from '@ethersproject/contracts';
import { BigNumber } from '@ethersproject/bignumber';

import {
  setLoadingItem,
  setSuccessItem,
  setErrorItem,
} from '@modules/pools/slices/approveSlice';
import handleTokenApprove from '@modules/common/actions/handleTokenApprove';
import getApprovePoolGasLimit from '@modules/pools/actions/getApprovePoolGasLimit';

const getGasLimit = (
  poolTokenAddress: string,
  poolTokenABI: ContractInterface,
  poolAddress: string,
  amount: BigNumber,
  provider: JsonRpcProvider,
  account: string,
): Promise<BigNumber> => {
  const poolContract = new Contract(
    poolTokenAddress,
    poolTokenABI,
    provider.getSigner(account),
  );

  return getApprovePoolGasLimit(poolContract, poolAddress, amount);
};

const approvePool = (
  poolTokenAddress: string,
  poolTokenABI: ContractInterface,
  poolAddress: string,
  amount: BigNumber,
  provider: JsonRpcProvider,
  account: string,
  gasLimit: BigNumber,
): Promise<BigNumber> => {
  const poolContract = new Contract(
    poolTokenAddress,
    poolTokenABI,
    provider.getSigner(account),
  );

  return handleTokenApprove(poolContract, poolAddress, amount, gasLimit);
};

function* approvePoolWorker({
  payload,
}: PayloadAction<{
  poolTokenAddress: string;
  poolTokenABI: ContractInterface;
  poolAddress: string;
  amount: BigNumber;
  library: JsonRpcProvider;
  account: string;
}>): Generator<
  CallEffect<BigNumber> | CallEffect<ContractReceipt> | PutEffect<AnyAction>,
  void,
  never
> {
  try {
    yield put(
      setLoadingItem({
        tokenAddress: payload.poolTokenAddress,
        isLoading: true,
      }),
    );
    yield put(
      setSuccessItem({
        tokenAddress: payload.poolTokenAddress,
        isSuccess: false,
      }),
    );
    yield put(
      setErrorItem({
        tokenAddress: payload.poolTokenAddress,
        error: null,
      }),
    );

    const gasLimit: BigNumber = yield call(
      getGasLimit,
      payload.poolTokenAddress,
      payload.poolTokenABI,
      payload.poolAddress,
      payload.amount,
      payload.library,
      payload.account,
    );

    const { wait }: ContractTransaction = yield call(
      approvePool,
      payload.poolTokenAddress,
      payload.poolTokenABI,
      payload.poolAddress,
      payload.amount,
      payload.library,
      payload.account,
      gasLimit.mul(120).div(100),
    );

    yield call(wait, 1);

    yield put(
      setSuccessItem({
        tokenAddress: payload.poolTokenAddress,
        isSuccess: true,
      }),
    );
  } catch (error: unknown) {
    yield put(
      setErrorItem({
        tokenAddress: payload.poolTokenAddress,
        error,
      }),
    );
    Sentry.captureException(
      `Approve underlying error,
      ' token address ${payload.poolTokenAddress}',
      ' pool address ${payload.poolAddress}',
      ' amount ${Number(payload.amount)}',
      ' account ${payload.account}'`,
    );
  } finally {
    yield put(
      setLoadingItem({
        tokenAddress: payload.poolTokenAddress,
        isLoading: false,
      }),
    );
  }
}

function* approvePoolSaga(): Generator {
  yield takeLatest('POOLS_APPROVE_UNDERLYING_REQUESTED', approvePoolWorker);
}

export default approvePoolSaga;
