import { FC, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { BigNumber } from '@ethersproject/bignumber';
import { Form } from 'antd';
import cn from 'classnames';

import PoolStakeFormEnable from '@modules/pools/components/PoolStakeFormEnable';
import PoolStakeFormSubmit from '@modules/pools/components/PoolStakeFormSubmit';
import PoolStakeFormPrompt from '@modules/pools/components/PoolStakeFormPrompt';
import PoolStakeFormDesc from '@modules/pools/components/PoolStakeFormDesc';
import PoolStakeFormDHV from '@modules/pools/components/PoolStakeFormDHV';
import Button from '@modules/common/components/Button';
import { loadingListSelector } from '@modules/pools/slices/approveSlice';
import checkMaxValue from '@modules/common/helpers/checkMaxValue';
import UnderlyingFormItem from '@modules/pools/types/UnderlyingFormItem';
import { useTypedSelector } from '@utils/store';
import constants from '@modules/common/constants';
import ENABLED_ICON from '@modules/pools/assets/enabled_icon.svg';
import POOLS, { Pool } from '@configs/pools';

import styles from './PoolStakeForm.module.scss';

const { useForm } = Form;

const PoolStakeForm: FC<{
  userTotalLockedDHV?: BigNumber | null;
  userPoolAmountDHV?: BigNumber | null;
  userBalanceDHV?: BigNumber | null;
  decimalsDHV?: number;
  symbolDHV?: string;
  сlusterRateAccuracy?: BigNumber;
  clusterRate?: BigNumber;
  allowance: (BigNumber | null)[];
  underlyingFormList: UnderlyingFormItem[];
  poolAddress: string;
  poolName: string;
  poolType: number | null;
  poolId: number;
  isDisabled: Pool['closed'];
  isLoading: boolean;
}> = ({
  userTotalLockedDHV,
  userPoolAmountDHV,
  userBalanceDHV,
  decimalsDHV,
  symbolDHV,
  сlusterRateAccuracy,
  clusterRate,
  allowance,
  underlyingFormList,
  poolAddress,
  poolName,
  poolType,
  poolId,
  isDisabled,
  isLoading,
}) => {
  const [inputValueList, setInputValueList] = useState<BigNumber[]>(
    underlyingFormList.map(() => BigNumber.from('0')),
  );

  const { t } = useTranslation();

  const loadingList = useTypedSelector(loadingListSelector);

  const { chainId } = useWeb3React();

  const [form] = useForm();

  const isSingleMinValueBigger: boolean = useMemo(() => {
    return underlyingFormList[0]
      ? checkMaxValue(
          constants.INPUT_MINIMAL_STEP,
          underlyingFormList[0].decimals,
          underlyingFormList[0].userBalance,
        )
      : false;
  }, [underlyingFormList]);

  const isSingleAllowanceSmaller: boolean = useMemo(() => {
    return !(
      allowance[0]?.gt(BigNumber.from('0')) &&
      inputValueList[0].lte(allowance[0] || BigNumber.from('0'))
    );
  }, [inputValueList, allowance]);

  const onInputChange = useCallback(
    (index: number, value: BigNumber) => {
      setInputValueList(
        inputValueList.map((item, innerIndex) =>
          innerIndex === index ? value : item,
        ),
      );
    },
    [setInputValueList, inputValueList],
  );

  const handleEnable = useCallback(() => form.submit(), [form]);

  return (
    <div className={cn(styles.form, clusterRate && styles['form-clusterRate'])}>
      {chainId &&
        POOLS[chainId]?.[poolAddress]?.[poolId]?.platformDescription &&
        underlyingFormList.length > 1 && (
          <PoolStakeFormDesc
            tokensCount={underlyingFormList.length}
            text={POOLS[chainId][poolAddress][poolId].platformDescription}
          />
        )}

      {underlyingFormList.length === 1 && (
        <PoolStakeFormPrompt
          symbol={
            poolType === 2 || poolType === 3
              ? poolName
              : underlyingFormList[0]?.symbol
          }
        />
      )}

      {userPoolAmountDHV &&
        userBalanceDHV &&
        decimalsDHV &&
        symbolDHV &&
        clusterRate && (
          <PoolStakeFormDHV
            userPoolAmountDHV={userPoolAmountDHV}
            clusterRate={clusterRate}
            decimalsDHV={decimalsDHV}
            symbolDHV={symbolDHV}
            poolName={poolName}
          />
        )}

      {underlyingFormList?.map(
        (item, index) =>
          !item.isHidden && (
            <PoolStakeFormEnable
              userTotalLockedDHV={userTotalLockedDHV}
              userPoolAmountDHV={userPoolAmountDHV}
              сlusterRateAccuracy={сlusterRateAccuracy}
              clusterRate={clusterRate}
              underlyingIndex={index}
              tokenAddress={item.tokenAddress}
              poolAddress={poolAddress}
              poolName={poolName}
              poolId={poolId}
              userBalance={item.userBalance}
              allowance={allowance[index]}
              decimals={item.decimals}
              symbol={item.symbol}
              onInputChange={onInputChange}
              formEnable={underlyingFormList.length === 1 ? form : undefined}
              className={cn(styles.enable, {
                [styles['enable--single']]: underlyingFormList.length === 1,
              })}
              isDisabled={isDisabled}
              isLoading={isLoading}
              isSingle={underlyingFormList.length === 1}
              key={item.symbol}
            />
          ),
      )}

      <div
        className={cn(
          styles['form-buttons'],
          underlyingFormList.length === 1 && styles['form-buttons--group'],
        )}
      >
        {underlyingFormList.length === 1 && !isSingleAllowanceSmaller && (
          <div className={styles['form-buttons--check']}>
            <img height="24" width="24" src={ENABLED_ICON} alt="" />
            <span>{t('POOLS.POOL_STAKE_FORM.ENABLED_TEXT')}</span>
          </div>
        )}

        {underlyingFormList.length === 1 && isSingleAllowanceSmaller && (
          <Button
            isLoading={
              isLoading ||
              !!(
                underlyingFormList[0]?.tokenAddress &&
                loadingList[underlyingFormList[0].tokenAddress]
              )
            }
            disabled={
              isLoading ||
              isDisabled ||
              isSingleMinValueBigger ||
              !!(
                underlyingFormList[0]?.tokenAddress &&
                loadingList[underlyingFormList[0].tokenAddress]
              )
            }
            onClick={handleEnable}
            text={t('POOLS.POOL_STAKE_FORM.BUTTON_TITLE')}
          />
        )}

        <PoolStakeFormSubmit
          poolAddress={poolAddress}
          poolId={poolId}
          underlyingList={underlyingFormList}
          inputValueList={inputValueList}
          allowance={allowance}
          isDisabled={isDisabled}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

PoolStakeForm.defaultProps = {
  userTotalLockedDHV: undefined,
  userPoolAmountDHV: undefined,
  userBalanceDHV: undefined,
  decimalsDHV: undefined,
  symbolDHV: undefined,
  сlusterRateAccuracy: undefined,
  clusterRate: undefined,
};

export default PoolStakeForm;
