import { JsonRpcProvider } from '@ethersproject/providers';
import { ContractInterface, Contract } from '@ethersproject/contracts';
import { BigNumber } from '@ethersproject/bignumber';

import getTotalLockedDhvByUser from '@modules/pools/actions/getTotalLockedDhvByUser';
import getClusterRateAccuracy from '@modules/pools/actions/getClusterRateAccuracy';
import getUserPoolAmount from '@modules/pools/actions/getUserPoolAmount';
import getClusterRate from '@modules/pools/actions/getClusterRate';
import getPoolInfo from '@modules/pools/actions/getPoolInfo';
import PoolInfo from '@modules/pools/types/PoolInfo';

const getPoolDHV = (
  clusterAddress: string,
  dhvPoolAddress: string,
  dhvPoolABI: ContractInterface,
  dhvPoolId: number,
  provider: JsonRpcProvider,
  account?: string,
): Promise<
  [BigNumber | null, BigNumber | null, BigNumber, BigNumber, PoolInfo]
> => {
  const dhvContract = new Contract(
    dhvPoolAddress,
    dhvPoolABI,
    account ? provider.getSigner(account) : provider,
  );

  return Promise.all([
    account ? getTotalLockedDhvByUser(dhvContract, account) : null,
    account ? getUserPoolAmount(dhvContract, dhvPoolId, account) : null,
    getClusterRateAccuracy(dhvContract),
    getClusterRate(dhvContract, clusterAddress),
    getPoolInfo(dhvContract, dhvPoolId),
  ]);
};

export default getPoolDHV;
