import POOLS from '@configs/pools';

interface InitialObject {
  [key: string]: {
    [pid: number]: null;
  };
}

const getInitialObject = (): InitialObject => {
  let initialObject: InitialObject | null = null;

  Object.keys(POOLS)?.forEach((item: string) => {
    Object.keys(POOLS[Number(item)])?.forEach((address: string) => {
      Object.keys(POOLS[Number(item)][address])?.forEach((pid: string) => {
        if (initialObject) {
          if (initialObject[address]) {
            initialObject[address][Number(pid)] = null;
          } else {
            initialObject[address] = {
              [Number(pid)]: null,
            };
          }
        } else {
          initialObject = {
            [address]: {
              [Number(pid)]: null,
            },
          };
        }
      });
    });
  });

  if (!initialObject) {
    initialObject = {};
  }

  return initialObject;
};

export default getInitialObject;
