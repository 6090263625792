import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import CHART_ICON_1 from '@modules/clusters/assets/charts/chart_1.png';
import CHART_ICON_2 from '@modules/clusters/assets/charts/chart_2.png';
import CHART_ICON_3 from '@modules/clusters/assets/charts/chart_3.png';
import CHART_ICON_4 from '@modules/clusters/assets/charts/chart_4.png';
import CHART_ICON_5 from '@modules/clusters/assets/charts/chart_5.png';
import CHART_ICON_6 from '@modules/clusters/assets/charts/chart_6.png';
import CHART_ICON_7 from '@modules/clusters/assets/charts/chart_7.png';

import styles from '@modules/clusters/components/ClusterCardChart/ClusterCardChart.module.scss';

const chartImageList = [
  CHART_ICON_1,
  CHART_ICON_2,
  CHART_ICON_3,
  CHART_ICON_4,
  CHART_ICON_5,
  CHART_ICON_6,
  CHART_ICON_7,
];

const ClusterCardChart: FC<{ className: string; name: string }> = ({
  className,
  name,
}) => {
  const { t } = useTranslation();

  return (
    <img
      className={cn(styles.image, className)}
      height="122"
      width="162"
      src={chartImageList[Math.floor(Math.random() * 7)]}
      alt={t('CLUSTERS.CLUSTER_CARD_CHART.IMAGE_ALT_TEXT', { name })}
    />
  );
};

export default ClusterCardChart;
