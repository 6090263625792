import { useContext } from 'react';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import WalletConnectQRCodeModal from '@walletconnect/qrcode-modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { useWeb3React } from '@web3-react/core';

import { WalletConnectContext } from '@modules/connections/context/WalletConnectContext';
import Connector from '@modules/connections/types/Connector';
import constants from '@modules/connections/constants';
import getRPC from '@modules/connections/helpers/getRPC';
import icon from '@modules/connections/assets/walletconnect_logo.svg';
import NETWORKS from '@configs/networks';

interface RpcInterface {
  [key: number]: string;
}

// Fix wallet connect issue: https://github.com/Web3Modal/web3modal/issues/38
const getWalletConnectProvider = (
  rpc: RpcInterface,
  chainId: number,
  deactivateConnection: () => void,
): Promise<WalletConnectProvider> => {
  const provider = new WalletConnectProvider({
    chainId,
    rpc,
    bridge: 'https://bridge.walletconnect.org',
    pollingInterval: constants.POLLING_INTERVAL,
    qrcodeModalOptions: {
      mobileLinks: ['metamask', 'ledger', 'trust'],
    },
  });

  return new Promise<WalletConnectProvider>((resolve, reject) => {
    if (provider.wc.clientMeta) {
      provider.wc.clientMeta.name = 'DeHive App';
      provider.wc.clientMeta.icons = [`${window.location.origin}/logo192.png`];
    }

    provider.wc.on('disconnect', () => {
      WalletConnectQRCodeModal.close();

      localStorage.removeItem(
        `settings-connection-network-${
          process.env.REACT_APP_NETWORK_ENVIRONMENT || 'test'
        }`,
      );
      localStorage.removeItem(
        `settings-connection-name-${
          process.env.REACT_APP_NETWORK_ENVIRONMENT || 'test'
        }`,
      );

      deactivateConnection();

      reject(new Error('Connection refused'));
    });

    provider
      .enable()
      .then(() => resolve(provider))
      .catch(reject);
  });
};

const WalletConnect = (chainId: number): (() => Promise<void>) => {
  const { handleChangeProvider } = useContext(WalletConnectContext);
  const { activate, deactivate } = useWeb3React();

  const rpc: RpcInterface = {};

  NETWORKS.forEach(({ chainId: id }) => {
    rpc[id] = getRPC(id);
  });

  const deactivateConnection = () => {
    handleChangeProvider(null);
    deactivate();
  };

  return () =>
    getWalletConnectProvider(rpc, chainId, deactivateConnection)
      .then((provider) => {
        const network = new WalletConnectConnector({ rpc });
        network.walletConnectProvider = provider;

        handleChangeProvider(provider);

        return activate(network);
      })
      .catch(() => {
        deactivateConnection();
      });
};

const WalletDisconnect = (): (() => Promise<void> | void) => {
  const { provider } = useContext(WalletConnectContext);
  const { deactivate } = useWeb3React();

  if (provider) {
    return () => provider.disconnect();
  }

  return () => deactivate();
};

const connector: Connector = {
  type: 'walletconnect',
  name: 'WalletConnect',
  icon,
  connect: WalletConnect,
  disconnect: WalletDisconnect,
};

export default connector;
