import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { PutEffect, takeLatest, put } from 'redux-saga/effects';

import { setPreError, setError } from '@modules/main/slices/productsSlice';

function* setErrorProductsWorker({
  payload,
}: PayloadAction<{
  error: unknown;
}>): Generator<PutEffect<AnyAction>, void, never> {
  yield put(setPreError(null));
  yield put(setError(payload.error));
}

function* setErrorProductsSaga(): Generator {
  yield takeLatest('MAIN_SET_ERROR_PRODUCTS', setErrorProductsWorker);
}

export default setErrorProductsSaga;
