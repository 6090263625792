import { BigNumber } from '@ethersproject/bignumber';

import ContractFunctions from '@modules/common/types/ContractFunctions';

const approveTokenAction = (
  tokenContract: ContractFunctions<BigNumber>,
  routerAddress: string,
  amount: BigNumber,
): Promise<BigNumber> => {
  return tokenContract.approve(routerAddress, amount);
};

export default approveTokenAction;
