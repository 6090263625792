import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import StoredCluster from '@modules/clusters/types/StoredCluster';

import styles from './ClusterModalPrompt.module.scss';

const ClusterModalPrompt: FC<{
  className?: string;
  symbol: StoredCluster['symbol'];
}> = ({ className, symbol }) => {
  const { t } = useTranslation();

  return (
    <p className={cn(styles.text, className)}>
      {t('CLUSTERS.CLUSTER_MODAL_PROMPT.TEXT', { symbol })}
    </p>
  );
};

ClusterModalPrompt.defaultProps = { className: undefined };

export default ClusterModalPrompt;
