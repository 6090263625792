import { parseUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';

import ContractFunctions from '@modules/common/types/ContractFunctions';

const handleClusterDisassemble = (
  clusterContract: ContractFunctions<BigNumber>,
  clusterDecimals: number,
  amount: string,
): Promise<BigNumber> => {
  return clusterContract.disassemble(
    BigNumber.from(parseUnits(amount, clusterDecimals)),
    true,
  );
};

export default handleClusterDisassemble;
