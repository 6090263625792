import NETWORKS from '@configs/networks';

const getTokenScanLink = (chainId: number, tokenAddress: string): string => {
  const networkData = NETWORKS.find(
    ({ chainId: networkId }) => chainId === networkId,
  );

  return networkData?.explorers
    ? `${networkData.explorers[0].url}/address/${tokenAddress}`
    : '';
};

export default getTokenScanLink;
