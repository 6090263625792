import { FC } from 'react';
import { IntercomProvider } from 'react-use-intercom';

const INTERCOM_APP_ID: string =
  process.env.REACT_APP_INTERCOM_ID || 'test-app-id';

const IntercomWrapper: FC = ({ children }) => {
  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>{children}</IntercomProvider>
  );
};

export default IntercomWrapper;
