import { JsonRpcProvider } from '@ethersproject/providers';
import { ContractInterface, Contract } from '@ethersproject/contracts';
import { BigNumber } from '@ethersproject/bignumber';

import getTokenAllowance from '@modules/common/actions/getTokenAllowance';
import { PoolUnderlying } from '@configs/pools';

const getPoolAllowance = (
  poolTokenList: PoolUnderlying[],
  poolTokenABI: ContractInterface,
  poolAddress: string,
  provider: JsonRpcProvider,
  account?: string,
): Promise<(BigNumber | null)[]> => {
  return Promise.all(
    poolTokenList.map(({ address }: PoolUnderlying) => {
      const tokenContract = new Contract(address, poolTokenABI, provider);

      return account
        ? getTokenAllowance(tokenContract, poolAddress, account)
        : null;
    }),
  );
};

export default getPoolAllowance;
