import { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import cn from 'classnames';
import useMediaQuery from '@modules/layout/hooks/useMediaQuery';
import Button from '@modules/common/components/Button';

import QUESTION_MODAL from '@modules/portfolio/assets/question_modal.svg';
import CROSSCHAIN_IMG from '@modules/portfolio/assets/crosschain_modal_img.png';

import styles from './PortfolioDHVModal.module.scss';

const PortfolioDHVModal: FC = () => {
  const [isInstructionVisible, setIsInstructionVisible] =
    useState<boolean>(false);

  const { t } = useTranslation();

  const isMobile = useMediaQuery(991);

  return (
    <>
      <Button
        className={styles.button}
        onClick={() => setIsInstructionVisible(true)}
        text={t('PORTFOLIO.PORTFOLIO_DHV_MODAL.BUTTON')}
        color="blue"
      />

      <Modal
        onCancel={() => setIsInstructionVisible(false)}
        visible={isInstructionVisible}
        footer={false}
        width={isMobile ? 500 : 700}
      >
        <div className={styles.modal}>
          <img
            src={CROSSCHAIN_IMG}
            className={styles['modal-img']}
            alt="CrossChain scheme"
          />
          <h1 className={styles['modal-title']}>
            {t('PORTFOLIO.PORTFOLIO_DHV_MODAL.TITLE')}
          </h1>

          <p className={styles['modal-description']}>
            <Trans
              i18nKey="PORTFOLIO.PORTFOLIO_DHV_MODAL.DESCRIPTION"
              components={[
                <a
                  href="https://anyswap.exchange/#/router"
                  target="_blank"
                  rel="noreferrer"
                  key={0}
                >
                  {' '}
                </a>,
              ]}
            />
          </p>

          <a
            href="https://anyswap.exchange/#/router"
            target="_blank"
            rel="noreferrer"
            className={cn(
              styles['modal-button'],
              styles['modal-button--gradient'],
            )}
          >
            {t('PORTFOLIO.PORTFOLIO_DHV_MODAL.LINK')}
          </a>

          <footer className={styles['modal-footer']}>
            <a
              href="https://medium.com/dehive/step-by-step-guide-how-to-move-
            dhv-to-other-chains-aaf0ecf50c81"
              target="_blank"
              rel="noreferrer"
              className={styles['modal-footer--link']}
            >
              <img src={QUESTION_MODAL} alt="Question icon" />
              <span>{t('PORTFOLIO.PORTFOLIO_DHV_MODAL.INSTRUCTION')}</span>
            </a>
          </footer>
        </div>
      </Modal>
    </>
  );
};

export default PortfolioDHVModal;
