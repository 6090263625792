import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { Tooltip } from 'antd';

import useMediaQuery from '@modules/layout/hooks/useMediaQuery';
import ButtonLink from '@modules/common/components/ButtonLink';
import QUESTION_ICONS from '@modules/portfolio/assets/question_table.svg';
import CLUSTERS from '@configs/clusters';
import POOLS from '@configs/pools';

import styles from './PortfolioMore.module.scss';

const PortfolioMore: FC<{ poolGroupAddress: string; poolId: number }> = ({
  poolGroupAddress,
  poolId,
}) => {
  const { t } = useTranslation();

  const { chainId } = useWeb3React();

  const isMobile = useMediaQuery(991);

  return chainId &&
    POOLS[chainId] &&
    POOLS[chainId][poolGroupAddress] &&
    POOLS[chainId][poolGroupAddress][poolId] ? (
    <>
      {POOLS[chainId][poolGroupAddress][poolId].type === 0 && (
        <ButtonLink
          className={styles.button}
          color="blue"
          size="medium"
          text={t('PORTFOLIO.PORTFOLIO_MORE.DETAILS')}
          path="/dhv"
        />
      )}

      {POOLS[chainId][poolGroupAddress][poolId].type === 1 &&
        Object.keys(CLUSTERS[chainId])?.map((clusterAddress: string) => {
          return CLUSTERS[chainId][clusterAddress].poolAddress ===
            poolGroupAddress &&
            CLUSTERS[chainId][clusterAddress].poolId === poolId ? (
            <ButtonLink
              className={styles.button}
              color="blue"
              size="medium"
              text={t('PORTFOLIO.PORTFOLIO_MORE.DETAILS')}
              path={`/clusters/${CLUSTERS[chainId][clusterAddress].path}`}
              key={clusterAddress}
            />
          ) : null;
        })}

      {POOLS[chainId][poolGroupAddress][poolId].description && (
        <Tooltip
          overlayClassName={styles.descriptionTooltip}
          placement="topRight"
          trigger={isMobile ? 'click' : 'hover'}
          title={POOLS[chainId][poolGroupAddress][poolId].description}
        >
          <img
            className={styles.description}
            height="30"
            width="30"
            src={QUESTION_ICONS}
            alt=""
          />
        </Tooltip>
      )}
    </>
  ) : null;
};

export default PortfolioMore;
