import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonLink from '@modules/common/components/ButtonLink';
import BLAIZE_LOGO from '@modules/layout/assets/secured/blaize_logo.svg';
import ZOKYO_LOGO from '@modules/layout/assets/secured/zokyo_logo.svg';
import HACKEN_LOGO from '@modules/layout/assets/secured/hacken_logo.svg';

import styles from './SecuredBy.module.scss';

const securedByList = [
  {
    title: 'Blaize.tech',
    link: 'https://blaize.tech/',
    logo: BLAIZE_LOGO,
  },
  {
    title: 'Zokyo',
    link:
      'https://github.com/DeHive-finance/' +
      'dehive-audits/blob/main/DeHive-Zokyo-Audit.pdf',
    logo: ZOKYO_LOGO,
  },
  {
    title: 'Hacken',
    link:
      'https://github.com/DeHive-finance/' +
      'dehive-audits/blob/main/DeHive-Hacken-Audit.pdf',
    logo: HACKEN_LOGO,
  },
];

const SecuredBy: FC = () => {
  const { t } = useTranslation();

  return (
    <section>
      <h3 className={styles.title}>{t('LAYOUT.SECURED_BY.TITLE')}</h3>

      <ul className={styles.list}>
        {securedByList.map(({ link, title, logo }) => (
          <li className={styles.listItem} key={title}>
            <ButtonLink
              isExternal
              className={styles.listItemLink}
              title={title}
              color="gray"
              size="extraSmall"
              icon={logo}
              path={link}
            />
          </li>
        ))}
      </ul>
    </section>
  );
};

export default SecuredBy;
