import en from './en/index.json';

const locales = {
  en: {
    name: 'English',
    nativeName: 'English',
    translation: en, // eslint-disable-line
  },
};

export default locales;
