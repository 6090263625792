import { Contract } from '@ethersproject/contracts';
import { JsonRpcProvider } from '@ethersproject/providers';
import { ControllerData } from '@configs/controllers';
import { BigNumber } from '@ethersproject/bignumber';

import getClusterPrice from '@modules/clusters/actions/getClusterPrice';

const getClusterPriceHelper = (
  clusterAddress: string,
  provider: JsonRpcProvider,
  controller: ControllerData,
): Promise<BigNumber> => {
  const clusterController = new Contract(
    controller.controllerAddress,
    controller.controllerABI,
    provider,
  );
  return getClusterPrice(clusterController, clusterAddress);
};

export default getClusterPriceHelper;
