import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { PutEffect, takeLatest, put } from 'redux-saga/effects';

import {
  setSuccessItem,
  setErrorItem,
} from '@modules/clusters/slices/disassembleSlice';

function* resetDisassembleClusterWorker({
  payload,
}: PayloadAction<{
  clusterAddress: string;
}>): Generator<PutEffect<AnyAction>, void, never> {
  yield put(
    setSuccessItem({
      clusterAddress: payload.clusterAddress,
      isSuccess: false,
    }),
  );
  yield put(
    setErrorItem({
      clusterAddress: payload.clusterAddress,
      error: null,
    }),
  );
}

function* resetDisassembleClusterSaga(): Generator {
  yield takeLatest(
    'CLUSTERS_RESET_DISASSEMBLE_CLUSTER_REQUESTED',
    resetDisassembleClusterWorker,
  );
}

export default resetDisassembleClusterSaga;
