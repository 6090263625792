import { FC, useState, useContext, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { Form, Modal, notification } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import TagManager from 'react-gtm-module';
import { NavLink } from 'react-router-dom';

import {
  inProgressSelector,
  isLoadingSelector,
  connectedSelector,
} from '@modules/connections/slices/connectionsSlice';
import { NetworkSelectorContext } from '@modules/networks/context/NetworkSelectorContext';
import ConnectorsDisconnects from '@modules/connections/types/ConnectorsDisconnects';
import ConnectorsConnects from '@modules/connections/types/ConnectorsConnects';
import Connector from '@modules/connections/types/Connector';
import injectedConnector from '@modules/connections/connectors/Injected';
import walletConnect from '@modules/connections/connectors/walletConnect';
import unstoppableConnector from '@modules/connections/connectors/unstapableDomains';
import onDisconnect from '@modules/connections/helpers/onDisconnect';
import useMediaQuery from '@modules/layout/hooks/useMediaQuery';
import { useTypedSelector } from '@utils/store';
import Button from '@modules/common/components/Button';

import LOADING_IMAGE from '@modules/common/assets/loading.svg';
import QuestionIcon from '../../assets/question_icon.svg';

import styles from './WalletConnections.module.scss';

const connectors: Connector[] = [
  injectedConnector,
  walletConnect,
  unstoppableConnector,
];

const connections = {} as ConnectorsConnects;

const disconnections = {} as ConnectorsDisconnects;

const makeShortAddress = (
  address: string,
  start = 0,
  median = 6,
  end = 4,
  lessDots?: boolean,
): string =>
  `${address.substring(start, median)}${
    lessDots ? '..' : '...'
  }${address.substring(address.length - end)}`;

const WalletConnections: FC<{
  textButton?: string;
  className?: string;
  isInline?: boolean;
  isBlue?: boolean;
  size?: 'big' | 'small';
}> = ({ textButton, className, isInline, isBlue, size }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { chainId } = useContext(NetworkSelectorContext);

  const { account } = useWeb3React();

  const inProgress = useTypedSelector(inProgressSelector);
  const isLoading = useTypedSelector(isLoadingSelector);
  const connected = useTypedSelector(connectedSelector);

  const [isVisibleWallets, setIsVisibleWallets] = useState<boolean>(false);
  const [isVisibleTerms, setIsVisibleTerms] = useState<boolean>(false);
  const [checkTerms, setCheckTerms] = useState<boolean>(false);

  const isMobile = useMediaQuery(991);

  const setAgree = () => {
    setIsVisibleTerms(false);
    setIsVisibleWallets(true);
    localStorage.setItem(
      `terms-and-privacy-${
        process.env.REACT_APP_NETWORK_ENVIRONMENT || 'test'
      }`,
      'true',
    );
  };

  const onCheckTerms = () => {
    setCheckTerms((prev) => !prev);
  };

  connectors.map(({ disconnect, connect, type }) => {
    disconnections[type] = disconnect();
    connections[type] = connect(chainId);

    return type;
  });

  const handleConnect = (connectorType: string) => {
    const connect = connections[connectorType];

    if (connect) {
      dispatch({
        type: 'CONNECTIONS_IN_PROGRESS_REQUESTED',
        payload: connectorType,
      });
      dispatch({
        type: 'CONNECTIONS_IS_LOADING_REQUESTED',
        payload: true,
      });

      onDisconnect(disconnections, connected);

      setTimeout(() => {
        connect()
          .then(() => {
            // There is an issue when the user drops metamask connection
            dispatch({
              type: 'CONNECTIONS_CONNECTED_REQUESTED',
              payload: connectorType,
            });

            setIsVisibleWallets(false);

            localStorage.setItem(
              `settings-connection-network-${
                process.env.REACT_APP_NETWORK_ENVIRONMENT || 'test'
              }`,
              chainId.toString(),
            );
            localStorage.setItem(
              `settings-connection-name-${
                process.env.REACT_APP_NETWORK_ENVIRONMENT || 'test'
              }`,
              connectorType,
            );
          })
          .catch((err: Error) => {
            notification.error({
              message: t('CONNECTIONS.WALLET_CONNECTIONS.CONNECTION_ERROR'),
              description: err.message || err,
            });
          })
          .finally(() => {
            dispatch({
              type: 'CONNECTIONS_IN_PROGRESS_REQUESTED',
              payload: '',
            });
            dispatch({
              type: 'CONNECTIONS_IS_LOADING_REQUESTED',
              payload: false,
            });
          });
      });
    }
  };

  const handleDisconnect = (connectorType: string) => {
    onDisconnect(disconnections, connectorType);
  };

  const accountAddress = useMemo(() => {
    if (account) {
      const udName = localStorage.getItem('username');
      const userDomainName =
        udName && (JSON.parse(udName) as { value: string; expiresAt: number });
      const timeStamp = new Date().getTime();

      if (
        connected === unstoppableConnector.type &&
        userDomainName &&
        userDomainName?.value &&
        userDomainName?.expiresAt > timeStamp
      ) {
        return userDomainName.value;
      }

      return isMobile
        ? makeShortAddress(account, 2, 7, 2, true)
        : makeShortAddress(account);
    }

    return textButton;
  }, [account, connected, isMobile, textButton]);

  const showModal = () => {
    const termModal = localStorage.getItem(
      `terms-and-privacy-${
        process.env.REACT_APP_NETWORK_ENVIRONMENT || 'test'
      }`,
    );
    if (termModal) {
      setIsVisibleWallets(true);
    } else {
      setIsVisibleTerms(true);
    }
  };

  useEffect(() => {
    if (account) {
      TagManager.dataLayer({
        dataLayer: {
          userId: account,
        },
      });
      if (connected) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'event',
            eventCategory: 'connectWallet',
            eventAction: `connectWallet |
           ${window.location.pathname} |
            ${connected}`,
            eventLabel: account,
          },
        });
      }
    }
  }, [account, connected]);

  return (
    <>
      {isInline ? (
        <button className={styles.button} onClick={showModal} type="button">
          {accountAddress}
        </button>
      ) : (
        <Button
          className={className}
          onClick={showModal}
          color={isBlue ? 'polygon' : undefined}
          size={size}
          text={accountAddress}
        />
      )}
      <Modal
        onCancel={() => setIsVisibleTerms(false)}
        visible={isVisibleTerms}
        footer={false}
        width={isMobile ? 290 : 550}
      >
        <Form onFinish={setAgree} className={styles['modal-terms']}>
          <p>{t('CONNECTIONS.TERMS_OF_USE.TERMS_TEXT')}</p>
          <div className={styles['modal-terms--agree']}>
            <input
              type="checkbox"
              onClick={onCheckTerms}
              className={styles.check}
              required
            />
            <p>
              {t('CONNECTIONS.TERMS_OF_USE.TERMS_TEXT_AGREE')}{' '}
              <NavLink
                onClick={() => setIsVisibleTerms(false)}
                exact
                to="/terms-of-use"
                className={styles.link}
              >
                {t('CONNECTIONS.TERMS_OF_USE.TERMS_LINK_TERMS')}
              </NavLink>{' '}
              {t('CONNECTIONS.TERMS_OF_USE.TERMS_TEXT_AND')}{' '}
              <NavLink
                onClick={() => setIsVisibleTerms(false)}
                exact
                to="/privacy-policy"
                className={styles.link}
              >
                {t('CONNECTIONS.TERMS_OF_USE.TERMS_LINK_PRIVACY')}
              </NavLink>
            </p>
          </div>
          <Button
            text={t('CONNECTIONS.TERMS_OF_USE.TERMS_BUTTON')}
            className={styles['modal-terms--btn']}
            isSubmit
            disabled={!checkTerms}
          />
        </Form>
      </Modal>

      <Modal
        onCancel={() => setIsVisibleWallets(false)}
        visible={isVisibleWallets}
        footer={false}
        width={isMobile ? 290 : 550}
        bodyStyle={
          isMobile
            ? { padding: '45px 8px 30px' }
            : { padding: '60px 56px 50px' }
        }
      >
        <div className={styles.modal}>
          <h4>{t('CONNECTIONS.WALLET_CONNECTIONS.MODAL_TITLE')}</h4>
          <div className={styles.learn}>
            <div className={styles.icon}>
              <img src={QuestionIcon} alt="" />
            </div>
            <a
              href="https://intercom.help/dehive/en/articles/
              5954818-how-to-connect-a-wallet"
              target="_blank"
              rel="noreferrer"
            >
              {t('CONNECTIONS.WALLET_CONNECTIONS.MODAL_LEARN_LINK')}
            </a>
          </div>
          {connectors?.map(({ type, name, icon }) => (
            <button
              disabled={isLoading}
              onClick={() =>
                account && connected === type
                  ? handleDisconnect(type)
                  : handleConnect(type)
              }
              type="button"
              key={type}
              className={styles.modalButton}
            >
              {inProgress === type && (
                <div className={styles.icon}>
                  <img src={LOADING_IMAGE} alt="Loading" />
                </div>
              )}
              {account && connected === type && (
                <div className={styles.icon}>
                  <CheckOutlined />
                </div>
              )}
              {!inProgress && !(account && connected === type) && (
                <div className={styles.icon}>
                  <img src={icon} alt={name} />
                </div>
              )}
              {account && connected === type
                ? t('CONNECTIONS.WALLET_CONNECTIONS.WALLET_DISCONNECT', {
                    name,
                  })
                : t('CONNECTIONS.WALLET_CONNECTIONS.WALLET_CONNECT', {
                    name,
                  })}
            </button>
          ))}
          {!account && (
            <div className={styles.after}>
              {t('CONNECTIONS.WALLET_CONNECTIONS.MODAL_AFTER_TEXT')}
            </div>
          )}
          {inProgress === injectedConnector.type &&
            t('CONNECTIONS.WALLET_CONNECTIONS.INJECTED_MESSAGE')}
        </div>
      </Modal>
    </>
  );
};

WalletConnections.defaultProps = {
  textButton: '',
  className: undefined,
  isInline: false,
  isBlue: false,
  size: undefined,
};

export default WalletConnections;
