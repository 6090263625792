import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import cn from 'classnames';

import NETWORKS from '@configs/networks';
import { Pool } from '@configs/pools';

import styles from '@modules/common/components/CardLabel/CardLabel.module.scss';

const getNetworkShortName = (id: number): string => {
  const networkShortName: string | undefined = NETWORKS.find(({ chainId }) => {
    return chainId === id;
  })?.shortName;

  return networkShortName || '';
};

const CardLabel: FC<{
  className?: string;
  isClosed?: Pool['closed'];
  platform: string;
  network: string;
}> = ({ className, isClosed, network, platform }) => {
  const { t } = useTranslation();

  const { chainId } = useWeb3React();

  return (
    <div
      className={cn(styles.container, className, {
        [styles[`container--${getNetworkShortName(chainId || 0)}`]]:
          !isClosed && chainId && getNetworkShortName(chainId || 0),
        [styles['container--closed']]: isClosed,
      })}
    >
      {isClosed && (
        <b className={styles.closed}>{t('COMMON.CARD_LABEL.CLOSED')}</b>
      )}

      {!isClosed && (
        <b
          className={cn(
            styles.network,
            chainId &&
              getNetworkShortName(chainId) &&
              styles[`network--${getNetworkShortName(chainId)}`],
          )}
        >
          {network}
        </b>
      )}

      {!isClosed && <span className={styles.platform}>{platform}</span>}
    </div>
  );
};

CardLabel.defaultProps = { className: undefined, isClosed: undefined };

export default CardLabel;
