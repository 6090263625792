import DHVPage from '@modules/dhv/components/DHVPage';
import ICON from '@modules/dhv/assets/pages/dhv_logo.svg';

const page = {
  path: '/dhv',
  menu: 1,
  icon: ICON,
  order: 3,
  title: 'DHV.DHV.NAV_LINK_NAME',
  parent: 0,
  component: DHVPage,
};

export default page;
