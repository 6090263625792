import { LedgerConnector } from '@web3-react/ledger-connector';
import { useWeb3React } from '@web3-react/core';

import Connector from '@modules/connections/types/Connector';
import constants from '@modules/connections/constants';
import getRPC from '@modules/connections/helpers/getRPC';
import icon from '@modules/connections/assets/ledger_logo.svg';

const Ledger = (chainId: number): (() => Promise<void>) => {
  const { activate } = useWeb3React();

  const network = new LedgerConnector({
    chainId,
    url: getRPC(chainId),
    pollingInterval: constants.POLLING_INTERVAL,
  });

  return () => activate(network);
};

const LedgerDisconnect = (): (() => void) => {
  const { deactivate } = useWeb3React();

  return () => deactivate();
};

const connector: Connector = {
  type: 'ledger',
  name: 'Ledger',
  icon,
  connect: Ledger,
  disconnect: LedgerDisconnect,
};

export default connector;
