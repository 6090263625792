import { FC, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { BigNumber } from '@ethersproject/bignumber';
import { Form } from 'antd';

import PoolRewardForm from '@modules/pools/components/PoolRewardForm';
import ActionModal from '@modules/common/components/ActionModal';
import {
  confirmedListSelector,
  pendingListSelector,
  successListSelector,
} from '@modules/pools/slices/rewardSlice';
import trackGTMActions from '@modules/common/helpers/trackGTMActions';
import useMediaQuery from '@modules/layout/hooks/useMediaQuery';
import { useTypedSelector } from '@utils/store';
import constants from '@modules/pools/constants';
import REWARD_ICON from '@modules/pools/assets/reward_icon.svg';
import POOLS, { Pool } from '@configs/pools';

const { useForm } = Form;

const INPUT_NAME = 'rewardsAmount';

const PoolReward: FC<{
  pendingRewards?: BigNumber[] | null;
  yieldBalance?: BigNumber | null;
  poolDecimals: number;
  poolAddress: string;
  poolSymbol: string;
  poolAsset: Pool['asset'];
  poolName: string;
  poolId: number;
  clusterPrice?: BigNumber | null;
}> = ({
  pendingRewards,
  yieldBalance,
  poolDecimals,
  poolAddress,
  poolSymbol,
  poolAsset,
  poolName,
  poolId,
  clusterPrice,
}) => {
  const { t } = useTranslation();

  const isMobile = useMediaQuery(991);

  const confirmedList = useTypedSelector(confirmedListSelector);
  const pendingList = useTypedSelector(pendingListSelector);
  const successList = useTypedSelector(successListSelector);

  const dispatch = useDispatch();

  const [form] = useForm();

  const { chainId, account, library } = useWeb3React<Web3Provider>();

  const onClose = () => {
    form.resetFields();
  };

  const onReward = (args: { [key: string]: string }) => {
    if (
      account &&
      library &&
      chainId &&
      POOLS[chainId] &&
      POOLS[chainId][poolAddress] &&
      POOLS[chainId][poolAddress][poolId] &&
      Number(args[INPUT_NAME]) > 0
    ) {
      trackGTMActions(
        'ClaimRewards',
        POOLS[chainId][poolAddress][poolId].symbol,
        POOLS[chainId][poolAddress][poolId].type || 1,
        chainId,
        account,
        'intent',
      );
      dispatch({
        type: 'POOLS_REWARD_POOL_REQUESTED',
        payload: {
          poolAddress,
          poolABI: POOLS[chainId][poolAddress][poolId].poolABI,
          poolId,
          isCluster: POOLS[chainId][poolAddress][poolId].type === 1,
          library,
          account,
        },
      });
    }

    form.resetFields();
  };

  const totalValue = useMemo(() => {
    let value = BigNumber.from('0');

    if (yieldBalance) {
      value = value.add(yieldBalance);
    }

    if (pendingRewards) {
      pendingRewards.forEach((item) => {
        value = value.add(item);
      });
    }

    return value;
  }, [yieldBalance, pendingRewards]);

  useEffect(() => {
    if (successList[poolAddress][poolId] && chainId) {
      trackGTMActions(
        'ClaimRewards',
        POOLS[chainId][poolAddress][poolId].symbol,
        POOLS[chainId][poolAddress][poolId].type || 1,
        chainId,
        account,
        'fact',
      );
    }
  }, [account, chainId, poolAddress, poolId, successList]);

  return (
    <ActionModal
      dispatchObject={{
        type: 'POOLS_RESET_REWARD_POOL_REQUESTED',
        payload: { poolAddress, poolId },
      }}
      successSubTitle={t('POOLS.POOLS_REWARD.SUCCESS_SUBTITLE')}
      defaultTitle={t('POOLS.POOLS_REWARD.DEFAULT_TITLE')}
      successTitle={t('POOLS.POOLS_REWARD.SUCCESS_TITLE')}
      buttonTitle={t('POOLS.POOLS_REWARD.BUTTON_TITLE')}
      buttonColor="polygon"
      buttonSize="medium"
      isConfirmed={confirmedList[poolAddress][poolId]}
      isDisabled={!totalValue.gt(BigNumber.from('0'))}
      isPending={pendingList[poolAddress][poolId]}
      isSuccess={successList[poolAddress][poolId]}
      modalAddress={`${poolId}_${poolAddress}`}
      modalPath={constants.MODAL_PATH_REWARD}
      modalIcon={REWARD_ICON}
      modalWidth={isMobile ? 290 : 455}
      onClose={onClose}
    >
      <PoolRewardForm
        pendingRewards={pendingRewards}
        yieldBalance={yieldBalance}
        poolDecimals={poolDecimals}
        poolAddress={poolAddress}
        poolSymbol={poolSymbol}
        poolAsset={poolAsset}
        poolName={poolName}
        poolId={poolId}
        clusterPrice={clusterPrice}
        isLoading={
          !!(
            confirmedList[poolAddress][poolId] ||
            pendingList[poolAddress][poolId]
          )
        }
        inputName={INPUT_NAME}
        onFinish={onReward}
        form={form}
      />
    </ActionModal>
  );
};

PoolReward.defaultProps = {
  pendingRewards: undefined,
  yieldBalance: undefined,
  clusterPrice: undefined,
};

export default PoolReward;
