import TermsOfUse from '@modules/info/components/TermsOfUse';

const page = {
  path: '/terms-of-use',
  menu: 2,
  order: 7,
  title: 'INFO.TERMS_OF_USE.NAV_LINK_NAME',
  component: TermsOfUse,
};

export default page;
