import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import CLOSE_ICON from '@modules/layout/assets/close_icon.svg';

import styles from './CloseButton.module.scss';

const CloseButton: FC<{
  className?: string;
  onClick: () => void;
}> = ({ className, onClick }) => {
  const { t } = useTranslation();

  return (
    <button
      className={cn(styles.container, className)}
      onClick={onClick}
      type="button"
    >
      <img
        className={styles.icon}
        src={CLOSE_ICON}
        alt={t('LAYOUT.CLOSE_BUTTON.ICON')}
      />
    </button>
  );
};

CloseButton.defaultProps = { className: undefined };

export default CloseButton;
