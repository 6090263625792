import { FC, useMemo, useState, useCallback } from 'react';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { BigNumber } from '@ethersproject/bignumber';
import cn from 'classnames';

import PoolCardUderlyingList from '@modules/pools/components/PoolCardUderlyingList';
import PoolCardRewardValue from '@modules/pools/components/PoolCardRewardValue';
import PoolCardRewardTimer from '@modules/pools/components/PoolCardRewardTimer';
import PoolCardRewardList from '@modules/pools/components/PoolCardRewardList';
import PoolCardRewardTabs from '@modules/pools/components/PoolCardRewardTabs';
import PoolCardStats from '@modules/pools/components/PoolCardStats';
import PoolCardIcon from '@modules/pools/components/PoolCardIcon';
import PoolUnstake from '@modules/pools/components/PoolUnstake';
import PoolReward from '@modules/pools/components/PoolReward';
import PoolStake from '@modules/pools/components/PoolStake';
import CardLabel from '@modules/common/components/CardLabel';
import { listSelector } from '@modules/underlying/slices/underlyingSlice';
import getActualNetworkName from '@modules/common/helpers/getActualNetworkName';
import useMediaQuery from '@modules/layout/hooks/useMediaQuery';
import getPoolName from '@modules/pools/helpers/getPoolName';
import StoredPool from '@modules/pools/types/StoredPool';
import PoweredBy from '@modules/pools/components/PoweredBy';
import { useTypedSelector } from '@utils/store';
import INFO_ICON from '@modules/common/assets/info_icon.svg';
import POOLS, { PoolUnderlying } from '@configs/pools';

import styles from '@modules/pools/components/PoolCard/PoolCard.module.scss';

const PoolCard: FC<{
  poolGroupAddress: string;
  className?: string;
  poolId: number;
  pool: StoredPool;
}> = ({ poolGroupAddress, className, poolId, pool }) => {
  const [tabNumber, setTabNumber] = useState<number>(0);

  const { t } = useTranslation();

  const underlyingList = useTypedSelector(listSelector);

  const { chainId, account } = useWeb3React();

  const isMobile = useMediaQuery(991);

  const handleChangeTab = useCallback(
    (id: number) => {
      setTabNumber(id);
    },
    [setTabNumber],
  );

  const userBalance: null | BigNumber = useMemo(() => {
    let balance: null | BigNumber = null;

    if (
      chainId &&
      POOLS[chainId] &&
      POOLS[chainId][poolGroupAddress] &&
      POOLS[chainId][poolGroupAddress][poolId] &&
      POOLS[chainId][poolGroupAddress][poolId].type === 4
    ) {
      POOLS[chainId][poolGroupAddress][poolId].underlying?.forEach(
        ({ address }: PoolUnderlying) => {
          if (underlyingList && underlyingList[address]) {
            balance = balance
              ? balance.add(
                  underlyingList[address].userBalance || BigNumber.from('0'),
                )
              : underlyingList[address].userBalance || null;
          }
        },
      );
    } else {
      balance = pool.userBalance || null;
    }

    return balance;
  }, [chainId, poolGroupAddress, poolId, pool, underlyingList]);

  const cardLabel = useMemo(() => {
    let localString = '';

    if (pool.type === 0 || pool.type === 2) {
      localString = 'POOLS.POOL_CARD.LABEL_STAKING';
    }

    if (pool.type === 3) {
      localString = 'POOLS.POOL_CARD.LABEL_IMPULSE';
    }

    if (pool.type === 4) {
      localString = 'POOLS.POOL_CARD.LABEL_STABLE';
    }

    if (pool.type === 1) {
      localString = 'POOLS.POOL_CARD.LABEL_CLUSTER';
    }

    return t(localString, {
      name: chainId ? getActualNetworkName(chainId) : '',
    });
  }, [chainId, pool, t]);

  return chainId &&
    POOLS[chainId] &&
    POOLS[chainId][poolGroupAddress] &&
    POOLS[chainId][poolGroupAddress][poolId] ? (
    <article
      className={cn(className, styles.container, {
        [styles['is-closed']]: POOLS[chainId][poolGroupAddress][poolId].closed,
        [styles['is-staked']]:
          account &&
          pool.userPoolAmount?.gt(BigNumber.from('0')) &&
          !POOLS[chainId][poolGroupAddress][poolId].closed,
        [styles['is-available']]:
          account &&
          userBalance?.gt(BigNumber.from('0')) &&
          !pool.userPoolAmount?.gt(BigNumber.from('0')) &&
          !POOLS[chainId][poolGroupAddress][poolId].closed,
      })}
    >
      <CardLabel
        className={styles.label}
        isClosed={POOLS[chainId][poolGroupAddress][poolId].closed}
        platform={POOLS[chainId][poolGroupAddress][poolId].platform}
        network={cardLabel}
      />

      <PoolCardIcon
        poolGroupAddress={poolGroupAddress}
        poolSymbol={pool.symbol}
        className={cn(styles.icon, {
          [styles['icon--solo']]:
            POOLS[chainId][poolGroupAddress][poolId].type === 0,
          [styles['icon--cluster']]:
            POOLS[chainId][poolGroupAddress][poolId].type === 1,
          [styles['icon--lp']]:
            POOLS[chainId][poolGroupAddress][poolId].type === 2,
          [styles['icon--impulse']]:
            POOLS[chainId][poolGroupAddress][poolId].type === 3,
          [styles['icon--stable']]:
            POOLS[chainId][poolGroupAddress][poolId].type === 4,
        })}
        poolId={poolId}
      />

      <section className={styles.content}>
        <div className={styles.contentMain}>
          <PoolCardIcon
            poolGroupAddress={poolGroupAddress}
            poolSymbol={pool.symbol}
            className={cn(styles.contentMainIcon, {
              [styles['contentMainIcon--solo']]:
                POOLS[chainId][poolGroupAddress][poolId].type === 0,
              [styles['contentMainIcon--cluster']]:
                POOLS[chainId][poolGroupAddress][poolId].type === 1,
              [styles['contentMainIcon--lp']]:
                POOLS[chainId][poolGroupAddress][poolId].type === 2,
              [styles['contentMainIcon--impulse']]:
                POOLS[chainId][poolGroupAddress][poolId].type === 3,
              [styles['contentMainIcon--stable']]:
                POOLS[chainId][poolGroupAddress][poolId].type === 4,
            })}
            poolId={poolId}
          />

          <div>
            <div className={styles.title}>
              <h3 className={styles.titleText}>
                {getPoolName(
                  poolGroupAddress,
                  underlyingList,
                  poolId,
                  pool,
                  chainId,
                )}
              </h3>

              <Tooltip
                overlayClassName={styles.titleTooltip}
                placement="topLeft"
                trigger={isMobile ? 'click' : 'hover'}
                title={POOLS[chainId][poolGroupAddress][poolId].description}
              >
                <img
                  className={styles.titlePrompt}
                  height="22"
                  width="20"
                  src={INFO_ICON}
                  alt={POOLS[chainId][poolGroupAddress][poolId].description}
                />
              </Tooltip>

              {POOLS[chainId][poolGroupAddress][poolId].getLink && (
                <a
                  className={styles.titleLink}
                  target="_blank"
                  href={POOLS[chainId][poolGroupAddress][poolId].getLink}
                  rel="noreferrer"
                >
                  {t('POOLS.POOL_CARD.GET')}
                </a>
              )}
            </div>

            {POOLS[chainId][poolGroupAddress][poolId].type === 4 && (
              <PoolCardUderlyingList
                underlying={
                  POOLS[chainId][poolGroupAddress][poolId].underlying || []
                }
                isClosed={POOLS[chainId][poolGroupAddress][poolId].closed}
              />
            )}
          </div>
        </div>

        {POOLS[chainId][poolGroupAddress][poolId].poweredBy && (
          <PoweredBy
            info={POOLS[chainId][poolGroupAddress][poolId].poweredBy}
          />
        )}

        {POOLS[chainId][poolGroupAddress][poolId].isClaimed &&
          POOLS[chainId][poolGroupAddress][poolId].isAccumulated && (
            <PoolCardRewardTabs
              tabNumber={tabNumber}
              onChange={handleChangeTab}
            />
          )}

        {(POOLS[chainId][poolGroupAddress][poolId].pendingRewards ||
          POOLS[chainId][poolGroupAddress][poolId].underlying) &&
          (!(
            POOLS[chainId][poolGroupAddress][poolId].isClaimed &&
            POOLS[chainId][poolGroupAddress][poolId].isAccumulated
          ) ||
            (POOLS[chainId][poolGroupAddress][poolId].isClaimed &&
              POOLS[chainId][poolGroupAddress][poolId].isAccumulated &&
              tabNumber === 0)) && (
            <PoolCardRewardList
              pendingRewards={(POOLS[chainId][poolGroupAddress][poolId]
                .isClaimed &&
              POOLS[chainId][poolGroupAddress][poolId].isAccumulated
                ? POOLS[chainId][poolGroupAddress][poolId].underlying?.map(
                    ({ address }) => address,
                  ) || []
                : POOLS[chainId][poolGroupAddress][poolId].pendingRewards ||
                  POOLS[chainId][poolGroupAddress][poolId].underlying?.map(
                    ({ address }) => address,
                  ) ||
                  []
              ).concat(
                POOLS[chainId][poolGroupAddress][poolId].type === 1
                  ? [poolGroupAddress]
                  : [],
              )}
              timerRewards={
                POOLS[chainId][poolGroupAddress][poolId].timerRewards?.list ||
                []
              }
              timestamp={
                POOLS[chainId][poolGroupAddress][poolId].timerRewards
                  ?.timestamp || 0
              }
              isClosed={POOLS[chainId][poolGroupAddress][poolId].closed}
              poolTokenAddress={pool.tokenAddress}
              poolSymbol={pool.symbol}
              poolType={POOLS[chainId][poolGroupAddress][poolId].type}
              poolId={poolId}
            />
          )}

        {((POOLS[chainId][poolGroupAddress][poolId].isClaimed &&
          !POOLS[chainId][poolGroupAddress][poolId].isAccumulated) ||
          (POOLS[chainId][poolGroupAddress][poolId].isClaimed &&
            POOLS[chainId][poolGroupAddress][poolId].isAccumulated &&
            tabNumber === 1)) &&
          POOLS[chainId][poolGroupAddress][poolId].timerRewards && (
            <PoolCardRewardTimer
              timestamp={
                POOLS[chainId][poolGroupAddress][poolId].timerRewards
                  ?.timestamp || 0
              }
              isClosed={POOLS[chainId][poolGroupAddress][poolId].closed}
              list={
                POOLS[chainId][poolGroupAddress][poolId].timerRewards?.list ||
                []
              }
            />
          )}

        <div
          className={cn(styles.rewards, {
            [styles['rewards--vertical']]:
              !POOLS[chainId][poolGroupAddress][poolId].pendingRewards,
          })}
        >
          <PoolCardRewardValue
            storedPendingRewards={pool.pendingRewards}
            pendingRewards={
              POOLS[chainId][poolGroupAddress][poolId].pendingRewards
            }
            userPoolAmount={pool.userPoolAmount}
            yieldBalance={pool.yieldBalance}
            clusterPrice={pool.clusterPrice}
            poolDecimals={pool.decimals}
            poolSymbol={pool.symbol}
            poolAsset={POOLS[chainId][poolGroupAddress][poolId].asset}
          />

          {(POOLS[chainId][poolGroupAddress][poolId].isClaimed &&
            !POOLS[chainId][poolGroupAddress][poolId].isAccumulated) ||
          (POOLS[chainId][poolGroupAddress][poolId].isClaimed &&
            POOLS[chainId][poolGroupAddress][poolId].isAccumulated &&
            tabNumber === 1) ? (
            <PoolReward
              pendingRewards={pool.pendingRewards}
              yieldBalance={pool.yieldBalance}
              clusterPrice={pool.clusterPrice}
              poolDecimals={pool.decimals}
              poolAddress={poolGroupAddress}
              poolSymbol={pool.symbol}
              poolAsset={POOLS[chainId][poolGroupAddress][poolId].asset}
              poolName={pool.name}
              poolId={poolId}
            />
          ) : (
            <span className={styles.accumulated}>
              {t('POOLS.POOL_CARD.ACCUMULATED')}
            </span>
          )}
        </div>
      </section>

      <PoolCardStats
        userPoolAmountInUnderlying={pool.userPoolAmountInUnderlying}
        totalPoolSupply={pool.poolSupply}
        userPoolAmount={pool.userPoolAmount}
        clusterPrice={pool.clusterPrice}
        poolDecimals={pool.decimals}
        poolAddress={poolGroupAddress}
        poolSymbol={
          POOLS[chainId][poolGroupAddress][poolId].symbol || pool.symbol
        }
        poolAsset={POOLS[chainId][poolGroupAddress][poolId].asset}
        poolId={poolId}
      />

      <footer className={styles.buttons}>
        <PoolStake
          isClosed={POOLS[chainId][poolGroupAddress][poolId].closed}
          className={styles.buttonsItem}
          underlying={
            POOLS[chainId][poolGroupAddress][poolId].type === 4
              ? POOLS[chainId][poolGroupAddress][poolId].underlying
              : undefined
          }
          poolAddress={poolGroupAddress}
          poolId={poolId}
          pool={pool}
        />

        <PoolUnstake
          className={styles.buttonsItem}
          underlying={
            POOLS[chainId][poolGroupAddress][poolId].type === 4
              ? POOLS[chainId][poolGroupAddress][poolId].underlying
              : undefined
          }
          poolAddress={poolGroupAddress}
          poolId={poolId}
          pool={pool}
        />
      </footer>
    </article>
  ) : null;
};

PoolCard.defaultProps = { className: undefined };

export default PoolCard;
