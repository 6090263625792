import { notification } from 'antd';

const errorOpenModalDisassemble = (): void => {
  notification.error({
    message: 'Leave cluster',
    description: 'Here is not available a cluster that you try to leave.',
  });
};

export default errorOpenModalDisassemble;
