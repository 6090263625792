import StoredUnderlyingList from '@modules/underlying/types/StoredUnderlyingList';
import StoredPool from '@modules/pools/types/StoredPool';
import POOLS from '@configs/pools';

const getPoolSymbol = (
  poolGroupAddress: string,
  underlyingList: StoredUnderlyingList | null,
  poolSymbol: StoredPool['symbol'],
  poolId: number,
  chainId?: number,
): string => {
  if (
    chainId &&
    underlyingList &&
    POOLS[chainId] &&
    POOLS[chainId][poolGroupAddress] &&
    POOLS[chainId][poolGroupAddress][poolId]?.underlying &&
    POOLS[chainId][poolGroupAddress][poolId]?.type === 4
  ) {
    let result = '';

    POOLS[chainId][poolGroupAddress][poolId].underlying?.forEach(
      ({ address }, index: number) => {
        if (
          index !==
          (POOLS[chainId][poolGroupAddress][poolId].underlying?.length || 0) - 1
        ) {
          result += `${underlyingList[address]?.symbol || ''}-`;
        } else {
          result += underlyingList[address]?.symbol || '';
        }
      },
    );

    return result;
  }

  if (
    chainId &&
    underlyingList &&
    POOLS[chainId] &&
    POOLS[chainId][poolGroupAddress] &&
    POOLS[chainId][poolGroupAddress][poolId]?.underlying &&
    (POOLS[chainId][poolGroupAddress][poolId]?.type === 2 ||
      POOLS[chainId][poolGroupAddress][poolId]?.type === 3)
  ) {
    let result = '';

    POOLS[chainId][poolGroupAddress][poolId].underlying?.forEach(
      ({ address }, index: number) => {
        if (
          index !==
          (POOLS[chainId][poolGroupAddress][poolId].underlying?.length || 0) - 1
        ) {
          result += `${underlyingList[address]?.symbol || ''}/`;
        } else {
          result += underlyingList[address]?.symbol || '';
        }
      },
    );

    return result;
  }

  return poolSymbol;
};

export default getPoolSymbol;
