import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';

const getApprovePoolGasLimit = (
  poolContract: Contract,
  poolAddress: string,
  amount: BigNumber,
): Promise<BigNumber> => {
  return poolContract.estimateGas.approve(poolAddress, amount);
};

export default getApprovePoolGasLimit;
