import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';

const getClusterGasPriceDisassemble = (
  clusterContract: Contract,
  amount: BigNumber,
): Promise<BigNumber> => {
  return clusterContract.estimateGas.disassemble(amount, true);
};

export default getClusterGasPriceDisassemble;
