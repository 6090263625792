import { FC, useState, useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import { Form } from 'antd';
import cn from 'classnames';

import WalletConnections from '@modules/connections/components/WalletConnections';
import DHVSwapEstimate from '@modules/dhv/components/DHVSwapEstimate';
import DHVSwapField from '@modules/dhv/components/DHVSwapField';
import FormSubmit from '@modules/common/components/FormSubmit';
import {
  isLoadingSelector as isLoadingTransactionSelector,
  isSuccessSelector,
} from '@modules/dhv/slices/dhvSwapTransactionSlice';
import {
  isLoadingSelector as isLoadingAllowanceSelector,
  preErrorSelector as preErrorAllowanceSelector,
  valueSelector,
} from '@modules/dhv/slices/dhvSwapAllowanceSlice';
import {
  isLoadingSelector as isLoadingBalancesSelector,
  preErrorSelector as preErrorBalancesSelector,
  dataSelector as dataBalancesSelector,
} from '@modules/dhv/slices/dhvSwapBalancesSlice';
import {
  isLoadingSelector as isLoadingValuesSelector,
  dataSelector as dataValuesSelector,
} from '@modules/dhv/slices/dhvSwapValuesSlice';
import maxValueWithCommission from '@modules/common/helpers/maxValueWithCommission';
import trackGTMActionsSwap from '@modules/common/helpers/trackGTMActionsSwap';
import getNetworkDecimals from '@modules/common/helpers/getNetworkDecimals';
import getNetworkSymbol from '@modules/common/helpers/getNetworkSymbol';
import checkMaxValue from '@modules/common/helpers/checkMaxValue';
import useMediaQuery from '@modules/layout/hooks/useMediaQuery';
import constants from '@modules/common/constants';
import { useTypedSelector } from '@utils/store';
import LOADING_IMAGE from '@modules/common/assets/loading.svg';
import SWAP_LIST from '@configs/swap';

import styles from './DHVSwap.module.scss';

const { useForm } = Form;

const INPUT_NAME_FROM = 'swapAmountFrom';
const INPUT_NAME_TO = 'swapAmountTo';
const FORM_NAME = 'swapDHV';

const getMinValue = (decimals: number | null): string | undefined => {
  return decimals ? `0.${'0'.repeat(decimals - 1)}1` : undefined;
};

const DHVSwap: FC<{ className: string }> = ({ className }) => {
  const { library, account, chainId } = useWeb3React<Web3Provider>();

  const [inputFromValuePrevious, setInputFromValuePrevious] =
    useState<BigNumber>(BigNumber.from('0'));
  const [inputToValuePrevious, setInputToValuePrevious] = useState<BigNumber>(
    BigNumber.from('0'),
  );
  const [attemptAllowance, setAttemptAllowance] = useState<number>(0);
  const [attemptBalances, setAttemptBalances] = useState<number>(0);
  const [previousChainId, setPreviousChainId] = useState<number | undefined>(
    chainId,
  );
  const [inputFromValue, setInputFromValue] = useState<BigNumber>(
    BigNumber.from('0'),
  );
  const [inputToValue, setInputToValue] = useState<BigNumber>(
    BigNumber.from('0'),
  );
  const [isReverse, setIsReverse] = useState<boolean>(false);

  const { t } = useTranslation();

  const isTablet = useMediaQuery(991);
  const isDesktop = useMediaQuery(1599);

  const isLoadingTransaction = useTypedSelector(isLoadingTransactionSelector);
  const isLoadingAllowance = useTypedSelector(isLoadingAllowanceSelector);
  const isLoadingBalances = useTypedSelector(isLoadingBalancesSelector);
  const isLoadingValues = useTypedSelector(isLoadingValuesSelector);
  const preErrorAllowance = useTypedSelector(preErrorAllowanceSelector);
  const preErrorBalances = useTypedSelector(preErrorBalancesSelector);
  const dataBalances = useTypedSelector(dataBalancesSelector);
  const dataValues = useTypedSelector(dataValuesSelector);
  const allowance = useTypedSelector(valueSelector);
  const isSuccess = useTypedSelector(isSuccessSelector);

  const [form] = useForm();

  const dispatch = useDispatch();

  const isMinValueBiggerNative: boolean = useMemo(() => {
    if (chainId && dataBalances) {
      return checkMaxValue(
        constants.INPUT_MINIMAL_STEP,
        getNetworkDecimals(chainId),
        maxValueWithCommission(
          dataBalances.transactionFee,
          dataBalances.balanceNative,
        ),
      );
    }

    return false;
  }, [chainId, dataBalances]);

  const isMinValueBiggerDHV: boolean = useMemo(() => {
    if (dataBalances) {
      return checkMaxValue(
        constants.INPUT_MINIMAL_STEP,
        dataBalances.decimalsDHV,
        dataBalances.balanceDHV,
      );
    }

    return false;
  }, [dataBalances]);

  const isAllowanceSmaller: boolean = useMemo(() => {
    return allowance && dataValues
      ? dataValues.amountIn.gt(BigNumber.from('0')) &&
          allowance.lt(dataValues.amountIn)
      : false;
  }, [dataValues, allowance]);

  const getBalances = useCallback(() => {
    if (library && account && chainId && SWAP_LIST[chainId]) {
      dispatch({
        type: 'DHV_GET_SWAP_BALANCES',
        payload: {
          transactionFee: SWAP_LIST[chainId].transactionFee,
          dhvAddress: SWAP_LIST[chainId].dhvAddress,
          tokenAbi: SWAP_LIST[chainId].tokenAbi,
          library,
          account,
        },
      });
    }
  }, [dispatch, library, account, chainId]);

  const getAllowance = useCallback(() => {
    if (library && account && chainId && SWAP_LIST[chainId]) {
      dispatch({
        type: 'SWAP_GET_ALLOWANCE',
        payload: {
          routerAddress: SWAP_LIST[chainId].swapRouter,
          dhvAddress: SWAP_LIST[chainId].dhvAddress,
          tokenAbi: SWAP_LIST[chainId].tokenAbi,
          library,
          account,
        },
      });
    }
  }, [dispatch, library, account, chainId]);

  const getAmountOut = useCallback(
    (amountIn: BigNumber) => {
      if (dataBalances && chainId && SWAP_LIST[chainId]) {
        dispatch({
          type: 'SWAP_GET_AMOUNT_OUT',
          payload: {
            transactionFee: SWAP_LIST[chainId].transactionFee,
            routerAddress: SWAP_LIST[chainId].swapRouter,
            routerABI: SWAP_LIST[chainId].swapAbi,
            isReverse,
            amountIn,
            swapPath: SWAP_LIST[chainId].swapPath,
            slippage: SWAP_LIST[chainId].slippage,
            library,
          },
        });
      }
    },
    [chainId, dispatch, isReverse, library, dataBalances],
  );

  const getAmountIn = useCallback(
    (amountOut: BigNumber) => {
      if (dataBalances && chainId && SWAP_LIST[chainId]) {
        dispatch({
          type: 'SWAP_GET_AMOUNT_IN',
          payload: {
            transactionFee: SWAP_LIST[chainId].transactionFee,
            routerAddress: SWAP_LIST[chainId].swapRouter,
            routerABI: SWAP_LIST[chainId].swapAbi,
            isReverse,
            amountOut,
            swapPath: SWAP_LIST[chainId].swapPath,
            slippage: SWAP_LIST[chainId].slippage,
            library,
          },
        });
      }
    },
    [chainId, dispatch, isReverse, library, dataBalances],
  );

  const handleApproveToken = useCallback(() => {
    if (library && account && chainId && SWAP_LIST[chainId] && dataValues) {
      dispatch({
        type: 'SWAP_APPROVE_TOKEN',
        payload: {
          routerAddress: SWAP_LIST[chainId].swapRouter,
          dhvAddress: SWAP_LIST[chainId].dhvAddress,
          tokenAbi: SWAP_LIST[chainId].tokenAbi,
          amountIn: dataValues.amountIn,
          library,
          account,
        },
      });
    }
  }, [dispatch, library, account, chainId, dataValues]);

  const hanleSwapTokens = useCallback(() => {
    if (library && account && chainId && SWAP_LIST[chainId] && dataValues) {
      trackGTMActionsSwap(
        isReverse ? 'DHV' : getNetworkSymbol(chainId),
        SWAP_LIST[chainId].supporter,
        chainId,
        account,
        'intent',
      );

      dispatch({
        type: 'DHV_SWAP_REQUESTED',
        payload: {
          routerAddress: SWAP_LIST[chainId].swapRouter,
          routerABI: SWAP_LIST[chainId].swapAbi,
          isReverse,
          amountOut: dataValues.minimumReceive,
          amountIn: dataValues.amountIn,
          swapPath: SWAP_LIST[chainId].swapPath,
          deadline: Date.now() + 600,
          library,
          account,
        },
      });
    }
  }, [dispatch, library, account, chainId, isReverse, dataValues]);

  const handleRevers = useCallback(() => {
    form.setFieldsValue({ [INPUT_NAME_FROM]: '0', [INPUT_NAME_TO]: '0' });

    setIsReverse(!isReverse);

    setInputToValue(BigNumber.from('0'));
    setInputFromValue(inputToValuePrevious);
  }, [form, isReverse, inputToValuePrevious]);

  const onFinish = useCallback(() => {
    if (isAllowanceSmaller && isReverse) {
      handleApproveToken();
    } else {
      hanleSwapTokens();
    }
  }, [handleApproveToken, hanleSwapTokens, isAllowanceSmaller, isReverse]);

  useEffect(() => {
    getBalances();

    const reGetBalances = setInterval(
      () => getBalances(),
      constants.GET_ITEMS_INTERVAL,
    );

    return () => clearInterval(reGetBalances);
  }, [getBalances]);

  useEffect(() => {
    getAllowance();

    const reGetAllowance = setInterval(
      () => getAllowance(),
      constants.GET_ITEMS_INTERVAL,
    );

    return () => clearInterval(reGetAllowance);
  }, [getAllowance]);

  useEffect(() => {
    if (preErrorBalances && attemptBalances < constants.GET_ITEMS_ATTEMPTS) {
      getBalances();

      setAttemptBalances(attemptBalances + 1);
    }

    if (preErrorBalances && attemptBalances >= constants.GET_ITEMS_ATTEMPTS) {
      dispatch({
        type: 'DHV_SWAP_SET_ERROR_TOKENS_INFO',
        payload: { error: preErrorBalances },
      });
    }
  }, [dispatch, attemptBalances, getBalances, preErrorBalances]);

  useEffect(() => {
    if (preErrorAllowance && attemptAllowance < constants.GET_ITEMS_ATTEMPTS) {
      getAllowance();

      setAttemptAllowance(attemptAllowance + 1);
    }

    if (preErrorAllowance && attemptAllowance >= constants.GET_ITEMS_ATTEMPTS) {
      dispatch({
        type: 'SWAP_SET_ALLOWANCE_ERROR',
        payload: { error: preErrorAllowance },
      });
    }
  }, [dispatch, attemptAllowance, getAllowance, preErrorAllowance]);

  useEffect(() => {
    if (
      inputFromValue.gt(BigNumber.from('0')) &&
      !(dataValues?.amountIn || BigNumber.from('0')).eq(inputFromValue)
    ) {
      const now = Date.now();

      const timer = setInterval(() => {
        if (Date.now() >= now + 500) {
          clearInterval(timer);

          getAmountOut(inputFromValue);
        }
      }, 100);

      return () => clearInterval(timer);
    }

    return () => false;
  }, [getAmountOut, dataValues, inputFromValue]);

  useEffect(() => {
    if (
      inputToValue.gt(BigNumber.from('0')) &&
      !(dataValues?.amountOut || BigNumber.from('0')).eq(inputToValue)
    ) {
      const now = Date.now();

      const timer = setInterval(() => {
        if (Date.now() >= now + 500) {
          clearInterval(timer);

          getAmountIn(inputToValue);
        }
      }, 100);

      return () => clearInterval(timer);
    }

    return () => false;
  }, [getAmountIn, dataValues, inputToValue]);

  useEffect(() => {
    if (
      chainId &&
      dataValues &&
      dataBalances &&
      (!dataValues.amountIn.eq(inputFromValuePrevious) ||
        !dataValues.amountOut.eq(inputToValuePrevious))
    ) {
      setInputFromValuePrevious(dataValues.amountIn);
      setInputToValuePrevious(dataValues.amountOut);
      setInputFromValue(BigNumber.from('0'));
      setInputToValue(BigNumber.from('0'));

      form.setFieldsValue({
        [INPUT_NAME_FROM]: formatUnits(
          dataValues.amountIn,
          isReverse ? dataBalances.decimalsDHV : getNetworkDecimals(chainId),
        ),
        [INPUT_NAME_TO]: formatUnits(
          dataValues.amountOut,
          isReverse ? getNetworkDecimals(chainId) : dataBalances.decimalsDHV,
        ),
      });

      form.validateFields().catch(() => false);
    }
  }, [
    form,
    chainId,
    isReverse,
    dataValues,
    dataBalances,
    inputToValuePrevious,
    inputFromValuePrevious,
  ]);

  useEffect(() => {
    if (chainId && SWAP_LIST[chainId] && isSuccess) {
      trackGTMActionsSwap(
        isReverse ? 'DHV' : getNetworkSymbol(chainId),
        SWAP_LIST[chainId].supporter,
        chainId,
        account,
        'fact',
      );

      form.setFieldsValue({ [INPUT_NAME_FROM]: '0', [INPUT_NAME_TO]: '0' });

      setInputFromValue(BigNumber.from('0'));
      setInputToValue(BigNumber.from('0'));
    }
  }, [form, account, chainId, isSuccess, isReverse]);

  useEffect(() => {
    if (previousChainId !== chainId) {
      setPreviousChainId(chainId);

      form.setFieldsValue({ [INPUT_NAME_FROM]: '0', [INPUT_NAME_TO]: '0' });

      setInputFromValue(BigNumber.from('0'));
      setInputToValue(BigNumber.from('0'));
    }
  }, [form, chainId, previousChainId]);

  return (
    <Form
      onFieldsChange={(changedFields) => {
        changedFields.forEach((item) => {
          const field = item as { name: string[]; value: string };

          if (field.name[0] === INPUT_NAME_FROM) {
            const decimals = isReverse
              ? dataBalances?.decimalsDHV || 0
              : (chainId && getNetworkDecimals(chainId)) || 0;

            if (
              decimals >= ((field.value || '0').split('.')[1]?.length || 0) &&
              !(dataValues?.amountIn || BigNumber.from('0')).eq(
                parseUnits(field.value || '0', decimals),
              )
            ) {
              setInputFromValue(parseUnits(field.value || '0', decimals));
            }
          }

          if (field.name[0] === INPUT_NAME_TO) {
            const decimals = isReverse
              ? (chainId && getNetworkDecimals(chainId)) || 0
              : dataBalances?.decimalsDHV || 0;

            if (
              decimals >= ((field.value || '0').split('.')[1]?.length || 0) &&
              !(dataValues?.amountOut || BigNumber.from('0')).eq(
                parseUnits(field.value || '0', decimals),
              )
            ) {
              setInputToValue(parseUnits(field.value || '0', decimals));
            }
          }
        });
      }}
      initialValues={{ [INPUT_NAME_FROM]: '0', [INPUT_NAME_TO]: '0' }}
      className={cn(
        styles.container,
        { [styles['is-loading']]: isLoadingAllowance || isLoadingBalances },
        className,
      )}
      onFinish={onFinish}
      form={form}
      name={FORM_NAME}
    >
      <h3 className={styles.title}>{t('DHV.DHV_SWAP.BLOCK_MAIN_TITLE')}</h3>

      {!account && (
        <WalletConnections
          textButton={t('DHV.DHV_SWAP.BUTTON_CONNECT_WALLET')}
          isInline
        />
      )}

      {account && chainId && SWAP_LIST[chainId] && (
        <p className={styles.info}>
          <span className={styles.infoTitle}>
            {t('DHV.DHV_SWAP.BLOCK_TITLE')}
          </span>{' '}
          <img
            className={styles.infoIcon}
            src={SWAP_LIST[chainId].icon}
            alt={SWAP_LIST[chainId].supporter}
          />{' '}
          <strong className={styles.infoSupporter}>
            {SWAP_LIST[chainId].supporter}
          </strong>{' '}
          <small className={styles.infoRecommendation}>
            {!isTablet && isDesktop && '('}
            {t('DHV.DHV_SWAP.BLOCK_SUBTITLE')}
            {!isTablet && isDesktop && ')'}
          </small>
        </p>
      )}

      {account && chainId && SWAP_LIST[chainId] && (
        <p className={styles.notice}>
          {t('DHV.DHV_SWAP.SLIPPAGE_MESSAGE', {
            slippage: SWAP_LIST[chainId].slippage,
          })}
        </p>
      )}

      {account && chainId && !SWAP_LIST[chainId] && (
        <p className={styles.notice}>
          {t('DHV.DHV_SWAP.WRONG_NETWORK_MESSAGE')}
        </p>
      )}

      <DHVSwapField
        commissionValue={
          dataBalances && !isReverse
            ? dataBalances.transactionFee
            : BigNumber.from('0')
        }
        isHasMaxButton
        tokenSymbol={
          isReverse ? 'DHV' : (chainId && getNetworkSymbol(chainId)) || ''
        }
        isDisabled={
          !dataBalances ||
          !account ||
          !!(chainId && !SWAP_LIST[chainId]) ||
          isLoadingValues ||
          isLoadingBalances ||
          isLoadingAllowance ||
          isLoadingTransaction
        }
        className={styles.field}
        inputName={INPUT_NAME_FROM}
        formName={FORM_NAME}
        decimals={
          isReverse
            ? dataBalances?.decimalsDHV || 0
            : (chainId && getNetworkDecimals(chainId)) || 0
        }
        balance={
          isReverse
            ? dataBalances?.balanceDHV || null
            : dataBalances?.balanceNative || null
        }
        title={t('DHV.DHV_SWAP.FORM_FIELD_TITLE_FROM')}
        form={form}
      />

      <button
        className={styles.button}
        disabled={
          !dataBalances ||
          !account ||
          !!(chainId && !SWAP_LIST[chainId]) ||
          isLoadingValues ||
          isLoadingBalances ||
          isLoadingAllowance ||
          isLoadingTransaction
        }
        onClick={handleRevers}
        type="button"
      />

      <DHVSwapField
        isNoValidateMax
        tokenSymbol={
          isReverse ? (chainId && getNetworkSymbol(chainId)) || '' : 'DHV'
        }
        isDisabled={
          !dataBalances ||
          !account ||
          !!(chainId && !SWAP_LIST[chainId]) ||
          isLoadingValues ||
          isLoadingBalances ||
          isLoadingAllowance ||
          isLoadingTransaction
        }
        className={styles.field}
        inputName={INPUT_NAME_TO}
        formName={FORM_NAME}
        minValue={
          isReverse
            ? getMinValue((chainId && getNetworkDecimals(chainId)) || 0)
            : getMinValue(dataBalances?.decimalsDHV || 0)
        }
        decimals={
          isReverse
            ? (chainId && getNetworkDecimals(chainId)) || 0
            : dataBalances?.decimalsDHV || 0
        }
        balance={
          isReverse
            ? dataBalances?.balanceNative || null
            : dataBalances?.balanceDHV || null
        }
        title={
          isDesktop
            ? t('DHV.DHV_SWAP.FORM_FIELD_TITLE_TO')
            : t('DHV.DHV_SWAP.FORM_FIELD_TITLE_TO_ESTIMATED')
        }
        form={form}
      />

      <DHVSwapEstimate
        decimals={
          isReverse
            ? (chainId && getNetworkDecimals(chainId)) || 0
            : dataBalances?.decimalsDHV || 0
        }
      />

      <FormSubmit
        isDisabled={
          !dataBalances ||
          !chainId ||
          !!(chainId && !SWAP_LIST[chainId]) ||
          (isReverse ? isMinValueBiggerDHV : isMinValueBiggerNative)
        }
        isLoading={
          isLoadingValues ||
          isLoadingBalances ||
          isLoadingAllowance ||
          isLoadingTransaction
        }
        className={styles.submit}
        name={
          isReverse && isAllowanceSmaller
            ? t('DHV.DHV_SWAP.BUTTON_APPROVE')
            : t('DHV.DHV_SWAP.BUTTON_BUY')
        }
      />

      {(isLoadingAllowance || isLoadingBalances) && (
        <img
          className={styles.loading}
          height="100"
          width="100"
          src={LOADING_IMAGE}
          alt="Loading"
        />
      )}
    </Form>
  );
};

export default DHVSwap;
