import * as Sentry from '@sentry/react';
import {
  call,
  CallEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';
import { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import {
  Contract,
  ContractInterface,
  ContractReceipt,
  ContractTransaction,
} from '@ethersproject/contracts';
import { JsonRpcProvider } from '@ethersproject/providers';
import { BigNumber } from '@ethersproject/bignumber';

import {
  setError,
  setIsLoading,
  setIsSuccess,
} from '@modules/dhv/slices/dhvSwapTransactionSlice';
import handleSwapExactEthForTokens from '@modules/dhv/actions/handleSwapExactEthForTokens';
import handleSwapExactTokensForEth from '@modules/dhv/actions/handleSwapExactTokensForEth';

function* dhvSwapWorker({
  payload,
}: PayloadAction<{
  routerAddress: string;
  routerABI: ContractInterface;
  isReverse: boolean;
  amountOut: BigNumber;
  amountIn: BigNumber;
  swapPath: string[];
  deadline: number;
  library: JsonRpcProvider;
  account: string;
}>): Generator<
  | CallEffect<Promise<ContractReceipt>>
  | CallEffect<unknown>
  | PutEffect<AnyAction>,
  void,
  never
> {
  try {
    yield put(setIsLoading(true));
    yield put(setError(null));
    yield put(setIsSuccess(false));

    const routerContract = new Contract(
      payload.routerAddress,
      payload.routerABI,
      payload.library.getSigner(payload.account),
    );

    const { wait }: ContractTransaction = yield call(
      payload.isReverse
        ? handleSwapExactTokensForEth
        : handleSwapExactEthForTokens,
      routerContract,
      payload.amountIn,
      payload.amountOut,
      payload.isReverse ? [...payload.swapPath].reverse() : payload.swapPath,
      payload.account,
      payload.deadline,
    );

    yield call(wait, 1);

    yield put(setIsSuccess(true));
  } catch (error: unknown) {
    yield put(setIsSuccess(false));
    yield put(setError(error));

    Sentry.captureException(
      `Swap error, 'sell ${
        payload.isReverse ? 'DHV' : 'Native Token'
      }', account ${payload.account}, amount in ${Number(
        payload.amountIn,
      )}, amount out ${Number(payload.amountOut)}, swap route ${
        payload.routerAddress
      }`,
    );
  } finally {
    yield put(setIsLoading(false));
  }
}

function* dhvSwapSaga(): Generator {
  yield takeLatest('DHV_SWAP_REQUESTED', dhvSwapWorker);
}

export default dhvSwapSaga;
