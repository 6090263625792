import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { formatUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import { Dropdown } from 'antd';
import cn from 'classnames';

import { listSelector } from '@modules/underlying/slices/underlyingSlice';
import formatValueToken from '@modules/common/helpers/formatValueToken';
import StoredPool from '@modules/pools/types/StoredPool';
import { useTypedSelector } from '@utils/store';
import ARROW_ICON from '@modules/portfolio/assets/details_arrow.svg';
import UNDERLYING_LIST from '@configs/underlying';
import POOLS from '@configs/pools';

import styles from './HoldingList.module.scss';

const HoldingList: FC<{
  userPoolAmountInUnderlying?: StoredPool['userPoolAmountInUnderlying'];
  isVisibleHidden?: boolean;
  poolAddress: string;
  buttonClass: string;
  buttonText: string;
  poolId: number;
  isHiddenComponent?: boolean;
}> = ({
  userPoolAmountInUnderlying,
  isVisibleHidden,
  poolAddress,
  buttonClass,
  buttonText,
  poolId,
  isHiddenComponent,
}) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const {
    i18n: { language },
  } = useTranslation();

  const underlyingList = useTypedSelector(listSelector);

  const { chainId, account } = useWeb3React();

  return (
    <Dropdown
      overlayClassName={styles.overlay}
      onVisibleChange={(status) => setIsOpened(status)}
      overlay={
        <ul className={styles.overlayList}>
          {chainId &&
            underlyingList &&
            POOLS[chainId] &&
            POOLS[chainId][poolAddress] &&
            POOLS[chainId][poolAddress][poolId]?.underlying?.map(
              ({ address, isHidden }, index: number) =>
                (isVisibleHidden || !isHidden) && (
                  <li className={styles.overlayListItem} key={address}>
                    {UNDERLYING_LIST[underlyingList[address]?.symbol]?.icon && (
                      <img
                        className={styles.overlayListItemIcon}
                        height="18"
                        width="18"
                        src={
                          UNDERLYING_LIST[underlyingList[address].symbol].icon
                        }
                        alt={underlyingList[address].symbol}
                      />
                    )}

                    {userPoolAmountInUnderlying &&
                    underlyingList[address]?.decimals ? (
                      <span className={styles.overlayListItemText}>
                        {account && userPoolAmountInUnderlying[index]
                          ? formatValueToken(
                              language,
                              Number(
                                formatUnits(
                                  userPoolAmountInUnderlying[index],
                                  underlyingList[address].decimals,
                                ),
                              ),
                            )
                          : '-'}
                      </span>
                    ) : (
                      <span className={styles.overlayListItemText}>
                        {account &&
                        underlyingList[address]?.userBalance &&
                        underlyingList[address]?.decimals
                          ? formatValueToken(
                              language,
                              Number(
                                formatUnits(
                                  underlyingList[address].userBalance ||
                                    BigNumber.from('0'),
                                  underlyingList[address].decimals,
                                ),
                              ),
                            )
                          : '-'}
                      </span>
                    )}
                  </li>
                ),
            )}
        </ul>
      }
      trigger={['click']}
    >
      <button
        className={cn(styles.button, { [styles.hidden]: isHiddenComponent })}
        type="button"
      >
        <span className={cn(styles.buttonText, buttonClass)}>{buttonText}</span>
        <img
          className={cn(styles.buttonIcon, {
            [styles['is-opened']]: isOpened,
          })}
          height="14"
          width="14"
          src={ARROW_ICON}
          alt=""
        />
      </button>
    </Dropdown>
  );
};

HoldingList.defaultProps = {
  userPoolAmountInUnderlying: undefined,
  isVisibleHidden: false,
  isHiddenComponent: false,
};

export default HoldingList;
