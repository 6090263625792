import { BigNumber } from '@ethersproject/bignumber';

import ContractFunctions from '@modules/common/types/ContractFunctions';

const getUnderlyingInfo = (
  controllerContract: ContractFunctions<BigNumber>,
  clusterAddress: string,
  clusterAmount: BigNumber,
): Promise<BigNumber> => {
  return controllerContract.getUnderlyingsInfo(clusterAddress, clusterAmount);
};

export default getUnderlyingInfo;
