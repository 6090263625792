import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { formatUnits } from '@ethersproject/units';
import { Web3Provider } from '@ethersproject/providers';
import cn from 'classnames';

import { getPrices } from '@modules/prices/slices/tokenPricesSlice';
import { getCurrencySelector } from '@modules/prices/slices/currencySlice';
import { useTypedSelector } from '@utils/store';

import formatValuePrice from '@modules/common/helpers/formatValuePrice';
import formatValueToken from '@modules/common/helpers/formatValueToken';
import getNetworkSymbol from '@modules/common/helpers/getNetworkSymbol';
import getTokenPrice from '@modules/common/helpers/getTokenPrice';
import StoredAssetList from '@modules/portfolio/types/StoredAssetList';
import PortfolioPieChart from '@modules/portfolio/components/PortfolioPieChart';

import POOLS from '@configs/pools';

import CHART_IMG from '@modules/portfolio/assets/coming_soon_chart.png';

import formatValuePercentage from '@modules/common/helpers/formatValuePercentage';
import styles from './PortfolioStats.module.scss';

const PortfolioStats: FC<{
  earningList: StoredAssetList | null;
  assetList: StoredAssetList | null;
}> = ({ earningList, assetList }) => {
  const prices = useTypedSelector(getPrices);
  const currency = useTypedSelector(getCurrencySelector);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { chainId } = useWeb3React<Web3Provider>();

  const getPrice = useCallback(
    (address: string, pid: number, data: StoredAssetList) => {
      let price = 0;

      if (chainId && prices && currency) {
        const { clusterPrice, symbol, decimals } = data[address][Number(pid)];

        price = POOLS[chainId][address]
          ? prices[
              POOLS[chainId][address][Number(pid)].asset ||
                POOLS[chainId][address][Number(pid)].symbol ||
                symbol
            ]
          : 0;
        if (!price && clusterPrice) {
          price =
            Number(formatUnits(clusterPrice, decimals)) *
            currency[chainId].price;
        }
      }

      return price;
    },
    [currency, chainId, prices],
  );

  const inWalletPrice = useMemo(() => {
    if (assetList) {
      let amount = 0;
      Object.keys(assetList)?.map((address: string) => {
        return Object.keys(assetList[address])?.map((pid: string) => {
          amount += getTokenPrice(
            assetList[address][Number(pid)].balanceOf,
            assetList[address][Number(pid)].decimals,
            getPrice(address, Number(pid), assetList),
          );
          return pid;
        });
      });

      return amount;
    }

    return null;
  }, [assetList, getPrice]);

  const stakedPrice = useMemo(() => {
    if (earningList) {
      let amount = 0;

      Object.keys(earningList)?.map((address: string) => {
        return Object.keys(earningList[address])?.map((pid: string) => {
          amount += getTokenPrice(
            earningList[address][Number(pid)].userPoolAmount,
            earningList[address][Number(pid)].decimals,
            getPrice(address, Number(pid), earningList),
          );

          return pid;
        });
      });

      return amount;
    }

    return null;
  }, [earningList, getPrice]);

  const inWalletPercent =
    (Number(inWalletPrice) / (Number(inWalletPrice) + Number(stakedPrice))) *
    100;

  const stakedPercent =
    (Number(stakedPrice) / (Number(inWalletPrice) + Number(stakedPrice))) * 100;

  return (
    <aside className={styles.stats}>
      <div className={styles.card}>
        <h3 className={styles['card--title']}>
          {t('PORTFOLIO.PORTFOLIO_STATS.TOTAL_TITLE')}
        </h3>
        <p className={styles['card--tav']}>
          {inWalletPrice !== null || stakedPrice !== null ? (
            <span>
              {formatValuePrice(
                language,
                (inWalletPrice || 0) + (stakedPrice || 0),
              )}
            </span>
          ) : (
            '-'
          )}
          {' ≈ '}
          {(inWalletPrice !== null || stakedPrice !== null) &&
          currency &&
          chainId
            ? formatValueToken(
                language,
                ((inWalletPrice || 0) + (stakedPrice || 0)) /
                  currency[chainId].price,
              )
            : '-'}{' '}
          {chainId ? getNetworkSymbol(chainId) : ''}
        </p>
      </div>

      <div className={styles.card}>
        <h3 className={styles['card--title']}>
          {t('PORTFOLIO.PORTFOLIO_STATS.ALLOCATION_TITLE')}
        </h3>
        <div className={styles.chart}>
          <div className={styles['chart--total']}>
            <PortfolioPieChart
              inWallet={inWalletPrice || 0}
              staking={stakedPrice || 0}
            />
            <div className={styles['chart--total--count']}>
              <h5>{t('PORTFOLIO.PORTFOLIO_STATS.TOTAL')}</h5>
              {inWalletPrice !== null || stakedPrice !== null
                ? formatValuePrice(
                    language,
                    (inWalletPrice || 0) + (stakedPrice || 0),
                  )
                : '-'}
              {(inWalletPrice !== null || stakedPrice !== null) &&
              currency &&
              chainId ? (
                <span>
                  {formatValueToken(
                    language,
                    ((inWalletPrice || 0) + (stakedPrice || 0)) /
                      currency[chainId].price,
                  )}{' '}
                  {chainId ? getNetworkSymbol(chainId) : ''}
                </span>
              ) : (
                '-'
              )}
            </div>
          </div>
          <ul className={styles['chart--details']}>
            <li>
              <div className={cn(styles.square, styles['square--blue'])} />
              <span>
                {t('PORTFOLIO.PORTFOLIO_STATS.ALLOCATION_IN_WALLET')}
              </span>{' '}
              <p className={styles.value}>
                {inWalletPrice !== null || stakedPrice !== null
                  ? formatValuePrice(language, inWalletPrice || 0)
                  : '-'}
                {}
                {(inWalletPrice !== null || stakedPrice !== null) && (
                  <i>
                    ({formatValuePercentage(language, inWalletPercent || 0)})
                  </i>
                )}
              </p>
            </li>
            <li>
              <div className={cn(styles.square, styles['square--green'])} />
              <span>
                {t('PORTFOLIO.PORTFOLIO_STATS.ALLOCATION_STAKED')}
              </span>{' '}
              <p className={styles.value}>
                {inWalletPrice !== null || stakedPrice !== null
                  ? formatValuePrice(language, stakedPrice || 0)
                  : '-'}
                {(inWalletPrice !== null || stakedPrice !== null) && (
                  <i>({formatValuePercentage(language, stakedPercent || 0)})</i>
                )}
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div className={cn(styles.card, styles['card--performance'])}>
        <h3 className={styles['card--title']}>
          {t('PORTFOLIO.PORTFOLIO_STATS.PERFORMANCE_TITLE')}
        </h3>
        <p>
          {inWalletPrice !== null || stakedPrice !== null
            ? formatValuePrice(
                language,
                (inWalletPrice || 0) + (stakedPrice || 0),
              )
            : '-'}
        </p>
        <div className={styles.coming}>
          <img src={CHART_IMG} alt="Chart" />
          <p className={styles['coming--text']}>
            {t('PORTFOLIO.PORTFOLIO_STATS.PERFORMANCE_MESSAGE')}
          </p>
        </div>
      </div>
    </aside>
  );
};

export default PortfolioStats;
