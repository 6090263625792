import { TrezorConnector } from '@web3-react/trezor-connector';
import { useWeb3React } from '@web3-react/core';

import Connector from '@modules/connections/types/Connector';
import constants from '@modules/connections/constants';
import getRPC from '@modules/connections/helpers/getRPC';
import icon from '@modules/connections/assets/trezor_logo.svg';

const Trezor = (chainId: number): (() => Promise<void>) => {
  const { activate } = useWeb3React();

  const network = new TrezorConnector({
    chainId,
    url: getRPC(chainId),
    pollingInterval: constants.POLLING_INTERVAL,
    manifestAppUrl: process.env.REACT_APP_TREZOR_APP_URL || '',
    manifestEmail: process.env.REACT_APP_TREZOR_EMAIL || '',
  });

  return () => activate(network);
};

const TrezorDisconnect = (): (() => void) => {
  const { deactivate } = useWeb3React();

  return () => deactivate();
};

const connector: Connector = {
  type: 'trezor',
  name: 'Trezor',
  icon,
  connect: Trezor,
  disconnect: TrezorDisconnect,
};

export default connector;
