import { FC } from 'react';
import { Web3ReactProvider } from '@web3-react/core';
import {
  ExternalProvider,
  JsonRpcFetchFunc,
  Web3Provider,
} from '@ethersproject/providers';

import WalletConnectWrapper from '@modules/connections/components/WalletConnectWrapper';
import Web3ReactManager from '@modules/connections/components/Web3ReactManager';
import constants from '@modules/connections/constants';

function getLibrary(
  provider: ExternalProvider | JsonRpcFetchFunc,
): Web3Provider {
  const library: Web3Provider = new Web3Provider(provider);

  library.pollingInterval = constants.POLLING_INTERVAL;

  return library;
}

const Web3ReactWrapper: FC = ({ children }) => {
  return (
    <WalletConnectWrapper>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ReactManager>{children}</Web3ReactManager>
      </Web3ReactProvider>
    </WalletConnectWrapper>
  );
};

export default Web3ReactWrapper;
