import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import UnderlyingFormItem from '@modules/pools/types/UnderlyingFormItem';
import StoredPool from '@modules/pools/types/StoredPool';

import styles from './PoolStakeFormPrompt.module.scss';

const PoolStakeFormPrompt: FC<{
  symbol: StoredPool['name'] | UnderlyingFormItem['symbol'];
}> = ({ symbol }) => {
  const { t } = useTranslation();

  return (
    <p className={styles.text}>
      {t('POOLS.POOL_STAKE_FORM_PROMPT.TEXT', { symbol })}
    </p>
  );
};

export default PoolStakeFormPrompt;
