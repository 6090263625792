import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';

import { setConnected } from '@modules/connections/slices/connectionsSlice';

function* setConnectedWorker({ payload }: PayloadAction<string>) {
  yield put(setConnected(payload));
}

function* connectedSaga(): Generator {
  yield takeLatest('CONNECTIONS_CONNECTED_REQUESTED', setConnectedWorker);
}

export default connectedSaga;
