import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '@utils/store';
import PoolsStatsListStored from '@modules/pools/types/PoolStatsList';

interface InitialState {
  isLoading: boolean;
  preError: unknown | null;
  error: unknown | null;
  list: PoolsStatsListStored | null;
}

const initialState: InitialState = {
  isLoading: false,
  preError: null,
  error: null,
  list: null,
};

const reducers = {
  setIsLoading(state: InitialState, action: PayloadAction<boolean>) {
    state.isLoading = action.payload;
  },
  setPreError(state: InitialState, action: PayloadAction<unknown | null>) {
    state.preError = action.payload;
  },
  setError(state: InitialState, action: PayloadAction<unknown | null>) {
    state.error = action.payload;
  },
  setPoolsApyApr(
    state: InitialState,
    action: PayloadAction<PoolsStatsListStored | null>,
  ) {
    state.list = {
      ...state.list,
      ...action.payload,
    };
  },
};

const poolsStatsSlice = createSlice({
  name: 'poolsStats',
  initialState,
  reducers,
});

export const isLoadingSelector = (state: RootState): boolean =>
  state.poolsStats.isLoading;
export const preErrorSelector = (state: RootState): unknown | null =>
  state.poolsStats.preError;
export const errorSelector = (state: RootState): unknown | null =>
  state.poolsStats.error;
export const poolsApyAprSelector = (
  state: RootState,
): PoolsStatsListStored | null => state.poolsStats.list;

export const { setIsLoading, setPreError, setError, setPoolsApyApr } =
  poolsStatsSlice.actions;

export default poolsStatsSlice.reducer;
