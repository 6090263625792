import * as Sentry from '@sentry/react';
import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import {
  CallEffect,
  PutEffect,
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { JsonRpcProvider } from '@ethersproject/providers';
import {
  ContractTransaction,
  ContractInterface,
  ContractReceipt,
  Contract,
} from '@ethersproject/contracts';
import { BigNumber } from '@ethersproject/bignumber';

import {
  setConfirmedItem,
  setPendingItem,
  setSuccessItem,
  setErrorItem,
  setValueItem,
} from '@modules/pools/slices/unstakeSlice';
import handlePoolWithdrawUnderlying from '@modules/pools/actions/handlePoolWithdrawUnderlying';
import handlePoolWithdraw from '@modules/pools/actions/handlePoolWithdraw';
import getPoolGasLimitUnStakeUnderlying from '@modules/pools/actions/getPoolGasLimitUnstakeUnderlying';
import getPoolGasLimitUnStake from '@modules/pools/actions/getPoolGasLimitUnstake';

const getGasLimit = (
  poolAddress: string,
  poolABI: ContractInterface,
  poolId: number,
  amount: BigNumber,
  provider: JsonRpcProvider,
  account: string,
  underlyingAddress?: string,
): Promise<BigNumber> => {
  const poolContract = new Contract(
    poolAddress,
    poolABI,
    provider.getSigner(account),
  );

  return underlyingAddress
    ? getPoolGasLimitUnStakeUnderlying(
        underlyingAddress,
        poolContract,
        poolId,
        amount,
      )
    : getPoolGasLimitUnStake(poolContract, poolId, amount);
};

const unstakePool = (
  poolAddress: string,
  poolABI: ContractInterface,
  poolId: number,
  amount: BigNumber,
  provider: JsonRpcProvider,
  account: string,
  gasLimit: BigNumber,
  underlyingAddress?: string,
): Promise<BigNumber> => {
  const poolContract = new Contract(
    poolAddress,
    poolABI,
    provider.getSigner(account),
  );

  return underlyingAddress
    ? handlePoolWithdrawUnderlying(
        underlyingAddress,
        poolContract,
        poolId,
        amount,
        gasLimit,
      )
    : handlePoolWithdraw(poolContract, poolId, amount, gasLimit);
};

function* unstakePoolWorker({
  payload,
}: PayloadAction<{
  underlyingAddress?: string;
  poolAddress: string;
  poolABI: ContractInterface;
  poolId: number;
  amount: BigNumber;
  library: JsonRpcProvider;
  account: string;
}>): Generator<
  | CallEffect<Promise<ContractReceipt>>
  | CallEffect<BigNumber>
  | CallEffect<unknown>
  | PutEffect<AnyAction>,
  void,
  never
> {
  try {
    yield put(
      setPendingItem({
        poolAddress: payload.poolAddress,
        poolId: payload.poolId,
        isPending: true,
      }),
    );
    yield put(
      setErrorItem({
        poolAddress: payload.poolAddress,
        poolId: payload.poolId,
        error: null,
      }),
    );
    const gasLimit: BigNumber = yield call(
      getGasLimit,
      payload.poolAddress,
      payload.poolABI,
      payload.poolId,
      payload.amount,
      payload.library,
      payload.account,
      payload.underlyingAddress,
    );

    const { wait }: ContractTransaction = yield call(
      unstakePool,
      payload.poolAddress,
      payload.poolABI,
      payload.poolId,
      payload.amount,
      payload.library,
      payload.account,
      gasLimit.mul(120).div(100),
      payload.underlyingAddress,
    );

    yield put(
      setConfirmedItem({
        poolAddress: payload.poolAddress,
        poolId: payload.poolId,
        isConfirmed: true,
      }),
    );
    yield put(
      setPendingItem({
        poolAddress: payload.poolAddress,
        poolId: payload.poolId,
        isPending: false,
      }),
    );

    yield call(wait, 1);

    yield put(
      setValueItem({
        poolAddress: payload.poolAddress,
        poolId: payload.poolId,
        value: [payload.amount],
      }),
    );
    yield put(
      setSuccessItem({
        poolAddress: payload.poolAddress,
        poolId: payload.poolId,
        isSuccess: true,
      }),
    );
    yield put(
      setConfirmedItem({
        poolAddress: payload.poolAddress,
        poolId: payload.poolId,
        isConfirmed: false,
      }),
    );
  } catch (error: unknown) {
    yield put(
      setConfirmedItem({
        poolAddress: payload.poolAddress,
        poolId: payload.poolId,
        isConfirmed: false,
      }),
    );
    yield put(
      setPendingItem({
        poolAddress: payload.poolAddress,
        poolId: payload.poolId,
        isPending: false,
      }),
    );
    yield put(
      setSuccessItem({
        poolAddress: payload.poolAddress,
        poolId: payload.poolId,
        isSuccess: false,
      }),
    );
    yield put(
      setErrorItem({
        poolAddress: payload.poolAddress,
        poolId: payload.poolId,
        error,
      }),
    );
    Sentry.captureException(
      `UnStake pool error,
      ' pool address ${payload.poolAddress}',
      ' pool id ${payload.poolId}',
      ' underlying address ${payload.underlyingAddress || '--'}',
      ' amount ${Number(payload.amount)}',
      ' account ${payload.account}'`,
    );
  }
}

function* unstakePoolSaga(): Generator {
  yield takeLatest('POOLS_UNSTAKE_POOL_REQUESTED', unstakePoolWorker);
}

export default unstakePoolSaga;
