import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import formatValuePrice from '@modules/common/helpers/formatValuePrice';
import {
  valueSelector,
  isLoadingSelector,
} from '@modules/layout/slices/tvlSlice';
import { useTypedSelector } from '@utils/store';
import LOADING_IMAGE from '@modules/common/assets/loading.svg';

import styles from './TotalValueLocked.module.scss';

const TotalValueLocked: FC<{ className?: string; small?: boolean }> = ({
  className,
  small,
}) => {
  const tvl = useTypedSelector(valueSelector);
  const isLoading = useTypedSelector(isLoadingSelector);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <dl className={cn(styles.container, className)}>
      <dt className={styles.title}>
        {small
          ? t('LAYOUT.TOTAL_VALUE_LOCKED.TITLE_MOBILE')
          : t('LAYOUT.TOTAL_VALUE_LOCKED.TITLE')}
      </dt>

      <dd className={cn(styles.value, { [styles['is-loading']]: isLoading })}>
        <span className={styles.valueText}>
          {tvl ? formatValuePrice(language, tvl) : '-'}
        </span>

        {isLoading && (
          <img
            className={styles.loading}
            height="16"
            width="16"
            src={LOADING_IMAGE}
            alt=""
          />
        )}
      </dd>
    </dl>
  );
};

TotalValueLocked.defaultProps = { className: undefined, small: false };

export default TotalValueLocked;
