import { FC } from 'react';
import cn from 'classnames';

import styles from '@modules/pools/components/PoolCardRewardTabs/PoolCardRewardTabs.module.scss';

const PoolCardRewardTabs: FC<{
  tabNumber: number;
  onChange: (id: number) => void;
}> = ({ tabNumber, onChange }) => {
  return (
    <div className={styles.container}>
      <button
        className={cn(styles.button, {
          [styles['is-active']]: tabNumber === 0,
        })}
        onClick={() => onChange(0)}
        type="button"
      >
        Accumulated
      </button>

      <button
        className={cn(styles.button, {
          [styles['is-active']]: tabNumber === 1,
        })}
        onClick={() => onChange(1)}
        type="button"
      >
        Incentivisation
      </button>
    </div>
  );
};

export default PoolCardRewardTabs;
