import { FC } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import styles from './ButtonLink.module.scss';

const ButtonLink: FC<{
  isExternal?: boolean;
  className?: string;
  onClick?: () => void;
  title?: string;
  color?: 'gradient' | 'pink' | 'gray' | 'blue' | 'lightBlue';
  size?:
    | 'extraLarge'
    | 'large'
    | 'big'
    | 'medium'
    | 'little'
    | 'small'
    | 'extraSmall';
  icon?: string;
  text?: string;
  path: string;
  hash?: string;
}> = ({
  isExternal,
  className,
  onClick,
  title,
  color,
  size,
  icon,
  text,
  path,
  hash,
}) => {
  if (isExternal) {
    return (
      <a
        className={cn(
          styles.container,
          color && styles[`container--${color}`],
          size && styles[`container--${size}`],
          className,
        )}
        onClick={onClick}
        target="_blank"
        title={title}
        href={`${path}${hash || ''}`}
        rel="noreferrer"
      >
        {icon && (
          <img className={styles.icon} src={icon} alt={title || text || ''} />
        )}
        {text && <span className={styles.text}>{text}</span>}
      </a>
    );
  }

  if (hash) {
    return (
      <HashLink
        className={cn(
          styles.container,
          color && styles[`container--${color}`],
          size && styles[`container--${size}`],
          className,
        )}
        smooth
        title={title}
        to={`${path}${hash}`}
      >
        {icon && (
          <img className={styles.icon} src={icon} alt={title || text || ''} />
        )}
        {text && <span className={styles.text}>{text}</span>}
      </HashLink>
    );
  }

  return (
    <Link
      className={cn(
        styles.container,
        color && styles[`container--${color}`],
        size && styles[`container--${size}`],
        className,
      )}
      onClick={onClick}
      title={title}
      to={path}
    >
      {icon && (
        <img className={styles.icon} src={icon} alt={title || text || ''} />
      )}
      {text && <span className={styles.text}>{text}</span>}
    </Link>
  );
};

ButtonLink.defaultProps = {
  isExternal: false,
  className: undefined,
  onClick: undefined,
  title: undefined,
  color: 'gradient',
  size: 'extraLarge',
  icon: undefined,
  text: undefined,
  hash: undefined,
};

export default ButtonLink;
