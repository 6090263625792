import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { PutEffect, takeLatest, put } from 'redux-saga/effects';

import { setPreError, setError } from '@modules/pools/slices/poolsStatsSlice';

function* setErrorPoolsStatsWorker({
  payload,
}: PayloadAction<{
  error: unknown;
}>): Generator<PutEffect<AnyAction>, void, never> {
  yield put(setPreError(null));
  yield put(setError(payload.error));
}

function* setErrorPoolsStatsSaga(): Generator {
  yield takeLatest('POOLS_SET_ERROR_POOLS_STATS', setErrorPoolsStatsWorker);
}

export default setErrorPoolsStatsSaga;
