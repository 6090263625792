import * as Sentry from '@sentry/react';
import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import {
  CallEffect,
  PutEffect,
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { JsonRpcProvider } from '@ethersproject/providers';
import {
  ContractTransaction,
  ContractInterface,
  ContractReceipt,
  Contract,
} from '@ethersproject/contracts';
import { BigNumber } from '@ethersproject/bignumber';

import {
  setConfirmedItem,
  setPendingItem,
  setSuccessItem,
  setErrorItem,
} from '@modules/clusters/slices/disassembleSlice';
import handleClusterDisassemble from '@modules/clusters/actions/handleClusterDisassemble';
import { ClusterList } from '@configs/clusters';

const disassembleCluster = (
  clusterDecimals: number,
  clusterAddress: string,
  clusterABI: ContractInterface,
  provider: JsonRpcProvider,
  amount: string,
  account: string,
): Promise<BigNumber> => {
  const clusterContract = new Contract(
    clusterAddress,
    clusterABI,
    provider.getSigner(account),
  );

  return handleClusterDisassemble(clusterContract, clusterDecimals, amount);
};

function* disassembleClusterWorker({
  payload,
}: PayloadAction<{
  clusterDecimals: number;
  clusterAddress: string;
  clusters: ClusterList;
  library: JsonRpcProvider;
  account: string;
  amount: string;
}>): Generator<
  | CallEffect<Promise<ContractReceipt>>
  | CallEffect<BigNumber>
  | CallEffect<unknown>
  | PutEffect<AnyAction>,
  void,
  never
> {
  try {
    yield put(
      setPendingItem({
        clusterAddress: payload.clusterAddress,
        isPending: true,
      }),
    );
    yield put(
      setErrorItem({
        clusterAddress: payload.clusterAddress,
        error: null,
      }),
    );

    const { wait }: ContractTransaction = yield call(
      disassembleCluster,
      payload.clusterDecimals,
      payload.clusterAddress,
      payload.clusters[payload.clusterAddress].tokenABI,
      payload.library,
      payload.amount,
      payload.account,
    );

    yield put(
      setConfirmedItem({
        clusterAddress: payload.clusterAddress,
        isConfirmed: true,
      }),
    );
    yield put(
      setPendingItem({
        clusterAddress: payload.clusterAddress,
        isPending: false,
      }),
    );

    yield call(wait, 1);

    yield put(
      setSuccessItem({
        clusterAddress: payload.clusterAddress,
        isSuccess: true,
      }),
    );
    yield put(
      setConfirmedItem({
        clusterAddress: payload.clusterAddress,
        isConfirmed: false,
      }),
    );
  } catch (error: unknown) {
    yield put(
      setConfirmedItem({
        clusterAddress: payload.clusterAddress,
        isConfirmed: false,
      }),
    );
    yield put(
      setPendingItem({
        clusterAddress: payload.clusterAddress,
        isPending: false,
      }),
    );
    yield put(
      setSuccessItem({
        clusterAddress: payload.clusterAddress,
        isSuccess: false,
      }),
    );
    yield put(setErrorItem({ clusterAddress: payload.clusterAddress, error }));
    Sentry.captureException(
      `Disassemble error,
      ' cluster address ${payload.clusterAddress}',
      ' amount ${payload.amount}',
      ' account ${payload.account}'`,
    );
  }
}

function* disassembleClusterSaga(): Generator {
  yield takeLatest(
    'CLUSTERS_DISASSEMBLE_CLUSTER_REQUESTED',
    disassembleClusterWorker,
  );
}

export default disassembleClusterSaga;
