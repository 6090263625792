import { ContractInterface } from '@ethersproject/contracts';

import Controller from '@abis/Controller.json';

export interface ControllerData {
  controllerAddress: string;
  controllerABI: ContractInterface;
}

interface ControllerByChain {
  [key: number]: ControllerData;
}

const CONTROLLERS: ControllerByChain = {
  1: {
    controllerAddress: '0xE806Db0693788Ec79D6c45a8934E0EAF94AFA37E',
    controllerABI: Controller,
  },
  4: {
    controllerAddress: '0x8164cbd67FB431161Ccbd9358bcfF0f66Ae79a31',
    controllerABI: Controller,
  },
  56: {
    controllerAddress: '0x3c45a344C8b25c584107eeAF13b011674bAdc42A',
    controllerABI: Controller,
  },
  137: {
    controllerAddress: '0xE806Db0693788Ec79D6c45a8934E0EAF94AFA37E',
    controllerABI: Controller,
  },
  100: {
    controllerAddress: '0xDB1255A43e52E05B9d0371dB574f23cA1F208231',
    controllerABI: Controller,
  },
  80001: {
    controllerAddress: '0xECA63D9528b50cD05D0DF0A6f36d9E4C7d33cB79',
    controllerABI: Controller,
  },
};

export default CONTROLLERS;
