import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { PutEffect, takeLatest, put } from 'redux-saga/effects';

import {
  setPreError,
  setError,
} from '@modules/underlying/slices/underlyingSlice';

function* setErrorUnderlyingWorker({
  payload,
}: PayloadAction<{
  error: unknown;
}>): Generator<PutEffect<AnyAction>, void, never> {
  yield put(setPreError(null));
  yield put(setError(payload.error));
}

function* setErrorUnderlyingSaga(): Generator {
  yield takeLatest(
    'UNDERLYING_SET_ERROR_UNDERLYING_REQUESTED',
    setErrorUnderlyingWorker,
  );
}

export default setErrorUnderlyingSaga;
