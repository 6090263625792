import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import getInitialObject from '@modules/common/helpers/getInitialObject';
import BooleanList from '@modules/common/types/BooleanList';
import ErrorList from '@modules/common/types/ErrorList';
import type { RootState } from '@utils/store';

interface InitialState {
  confirmedList: BooleanList;
  pendingList: BooleanList;
  successList: BooleanList;
  errorList: ErrorList;
}

const initialState: InitialState = {
  confirmedList: getInitialObject(),
  pendingList: getInitialObject(),
  successList: getInitialObject(),
  errorList: getInitialObject(),
};

const reducers = {
  setConfirmedItem(
    state: InitialState,
    action: PayloadAction<{ clusterAddress: string; isConfirmed: boolean }>,
  ) {
    state.confirmedList[action.payload.clusterAddress] =
      action.payload.isConfirmed;
  },
  setPendingItem(
    state: InitialState,
    action: PayloadAction<{ clusterAddress: string; isPending: boolean }>,
  ) {
    state.pendingList[action.payload.clusterAddress] = action.payload.isPending;
  },
  setSuccessItem(
    state: InitialState,
    action: PayloadAction<{ clusterAddress: string; isSuccess: boolean }>,
  ) {
    state.successList[action.payload.clusterAddress] = action.payload.isSuccess;
  },
  setErrorItem(
    state: InitialState,
    action: PayloadAction<{ clusterAddress: string; error: unknown | null }>,
  ) {
    state.errorList[action.payload.clusterAddress] = action.payload.error;
  },
};

const assembleSlice = createSlice({
  name: 'assemble',
  initialState,
  reducers,
});

export const confirmedListSelector = (state: RootState): BooleanList =>
  state.assemble.confirmedList;
export const pendingListSelector = (state: RootState): BooleanList =>
  state.assemble.pendingList;
export const successListSelector = (state: RootState): BooleanList =>
  state.assemble.successList;
export const errorListSelector = (state: RootState): ErrorList =>
  state.assemble.errorList;

export const {
  setConfirmedItem,
  setPendingItem,
  setSuccessItem,
  setErrorItem,
} = assembleSlice.actions;

export default assembleSlice.reducer;
