import ContractFunctions from '@modules/common/types/ContractFunctions';
import { BigNumber } from '@ethersproject/bignumber';

const handleSwapExactEthForTokens = (
  routerContract: ContractFunctions<BigNumber[]>,
  amountIn: BigNumber,
  amountOutMin: BigNumber,
  path: string[],
  account: string,
  deadline: number,
): Promise<BigNumber[]> => {
  return routerContract.swapExactETHForTokens(
    amountOutMin,
    path,
    account,
    deadline,
    {
      value: amountIn,
    },
  );
};

export default handleSwapExactEthForTokens;
