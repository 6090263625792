import { AnyAction } from '@reduxjs/toolkit';
import {
  call,
  CallEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
  setError,
  setIsLoading,
  setPreError,
  setChartData,
} from '@modules/dhv/slices/dhvChartPricesSlice';
import StoredChartData from '@modules/dhv/types/ChartData';

const API = process.env.REACT_APP_API || 'http://localhost:1234/api';

const getChartData = (): Promise<AxiosResponse<StoredChartData[]>> => {
  return axios.get(`${API}/history/chart?assetSymbol=DHV`);
};

function* getChartDataWorker(): Generator<
  CallEffect<AxiosResponse<StoredChartData[]>> | PutEffect<AnyAction>,
  void,
  never
> {
  try {
    yield put(setIsLoading(true));
    yield put(setPreError(null));
    yield put(setError(null));

    const { data } = yield call(getChartData);

    yield put(setChartData(data));
  } catch (error: unknown) {
    yield put(setPreError(error));
  } finally {
    yield put(setIsLoading(false));
  }
}

function* getChartDataSaga(): Generator {
  yield takeLatest('DHV_GET_CHART_DATA_REQUESTED', getChartDataWorker);
}

export default getChartDataSaga;
