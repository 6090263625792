import { formatUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';

const getTokenPrice = (
  value: BigNumber,
  decimal: number,
  price: number,
): number => {
  return Number(formatUnits(value, decimal)) * price;
};

export default getTokenPrice;
