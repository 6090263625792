import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { PutEffect, takeLatest, put } from 'redux-saga/effects';

import {
  setPreError,
  setError,
} from '@modules/dhv/slices/dhvSwapAllowanceSlice';

function* setAllowanceErrorWorker({
  payload,
}: PayloadAction<{
  error: unknown;
}>): Generator<PutEffect<AnyAction>, void, never> {
  yield put(setPreError(null));
  yield put(setError(payload.error));
}

function* setAllowanceErrorSaga(): Generator {
  yield takeLatest('SWAP_SET_ALLOWANCE_ERROR', setAllowanceErrorWorker);
}

export default setAllowanceErrorSaga;
