import { FC, useState, useEffect, useContext, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Dropdown } from 'antd';
import cn from 'classnames';

import getActualNetworkName from '@modules/common/helpers/getActualNetworkName';
import { NetworkSelectorContext } from '@modules/networks/context/NetworkSelectorContext';
import NETWORKS from '@configs/networks';
import DROPDOWN_ARROW from '@modules/networks/assets/dropdown_arrow.svg';

import styles from './NetworkSelector.module.scss';

const NetworkSelector: FC<{ className?: string }> = ({ className }) => {
  const { handleChangeChainId, chainId } = useContext(NetworkSelectorContext);

  const { chainId: networkId } = useWeb3React();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const handleChangeNetwork = useCallback(
    (id: number) => {
      setIsLoading(true);
      setIsOpened(false);

      localStorage.setItem(
        `settings-connection-network-${
          process.env.REACT_APP_NETWORK_ENVIRONMENT || 'test'
        }`,
        id.toString(),
      );
      localStorage.setItem(
        `settings-network-${process.env.REACT_APP_NETWORK_ENVIRONMENT || 'id'}`,
        id.toString(),
      );

      handleChangeChainId(id);

      setIsLoading(false);
    },
    [handleChangeChainId],
  );

  useEffect(() => {
    if (networkId) {
      handleChangeNetwork(networkId);
    }
  }, [handleChangeNetwork, networkId]);

  return (
    <Dropdown
      overlayClassName={styles.overlay}
      onVisibleChange={(status) => setIsOpened(status)}
      placement="bottomRight"
      disabled={isLoading}
      trigger={['click']}
      overlay={
        <ul className={styles.list}>
          {NETWORKS.map(({ name, chainId: id, icon }) => (
            <li className={styles.listItem} key={id}>
              <button
                className={cn(styles.option, {
                  [styles['option--active']]: id === chainId,
                })}
                disabled={isLoading || id === chainId}
                onClick={() => handleChangeNetwork(id)}
                type="button"
              >
                {icon && (
                  <img className={styles.optionIcon} src={icon} alt={name} />
                )}

                <span className={styles.optionText}>{name}</span>
              </button>
            </li>
          ))}
        </ul>
      }
    >
      <button
        className={cn(
          styles.button,
          isOpened ? styles['is-opened'] : null,
          className,
        )}
        disabled={isLoading}
        type="button"
      >
        <span className={styles.buttonText}>
          {getActualNetworkName(chainId || NETWORKS[0].chainId)}
        </span>

        <img
          className={styles.buttonArrow}
          src={DROPDOWN_ARROW}
          alt="Network selector dropdown arrow"
        />
      </button>
    </Dropdown>
  );
};

NetworkSelector.defaultProps = { className: undefined };

export default NetworkSelector;
