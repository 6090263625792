import { FC, useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector';
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from '@web3-react/walletconnect-connector';
import { notification } from 'antd';

import NETWORKS from '@configs/networks';
import { connectedSelector } from '@modules/connections/slices/connectionsSlice';
import { NetworkSelectorContext } from '@modules/networks/context/NetworkSelectorContext';
import ConnectorsDisconnects from '@modules/connections/types/ConnectorsDisconnects';
import ConnectorsConnects from '@modules/connections/types/ConnectorsConnects';
import Connector from '@modules/connections/types/Connector';
import RpcError from '@modules/connections/types/RpcError';
import injectedConnector from '@modules/connections/connectors/Injected';
import networkConnector from '@modules/connections/connectors/network';
import ledgerConnector from '@modules/connections/connectors/ledger';
import trezorConnector from '@modules/connections/connectors/trezor';
import walletConnect from '@modules/connections/connectors/walletConnect';
import unstoppableConnector from '@modules/connections/connectors/unstapableDomains';
import onDisconnect from '@modules/connections/helpers/onDisconnect';
import { useTypedSelector } from '@utils/store';

const getErrorMessage = (error: Error): string => {
  if (error instanceof NoEthereumProviderError) {
    return 'CONNECTIONS.WEB_3_REACT_MANAGER.ERROR_NO_PROVIDER';
  }

  if (error instanceof UnsupportedChainIdError) {
    return 'CONNECTIONS.WEB_3_REACT_MANAGER.ERROR_UNSUPPORTED_NETWORK';
  }

  if (
    error instanceof UserRejectedRequestErrorInjected ||
    error instanceof UserRejectedRequestErrorWalletConnect
  ) {
    return 'CONNECTIONS.WEB_3_REACT_MANAGER.ERROR_USER_REJECTED';
  }

  return error.message;
};

const connectors: Connector[] = [
  injectedConnector,
  ledgerConnector,
  trezorConnector,
  walletConnect,
  unstoppableConnector,
];

const connections = {} as ConnectorsConnects;

const disconnections = {} as ConnectorsDisconnects;

const Web3ReactManager: FC = ({ children }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { chainId } = useContext(NetworkSelectorContext);

  const { active, error } = useWeb3React();

  const [previousChainId, setPreviousChainId] = useState<number>(chainId);
  const [isPreConnected, setIsPreConnected] = useState<boolean>(false);

  const connected = useTypedSelector(connectedSelector);

  const networkDisconnect = networkConnector.disconnect();
  const networkConnect = networkConnector.connect(chainId);

  connectors.map(({ disconnect, connect, type }) => {
    disconnections[type] = disconnect();
    connections[type] = connect(chainId);

    return type;
  });

  useEffect(() => {
    if (error) {
      notification.error({
        message: t('CONNECTIONS.WEB_3_REACT_MANAGER.WALLET_CONNECTION_ERROR'),
        description: t(getErrorMessage(error)),
      });
    }
  }, [error, t]);

  useEffect(() => {
    if (!isPreConnected && active) {
      setIsPreConnected(true);
    }
  }, [isPreConnected, active]);

  useEffect(() => {
    if (!isPreConnected && !active) {
      const networkId = localStorage.getItem(
        `settings-connection-network-${
          process.env.REACT_APP_NETWORK_ENVIRONMENT || 'test'
        }`,
      );
      const connectorType = localStorage.getItem(
        `settings-connection-name-${
          process.env.REACT_APP_NETWORK_ENVIRONMENT || 'test'
        }`,
      );

      if (
        chainId === Number(networkId) &&
        connectorType &&
        ((connectorType === injectedConnector.type &&
          window.ethereum?.isMetaMask &&
          window.ethereum?.selectedAddress) ||
          connectorType !== injectedConnector.type)
      ) {
        const connect = connections[connectorType];

        connect()
          .then(() => {
            dispatch({
              type: 'CONNECTIONS_CONNECTED_REQUESTED',
              payload: connectorType,
            });
          })
          .catch((err: Error) => {
            notification.error({
              message: t(
                'CONNECTIONS.WEB_3_REACT_MANAGER.RPC_CONNECTION_ERROR',
              ),
              description: err.message || err,
            });
          })
          .finally(() => setIsPreConnected(true));
      } else if (
        chainId === Number(networkId) &&
        connectorType &&
        connectorType !== injectedConnector.type
      ) {
        const connect = connections[connectorType];

        connect()
          .then(() => {
            dispatch({
              type: 'CONNECTIONS_CONNECTED_REQUESTED',
              payload: connectorType,
            });
          })
          .catch((err: Error) => {
            notification.error({
              message: t(
                'CONNECTIONS.WEB_3_REACT_MANAGER.RPC_CONNECTION_ERROR',
              ),
              description: err.message || err,
            });
          })
          .finally(() => setIsPreConnected(true));
      } else {
        networkConnect()
          .then(() => {
            dispatch({
              type: 'CONNECTIONS_CONNECTED_REQUESTED',
              payload: networkConnector.type,
            });
          })
          .catch((err: Error) => {
            notification.error({
              message: t(
                'CONNECTIONS.WEB_3_REACT_MANAGER.RPC_CONNECTION_ERROR',
              ),
              description: err.message || err,
            });
          })
          .finally(() => setIsPreConnected(true));
      }
    }
  }, [isPreConnected, networkConnect, dispatch, chainId, active, t]);

  useEffect(() => {
    if (previousChainId !== chainId && isPreConnected && active) {
      if (connected === injectedConnector.type) {
        window?.ethereum
          .request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${chainId.toString(16)}` }],
          })
          .then(() => setPreviousChainId(chainId))
          .catch((err: RpcError) => {
            if (err?.code === 4902) {
              const networkData = NETWORKS.find(
                ({ chainId: networkId }) => chainId === networkId,
              );

              if (networkData) {
                const params = [
                  {
                    chainId: `0x${chainId.toString(16)}`,
                    chainName: networkData.name,
                    nativeCurrency: networkData.nativeCurrency,
                    rpcUrls: networkData.rpc,
                    blockExplorerUrls: [networkData.explorers[0].url],
                  },
                ];

                window.ethereum
                  .request({ method: 'wallet_addEthereumChain', params })
                  .then(() => setPreviousChainId(chainId))
                  .catch((er: Error) => {
                    notification.error({
                      message: t(
                        'CONNECTIONS.WEB_3_REACT_MANAGER.SWITCH_NETWORK_ERROR',
                      ),
                      description: er.message || er,
                    });
                  });
              }
            } else {
              notification.error({
                message: t(
                  'CONNECTIONS.WEB_3_REACT_MANAGER.SWITCH_NETWORK_ERROR',
                ),
                description: err.message || err,
              });
            }
          });
      } else if (connected === networkConnector.type) {
        setPreviousChainId(chainId);

        networkDisconnect()?.catch((err: Error) => {
          notification.error({
            message: t('CONNECTIONS.WEB_3_REACT_MANAGER.RPC_CONNECTION_ERROR'),
            description: err.message || err,
          });
        });

        setTimeout(() => {
          networkConnect()
            .then(() => {
              dispatch({
                type: 'CONNECTIONS_CONNECTED_REQUESTED',
                payload: networkConnector.type,
              });
            })
            .catch((err: Error) => {
              notification.error({
                message: t(
                  'CONNECTIONS.WEB_3_REACT_MANAGER.RPC_CONNECTION_ERROR',
                ),
                description: err.message || err,
              });
            });
        });
      } else {
        onDisconnect(disconnections, connected);
        setPreviousChainId(chainId);
      }
    }

    if (previousChainId !== chainId && isPreConnected && !active) {
      setPreviousChainId(chainId);

      networkDisconnect()?.catch((err: Error) => {
        notification.error({
          message: t('CONNECTIONS.WEB_3_REACT_MANAGER.RPC_CONNECTION_ERROR'),
          description: err.message || err,
        });
      });

      setTimeout(() => {
        networkConnect()
          .then(() => {
            dispatch({
              type: 'CONNECTIONS_CONNECTED_REQUESTED',
              payload: networkConnector.type,
            });
          })
          .catch((err: Error) => {
            notification.error({
              message: t(
                'CONNECTIONS.WEB_3_REACT_MANAGER.RPC_CONNECTION_ERROR',
              ),
              description: err.message || err,
            });
          });
      });
    }

    if (previousChainId === chainId && isPreConnected && !active) {
      if (connected === injectedConnector.type) {
        localStorage.removeItem(
          `settings-connection-network-${
            process.env.REACT_APP_NETWORK_ENVIRONMENT || 'test'
          }`,
        );
        localStorage.removeItem(
          `settings-connection-name-${
            process.env.REACT_APP_NETWORK_ENVIRONMENT || 'test'
          }`,
        );
      }

      networkConnect()
        .then(() => {
          dispatch({
            type: 'CONNECTIONS_CONNECTED_REQUESTED',
            payload: networkConnector.type,
          });
        })
        .catch((err: Error) => {
          notification.error({
            message: t('CONNECTIONS.WEB_3_REACT_MANAGER.RPC_CONNECTION_ERROR'),
            description: err.message || err,
          });
        });
    }
  }, [
    networkDisconnect,
    previousChainId,
    networkConnect,
    isPreConnected,
    connected,
    chainId,
    active,
    t,
    dispatch,
  ]);

  return <>{children}</>;
};

export default Web3ReactManager;
