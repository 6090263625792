import { FC, useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
  ResponsiveContainer,
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Area,
} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import cn from 'classnames';

import ValueChanges from '@modules/common/components/ValueChanges';
import {
  getDhvChart,
  preErrorSelector,
  errorSelector,
  isLoadingSelector,
} from '@modules/dhv/slices/dhvChartPricesSlice';
import formatValuePrice from '@modules/common/helpers/formatValuePrice';
import useMediaQuery from '@modules/layout/hooks/useMediaQuery';
import { useTypedSelector } from '@utils/store';
import constants from '@modules/common/constants';
import StoredChartData from '@modules/dhv/types/ChartData';
import LOADING_IMAGE from '@modules/common/assets/loading.svg';

import styles from './DHVPrice.module.scss';

const timePeriodRange: {
  [key: string]: {
    name: string;
    days: number;
  };
} = {
  1: {
    name: 'DHV.DHV_PRICE.PERIOD_BUTTON_DAY',
    days: 1,
  },
  7: {
    name: 'DHV.DHV_PRICE.PERIOD_BUTTON_WEEK',
    days: 7,
  },
  30: {
    name: 'DHV.DHV_PRICE.PERIOD_BUTTON_MONTH',
    days: 30,
  },
  90: {
    name: 'DHV.DHV_PRICE.PERIOD_BUTTON_THREE_MONTH',
    days: 90,
  },
};

const CustomToolTip: FC<{
  viewChartData: StoredChartData[] | null;
  data: TooltipProps<ValueType, NameType>;
}> = ({ data, viewChartData }) => {
  const {
    i18n: { language },
  } = useTranslation();

  const priceUSD = useMemo(() => {
    return viewChartData?.find(
      ({ priceTimestamp }) => priceTimestamp === data.label,
    )?.priceUSD;
  }, [viewChartData, data]);

  return (
    <div className={styles.chartTooltip}>
      {data && (
        <span className={styles.chartTooltipItem}>
          {moment(Number(data.label)).format('DD MMM HH:mm')}
        </span>
      )}{' '}
      {priceUSD && (
        <span className={styles.chartTooltipItem}>
          {formatValuePrice(language, Number(priceUSD))}
        </span>
      )}
    </div>
  );
};

const DHVPrice: FC<{ className: string }> = ({ className }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const dispatch = useDispatch();

  const isLoading = useTypedSelector(isLoadingSelector);
  const chartData = useTypedSelector(getDhvChart);
  const preError = useTypedSelector(preErrorSelector);
  const error = useTypedSelector(errorSelector);

  const [callAttempts, setCallAttempts] = useState(0);
  const [timeFilter, setTimeFilter] = useState(timePeriodRange[7]);

  const isTablet = useMediaQuery(1599);
  const isMobile = useMediaQuery(991);

  const getChartData = useCallback(
    () => dispatch({ type: 'DHV_GET_CHART_DATA_REQUESTED' }),
    [dispatch],
  );

  useEffect(() => {
    getChartData();

    const reGetChartData = setInterval(
      () => getChartData(),
      constants.GET_ITEMS_INTERVAL,
    );

    return () => clearInterval(reGetChartData);
  }, [getChartData]);

  useEffect(() => {
    if (preError && callAttempts < constants.GET_ITEMS_ATTEMPTS) {
      getChartData();
      setCallAttempts(callAttempts + 1);
    }

    if (preError && callAttempts >= constants.GET_ITEMS_ATTEMPTS) {
      dispatch({
        type: 'DHV_SET_ERROR_CHART_DATA_REQUESTED',
        payload: { error: preError },
      });
    }
  }, [preError, callAttempts, getChartData, dispatch]);

  const viewChartData = useMemo(() => {
    const nowTimeStamp = new Date().getTime();
    const endTimeStamp = nowTimeStamp - timeFilter.days * 24 * 60 * 60 * 1000;
    return (
      chartData &&
      chartData.filter(
        ({ priceTimestamp }) =>
          Number(priceTimestamp) > endTimeStamp &&
          Number(priceTimestamp) < nowTimeStamp,
      )
    );
  }, [chartData, timeFilter.days]);

  const dhvPriceDayAgo = useMemo(() => {
    return viewChartData ? Number(viewChartData[0]?.priceUSD) : null;
  }, [viewChartData]);

  const currentPrice = useMemo(() => {
    return chartData ? Number(chartData[chartData.length - 1]?.priceUSD) : null;
  }, [chartData]);

  const chartSize = useMemo(() => {
    if (isMobile) {
      return 248;
    }

    if (isTablet) {
      return 160;
    }

    return 248;
  }, [isMobile, isTablet]);

  return (
    <div
      className={cn(
        styles.container,
        { [styles['is-loading']]: isLoading },
        className,
      )}
    >
      <h3 className={styles.title}>{t('DHV.DHV_PRICE.BLOCK_TITLE')}</h3>

      <div className={styles.price}>
        <span className={styles.priceValue}>
          {currentPrice ? formatValuePrice(language, currentPrice) : '-'}
        </span>

        {dhvPriceDayAgo && currentPrice ? (
          <ValueChanges
            valuePrevious={dhvPriceDayAgo}
            valueCurrent={currentPrice}
            textBefore={`(${t(timeFilter.name)}`}
            textAfter=")"
          />
        ) : (
          '-'
        )}
      </div>

      {viewChartData && (
        <ResponsiveContainer className={styles.chart} height={chartSize}>
          <AreaChart
            data={viewChartData}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="70.5%" stopColor="#00FFF5" stopOpacity={0.1} />
                <stop
                  offset="100%"
                  stopColor="#0991900E"
                  stopOpacity={0.053125}
                />
              </linearGradient>
            </defs>

            <CartesianGrid
              strokeDasharray="1"
              vertical={false}
              stroke="#FFFFFF14"
            />

            {!isMobile && (
              <YAxis
                tickFormatter={(value: string) => `$${value}`}
                tick={{
                  fill: '#4D4E59',
                  fontSize: isTablet ? 12 : 14,
                }}
                type="number"
              />
            )}

            {!isMobile && (
              <XAxis
                tick={{
                  fill: '#4D4E59',
                  fontSize: isTablet ? 12 : 14,
                }}
                dataKey="priceTimestamp"
                domain={['dataMin', 'dataMax']}
                name="Time"
                type="number"
                interval={0}
                tickCount={5}
                tickFormatter={(unixTime) =>
                  moment(Number(unixTime)).format(
                    timeFilter.days === 1 ? 'HH:mm' : 'MMM DD',
                  )
                }
                padding={{ right: 25, left: 0 }}
                dy={3}
              />
            )}

            <Tooltip
              content={(data) => (
                <CustomToolTip data={data} viewChartData={viewChartData} />
              )}
            />

            <Area
              type="monotone"
              dataKey="priceUSD"
              stroke="#16E4DC"
              strokeWidth={1}
              fillOpacity={1}
              fill="url(#colorUv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      )}

      {!chartData && error && <p>{t('DHV.DHV_PRICE.ERROR_BLOCK_TITLE')}</p>}

      <div className={styles.radio}>
        {Object.values(timePeriodRange).map((period) => (
          <label
            className={styles.radioButton}
            htmlFor={period.name}
            key={period.name}
          >
            <input
              className={styles.radioButtonInput}
              onChange={() => setTimeFilter(period)}
              checked={timeFilter.name === period.name}
              value={period.name}
              name="timePeriod"
              type="radio"
              id={period.name}
            />
            <span className={styles.radioButtonText}>{t(period.name)}</span>
          </label>
        ))}
      </div>

      {isLoading && (
        <img
          className={styles.loading}
          height="100"
          width="100"
          src={LOADING_IMAGE}
          alt=""
        />
      )}
    </div>
  );
};

export default DHVPrice;
