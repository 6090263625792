import StableList from '@modules/pools/components/StableList';
import ICON from '@modules/pools/assets/pages/stables_logo.svg';

const page = {
  path: '/stables',
  menu: 1,
  icon: ICON,
  order: 5,
  title: 'POOLS.STABLE_LIST.NAV_LINK_NAME',
  parent: 0,
  component: StableList,
};

export default page;
