import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { PutEffect, takeLatest, put } from 'redux-saga/effects';

import {
  setSuccessItem,
  setErrorItem,
} from '@modules/pools/slices/unstakeSlice';

function* resetUnstakePoolWorker({
  payload,
}: PayloadAction<{
  poolAddress: string;
  poolId: number;
}>): Generator<PutEffect<AnyAction>, void, never> {
  yield put(
    setSuccessItem({
      poolAddress: payload.poolAddress,
      poolId: payload.poolId,
      isSuccess: false,
    }),
  );
  yield put(
    setErrorItem({
      poolAddress: payload.poolAddress,
      poolId: payload.poolId,
      error: null,
    }),
  );
}

function* resetUnstakePoolSaga(): Generator {
  yield takeLatest(
    'POOLS_RESET_UNSTAKE_POOL_REQUESTED',
    resetUnstakePoolWorker,
  );
}

export default resetUnstakePoolSaga;
