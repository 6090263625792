import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

import formatValuePercentage from '@modules/common/helpers/formatValuePercentage';
import useMediaQuery from '@modules/layout/hooks/useMediaQuery';

import styles from './PortfolioPieChart.module.scss';

interface CustomPayload {
  percent: number;
}

const chartColors = [
  { start: '#5D82FF', end: '#41B7FF' },
  { start: '#41B7FF', end: '#00FF73' },
];

const returnPercent = (payload?: CustomPayload): number => {
  return payload?.percent || 0;
};

const CustomTooltip = ({
  active,
  payload,
}: TooltipProps<ValueType, NameType>) => {
  const {
    i18n: { language },
  } = useTranslation();

  return active ? (
    <div className={styles.tooltip}>
      {formatValuePercentage(language, returnPercent(payload?.[0]?.payload))}
    </div>
  ) : null;
};

const PortfolioPieChart: FC<{
  inWallet: number;
  staking: number;
}> = ({ inWallet, staking }) => {
  const { t } = useTranslation();

  const isTablet = useMediaQuery(1600);
  const isMobile = useMediaQuery(992);

  const chartData = useMemo(() => {
    return [
      {
        id: 0,
        name: t('PORTFOLIO.PORTFOLIO_STATS.ALLOCATION_IN_WALLET'),
        value: inWallet,
        percent: (inWallet / (inWallet + staking)) * 100,
      },
      {
        id: 1,
        name: t('PORTFOLIO.PORTFOLIO_STATS.ALLOCATION_STAKED'),
        value: staking,
        percent: (staking / (inWallet + staking)) * 100,
      },
    ];
  }, [inWallet, staking, t]);

  const chartSize = useMemo(() => {
    if (isMobile) {
      return 160;
    }
    if (isTablet) {
      return 100;
    }
    return 192;
  }, [isMobile, isTablet]);

  const chartInnerRadius = useMemo(() => {
    if (isMobile) {
      return 65;
    }
    if (isTablet) {
      return 40;
    }
    return 76;
  }, [isMobile, isTablet]);

  const chartOuterRadius = useMemo(() => {
    if (isMobile) {
      return 80;
    }
    if (isTablet) {
      return 50;
    }
    return 96;
  }, [isMobile, isTablet]);

  return (
    <div className={styles.pieArea}>
      <PieChart width={chartSize} height={chartSize}>
        <defs>
          {chartData.map((entry) => (
            <linearGradient
              id={`cellGradient${entry.id}`}
              key={`cellGradient${entry.id}`}
            >
              <stop
                offset="0%"
                stopColor={chartColors[entry.id % chartColors.length].start}
              />
              <stop
                offset="100%"
                stopColor={chartColors[entry.id % chartColors.length].end}
              />
            </linearGradient>
          ))}
        </defs>
        <Pie
          data={chartData}
          innerRadius={chartInnerRadius}
          outerRadius={chartOuterRadius}
          fill="#8884d8"
          paddingAngle={3}
          dataKey="value"
          labelLine
        >
          {chartData.map((entry) => (
            <Cell
              key={`cell-${entry.id}`}
              fill={`url(#cellGradient${entry.id})`}
              stroke="none"
            />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} wrapperStyle={{ zIndex: 5 }} />
      </PieChart>
    </div>
  );
};

export default PortfolioPieChart;
