import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { RootState } from '@utils/store';
import StoredUnderlyingList from '@modules/underlying/types/StoredUnderlyingList';

interface InitialState {
  isLoading: boolean;
  preError: unknown | null;
  error: unknown | null;
  list: StoredUnderlyingList | null;
}

const initialState: InitialState = {
  isLoading: false,
  preError: null,
  error: null,
  list: null,
};

const reducers = {
  setIsLoading(state: InitialState, action: PayloadAction<boolean>) {
    state.isLoading = action.payload;
  },
  setPreError(state: InitialState, action: PayloadAction<unknown | null>) {
    state.preError = action.payload;
  },
  setError(state: InitialState, action: PayloadAction<unknown | null>) {
    state.error = action.payload;
  },
  setList(state: InitialState, action: PayloadAction<StoredUnderlyingList>) {
    state.list = {
      ...state.list,
      ...action.payload,
    };
  },
};

const underlyingSlice = createSlice({
  name: 'underlying',
  initialState,
  reducers,
});

export const isLoadingSelector = (state: RootState): boolean =>
  state.underlying.isLoading;
export const preErrorSelector = (state: RootState): unknown | null =>
  state.underlying.preError;
export const errorSelector = (state: RootState): unknown | null =>
  state.underlying.error;
export const listSelector = (state: RootState): StoredUnderlyingList | null =>
  state.underlying.list;

export const { setIsLoading, setPreError, setError, setList } =
  underlyingSlice.actions;

export default underlyingSlice.reducer;
