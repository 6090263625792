import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import {
  CallEffect,
  PutEffect,
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { JsonRpcProvider } from '@ethersproject/providers';
import { ContractInterface, Contract } from '@ethersproject/contracts';
import { BigNumber } from '@ethersproject/bignumber';

import {
  setIsLoading,
  setPreError,
  setValue,
} from '@modules/dhv/slices/dhvSwapAllowanceSlice';
import getTokenAllowance from '@modules/dhv/actions/getTokenAllowance';

function* getAllowanceWorker({
  payload,
}: PayloadAction<{
  routerAddress: string;
  dhvAddress: string;
  tokenAbi: ContractInterface;
  library: JsonRpcProvider;
  account: string;
}>): Generator<CallEffect<BigNumber> | PutEffect<AnyAction>, void, never> {
  try {
    yield put(setIsLoading(true));
    yield put(setPreError(null));

    const tokenContract = new Contract(
      payload.dhvAddress,
      payload.tokenAbi,
      payload.library,
    );

    const allowance = yield call(
      getTokenAllowance,
      tokenContract,
      payload.routerAddress,
      payload.account,
    );

    yield put(setValue(allowance));
  } catch (error: unknown) {
    yield put(setPreError(error));
  } finally {
    yield put(setIsLoading(false));
  }
}

function* getAllowanceSaga(): Generator {
  yield takeLatest('SWAP_GET_ALLOWANCE', getAllowanceWorker);
}

export default getAllowanceSaga;
