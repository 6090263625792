import { FC } from 'react';
import cn from 'classnames';

import LOADING_IMAGE from '@modules/common/assets/loading.svg';

import styles from './Button.module.scss';

const Button: FC<{
  className?: string;
  isLoading?: boolean;
  isSubmit?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  color?: 'gradient' | 'pink' | 'polygon' | 'azure' | 'blue';
  size?: 'large' | 'big' | 'sbig' | 'medium' | 'small' | 'little';
  text?: string;
}> = ({
  className,
  isLoading,
  isSubmit,
  disabled,
  onClick,
  color,
  size,
  text,
}) => {
  return (
    <button
      className={cn(
        styles.container,
        color && styles[`container--${color}`],
        size && styles[`container--${size}`],
        className,
      )}
      disabled={disabled}
      onClick={onClick}
      type={isSubmit ? 'submit' : 'button'}
    >
      {isLoading && (
        <img
          className={styles.loading}
          height="22"
          width="22"
          src={LOADING_IMAGE}
          alt=""
        />
      )}
      {text}
    </button>
  );
};

Button.defaultProps = {
  className: undefined,
  isLoading: false,
  isSubmit: false,
  disabled: false,
  onClick: undefined,
  color: 'gradient',
  size: 'big',
  text: undefined,
};

export default Button;
