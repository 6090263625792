import ContractFunctions from '@modules/common/types/ContractFunctions';
import { BigNumber } from '@ethersproject/bignumber';

const getImpulseYieldBalance = (
  poolContract: ContractFunctions<BigNumber>,
  poolId: number,
  account: string,
): Promise<BigNumber> => {
  return poolContract.yieldBalance(poolId, account);
};

export default getImpulseYieldBalance;
