import * as Sentry from '@sentry/react';
import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import {
  CallEffect,
  PutEffect,
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { JsonRpcProvider } from '@ethersproject/providers';
import {
  ContractTransaction,
  ContractInterface,
  ContractReceipt,
  Contract,
} from '@ethersproject/contracts';
import { BigNumber } from '@ethersproject/bignumber';

import {
  setConfirmedItem,
  setPendingItem,
  setSuccessItem,
  setErrorItem,
} from '@modules/clusters/slices/assembleSlice';
import getClusterGasPriceAssemble from '@modules/clusters/actions/getClusterGasPriceAssemble';
import getClusterAmountFromEth from '@modules/clusters/actions/getClusterAmountFromEth';
import handleClusterAssemble from '@modules/clusters/actions/handleClusterAssemble';
import getUnderlyingInfo from '@modules/clusters/actions/getUnderlyingInfo';
import { ClusterList } from '@configs/clusters';

const getMethodGasPrice = (
  clusterAddress: string,
  clusterAmount: BigNumber,
  clusterABI: ContractInterface,
  provider: JsonRpcProvider,
  account: string,
  amount: BigNumber,
): Promise<BigNumber> => {
  const clusterContract = new Contract(
    clusterAddress,
    clusterABI,
    provider.getSigner(account),
  );

  return getClusterGasPriceAssemble(clusterContract, clusterAmount, amount);
};

const getClusterAmount = (
  controllerAddress: string,
  clusterDecimals: number,
  clusterAddress: string,
  controllerABI: ContractInterface,
  provider: JsonRpcProvider,
  amount: string,
): Promise<BigNumber> => {
  const controllerContract = new Contract(
    controllerAddress,
    controllerABI,
    provider,
  );

  return getClusterAmountFromEth(
    controllerContract,
    clusterDecimals,
    clusterAddress,
    amount,
  );
};

const getEthFromClusterAmount = (
  controllerAddress: string,
  clusterAddress: string,
  clusterAmount: BigNumber,
  controllerABI: ContractInterface,
  provider: JsonRpcProvider,
): Promise<BigNumber> => {
  const controllerContract = new Contract(
    controllerAddress,
    controllerABI,
    provider,
  );

  return getUnderlyingInfo(controllerContract, clusterAddress, clusterAmount);
};

const assembleCluster = (
  clusterAddress: string,
  clusterAmount: BigNumber,
  clusterABI: ContractInterface,
  ethAmount: BigNumber,
  gasLimit: BigNumber,
  provider: JsonRpcProvider,
  account: string,
): Promise<BigNumber> => {
  const clusterContract = new Contract(
    clusterAddress,
    clusterABI,
    provider.getSigner(account),
  );

  return handleClusterAssemble(
    clusterContract,
    clusterAmount,
    ethAmount,
    gasLimit,
  );
};

function* assembleClusterWorker({
  payload,
}: PayloadAction<{
  controllerAddress: string;
  networkDecimals: number;
  clusterDecimals: number;
  clusterAddress: string;
  controllerABI: ContractInterface;
  clusters: ClusterList;
  library: JsonRpcProvider;
  account: string;
  amount: string;
}>): Generator<
  | CallEffect<Promise<ContractReceipt>>
  | CallEffect<BigNumber>
  | CallEffect<unknown>
  | PutEffect<AnyAction>,
  void,
  never
> {
  try {
    yield put(
      setPendingItem({
        clusterAddress: payload.clusterAddress,
        isPending: true,
      }),
    );
    yield put(
      setErrorItem({
        clusterAddress: payload.clusterAddress,
        error: null,
      }),
    );

    const amountValue: BigNumber = yield call(
      getClusterAmount,
      payload.controllerAddress,
      payload.clusterDecimals,
      payload.clusterAddress,
      payload.controllerABI,
      payload.library,
      payload.amount,
    );

    const ethValue = yield call(
      getEthFromClusterAmount,
      payload.controllerAddress,
      payload.clusterAddress,
      amountValue,
      payload.controllerABI,
      payload.library,
    );

    const gasLimit: BigNumber = yield call(
      getMethodGasPrice,
      payload.clusterAddress,
      // sending 2.5% less cluster token amount to avoid trx failures due to eth
      // price changes
      amountValue.mul(975).div(1000),
      payload.clusters[payload.clusterAddress].tokenABI,
      payload.library,
      payload.account,
      ethValue[3],
    );

    const { wait }: ContractTransaction = yield call(
      assembleCluster,
      payload.clusterAddress,
      // sending 2.5% less cluster token amount to avoid trx failures due to eth
      // price changes
      amountValue.mul(975).div(1000),
      payload.clusters[payload.clusterAddress].tokenABI,
      ethValue[3],
      gasLimit.mul(120).div(100),
      payload.library,
      payload.account,
    );

    yield put(
      setConfirmedItem({
        clusterAddress: payload.clusterAddress,
        isConfirmed: true,
      }),
    );
    yield put(
      setPendingItem({
        clusterAddress: payload.clusterAddress,
        isPending: false,
      }),
    );

    yield call(wait, 1);

    yield put(
      setSuccessItem({
        clusterAddress: payload.clusterAddress,
        isSuccess: true,
      }),
    );
    yield put(
      setConfirmedItem({
        clusterAddress: payload.clusterAddress,
        isConfirmed: false,
      }),
    );
  } catch (error: unknown) {
    yield put(
      setConfirmedItem({
        clusterAddress: payload.clusterAddress,
        isConfirmed: false,
      }),
    );
    yield put(
      setPendingItem({
        clusterAddress: payload.clusterAddress,
        isPending: false,
      }),
    );
    yield put(
      setSuccessItem({
        clusterAddress: payload.clusterAddress,
        isSuccess: false,
      }),
    );
    yield put(setErrorItem({ clusterAddress: payload.clusterAddress, error }));
    Sentry.captureException(
      `Assemble error,
      ' cluster address ${payload.clusterAddress}',
      ' amount ${payload.amount}',
      ' account ${payload.account}'`,
    );
  }
}

function* assembleClusterSaga(): Generator {
  yield takeLatest(
    'CLUSTERS_ASSEMBLE_CLUSTER_REQUESTED',
    assembleClusterWorker,
  );
}

export default assembleClusterSaga;
