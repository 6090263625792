import { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { BigNumber } from '@ethersproject/bignumber';
import cn from 'classnames';

import PortfolioEarning from '@modules/portfolio/components/PortfolioEarning';
import PortfolioAssets from '@modules/portfolio/components/PortfolioAssets';
import PortfolioStats from '@modules/portfolio/components/PortfolioStats';
import PortfolioEmpty from '@modules/portfolio/components/PortfolioEmpty';
import PortfolioError from '@modules/portfolio/components/PortfolioError';
import PortfolioDHV from '@modules/portfolio/components/PortfolioDHV';
import { preErrorSelector as preErrorSelectorUnderlying } from '@modules/underlying/slices/underlyingSlice';
import { preErrorSelector as pricesPreError } from '@modules/prices/slices/tokenPricesSlice';
import { preErrorSelector as currencyPreError } from '@modules/prices/slices/currencySlice';
import {
  preErrorSelector,
  isLoadingSelector,
  listSelector as listSelectorPools,
} from '@modules/pools/slices/poolsSlice';
import { successListSelector as successListSelectorUnstake } from '@modules/pools/slices/unstakeSlice';
import { successListSelector as successListSelectorApprove } from '@modules/pools/slices/approveSlice';
import { successListSelector as successListSelectorStake } from '@modules/pools/slices/stakeSlice';
import { successListSelector as successListSelectorReward } from '@modules/pools/slices/rewardSlice';
import StoredAssetList from '@modules/portfolio/types/StoredAssetList';
import UnderlyingItem from '@modules/underlying/types/UnderlyingItem';
import { useTypedSelector } from '@utils/store';
import constants from '@modules/common/constants';
import LOADING_IMAGE from '@modules/common/assets/loading.svg';
import CONTROLLERS from '@configs/controllers';
import POOLS, { PoolUnderlying, PoolList } from '@configs/pools';

import EMPTY_EARNING_ICON from '@modules/portfolio/assets/empty_earning_logo.svg';
import EMPTY_ASSETS_ICON from '@modules/portfolio/assets/empty_assets_logo.svg';

import styles from './Portfolio.module.scss';

const Portfolio: FC = () => {
  const [attempt, setAttempt] = useState<number>(0);
  const [balanceAttempts, setBalanceAttempts] = useState<number>(0);
  const [currencyAttempts, setCurrencyAttempts] = useState<number>(0);
  const [attemptUnderlying, setAttemptUnderlying] = useState<number>(0);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { chainId, library, account } = useWeb3React<Web3Provider>();

  const isLoading = useTypedSelector(isLoadingSelector);
  const pools = useTypedSelector(listSelectorPools);
  const preErrorUnderlying = useTypedSelector(preErrorSelectorUnderlying);
  const preErrorPrices = useTypedSelector(pricesPreError);
  const preErrorCurrency = useTypedSelector(currencyPreError);
  const preError = useTypedSelector(preErrorSelector);
  const successListUnstake = useTypedSelector(successListSelectorUnstake);
  const successListApprove = useTypedSelector(successListSelectorApprove);
  const successListStake = useTypedSelector(successListSelectorStake);
  const successListReward = useTypedSelector(successListSelectorReward);

  const getPoolList = useCallback(() => {
    const poolList: PoolList = {};

    if (library && chainId && POOLS[chainId]) {
      Object.keys(POOLS[chainId])?.map((address: string) => {
        return Object.keys(POOLS[chainId][address]).map((pid: string) => {
          if (poolList[address]) {
            poolList[address][Number(pid)] =
              POOLS[chainId][address][Number(pid)];
          } else {
            poolList[address] = {
              [Number(pid)]: POOLS[chainId][address][Number(pid)],
            };
          }

          return pid;
        });
      });
    }

    if (poolList && library && chainId) {
      dispatch({
        type: 'POOLS_GET_POOL_LIST_REQUESTED',
        payload: {
          pools: poolList,
          library,
          controller: CONTROLLERS[chainId],
          account,
        },
      });
    }
  }, [account, chainId, dispatch, library]);

  const getUnderlyingList = useCallback(() => {
    if (chainId && library && POOLS[chainId]) {
      const list: UnderlyingItem[] = [];

      Object.keys(POOLS[chainId]).forEach((address: string) => {
        Object.keys(POOLS[chainId][address]).forEach((pid: string) => {
          const preList: string[] = Array.from(
            new Set<string>([
              ...(POOLS[chainId][address][Number(pid)].pendingRewards || []),
              ...(POOLS[chainId][address][Number(pid)].underlying?.map(
                (item: PoolUnderlying) => {
                  return item.address;
                },
              ) || []),
            ]),
          );

          preList?.forEach((item: string) => {
            list.push({
              underlyingABI: POOLS[chainId][address][Number(pid)].tokenABI,
              address: item,
            });
          });
        });
      });

      dispatch({
        type: 'UNDERLYING_GET_UNDERLYING_LIST_REQUESTED',
        payload: { underlyingList: list, library, account },
      });
    }
  }, [dispatch, chainId, account, library]);

  useEffect(() => {
    getPoolList();

    const reGetPoolList = setInterval(
      () => getPoolList(),
      constants.GET_ITEMS_INTERVAL,
    );

    return () => clearInterval(reGetPoolList);
  }, [getPoolList]);

  useEffect(() => {
    if (preError && attempt < constants.GET_ITEMS_ATTEMPTS) {
      getPoolList();

      setAttempt(attempt + 1);
    }

    if (preError && attempt >= constants.GET_ITEMS_ATTEMPTS) {
      dispatch({
        type: 'POOLS_SET_ERROR_POOL_REQUESTED',
        payload: { error: preError },
      });
    }
  }, [preError, getPoolList, dispatch, attempt]);

  useEffect(() => {
    getUnderlyingList();

    const reGetUnderlyingList = setInterval(
      () => getUnderlyingList(),
      constants.GET_ITEMS_INTERVAL,
    );

    return () => clearInterval(reGetUnderlyingList);
  }, [getUnderlyingList]);

  useEffect(() => {
    if (
      preErrorUnderlying &&
      attemptUnderlying < constants.GET_ITEMS_ATTEMPTS
    ) {
      getUnderlyingList();

      setAttemptUnderlying(attemptUnderlying + 1);
    }

    if (
      preErrorUnderlying &&
      attemptUnderlying >= constants.GET_ITEMS_ATTEMPTS
    ) {
      dispatch({
        type: 'UNDERLYING_SET_ERROR_UNDERLYING_REQUESTED',
        payload: { error: preErrorUnderlying },
      });
    }
  }, [preErrorUnderlying, getUnderlyingList, dispatch, attemptUnderlying]);

  useEffect(() => {
    if (
      successListUnstake ||
      successListApprove ||
      successListStake ||
      successListReward
    ) {
      getPoolList();
    }
  }, [
    successListUnstake,
    getPoolList,
    successListApprove,
    successListStake,
    successListReward,
  ]);

  const getCurrencyPrice = useCallback(() => {
    dispatch({
      type: 'PRICES_GET_CURRENCY',
    });
  }, [dispatch]);

  useEffect(() => {
    getCurrencyPrice();

    const reGetCurrencyPrice = setInterval(
      () => getCurrencyPrice(),
      constants.GET_ITEMS_INTERVAL,
    );
    return clearInterval(reGetCurrencyPrice);
  }, [getCurrencyPrice]);

  useEffect(() => {
    if (preErrorCurrency && currencyAttempts < constants.GET_ITEMS_ATTEMPTS) {
      getCurrencyPrice();
      setCurrencyAttempts(currencyAttempts + 1);
    }
    if (preErrorCurrency && currencyAttempts >= constants.GET_ITEMS_ATTEMPTS) {
      dispatch({
        type: 'PRICE_SET_ERROR_CURRENCY_PRICE',
        payload: { error: preErrorCurrency },
      });
    }
  }, [currencyAttempts, dispatch, getCurrencyPrice, preErrorCurrency]);

  const getTokenPrices = useCallback(() => {
    dispatch({
      type: 'DHV_GET_TOKENS_PRICES_REQUESTED',
    });
  }, [dispatch]);

  useEffect(() => {
    getTokenPrices();

    const reGetTokenPrices = setInterval(
      () => getTokenPrices(),
      constants.GET_ITEMS_INTERVAL,
    );
    return clearInterval(reGetTokenPrices);
  }, [getTokenPrices]);

  useEffect(() => {
    if (preErrorPrices && balanceAttempts < constants.GET_ITEMS_ATTEMPTS) {
      getTokenPrices();
      setBalanceAttempts(balanceAttempts + 1);
    }
    if (preErrorPrices && balanceAttempts >= constants.GET_ITEMS_ATTEMPTS) {
      dispatch({
        type: 'DHV_SET_ERROR_TOKENS_PRICES_REQUESTED',
        payload: { error: preErrorPrices },
      });
    }
  }, [balanceAttempts, dispatch, getTokenPrices, preErrorPrices]);

  const assetsList = useMemo(() => {
    if (pools && chainId) {
      let assetPoolList: StoredAssetList | null = null;

      Object.keys(pools).forEach((address) =>
        Object.keys(pools[address]).map((pid) => {
          const {
            userBalance,
            userPoolAmount,
            symbol,
            decimals,
            clusterPrice,
          } = pools[address][Number(pid)];

          if (userBalance && userPoolAmount && POOLS[chainId][address]) {
            if (
              userBalance.gt(BigNumber.from('0')) ||
              userPoolAmount.gt(BigNumber.from('0'))
            ) {
              if (!assetPoolList) {
                assetPoolList = {};
              }

              if (!assetPoolList[address]) {
                assetPoolList[address] = {};
              }

              assetPoolList[address][Number(pid)] = {
                symbol,
                decimals,
                balanceOf: userBalance,
                userPoolAmount,
                clusterPrice,
              };
            }
          }
          return pid;
        }),
      );

      return { [chainId.toString()]: assetPoolList };
    }
    return null;
  }, [pools, chainId]);

  const earningList = useMemo(() => {
    if (pools && chainId) {
      let earningPoolList: StoredAssetList | null = null;

      Object.keys(pools).forEach((address) =>
        Object.keys(pools[address]).map((pid) => {
          const {
            userBalance,
            userPoolAmount,
            symbol,
            decimals,
            clusterPrice,
            userPoolAmountInUnderlying,
          } = pools[address][Number(pid)];

          if (userPoolAmount && POOLS[chainId][address]) {
            if (userPoolAmount.gt(BigNumber.from('0'))) {
              if (!earningPoolList) {
                earningPoolList = {};
              }

              if (!earningPoolList[address]) {
                earningPoolList[address] = {};
              }

              earningPoolList[address][Number(pid)] = {
                symbol,
                decimals,
                balanceOf: userBalance || BigNumber.from('0'),
                userPoolAmount,
                clusterPrice,
                userPoolAmountInUnderlying,
              };
            }
          }
          return pid;
        }),
      );

      return { [chainId.toString()]: earningPoolList };
    }
    return null;
  }, [pools, chainId]);

  return (
    <article className={styles.container}>
      <h1 className={styles.title}>{t('PORTFOLIO.PORTFOLIO.PAGE_TITLE')}</h1>

      <p className={styles.description}>
        {t('PORTFOLIO.PORTFOLIO.PAGE_DESCRIPTION')}
      </p>

      <PortfolioStats
        earningList={(chainId && earningList && earningList[chainId]) || null}
        assetList={(chainId && assetsList && assetsList[chainId]) || null}
      />

      <PortfolioDHV
        assets={(assetsList && chainId && assetsList[chainId]) || null}
      />

      <section
        className={cn(styles.card, { [styles['is-loading']]: isLoading })}
      >
        <h3 className={styles.cardTitle}>{t('PORTFOLIO.PORTFOLIO.ASSETS')}</h3>

        {chainId && assetsList && assetsList[chainId] && (
          <div className={styles.cardTable}>
            <PortfolioAssets list={assetsList[chainId]} />
          </div>
        )}

        {!(chainId && assetsList && assetsList[chainId]) && !preError && (
          <PortfolioEmpty icon={EMPTY_ASSETS_ICON} />
        )}

        {!(chainId && assetsList && assetsList[chainId]) && preError && (
          <PortfolioError />
        )}

        {isLoading && (
          <img
            className={styles.cardLoading}
            height="100"
            width="100"
            src={LOADING_IMAGE}
            alt=""
          />
        )}
      </section>

      <section
        className={cn(styles.card, { [styles['is-loading']]: isLoading })}
      >
        <h3 className={styles.cardTitle}>{t('PORTFOLIO.PORTFOLIO.EARNING')}</h3>

        {chainId && earningList && earningList[chainId] && (
          <div className={styles.cardTable}>
            <PortfolioEarning list={earningList[chainId]} />
          </div>
        )}

        {!(chainId && earningList && earningList[chainId]) && !preError && (
          <PortfolioEmpty icon={EMPTY_EARNING_ICON} />
        )}

        {!(chainId && earningList && earningList[chainId]) && preError && (
          <PortfolioError />
        )}

        {isLoading && (
          <img
            className={styles.cardLoading}
            height="100"
            width="100"
            src={LOADING_IMAGE}
            alt=""
          />
        )}
      </section>
    </article>
  );
};

export default Portfolio;
