import { FC, useMemo, useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import qs from 'query-string';
import cn from 'classnames';

import ErrorBlock from '@modules/common/components/ErrorBlock';
import NotFound from '@modules/common/components/NotFound';
import AddToken from '@modules/common/components/AddToken';
import PoolCard from '@modules/pools/components/PoolCard';
import DHVBalance from '@modules/dhv/components/DHVBalance';
import DHVHowTo from '@modules/dhv/components/DHVHowTo';
import DHVPrice from '@modules/dhv/components/DHVPrice';
import DHVSwap from '@modules/dhv/components/DHVSwap';
import {
  isLoadingSelector,
  preErrorSelector,
  errorSelector,
  listSelector,
} from '@modules/pools/slices/poolsSlice';
import { successListSelector as successListSelectorUnstake } from '@modules/pools/slices/unstakeSlice';
import { successListSelector as successListSelectorApprove } from '@modules/pools/slices/approveSlice';
import { successListSelector as successListSelectorStake } from '@modules/pools/slices/stakeSlice';
import { successListSelector as successListSelectorReward } from '@modules/pools/slices/rewardSlice';
import { preErrorSelector as preErrorSelectorPoolsStats } from '@modules/pools/slices/poolsStatsSlice';
import { preErrorSelector as preErrorSelectorUnderlying } from '@modules/underlying/slices/underlyingSlice';
import errorOpenModalUnstake from '@modules/pools/helpers/errorOpenModalUnstake';
import errorOpenModalReward from '@modules/pools/helpers/errorOpenModalReward';
import errorOpenModalStake from '@modules/pools/helpers/errorOpenModalStake';
import { useTypedSelector } from '@utils/store';
import UnderlyingItem from '@modules/underlying/types/UnderlyingItem';
import constantsPools from '@modules/pools/constants';
import constants from '@modules/common/constants';
import UNDERLYING_LIST from '@configs/underlying';
import DHV_LIST from '@configs/dhv';
import POOLS, { PoolUnderlying, PoolList } from '@configs/pools';
import CONTROLLERS from '@configs/controllers';

import LOADMORE_ICON from '@modules/dhv/assets/arrow_loadmore.svg';
import LOADING_IMAGE from '@modules/common/assets/loading.svg';

import styles from './DHVPage.module.scss';

const DHVPage: FC = () => {
  const [isLoadAll, setIsLoadAll] = useState<boolean>(false);
  const [attempt, setAttempt] = useState<number>(0);
  const [attemptPoolStats, setAttemptPoolStats] = useState<number>(0);
  const [attemptUnderlying, setAttemptUnderlying] = useState<number>(0);

  const { t } = useTranslation();

  const { search } = useLocation<{ search: string }>();

  const dispatch = useDispatch();

  const { chainId, account, library } = useWeb3React<Web3Provider>();

  const isLoading = useTypedSelector(isLoadingSelector);
  const preError = useTypedSelector(preErrorSelector);
  const error = useTypedSelector(errorSelector);
  const pools = useTypedSelector(listSelector);
  const preErrorPoolsStats = useTypedSelector(preErrorSelectorPoolsStats);
  const successListUnstake = useTypedSelector(successListSelectorUnstake);
  const successListApprove = useTypedSelector(successListSelectorApprove);
  const successListStake = useTypedSelector(successListSelectorStake);
  const successListReward = useTypedSelector(successListSelectorReward);
  const preErrorUnderlying = useTypedSelector(preErrorSelectorUnderlying);

  const getPoolsStats = useCallback(() => {
    if (POOLS && chainId) {
      dispatch({
        type: 'POOLS_GET_POOL_STATS',
        payload: {
          pools: Object.values(POOLS[chainId])
            .map((element) => Object.values(element))
            .flat()
            .filter(({ symbol }) => symbol)
            .map(({ symbol }) => symbol),
          chainId,
        },
      });
    }
  }, [dispatch, chainId]);

  useEffect(() => {
    getPoolsStats();

    const reGetPoolsStats = setInterval(
      () => getPoolsStats(),
      constants.GET_ITEMS_INTERVAL,
    );

    return () => clearInterval(reGetPoolsStats);
  }, [getPoolsStats]);

  useEffect(() => {
    if (preErrorPoolsStats && attemptPoolStats < constants.GET_ITEMS_ATTEMPTS) {
      getPoolsStats();

      setAttemptPoolStats(attemptPoolStats + 1);
    }

    if (
      preErrorPoolsStats &&
      attemptPoolStats >= constants.GET_ITEMS_ATTEMPTS
    ) {
      dispatch({
        type: 'POOLS_SET_ERROR_POOLS_STATS',
        payload: { error: preErrorPoolsStats },
      });
    }
  }, [preErrorPoolsStats, getPoolsStats, dispatch, attemptPoolStats]);

  const getPoolList = useCallback(() => {
    const poolList: PoolList = {};

    if (library && chainId && POOLS[chainId]) {
      Object.keys(POOLS[chainId])?.map((address: string) => {
        return Object.keys(POOLS[chainId][address])?.map((pid: string) => {
          if (
            POOLS[chainId][address][Number(pid)].type === 0 ||
            POOLS[chainId][address][Number(pid)].type === 2
          ) {
            if (poolList[address]) {
              poolList[address][Number(pid)] =
                POOLS[chainId][address][Number(pid)];
            } else {
              poolList[address] = {
                [Number(pid)]: POOLS[chainId][address][Number(pid)],
              };
            }
          }

          return pid;
        });
      });
    }

    if (poolList && library) {
      const firstPoolGroupAddress = Object.keys(poolList)[0];
      const firstPoolGroup = firstPoolGroupAddress
        ? poolList[firstPoolGroupAddress]
        : null;
      const firstPoolId = firstPoolGroup
        ? Object.keys(firstPoolGroup)[0]
        : null;

      let secondPoolId = firstPoolGroup ? Object.keys(firstPoolGroup)[1] : null;

      const secondPoolGroupAddress = secondPoolId
        ? null
        : Object.keys(poolList)[1];
      const secondPoolGroup = secondPoolGroupAddress
        ? poolList[secondPoolGroupAddress]
        : null;

      if (secondPoolGroup) {
        secondPoolId = secondPoolGroup ? Object.keys(secondPoolGroup)[0] : null;
      }

      const firstTwoElements =
        firstPoolId && firstPoolGroup
          ? {
              [firstPoolGroupAddress]: {
                [firstPoolId]: firstPoolGroup[Number(firstPoolId)],
              },
            }
          : {};

      if (!secondPoolGroup && firstPoolGroup && secondPoolId) {
        firstTwoElements[firstPoolGroupAddress][secondPoolId] =
          firstPoolGroup[Number(secondPoolId)];
      }

      if (secondPoolGroupAddress && secondPoolGroup && secondPoolId) {
        firstTwoElements[secondPoolGroupAddress] = {
          [secondPoolId]: secondPoolGroup[Number(secondPoolId)],
        };
      }

      if (chainId) {
        dispatch({
          type: 'POOLS_GET_POOL_LIST_REQUESTED',
          payload: {
            pools: isLoadAll ? poolList : firstTwoElements,
            library,
            controller: CONTROLLERS[chainId],
            account,
          },
        });
      }
    }
  }, [dispatch, chainId, library, account, isLoadAll]);

  const getUnderlyingList = useCallback(() => {
    if (chainId && library && POOLS[chainId]) {
      const list: UnderlyingItem[] = [];

      Object.keys(POOLS[chainId]).forEach((address: string) => {
        Object.keys(POOLS[chainId][address]).forEach((pid: string) => {
          if (
            POOLS[chainId][address][Number(pid)].type === 0 ||
            POOLS[chainId][address][Number(pid)].type === 2
          ) {
            const preList: string[] = Array.from(
              new Set<string>([
                ...(POOLS[chainId][address][Number(pid)].pendingRewards || []),
                ...(POOLS[chainId][address][Number(pid)].underlying?.map(
                  (item: PoolUnderlying) => {
                    return item.address;
                  },
                ) || []),
              ]),
            );

            preList?.forEach((item: string) => {
              list.push({
                underlyingABI: POOLS[chainId][address][Number(pid)].tokenABI,
                address: item,
              });
            });
          }
        });
      });

      dispatch({
        type: 'UNDERLYING_GET_UNDERLYING_LIST_REQUESTED',
        payload: { underlyingList: list, library, account },
      });
    }
  }, [dispatch, chainId, account, library]);

  useEffect(() => {
    getPoolList();

    const reGetPoolList = setInterval(
      () => getPoolList(),
      constants.GET_ITEMS_INTERVAL,
    );

    return () => clearInterval(reGetPoolList);
  }, [getPoolList]);

  useEffect(() => {
    if (isLoadAll) {
      getPoolList();
    }
  }, [getPoolList, isLoadAll]);

  useEffect(() => {
    if (preError && attempt < constants.GET_ITEMS_ATTEMPTS) {
      getPoolList();

      setAttempt(attempt + 1);
    }

    if (preError && attempt >= constants.GET_ITEMS_ATTEMPTS) {
      dispatch({
        type: 'POOLS_SET_ERROR_POOL_REQUESTED',
        payload: { error: preError },
      });
    }
  }, [preError, getPoolList, dispatch, attempt]);

  useEffect(() => {
    if (
      successListUnstake ||
      successListApprove ||
      successListStake ||
      successListReward
    ) {
      getPoolList();
    }
  }, [
    successListUnstake,
    getPoolList,
    successListApprove,
    successListStake,
    successListReward,
  ]);

  useEffect(() => {
    getUnderlyingList();

    const reGetUnderlyingList = setInterval(
      () => getUnderlyingList(),
      constants.GET_ITEMS_INTERVAL,
    );

    return () => clearInterval(reGetUnderlyingList);
  }, [getUnderlyingList]);

  useEffect(() => {
    if (
      preErrorUnderlying &&
      attemptUnderlying < constants.GET_ITEMS_ATTEMPTS
    ) {
      getUnderlyingList();

      setAttemptUnderlying(attemptUnderlying + 1);
    }

    if (
      preErrorUnderlying &&
      attemptUnderlying >= constants.GET_ITEMS_ATTEMPTS
    ) {
      dispatch({
        type: 'UNDERLYING_SET_ERROR_UNDERLYING_REQUESTED',
        payload: { error: preErrorUnderlying },
      });
    }
  }, [preErrorUnderlying, getUnderlyingList, dispatch, attemptUnderlying]);

  useEffect(() => {
    if (pools && library && chainId && POOLS[chainId]) {
      let configPoolsCount = 0;
      let storedPoolsCount = 0;

      Object.keys(POOLS[chainId])?.map((address: string) => {
        return Object.keys(POOLS[chainId][address])?.map((pid: string) => {
          if (
            POOLS[chainId][address][Number(pid)].type === 0 ||
            POOLS[chainId][address][Number(pid)].type === 2
          ) {
            configPoolsCount += 1;
          }

          return pid;
        });
      });

      Object.keys(pools)?.map((address: string) => {
        return Object.keys(pools[address])?.map((pid: string) => {
          if (
            pools[address][Number(pid)].type === 0 ||
            pools[address][Number(pid)].type === 2
          ) {
            storedPoolsCount += 1;
          }

          return pid;
        });
      });

      if (configPoolsCount < 3 || storedPoolsCount > 2) {
        setIsLoadAll(true);
      }
    }
  }, [pools, library, chainId]);

  useEffect(() => {
    const searchList = qs.parse(search);

    if (
      chainId &&
      POOLS[chainId] &&
      searchList[constantsPools.MODAL_PATH_UNSTAKE]
    ) {
      let availablePool = false;

      Object.keys(POOLS[chainId])?.forEach((address: string) => {
        Object.keys(POOLS[chainId][address])?.forEach((pid: string) => {
          if (
            searchList[constantsPools.MODAL_PATH_UNSTAKE] ===
            `${pid}_${address}`
          ) {
            availablePool = true;
          }
        });
      });

      if (!availablePool) {
        errorOpenModalUnstake();
      }
    }

    if (
      chainId &&
      POOLS[chainId] &&
      searchList[constantsPools.MODAL_PATH_STAKE]
    ) {
      let availablePool = false;

      Object.keys(POOLS[chainId])?.forEach((address: string) => {
        Object.keys(POOLS[chainId][address])?.forEach((pid: string) => {
          if (
            searchList[constantsPools.MODAL_PATH_STAKE] === `${pid}_${address}`
          ) {
            availablePool = true;
          }
        });
      });

      if (!availablePool) {
        errorOpenModalStake();
      }
    }

    if (
      chainId &&
      POOLS[chainId] &&
      searchList[constantsPools.MODAL_PATH_REWARD]
    ) {
      let availablePool = false;

      Object.keys(POOLS[chainId])?.forEach((address: string) => {
        Object.keys(POOLS[chainId][address])?.forEach((pid: string) => {
          if (
            searchList[constantsPools.MODAL_PATH_REWARD] === `${pid}_${address}`
          ) {
            availablePool = true;
          }
        });
      });

      if (!availablePool) {
        errorOpenModalReward();
      }
    }
  }, [chainId, search]);

  const isPoolAvailable = useMemo(() => {
    if (chainId && POOLS[chainId]) {
      let availablePools = false;

      Object.keys(POOLS[chainId])?.map((address: string) => {
        return Object.keys(POOLS[chainId][address])?.map((pid: string) => {
          if (
            POOLS[chainId][address][Number(pid)].type === 0 ||
            POOLS[chainId][address][Number(pid)].type === 2
          ) {
            availablePools = true;
          }

          return pid;
        });
      });

      return availablePools;
    }

    return false;
  }, [chainId]);

  const isAvailablePoolStored = useMemo(() => {
    if (pools) {
      let availablePools = false;

      Object.keys(pools)?.map((address: string) => {
        return Object.keys(pools[address])?.map((pid: string) => {
          if (
            pools[address][Number(pid)].type === 0 ||
            pools[address][Number(pid)].type === 2
          ) {
            availablePools = true;
          }

          return pid;
        });
      });

      return availablePools;
    }

    return false;
  }, [pools]);

  return (
    <article className={styles.container}>
      <div className={styles.title}>
        <h1 className={styles.titleText}>{t('DHV.DHV.PAGE_TITLE')}</h1>

        {chainId && DHV_LIST[chainId] && (
          <AddToken
            tooltipClassName={styles.titleAddTokenTooltip}
            tokenStandart={DHV_LIST[chainId].tokenStandart}
            tokenDecimals={DHV_LIST[chainId].dedcimals}
            tokenAddress={DHV_LIST[chainId].tokenAddress || ''}
            tokenSymbol={DHV_LIST[chainId].symbol}
            tokenIcon={UNDERLYING_LIST[DHV_LIST[chainId].symbol]?.icon || ''}
            className={styles.titleAddToken}
          />
        )}
      </div>

      <p className={styles.description}>
        <Trans
          components={[
            <a
              className={styles.descriptionLink}
              target="_blank"
              href="https://coinmarketcap.com/currencies/dehive/"
              rel="noreferrer"
              key={0}
            >
              {' '}
            </a>,
          ]}
          i18nKey="DHV.DHV.PAGE_DESCRIPTION"
        />
      </p>

      <DHVHowTo />

      <section className={styles.cards}>
        <DHVPrice className={styles.cardsItem} />

        <DHVSwap className={styles.cardsItem} />

        <DHVBalance className={styles.cardsItem} />
      </section>

      {isPoolAvailable ? (
        <section
          className={cn(styles.list, { [styles['is-loading']]: isLoading })}
        >
          {pools &&
            Object.keys(pools)?.map((address: string) => {
              return Object.keys(pools[address])?.map((pid: string) => {
                if (
                  pools[address][Number(pid)].type === 0 ||
                  pools[address][Number(pid)].type === 2
                ) {
                  return (
                    <PoolCard
                      poolGroupAddress={address}
                      className={styles.listItem}
                      poolId={Number(pid)}
                      pool={pools[address][Number(pid)]}
                      key={`${address}_${pid}`}
                    />
                  );
                }

                return null;
              });
            })}

          {!isLoadAll && (
            <button
              className={styles.listMore}
              disabled={isLoading}
              onClick={() => setIsLoadAll(true)}
              type="button"
            >
              <span className={styles.listMoreText}>
                {t('DHV.DHV.LOAD_MORE')}
              </span>

              <img
                className={styles.listMoreArrow}
                src={LOADMORE_ICON}
                alt=""
              />
            </button>
          )}

          {isLoading && (
            <img
              className={styles.listLoading}
              height="100"
              width="100"
              src={LOADING_IMAGE}
              alt=""
            />
          )}
        </section>
      ) : (
        <NotFound title={t('DHV.DHV.NOT_FOUND_BLOCK_TITLE')} />
      )}

      {isPoolAvailable && !isAvailablePoolStored && error && (
        <ErrorBlock title={t('DHV.DHV.ERROR_BLOCK_TITLE')} />
      )}
    </article>
  );
};

export default DHVPage;
