import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { PutEffect, takeLatest, put } from 'redux-saga/effects';

import { setPreError, setError } from '@modules/dhv/slices/dhvChartPricesSlice';

function* setErrorChartDataWorker({
  payload,
}: PayloadAction<{
  error: unknown;
}>): Generator<PutEffect<AnyAction>, void, never> {
  yield put(setPreError(null));
  yield put(setError(payload.error));
}

function* setErrorChartDataSaga(): Generator {
  yield takeLatest(
    'DHV_SET_ERROR_CHART_DATA_REQUESTED',
    setErrorChartDataWorker,
  );
}

export default setErrorChartDataSaga;
