const MODAL_PATH_UNSTAKE = 'unstake';
const MODAL_PATH_REWARD = 'reward';
const MODAL_PATH_STAKE = 'stake';

interface INomoPool {
  address: string;
  assets: [string, string, number, null];
}

const NOMO_POOL: INomoPool = {
  address: '0x0000000000000000000000000000000000000001',
  assets: ['', '', 18, null],
};

export default {
  MODAL_PATH_UNSTAKE,
  MODAL_PATH_REWARD,
  MODAL_PATH_STAKE,
  NOMO_POOL,
};
