import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import {
  CallEffect,
  PutEffect,
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { JsonRpcProvider } from '@ethersproject/providers';
import { ContractInterface } from '@ethersproject/contracts';

import {
  setIsLoading,
  setPreError,
  setError,
  setList,
} from '@modules/pools/slices/poolsSlice';
import getPoolAllowance from '@modules/pools/helpers/getPoolAllowance';
import getPoolAsset from '@modules/pools/helpers/getPoolAsset';
import getPoolData from '@modules/pools/helpers/getPoolData';
import getPoolDHV from '@modules/pools/helpers/getPoolDHV';
import getClusterPriceHelper from '@modules/pools/helpers/getClusterPrice';
import StoredPoolList from '@modules/pools/types/StoredPoolList';
import StoredPool from '@modules/pools/types/StoredPool';
import { PoolUnderlying, PoolList } from '@configs/pools';
import { ControllerData } from '@configs/controllers';

interface ReturnedStoredPool extends StoredPool {
  poolAddress: string;
  poolId: number;
}

const getPool = async ({
  poolTokenABI,
  poolAddress,
  poolDHVAddress,
  poolDHVABI,
  underlying,
  poolType,
  poolABI,
  poolId,
  isMulti,
  isCluster,
  isImpulse,
  provider,
  controller,
  account,
}: {
  poolTokenABI: ContractInterface;
  poolAddress: string;
  poolDHVAddress?: string;
  poolDHVABI?: ContractInterface;
  underlying?: PoolUnderlying[];
  poolType: number | null;
  poolABI: ContractInterface;
  poolId: number;
  isMulti: boolean;
  isCluster: boolean;
  isImpulse: boolean;
  provider: JsonRpcProvider;
  controller: ControllerData;
  account?: string;
}): Promise<ReturnedStoredPool> => {
  const poolInfo = await getPoolData(
    poolAddress,
    poolABI,
    poolId,
    isMulti,
    isCluster,
    isImpulse,
    provider,
    account,
  );

  const poolAsset = await getPoolAsset(
    poolInfo[0].assetToken,
    poolTokenABI,
    provider,
    account,
  );

  const poolAllowance = await getPoolAllowance(
    isMulti && underlying ? underlying : [{ address: poolInfo[0].assetToken }],
    poolTokenABI,
    poolAddress,
    provider,
    account,
  );

  let result: ReturnedStoredPool = {
    poolId,
    poolAddress,
    name: poolAsset[0],
    type: poolType,
    symbol: poolAsset[1],
    decimals: poolAsset[2],
    paused: poolInfo[0].paused,
    tokenAddress: poolInfo[0].assetToken,
    allowance: poolAllowance,
    poolSupply: poolInfo[0].poolSupply,
    userBalance: poolAsset[3],
    yieldBalance: poolInfo[4] || poolInfo[5],
    pendingRewards: poolInfo[1],
    userPoolAmount: poolInfo[2],
    userPoolAmountInUnderlying: poolInfo[3],
  };

  if (poolType === 1) {
    const clusterPrice = await getClusterPriceHelper(
      poolInfo[0].assetToken,
      provider,
      controller,
    );

    result = {
      ...result,
      clusterPrice,
    };
  }

  if (poolDHVAddress && poolDHVABI) {
    const poolDHV = await getPoolDHV(
      poolInfo[0].assetToken,
      poolDHVAddress,
      poolDHVABI,
      poolId,
      provider,
      account,
    );

    result = {
      ...result,
      сlusterRateAccuracy: poolDHV[2],
      clusterRate: poolDHV[3],
      userPoolAmountDHV: poolDHV[1],
      userTotalLockedDHV: poolDHV[0],
    };

    if (
      poolDHV[4].assetToken !== '0x0000000000000000000000000000000000000000'
    ) {
      const poolDHVAsset = await getPoolAsset(
        poolDHV[4].assetToken,
        poolTokenABI,
        provider,
        account,
      );
      result = {
        ...result,
        symbolDHV: poolDHVAsset[1],
        decimalsDHV: poolDHVAsset[2],
        userBalanceDHV: poolDHVAsset[3],
      };
    }
  }

  return result;
};

const getPoolList = (
  pools: PoolList,
  provider: JsonRpcProvider,
  controller: ControllerData,
  account?: string,
): Promise<ReturnedStoredPool[]> => {
  const getPoolListInfo: Promise<ReturnedStoredPool>[] = [];

  Object.keys(pools).map((address: string) => {
    return Object.keys(pools[address]).map((pid: string) => {
      return getPoolListInfo.push(
        getPool({
          poolTokenABI: pools[address][Number(pid)].tokenABI,
          poolAddress: address,
          poolDHVAddress: pools[address][Number(pid)].dhvAddress,
          poolDHVABI: pools[address][Number(pid)].dhvABI,
          underlying: pools[address][Number(pid)].underlying,
          poolType: pools[address][Number(pid)].type,
          poolABI: pools[address][Number(pid)].poolABI,
          poolId: Number(pid),
          isMulti: pools[address][Number(pid)].type === 4,
          isCluster: pools[address][Number(pid)].type === 1,
          isImpulse:
            pools[address][Number(pid)].type === 3 ||
            pools[address][Number(pid)].type === 4,
          provider,
          controller,
          account,
        }),
      );
    });
  });

  return Promise.all(getPoolListInfo);
};

function* getPoolListWorker({
  payload,
}: PayloadAction<{
  pools: PoolList;
  library: JsonRpcProvider;
  controller: ControllerData;
  account?: string;
}>): Generator<
  CallEffect<ReturnedStoredPool[]> | PutEffect<AnyAction>,
  void,
  never
> {
  try {
    yield put(setIsLoading(true));
    yield put(setPreError(null));
    yield put(setError(null));

    const poolListResult: ReturnedStoredPool[] = yield call(
      getPoolList,
      payload.pools,
      payload.library,
      payload.controller,
      payload.account,
    );

    const storedPoolListResult: StoredPoolList = {};

    poolListResult.map((pool: ReturnedStoredPool) => {
      if (!storedPoolListResult[pool.poolAddress]) {
        storedPoolListResult[pool.poolAddress] = {};
      }

      storedPoolListResult[pool.poolAddress][pool.poolId] = {
        name: pool.name,
        type: pool.type,
        symbol: pool.symbol,
        decimals: pool.decimals,
        paused: pool.paused,
        tokenAddress: pool.tokenAddress,
        symbolDHV: pool.symbolDHV,
        decimalsDHV: pool.decimalsDHV,
        userBalanceDHV: pool.userBalanceDHV,
        userPoolAmountDHV: pool.userPoolAmountDHV,
        userTotalLockedDHV: pool.userTotalLockedDHV,
        clusterRate: pool.clusterRate,
        allowance: pool.allowance,
        poolSupply: pool.poolSupply,
        userBalance: pool.userBalance,
        yieldBalance: pool.yieldBalance,
        pendingRewards: pool.pendingRewards,
        userPoolAmount: pool.userPoolAmount,
        userPoolAmountInUnderlying: pool.userPoolAmountInUnderlying,
        clusterPrice: pool.clusterPrice,
      };

      return pool;
    });

    yield put(setList(storedPoolListResult));
  } catch (error: unknown) {
    yield put(setPreError(error));
  } finally {
    yield put(setIsLoading(false));
  }
}

function* getPoolListSaga(): Generator {
  yield takeLatest('POOLS_GET_POOL_LIST_REQUESTED', getPoolListWorker);
}

export default getPoolListSaga;
