import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Pagination } from 'swiper';
import cn from 'classnames';
import 'swiper/swiper.scss';
import 'swiper/modules/pagination/pagination.min.css';

import ProductCard from '@modules/main/components/ProductCard';
import useMediaQuery from '@modules/layout/hooks/useMediaQuery';
import IMPULSE_ICON from '@modules/main/assets/products/impulse_icon.png';
import CLUSTER_ICON from '@modules/main/assets/products/cluster_icon.png';
import STABLE_ICON from '@modules/main/assets/products/stable_icon.png';
import DHV_ICON from '@modules/main/assets/products/dhv_icon.png';

import styles from './ProductSlider.module.scss';

const productList = [
  {
    type: 'dhv',
    link: '/dhv',
    image: DHV_ICON,
    title: 'MAIN.PRODUCT_SLIDER.CARD_1_TITLE',
    subtitle: 'MAIN.PRODUCT_SLIDER.CARD_1_SUBTITLE',
    description: 'MAIN.PRODUCT_SLIDER.CARD_1_DESCRIPTION',
  },
  {
    type: 'impulses',
    link: '/impulses',
    image: IMPULSE_ICON,
    title: 'MAIN.PRODUCT_SLIDER.CARD_2_TITLE',
    subtitle: 'MAIN.PRODUCT_SLIDER.CARD_2_SUBTITLE',
    description: 'MAIN.PRODUCT_SLIDER.CARD_2_DESCRIPTION',
  },
  {
    type: 'clusters',
    link: '/clusters',
    image: CLUSTER_ICON,
    title: 'MAIN.PRODUCT_SLIDER.CARD_3_TITLE',
    subtitle: 'MAIN.PRODUCT_SLIDER.CARD_3_SUBTITLE',
    description: 'MAIN.PRODUCT_SLIDER.CARD_3_DESCRIPTION',
  },
  {
    type: 'stables',
    link: '/stables',
    image: STABLE_ICON,
    title: 'MAIN.PRODUCT_SLIDER.CARD_4_TITLE',
    subtitle: 'MAIN.PRODUCT_SLIDER.CARD_4_SUBTITLE',
    description: 'MAIN.PRODUCT_SLIDER.CARD_4_DESCRIPTION',
  },
];

const ProductSlider: FC = () => {
  const { t } = useTranslation();

  const isMobile = useMediaQuery(991);

  return isMobile ? (
    <section>
      <Swiper
        slidesPerView="auto"
        spaceBetween={10}
        pagination={{
          bulletActiveClass: styles['is-active'],
          bulletClass: styles.paginationItem,
          clickable: true,
          el: '.product-pagination',
        }}
        className={styles.slider}
        modules={[Pagination]}
        effect="fade"
      >
        {productList.map(
          ({ type, link, image, title, subtitle, description }) => (
            <SwiperSlide className={styles.sliderItem} key={link}>
              <ProductCard
                type={type}
                link={link}
                image={image}
                title={t(title)}
                subtitle={t(subtitle)}
                description={t(description)}
              />
            </SwiperSlide>
          ),
        )}
      </Swiper>

      <div className={cn('product-pagination', styles.pagination)} />
    </section>
  ) : (
    <section>
      <ul className={styles.list}>
        {productList.map(
          ({ type, link, image, title, subtitle, description }) => (
            <li key={link}>
              <ProductCard
                type={type}
                link={link}
                image={image}
                title={t(title)}
                subtitle={t(subtitle)}
                description={t(description)}
              />
            </li>
          ),
        )}
      </ul>
    </section>
  );
};

export default ProductSlider;
