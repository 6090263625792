import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { PutEffect, takeLatest, put } from 'redux-saga/effects';

import { setPreError, setError } from '@modules/clusters/slices/clustersSlice';

function* setErrorClusterWorker({
  payload,
}: PayloadAction<{
  error: unknown;
}>): Generator<PutEffect<AnyAction>, void, never> {
  yield put(setPreError(null));
  yield put(setError(payload.error));
}

function* setErrorClusterSaga(): Generator {
  yield takeLatest(
    'CLUSTERS_SET_ERROR_CLUSTER_REQUESTED',
    setErrorClusterWorker,
  );
}

export default setErrorClusterSaga;
