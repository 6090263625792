import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import CloseButton from '@modules/layout/components/CloseButton';
import SecuredBy from '@modules/layout/components/SecuredBy';
import NavBar from '@modules/layout/components/NavBar';

import styles from './Sidebar.module.scss';

const Sidebar: FC<{ className?: string; onClick: () => void }> = ({
  className,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <aside className={cn(styles.container, className)}>
      <div className={styles.header}>
        <h3 className={styles.title}>{t('LAYOUT.SIDEBAR.TITLE')}</h3>

        <CloseButton onClick={onClick} />
      </div>

      <NavBar className={styles.navbar} onClick={onClick} />

      <SecuredBy />
    </aside>
  );
};

Sidebar.defaultProps = { className: undefined };

export default Sidebar;
