import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import rootReducer from '@utils/reducers';
import rootSaga from '@utils/sagas';

const defaultMiddleware = getDefaultMiddleware({
  // Ignore saga action types where used Web3Provider and BigNumber as arguments
  serializableCheck: false,
  thunk: false,
});

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: [...defaultMiddleware, sagaMiddleware],
  devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
