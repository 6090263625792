import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { PutEffect, takeLatest, put } from 'redux-saga/effects';

import { setPreError, setError } from '@modules/pools/slices/poolsTvlSlices';

function* setErrorPoolsTvlWorker({
  payload,
}: PayloadAction<{
  error: unknown;
}>): Generator<PutEffect<AnyAction>, void, never> {
  yield put(setPreError(null));
  yield put(setError(payload.error));
}

function* setErrorPoolsTvlSaga(): Generator {
  yield takeLatest('POOLS_SET_ERROR_POOLS_TVL', setErrorPoolsTvlWorker);
}

export default setErrorPoolsTvlSaga;
