import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import { InputNumber, Select, Form } from 'antd';
import { FormInstance } from 'antd/lib/form';

import PoolUnstakeFormPrompt from '@modules/pools/components/PoolUnstakeFormPrompt';
import FormSubmit from '@modules/common/components/FormSubmit';
import FormInput from '@modules/common/components/FormInput';
import {
  isLoadingSelector,
  listSelector,
} from '@modules/underlying/slices/underlyingSlice';
import { getPrices } from '@modules/prices/slices/tokenPricesSlice';
import formatValuePercentage from '@modules/common/helpers/formatValuePercentage';
import formatValueToken from '@modules/common/helpers/formatValueToken';
import formatValuePrice from '@modules/common/helpers/formatValuePrice';
import onInputFormatter from '@modules/common/helpers/onInputFormatter';
import getTokenPrice from '@modules/common/helpers/getTokenPrice';
import checkMaxValue from '@modules/common/helpers/checkMaxValue';
import constants from '@modules/common/constants';
import { useTypedSelector } from '@utils/store';
import { PoolUnderlying } from '@configs/pools';
import UNDERLYING_LIST from '@configs/underlying';

import './select.scss';
import cn from 'classnames';
import styles from './PoolUnstakeForm.module.scss';

const COMMISSION_VALUE = 0.03;

const { Option } = Select;
const { Item } = Form;

const onInputValidator = (
  value: string,
  errorTextBig: string,
  errorTextSmall: string,
): Promise<string> => {
  return new Promise((resolve) => {
    if (Number(value) > 100) {
      throw new Error(errorTextBig);
    }

    if (Number(value) <= 0) {
      throw new Error(errorTextSmall);
    }

    resolve('success');
  });
};

const getDefaultSelect = (underlying?: PoolUnderlying[]): number => {
  let result = 0;

  underlying?.find(({ isHidden }: PoolUnderlying, index: number) => {
    if (!isHidden) {
      result = index;

      return index;
    }

    return false;
  });

  return result;
};

const PoolUnstakeForm: FC<{
  userPoolAmount?: BigNumber | null;
  poolDecimals: number;
  poolSymbol: string;
  poolName: string;
  poolType: number;
  underlying?: PoolUnderlying[];
  isLoading: boolean;
  selectName: string;
  inputName: string;
  onFinish: (values: { [key: string]: string }) => void;
  form: FormInstance;
}> = ({
  userPoolAmount,
  poolDecimals,
  poolSymbol,
  poolName,
  poolType,
  underlying,
  isLoading,
  selectName,
  inputName,
  onFinish,
  form,
}) => {
  const [amountIndex, setAmountIndex] = useState<number>(
    getDefaultSelect(underlying),
  );
  const [inputValue, setInputValue] = useState<number>(0);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const isLoadingUnderlying = useTypedSelector(isLoadingSelector);
  const underlyingList = useTypedSelector(listSelector);
  const prices = useTypedSelector(getPrices);

  const isMinValueBigger: boolean = useMemo(() => {
    return checkMaxValue(
      constants.INPUT_MINIMAL_UNSTAKE_STEP,
      poolDecimals,
      userPoolAmount,
    );
  }, [poolDecimals, userPoolAmount]);

  return (
    <Form
      onFieldsChange={(changedFields) => {
        changedFields.forEach((item) => {
          const field = item as { name: string[]; value: number };

          if (field.name[0] === selectName) {
            setAmountIndex(field.value);
          }

          if (field.name[0] === inputName && field.value) {
            setInputValue(field.value);
          }
        });
      }}
      initialValues={
        underlying
          ? {
              [selectName]: getDefaultSelect(underlying),
              [inputName]: '0',
            }
          : { [inputName]: '0' }
      }
      onFinish={onFinish}
      form={form}
      name="unstakePool"
      className={cn(styles.form, { [styles.largePadding]: poolType === 4 })}
    >
      {!underlying && (
        <PoolUnstakeFormPrompt
          name={poolType === 2 || poolType === 3 ? poolName : poolSymbol}
        />
      )}

      {underlying && (
        <p className={styles['form-description']}>
          {t('POOLS.POOL_UNSTAKE_FORM.DESCRIPTION')}
        </p>
      )}

      {underlying && (
        <Item
          name={selectName}
          rules={[
            {
              required: true,
              message: t(
                'POOLS.POOL_UNSTAKE_FORM.VALIDATION_ERROR_NOT_SELECTED',
              ),
            },
          ]}
        >
          <Select
            disabled={isLoading || isLoadingUnderlying}
            className={cn('unstakeSelect', styles.unstakeSelect)}
            dropdownClassName="unstakeDropdown"
          >
            {underlying.map(
              ({ address, isHidden }: PoolUnderlying, index: number) =>
                !isHidden && (
                  <Option value={index} key={address}>
                    {underlyingList &&
                      UNDERLYING_LIST[underlyingList[address]?.symbol]
                        ?.icon && (
                        <img
                          height="20"
                          width="20"
                          src={
                            UNDERLYING_LIST[underlyingList[address].symbol].icon
                          }
                          alt={underlyingList[address].symbol}
                        />
                      )}

                    <span>
                      {underlyingList && underlyingList[address]?.symbol}
                    </span>
                  </Option>
                ),
            )}
          </Select>
        </Item>
      )}

      <dl className={styles['form-price']}>
        <dt>
          {t(
            underlying
              ? 'POOLS.POOL_UNSTAKE_FORM.VALUE_TITLE'
              : 'POOLS.POOL_UNSTAKE_FORM.STAKED_TITLE',
            { name: poolName },
          )}
        </dt>
        <dd>
          {prices &&
            underlying &&
            userPoolAmount &&
            prices[poolSymbol] &&
            formatValuePrice(
              language,
              getTokenPrice(userPoolAmount, poolDecimals, prices[poolSymbol]),
            )}
          {!underlying &&
            userPoolAmount &&
            formatValueToken(
              language,
              Number(formatUnits(userPoolAmount, poolDecimals)),
            )}
          {!userPoolAmount && '-'}{' '}
          {!underlying && (poolType === 2 || poolType === 3) ? poolName : ''}
          {!underlying && (poolType === 0 || poolType === 1) ? poolSymbol : ''}
        </dd>
      </dl>
      <p className={styles['form-hint']}>
        Input share you want to withdraw (up to 100%)
      </p>
      <div className={styles['form-input']}>
        {underlying ? (
          <Item
            name={inputName}
            rules={[
              {
                required: true,
                message: t('POOLS.POOL_UNSTAKE_FORM.VALIDATION_ERROR_EMPTY'),
              },
              {
                validator: (rule, value: string) =>
                  onInputValidator(
                    value,
                    t('POOLS.POOL_UNSTAKE_FORM.VALIDATION_ERROR_BIG'),
                    t('POOLS.POOL_UNSTAKE_FORM.VALIDATION_ERROR_SMALL'),
                  ),
              },
            ]}
          >
            <InputNumber
              stringMode
              formatter={onInputFormatter}
              bordered={false}
              disabled={isLoading}
              step={1}
              min="0"
              placeholder="0"
              className={styles['form-inputNumber']}
            />
          </Item>
        ) : (
          <FormInput
            isDisabled={isLoading || isMinValueBigger}
            inputName={inputName}
            maxValue={userPoolAmount}
            decimals={poolDecimals}
            form={form}
          />
        )}
      </div>

      <div className={styles['form-submit']}>
        <FormSubmit
          isDisabled={isMinValueBigger}
          isLoading={isLoading}
          color="pink"
          name={t('POOLS.POOL_UNSTAKE_FORM.BUTTON_TITLE')}
        />
      </div>

      {underlying && (
        <p className={styles['form-commission']}>
          {t('POOLS.POOL_UNSTAKE_FORM.COMMISSION', {
            value: COMMISSION_VALUE
              ? formatValuePercentage(language, COMMISSION_VALUE)
              : '-',
            name: poolName,
          })}
        </p>
      )}

      {underlying && (
        <dl className={styles['form-estimated']}>
          <dt>
            {t('POOLS.POOL_UNSTAKE_FORM.ESTIMATED', {
              symbol:
                underlying &&
                underlyingList &&
                underlyingList[underlying[amountIndex].address]?.symbol,
            })}
          </dt>
          <dd>
            {prices && userPoolAmount && prices[poolSymbol]
              ? formatValuePrice(
                  language,
                  getTokenPrice(
                    userPoolAmount
                      .mul(parseUnits(inputValue.toString().split('.')[0], 0))
                      .div(100)
                      .sub(
                        userPoolAmount
                          .mul(parseUnits(COMMISSION_VALUE.toString(), 2))
                          .div(10000),
                      ),
                    poolDecimals,
                    prices[poolSymbol],
                  ),
                )
              : '-'}
          </dd>
        </dl>
      )}
    </Form>
  );
};

PoolUnstakeForm.defaultProps = {
  userPoolAmount: undefined,
  underlying: undefined,
};

export default PoolUnstakeForm;
