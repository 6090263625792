import CookieNotice from '@modules/info/components/CookieNotice';

const page = {
  path: '/cookie-notice',
  menu: 2,
  order: 9,
  title: 'INFO.COOKIE_NOTICE.NAV_LINK_NAME',
  component: CookieNotice,
};

export default page;
