import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { notification } from 'antd';
import cn from 'classnames';

import WalletConnections from '@modules/connections/components/WalletConnections';
import {
  errorSelector as errorSelectorSwap,
  isSuccessSelector as successSelectorSwap,
} from '@modules/dhv/slices/dhvSwapTransactionSlice';
import {
  errorSelector as errorSelectorSwapAllowance,
  isSuccessSelector as successSelectorSwapAllowance,
} from '@modules/dhv/slices/dhvSwapAllowanceSlice';
import {
  errorListSelector as errorListSelectorDisassemble,
  successListSelector as successListSelectorDisassemble,
} from '@modules/clusters/slices/disassembleSlice';
import {
  errorListSelector as errorListSelectorAssemble,
  successListSelector as successListSelectorAssemble,
} from '@modules/clusters/slices/assembleSlice';
import {
  successListSelector as successListSelectorUnstake,
  errorListSelector as errorListSelectorUnstake,
} from '@modules/pools/slices/unstakeSlice';
import {
  successListSelector as successListSelectorStake,
  errorListSelector as errorListSelectorStake,
} from '@modules/pools/slices/stakeSlice';
import {
  successListSelector as successListSelectorApprove,
  errorListSelector as errorListSelectorApprove,
} from '@modules/pools/slices/approveSlice';
import {
  successListSelector as successListSelectorReward,
  errorListSelector as errorListSelectorReward,
} from '@modules/pools/slices/rewardSlice';
import { errorSelector as errorSelectorUnderlying } from '@modules/underlying/slices/underlyingSlice';
import { errorSelector as errorSelectorClusters } from '@modules/clusters/slices/clustersSlice';
import { errorSelector as errorSelectorPools } from '@modules/pools/slices/poolsSlice';
import { errorSelector as errorSelectorDhvSwap } from '@modules/dhv/slices/dhvSwapBalancesSlice';
import { errorSelector as errorSelectorTokenPrices } from '@modules/prices/slices/tokenPricesSlice';
import { errorSelector as errorSelectorCurrency } from '@modules/prices/slices/currencySlice';
import { errorSelector as errorSelectorTvl } from '@modules/layout/slices/tvlSlice';
import { errorSelector as errorSelectorPoolsTvl } from '@modules/pools/slices/poolsTvlSlices';
import { errorSelector as errorSelectorPoolsStats } from '@modules/pools/slices/poolsStatsSlice';
import { errorSelector as errorSelectorProducts } from '@modules/main/slices/productsSlice';
import RpcError from '@modules/connections/types/RpcError';
import { useTypedSelector } from '@utils/store';
import INFO_ICON from '@modules/common/assets/info_icon.svg';

import styles from '@modules/layout/components/NotificationBar/NotificationBar.module.scss';

const NotificationBarItem: FC = ({ children }) => {
  return (
    <li className={styles.listItem}>
      <img
        className={styles.listIcon}
        height="21"
        width="19"
        src={INFO_ICON}
        alt=""
      />
      {children}
    </li>
  );
};

const NotificationBar: FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation();

  const { account, error } = useWeb3React();

  const errorListDisassemble = useTypedSelector(errorListSelectorDisassemble);
  const errorListAssemble = useTypedSelector(errorListSelectorAssemble);
  const errorListUnstake = useTypedSelector(errorListSelectorUnstake);
  const successListStake = useTypedSelector(successListSelectorStake);
  const errorListStake = useTypedSelector(errorListSelectorStake);
  const successListApprove = useTypedSelector(successListSelectorApprove);
  const errorListApprove = useTypedSelector(errorListSelectorApprove);
  const successListReward = useTypedSelector(successListSelectorReward);
  const errorListReward = useTypedSelector(errorListSelectorReward);
  const errorUnderlying = useTypedSelector(errorSelectorUnderlying);
  const errorClusters = useTypedSelector(errorSelectorClusters);
  const errorPools = useTypedSelector(errorSelectorPools);
  const errorSwapTokenInfo = useTypedSelector(errorSelectorDhvSwap);
  const errorTokenPrices = useTypedSelector(errorSelectorTokenPrices);
  const errorCurrency = useTypedSelector(errorSelectorCurrency);
  const errorTvl = useTypedSelector(errorSelectorTvl);
  const errorPoolsTvl = useTypedSelector(errorSelectorPoolsTvl);
  const errorPoolsStats = useTypedSelector(errorSelectorPoolsStats);
  const errorProducts = useTypedSelector(errorSelectorProducts);
  const errorSwap = useTypedSelector(errorSelectorSwap);
  const errorSwapAllowance = useTypedSelector(errorSelectorSwapAllowance);

  const successListUnstake = useTypedSelector(successListSelectorUnstake);
  const successListAssemble = useTypedSelector(successListSelectorAssemble);
  const successListDisassemble = useTypedSelector(
    successListSelectorDisassemble,
  );
  const successSwap = useTypedSelector(successSelectorSwap);
  const successSwapAllowance = useTypedSelector(successSelectorSwapAllowance);

  useEffect(() => {
    if (successSwap) {
      notification.success({
        message: 'Buying successful',
      });
    }
  }, [successSwap]);

  useEffect(() => {
    if (successSwapAllowance) {
      notification.success({
        message: 'Approval successful',
      });
    }
  }, [successSwapAllowance]);

  useEffect(() => {
    Object.values(successListAssemble).forEach((isSuccess) => {
      if (isSuccess) {
        notification.success({
          message: 'Assemble successful',
        });
      }
    });
  }, [successListAssemble]);

  useEffect(() => {
    Object.values(successListDisassemble).forEach((isSuccess) => {
      if (isSuccess) {
        notification.success({
          message: 'Disassemble successful',
        });
      }
    });
  }, [successListDisassemble]);

  useEffect(() => {
    Object.keys(successListUnstake)?.forEach((address: string) => {
      Object.keys(successListUnstake[address])?.forEach((pid: string) => {
        if (successListUnstake[address][Number(pid)]) {
          notification.success({
            message: 'Successful unstake of the pool',
          });
        }
      });
    });
  }, [successListUnstake]);

  useEffect(() => {
    Object.keys(errorListUnstake)?.forEach((address: string) => {
      Object.keys(errorListUnstake[address])?.forEach((pid: string) => {
        if (errorListUnstake[address][Number(pid)]) {
          if ((errorListUnstake[address][Number(pid)] as RpcError)?.code) {
            notification.error({
              message: 'Error with unstake the pool',
              description: `Internal RPC or wallet error: ${
                (errorListUnstake[address][Number(pid)] as RpcError).message
              }`,
            });
          } else if (
            (errorListUnstake[address][Number(pid)] as Error)?.message
          ) {
            notification.error({
              message: 'Error with unstake the pool',
              description: (errorListUnstake[address][Number(pid)] as Error)
                .message,
            });
          }
        }
      });
    });
  }, [errorListUnstake]);

  useEffect(() => {
    Object.keys(successListStake)?.forEach((address: string) => {
      Object.keys(successListStake[address])?.forEach((pid: string) => {
        if (successListStake[address][Number(pid)]) {
          notification.success({
            message: 'Successful stake of the pool',
          });
        }
      });
    });
  }, [successListStake]);

  useEffect(() => {
    Object.keys(errorListStake)?.forEach((address: string) => {
      Object.keys(errorListStake[address])?.forEach((pid: string) => {
        if (errorListStake[address][Number(pid)]) {
          if ((errorListStake[address][Number(pid)] as RpcError)?.code) {
            notification.error({
              message: 'Error with stake the pool',
              description: `Internal RPC or wallet error: ${
                (errorListStake[address][Number(pid)] as RpcError).message
              }`,
            });
          } else if ((errorListStake[address][Number(pid)] as Error)?.message) {
            notification.error({
              message: 'Error with stake the pool',
              description: (errorListStake[address][Number(pid)] as Error)
                .message,
            });
          }
        }
      });
    });
  }, [errorListStake]);

  useEffect(() => {
    Object.keys(successListApprove)?.forEach((address: string) => {
      if (successListApprove[address]) {
        notification.success({
          message: 'Successful approve the token',
        });
      }
    });
  }, [successListApprove]);

  useEffect(() => {
    Object.keys(errorListApprove)?.forEach((address: string) => {
      if (errorListApprove[address]) {
        if ((errorListApprove[address] as RpcError)?.code) {
          notification.error({
            message: 'Error with approve the token',
            description: `Internal RPC or wallet error: ${
              (errorListApprove[address] as RpcError).message
            }`,
          });
        } else if ((errorListApprove[address] as Error)?.message) {
          notification.error({
            message: 'Error with approve the token',
            description: (errorListApprove[address] as Error).message,
          });
        }
      }
    });
  }, [errorListApprove]);

  useEffect(() => {
    Object.keys(successListReward)?.forEach((address: string) => {
      Object.keys(successListReward[address])?.forEach((pid: string) => {
        if (successListReward[address][Number(pid)]) {
          notification.success({
            message: 'Successful reward of the pool',
          });
        }
      });
    });
  }, [successListReward]);

  useEffect(() => {
    Object.keys(errorListReward)?.forEach((address: string) => {
      Object.keys(errorListReward[address])?.forEach((pid: string) => {
        if (errorListReward[address][Number(pid)]) {
          if ((errorListReward[address][Number(pid)] as RpcError)?.code) {
            notification.error({
              message: 'Error with claim the pool',
              description: `Internal RPC or wallet error: ${
                (errorListReward[address][Number(pid)] as RpcError).message
              }`,
            });
          } else if (
            (errorListReward[address][Number(pid)] as Error)?.message
          ) {
            notification.error({
              message: 'Error with claim the pool',
              description: (errorListReward[address][Number(pid)] as Error)
                .message,
            });
          }
        }
      });
    });
  }, [errorListReward]);

  useEffect(() => {
    Object.keys(errorListDisassemble)?.forEach((address: string) => {
      if (errorListDisassemble[address]) {
        if ((errorListDisassemble[address] as RpcError)?.code) {
          notification.error({
            message: 'Error disassembling the cluster',
            description: `Internal RPC or wallet error: ${
              (errorListDisassemble[address] as RpcError).message
            }`,
          });
        } else if ((errorListDisassemble[address] as Error)?.message) {
          notification.error({
            message: 'Error disassembling the cluster',
            description: (errorListDisassemble[address] as Error).message,
          });
        }
      }
    });
  }, [errorListDisassemble]);

  useEffect(() => {
    Object.keys(errorListAssemble)?.forEach((address: string) => {
      if (errorListAssemble[address]) {
        if ((errorListAssemble[address] as RpcError)?.code) {
          notification.error({
            message: 'Error assembling the cluster',
            description: `Internal RPC or wallet error: ${
              (errorListAssemble[address] as RpcError).message
            }`,
          });
        } else if ((errorListAssemble[address] as Error)?.message) {
          notification.error({
            message: 'Error assembling the cluster',
            description: (errorListAssemble[address] as Error).message,
          });
        }
      }
    });
  }, [errorListAssemble]);

  useEffect(() => {
    if ((errorUnderlying as RpcError)?.code) {
      notification.error({
        message: 'Error getting info of the cluster assets',
        description: `Internal RPC or wallet error: ${
          (errorUnderlying as RpcError).message
        }`,
      });
    } else if ((errorUnderlying as Error)?.message) {
      notification.error({
        message: 'Error getting info of the cluster assets',
        description: (errorUnderlying as Error).message,
      });
    }
  }, [errorUnderlying]);

  useEffect(() => {
    if ((errorClusters as RpcError)?.code) {
      notification.error({
        message: 'Error getting info of the clusters',
        description: `Internal RPC or wallet error: ${
          (errorClusters as RpcError).message
        }`,
      });
    } else if ((errorClusters as Error)?.message) {
      notification.error({
        message: 'Error getting info of the clusters',
        description: (errorClusters as Error).message,
      });
    }
  }, [errorClusters]);

  useEffect(() => {
    if ((errorPools as RpcError)?.code) {
      notification.error({
        message: 'Error getting info of the pools',
        description: `Internal RPC or wallet error: ${
          (errorPools as RpcError).message
        }`,
      });
    } else if ((errorPools as Error)?.message) {
      notification.error({
        message: 'Error getting info of the pools',
        description: (errorPools as Error).message,
      });
    }
  }, [errorPools]);

  useEffect(() => {
    if ((errorSwapTokenInfo as RpcError)?.code) {
      notification.error({
        message: 'Error getting info of the swap token info',
        description: `Internal RPC or wallet error: ${
          (errorSwapTokenInfo as RpcError).message
        }`,
      });
    } else if ((errorSwapTokenInfo as Error)?.message) {
      notification.error({
        message: 'Error getting info of the swap token info',
        description: (errorSwapTokenInfo as Error).message,
      });
    }
  }, [errorSwapTokenInfo]);

  useEffect(() => {
    if ((errorTokenPrices as RpcError)?.code) {
      notification.error({
        message: 'Error getting info of the token prices',
        description: `Internal RPC or wallet error: ${
          (errorTokenPrices as RpcError).message
        }`,
      });
    } else if ((errorTokenPrices as Error)?.message) {
      notification.error({
        message: 'Error getting info of the token prices',
        description: (errorTokenPrices as Error).message,
      });
    }
  }, [errorTokenPrices]);

  useEffect(() => {
    if ((errorCurrency as RpcError)?.code) {
      notification.error({
        message: 'Error getting info of the network currency',
        description: `Internal RPC or wallet error: ${
          (errorCurrency as RpcError).message
        }`,
      });
    } else if ((errorCurrency as Error)?.message) {
      notification.error({
        message: 'Error getting info of the network currency',
        description: (errorCurrency as Error).message,
      });
    }
  }, [errorCurrency]);

  useEffect(() => {
    if ((errorTvl as RpcError)?.code) {
      notification.error({
        message: 'Error getting info of the tvl',
        description: `Internal RPC or wallet error: ${
          (errorTvl as RpcError).message
        }`,
      });
    } else if ((errorTvl as Error)?.message) {
      notification.error({
        message: 'Error getting info of the tvl',
        description: (errorTvl as Error).message,
      });
    }
  }, [errorTvl]);

  useEffect(() => {
    if ((errorPoolsTvl as RpcError)?.code) {
      notification.error({
        message: 'Error getting info of the pools tvl',
        description: `Internal RPC or wallet error: ${
          (errorPoolsTvl as RpcError).message
        }`,
      });
    } else if ((errorPoolsTvl as Error)?.message) {
      notification.error({
        message: 'Error getting info of the pools tvl',
        description: (errorPoolsTvl as Error).message,
      });
    }
  }, [errorPoolsTvl]);

  useEffect(() => {
    if ((errorPoolsStats as RpcError)?.code) {
      notification.error({
        message: 'Error getting info of the pools stats',
        description: `Internal RPC or wallet error: ${
          (errorPoolsStats as RpcError).message
        }`,
      });
    } else if ((errorPoolsStats as Error)?.message) {
      notification.error({
        message: 'Error getting info of the pools stats',
        description: (errorPoolsStats as Error).message,
      });
    }
  }, [errorPoolsStats]);

  useEffect(() => {
    if ((errorProducts as RpcError)?.code) {
      notification.error({
        message: 'Error getting info of the product prices',
        description: `Internal RPC or wallet error: ${
          (errorProducts as RpcError).message
        }`,
      });
    } else if ((errorProducts as Error)?.message) {
      notification.error({
        message: 'Error getting info of the product prices',
        description: (errorProducts as Error).message,
      });
    }
  }, [errorProducts]);

  useEffect(() => {
    if ((errorSwap as RpcError)?.code) {
      notification.error({
        message: 'Error swapping tokens',
        description: `Internal RPC or wallet error: ${
          (errorSwap as RpcError).message
        }`,
      });
    } else if ((errorSwap as Error)?.message) {
      notification.error({
        message: 'Error swapping tokens',
        description: (errorSwap as Error).message,
      });
    }
  }, [errorSwap]);

  useEffect(() => {
    if ((errorSwapAllowance as RpcError)?.code) {
      notification.error({
        message: 'Error getting info of the swap allowance',
        description: `Internal RPC or wallet error: ${
          (errorSwapAllowance as RpcError).message
        }`,
      });
    } else if ((errorSwapAllowance as Error)?.message) {
      notification.error({
        message: 'Error getting info of the swap allowance',
        description: (errorSwapAllowance as Error).message,
      });
    }
  }, [errorSwapAllowance]);

  return (
    <aside className={cn(styles.container, className)}>
      <ul className={styles.list}>
        {!account && (
          <NotificationBarItem>
            <span className={styles.listText}>
              {t('LAYOUT.NOTIFICATION_BAR.NO_ACCOUNT_INFO_PART_1')}
            </span>{' '}
            <WalletConnections
              textButton={t('LAYOUT.NOTIFICATION_BAR.NO_ACCOUNT_INFO_PART_2')}
              isInline
            />{' '}
            <span className={styles.listText}>
              {t('LAYOUT.NOTIFICATION_BAR.NO_ACCOUNT_INFO_PART_3')}
            </span>
          </NotificationBarItem>
        )}

        {error instanceof UnsupportedChainIdError && (
          <NotificationBarItem>
            <span className={styles.listText}>
              {t('LAYOUT.NOTIFICATION_BAR.UNSUPPORTED_NETWORK_INFO')}
            </span>
          </NotificationBarItem>
        )}

        {(errorUnderlying as Error)?.message && (
          <NotificationBarItem>
            <span className={styles.listText}>
              Something happened with getting info of the cluster assets. Please
              wait the next request or reload the page.
            </span>
          </NotificationBarItem>
        )}

        {(errorClusters as Error)?.message && (
          <NotificationBarItem>
            <span className={styles.listText}>
              Something happened with getting info of the clusters. Please wait
              the next request or reload the page.
            </span>
          </NotificationBarItem>
        )}

        {(errorPools as Error)?.message && (
          <NotificationBarItem>
            <span className={styles.listText}>
              Something happened with getting info of the pools. Please wait the
              next request or reload the page.
            </span>
          </NotificationBarItem>
        )}

        {(errorSwapTokenInfo as Error)?.message && (
          <NotificationBarItem>
            <span className={styles.listText}>
              Something happened with getting info of the swap tokens. Please
              wait the next request or reload the page.
            </span>
          </NotificationBarItem>
        )}

        {(errorTokenPrices as Error)?.message && (
          <NotificationBarItem>
            <span className={styles.listText}>
              Something happened with getting info of the token prices. Please
              wait the next request or reload the page.
            </span>
          </NotificationBarItem>
        )}

        {(errorCurrency as Error)?.message && (
          <NotificationBarItem>
            <span className={styles.listText}>
              Something happened with getting info of the network currency.
              Please wait the next request or reload the page.
            </span>
          </NotificationBarItem>
        )}

        {(errorTvl as Error)?.message && (
          <NotificationBarItem>
            <span className={styles.listText}>
              Something happened with getting info of the tvl. Please wait the
              next request or reload the page.
            </span>
          </NotificationBarItem>
        )}

        {(errorPoolsTvl as Error)?.message && (
          <NotificationBarItem>
            <span className={styles.listText}>
              Something happened with getting info of the pools tvl. Please wait
              the next request or reload the page.
            </span>
          </NotificationBarItem>
        )}

        {(errorPoolsStats as Error)?.message && (
          <NotificationBarItem>
            <span className={styles.listText}>
              Something happened with getting info of the pools stats. Please
              wait the next request or reload the page.
            </span>
          </NotificationBarItem>
        )}

        {(errorProducts as Error)?.message && (
          <NotificationBarItem>
            <span className={styles.listText}>
              Something happened with getting info of the product prices. Please
              wait the next request or reload the page.
            </span>
          </NotificationBarItem>
        )}

        {(errorSwap as Error)?.message && (
          <NotificationBarItem>
            <span className={styles.listText}>
              Something happened with swapping tokens. Please wait the next
              request or reload the page.
            </span>
          </NotificationBarItem>
        )}

        {(errorSwapAllowance as Error)?.message && (
          <NotificationBarItem>
            <span className={styles.listText}>
              Something happened with getting info of the swap allowance. Please
              wait the next request or reload the page.
            </span>
          </NotificationBarItem>
        )}
      </ul>
    </aside>
  );
};

NotificationBar.defaultProps = { className: undefined };

export default NotificationBar;
