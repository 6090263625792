import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BigNumber } from '@ethersproject/bignumber';

import type { RootState } from '@utils/store';

interface InitialState {
  isLoadingDisassemble: boolean;
  errorDisassemble: unknown | null;
  disassemble: BigNumber | null;
  isLoadingAssemble: boolean;
  errorAssemble: unknown | null;
  assemble: BigNumber | null;
}

const initialState: InitialState = {
  isLoadingDisassemble: false,
  errorDisassemble: null,
  disassemble: null,
  isLoadingAssemble: false,
  errorAssemble: null,
  assemble: null,
};

const reducers = {
  setIsLoadingDisassemble(state: InitialState, action: PayloadAction<boolean>) {
    state.isLoadingDisassemble = action.payload;
  },
  setErrorDisassemble(
    state: InitialState,
    action: PayloadAction<unknown | null>,
  ) {
    state.errorDisassemble = action.payload;
  },
  setDisassemble(state: InitialState, action: PayloadAction<BigNumber>) {
    state.disassemble = action.payload;
  },
  setIsLoadingAssemble(state: InitialState, action: PayloadAction<boolean>) {
    state.isLoadingAssemble = action.payload;
  },
  setErrorAssemble(state: InitialState, action: PayloadAction<unknown | null>) {
    state.errorAssemble = action.payload;
  },
  setAssemble(state: InitialState, action: PayloadAction<BigNumber>) {
    state.assemble = action.payload;
  },
};

const commissionSlice = createSlice({
  name: 'commission',
  initialState,
  reducers,
});

export const isLoadingDisassembleSelector = (state: RootState): boolean =>
  state.commission.isLoadingDisassemble;
export const errorDisassembleSelector = (state: RootState): unknown | null =>
  state.commission.errorDisassemble;
export const disassembleSelector = (state: RootState): BigNumber | null =>
  state.commission.disassemble;
export const isLoadingAssembleSelector = (state: RootState): boolean =>
  state.commission.isLoadingAssemble;
export const errorAssembleSelector = (state: RootState): unknown | null =>
  state.commission.errorAssemble;
export const assembleSelector = (state: RootState): BigNumber | null =>
  state.commission.assemble;

export const {
  setIsLoadingDisassemble,
  setErrorDisassemble,
  setDisassemble,
  setIsLoadingAssemble,
  setErrorAssemble,
  setAssemble,
} = commissionSlice.actions;

export default commissionSlice.reducer;
