import { FC, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import ProductSlider from '@modules/main/components/ProductSlider';
import { preErrorSelector } from '@modules/main/slices/productsSlice';
import { useTypedSelector } from '@utils/store';
import constants from '@modules/common/constants';

import styles from './Main.module.scss';

const Main: FC = () => {
  const [attempt, setAttempt] = useState(0);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const preError = useTypedSelector(preErrorSelector);

  const getProducts = useCallback(() => {
    dispatch({ type: 'MAIN_GET_PRODUCTS' });
  }, [dispatch]);

  useEffect(() => {
    getProducts();

    const reGetProducts = setInterval(
      () => getProducts(),
      constants.GET_ITEMS_INTERVAL,
    );

    return () => clearInterval(reGetProducts);
  }, [getProducts]);

  useEffect(() => {
    if (preError && attempt < constants.GET_ITEMS_ATTEMPTS) {
      getProducts();

      setAttempt(attempt + 1);
    }

    if (preError && attempt >= constants.GET_ITEMS_ATTEMPTS) {
      dispatch({
        type: 'MAIN_SET_ERROR_PRODUCTS',
        payload: { error: preError },
      });
    }
  }, [preError, getProducts, dispatch, attempt]);

  return (
    <article className={styles.container}>
      <h1 className={styles.title}>{t('MAIN.MAIN.PAGE_TITLE')}</h1>

      <p className={styles.description}>{t('MAIN.MAIN.PAGE_DESCRIPTION')}</p>

      <ProductSlider />
    </article>
  );
};

export default Main;
