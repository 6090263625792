import CLUSTERS from '@configs/clusters';

interface InitialObject {
  [key: string]: null;
}

const getInitialObject = (): InitialObject => {
  let initialObject: InitialObject | null = null;

  Object.keys(CLUSTERS)?.map((item: string) => {
    return Object.keys(CLUSTERS[Number(item)])?.map((address: string) => {
      if (initialObject) {
        initialObject[address] = null;
      } else {
        initialObject = { [address]: null };
      }

      return address;
    });
  });

  if (!initialObject) {
    initialObject = {};
  }

  return initialObject;
};

export default getInitialObject;
