import { BigNumber } from '@ethersproject/bignumber';

import ContractFunctions from '@modules/common/types/ContractFunctions';

const getUserPoolAmountInUnderlying = (
  poolContract: ContractFunctions<BigNumber[]>,
  poolId: number,
  account: string,
): Promise<BigNumber[]> => {
  return poolContract.userPoolAmountInUnderlying(poolId, account);
};

export default getUserPoolAmountInUnderlying;
