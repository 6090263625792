import { FC } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import pages, { Page } from '@utils/pages';

import HELP_ICON from '@modules/layout/assets/help_icon.svg';

import styles from './NavBar.module.scss';

const getPageList = (parent?: number): Page[] => {
  const pageList: Page[] = [];

  pages.forEach((item: Page) => {
    if (parent === item.parent && item.menu === 1) {
      pageList.push(item);
    }
  });

  return pageList;
};

const NavBarItem: FC<{
  classNameActive: string;
  classNameTitle: string;
  classNameItem: string;
  classNameIcon: string;
  classNameLink: string;
  onClick: () => void;
  page: Page;
}> = ({
  classNameActive,
  classNameTitle,
  classNameItem,
  classNameIcon,
  classNameLink,
  children,
  onClick,
  page,
}) => {
  const { t } = useTranslation();

  const match = useRouteMatch('/clusters/:token');

  return (
    <li className={classNameItem}>
      <NavLink
        activeClassName={classNameActive}
        className={cn(classNameLink, {
          [classNameActive]: page.path === '/clusters' && match?.isExact,
        })}
        onClick={onClick}
        exact
        to={page.path}
      >
        {page.icon && (
          <img
            className={classNameIcon}
            height="20"
            width="20"
            src={page.icon}
            alt={t(page.title)}
          />
        )}

        <span className={classNameTitle}>{t(page.title)}</span>
      </NavLink>

      {children}
    </li>
  );
};

const NavBar: FC<{ className?: string; onClick: () => void }> = ({
  className,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <nav className={className}>
      <ul className={styles.list}>
        {getPageList().map((item) => (
          <NavBarItem
            classNameActive={styles['is-active']}
            classNameTitle={styles.listItemTitle}
            classNameItem={styles.listItem}
            classNameIcon={styles.listItemIcon}
            classNameLink={styles.listItemLink}
            onClick={onClick}
            page={item}
            key={item.path}
          >
            {getPageList(item.order).length > 0 && (
              <ul className={styles.childList}>
                {getPageList(item.order).map((childItem) => (
                  <NavBarItem
                    classNameActive={styles['is-active']}
                    classNameTitle={styles.childListItemTitle}
                    classNameItem={styles.childListItem}
                    classNameIcon={styles.childListItemIcon}
                    classNameLink={styles.childListItemLink}
                    onClick={onClick}
                    page={childItem}
                    key={childItem.path}
                  />
                ))}
              </ul>
            )}
          </NavBarItem>
        ))}

        <li className={styles.listItem}>
          <a
            className={styles.listItemLink}
            target="_blank"
            href="https://intercom.help/dehive/en/"
            rel="noreferrer"
          >
            <img
              className={styles.listItemIcon}
              src={HELP_ICON}
              alt={t('LAYOUT.NAV_BAR.HELP_DOC')}
            />
            <span className={styles.listItemTitle}>
              {t('LAYOUT.NAV_BAR.HELP_DOC')}
            </span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

NavBar.defaultProps = { className: undefined };

export default NavBar;
