import { FC } from 'react';

import icon from '@modules/common/assets/not_found_logo.svg';
import styles from './ErrorBlock.module.scss';

const ErrorBlock: FC<{ title: string }> = ({ title }) => {
  return (
    <section className={styles.ErrorBlock}>
      <img className={styles['ErrorBlock-icon']} src={icon} alt={title} />

      <h3 className={styles['ErrorBlock-title']}>{title}</h3>
    </section>
  );
};

export default ErrorBlock;
