import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';

const getPoolGasPriceDeposit = (
  poolContract: Contract,
  poolId: number,
  amount: BigNumber,
): Promise<BigNumber> => {
  return poolContract.estimateGas.deposit(poolId, amount);
};

export default getPoolGasPriceDeposit;
