import { FC, useState, useCallback } from 'react';

import NETWORKS from '@configs/networks';
import {
  NetworkSelectorContextType,
  NetworkSelectorContext,
} from '@modules/networks/context/NetworkSelectorContext';

const { Provider } = NetworkSelectorContext;

const NetworkSelectorWrapper: FC = ({ children }) => {
  const [chainId, setChainId] = useState<number>(
    Number(
      localStorage.getItem(
        `settings-network-${process.env.REACT_APP_NETWORK_ENVIRONMENT || 'id'}`,
      ),
    ) || NETWORKS[0].chainId,
  );

  const handleChangeChainId = useCallback(
    (value: number) => setChainId(value),
    [],
  );

  const context: NetworkSelectorContextType = {
    handleChangeChainId,
    chainId,
  };

  return <Provider value={context}>{children}</Provider>;
};

export default NetworkSelectorWrapper;
