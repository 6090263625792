import { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Pagination } from 'swiper';
import { Modal } from 'antd';
import cn from 'classnames';

import Button from '@modules/common/components/Button';
import useMediaQuery from '@modules/layout/hooks/useMediaQuery';
import DHV_ICON from '@modules/dhv/assets/dhv_logo_color.png';

import styles from './DHVHowTo.module.scss';

const DHVHowTo: FC = () => {
  const [isInstructionVisible, setIsInstructionVisible] =
    useState<boolean>(false);

  const { t } = useTranslation();

  const isMobile = useMediaQuery(991);

  return (
    <aside className={styles.container}>
      <div className={styles.icon}>
        <img
          className={styles.iconImage}
          height="53"
          width="48"
          src={DHV_ICON}
          alt="DHV how to"
        />
      </div>

      <div className={styles.content}>
        <p className={styles.question}>
          {t('DHV.DHV_HOW_TO.BLOCK_DESCRIPTION')}
        </p>

        <Button
          className={styles.button}
          onClick={() => setIsInstructionVisible(true)}
          color="blue"
          text={t('DHV.DHV_HOW_TO.BUTTON_OPEN')}
          size="little"
        />
      </div>

      <Modal
        onCancel={() => setIsInstructionVisible(false)}
        visible={isInstructionVisible}
        footer={false}
        width={isMobile ? 290 : 763}
        bodyStyle={
          isMobile ? { padding: '56px 30px 24px' } : { padding: '73px 30px' }
        }
      >
        <h1 className={styles.modalTitle}>{t('DHV.DHV_HOW_TO.MODAL_TITLE')}</h1>

        {isMobile ? (
          <div className={styles.modalSlider}>
            <Swiper
              spaceBetween={8}
              slidesPerView={1.2}
              modules={[Pagination]}
              effect="fade"
              pagination={{
                clickable: true,
                el: '.modalSliderPagination',
                bulletClass: styles.bullet,
                bulletActiveClass: styles['is-active'],
              }}
              breakpoints={{
                300: {
                  slidesPerView: 1.1,
                },
                640: {
                  slidesPerView: 1.2,
                },
              }}
            >
              <SwiperSlide className={styles.item}>
                <Trans
                  i18nKey="DHV.DHV_HOW_TO.MODAL_STEP_1"
                  components={[
                    <span className={styles.itemStep} key={0} />,
                    <h3 className={styles.itemTitle} key={1}>
                      &nbsp;
                    </h3>,
                    <p className={styles.itemDescription} key={2} />,
                  ]}
                />
              </SwiperSlide>

              <SwiperSlide className={styles.item}>
                <Trans
                  i18nKey="DHV.DHV_HOW_TO.MODAL_STEP_2"
                  components={[
                    <span className={styles.itemStep} key={0} />,
                    <h3 className={styles.itemTitle} key={1}>
                      &nbsp;
                    </h3>,
                    <p className={styles.itemDescription} key={2} />,
                  ]}
                />
              </SwiperSlide>

              <SwiperSlide className={styles.item}>
                <Trans
                  i18nKey="DHV.DHV_HOW_TO.MODAL_STEP_3"
                  components={[
                    <span className={styles.itemStep} key={0} />,
                    <h3 className={styles.itemTitle} key={1}>
                      &nbsp;
                    </h3>,
                    <p className={styles.itemDescription} key={2} />,
                    <blockquote className={styles.itemBlockquote} key={3} />,
                  ]}
                />
              </SwiperSlide>

              <SwiperSlide className={styles.item}>
                <Trans
                  i18nKey="DHV.DHV_HOW_TO.MODAL_STEP_4"
                  components={[
                    <span className={styles.itemStep} key={0} />,
                    <h3 className={styles.itemTitle} key={1}>
                      &nbsp;
                    </h3>,
                    <p className={styles.itemDescription} key={2} />,
                  ]}
                />
              </SwiperSlide>

              <SwiperSlide className={styles.item}>
                <Trans
                  i18nKey="DHV.DHV_HOW_TO.MODAL_STEP_5"
                  components={[
                    <span className={styles.itemStep} key={0} />,
                    <h3 className={styles.itemTitle} key={1}>
                      &nbsp;
                    </h3>,
                    <p className={styles.itemDescription} key={2} />,
                  ]}
                />
              </SwiperSlide>
            </Swiper>
            <div className={cn('modalSliderPagination', styles.pagination)} />
          </div>
        ) : (
          <ul className={styles.modalList}>
            <li className={styles.item}>
              <Trans
                i18nKey="DHV.DHV_HOW_TO.MODAL_STEP_1"
                components={[
                  <span className={styles.itemStep} key={0} />,
                  <h3 className={styles.itemTitle} key={1}>
                    &nbsp;
                  </h3>,
                  <p className={styles.itemDescription} key={2} />,
                ]}
              />
            </li>

            <li className={styles.item}>
              <Trans
                i18nKey="DHV.DHV_HOW_TO.MODAL_STEP_2"
                components={[
                  <span className={styles.itemStep} key={0} />,
                  <h3 className={styles.itemTitle} key={1}>
                    &nbsp;
                  </h3>,
                  <p className={styles.itemDescription} key={2} />,
                ]}
              />
            </li>

            <li className={styles.item}>
              <Trans
                i18nKey="DHV.DHV_HOW_TO.MODAL_STEP_3"
                components={[
                  <span className={styles.itemStep} key={0} />,
                  <h3 className={styles.itemTitle} key={1}>
                    &nbsp;
                  </h3>,
                  <p className={styles.itemDescription} key={2} />,
                  <blockquote className={styles.itemBlockquote} key={3} />,
                ]}
              />
            </li>

            <li className={styles.item}>
              <Trans
                i18nKey="DHV.DHV_HOW_TO.MODAL_STEP_4"
                components={[
                  <span className={styles.itemStep} key={0} />,
                  <h3 className={styles.itemTitle} key={1}>
                    &nbsp;
                  </h3>,
                  <p className={styles.itemDescription} key={2} />,
                ]}
              />
            </li>

            <li className={styles.item}>
              <Trans
                i18nKey="DHV.DHV_HOW_TO.MODAL_STEP_5"
                components={[
                  <span className={styles.itemStep} key={0} />,
                  <h3 className={styles.itemTitle} key={1}>
                    &nbsp;
                  </h3>,
                  <p key={2} />,
                ]}
              />
            </li>
          </ul>
        )}

        <footer className={styles.modalFooter}>
          <p className={styles.modalFooterDescription}>
            {t('DHV.DHV_HOW_TO.MODAL_FOOTER')}
          </p>
        </footer>
      </Modal>
    </aside>
  );
};

export default DHVHowTo;
