import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { formatUnits } from '@ethersproject/units';

import { dataSelector } from '@modules/dhv/slices/dhvSwapValuesSlice';
import getNetworkDecimals from '@modules/common/helpers/getNetworkDecimals';
import formatValueToken from '@modules/common/helpers/formatValueToken';
import getNetworkSymbol from '@modules/common/helpers/getNetworkSymbol';
import useMediaQuery from '@modules/layout/hooks/useMediaQuery';
import { useTypedSelector } from '@utils/store';

import styles from './DHVSwapEstimate.module.scss';

const DHVSwapEstimate: FC<{ decimals: number }> = ({ decimals }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const data = useTypedSelector(dataSelector);

  const { chainId } = useWeb3React();

  const isMobile = useMediaQuery(991);

  return (
    <dl className={styles.container}>
      <dt className={styles.title}>
        {t('DHV.DHV_SWAP_ESTIMATE.MINIMUM_RECEIVE')}
        {!isMobile && ':'}
      </dt>

      <dd className={styles.value}>
        {data?.minimumReceive && decimals
          ? formatValueToken(
              language,
              Number(formatUnits(data.minimumReceive, decimals)),
            )
          : '-'}
      </dd>

      <dt className={styles.title}>
        {t('DHV.DHV_SWAP_ESTIMATE.NETWORK_FEE')}
        {!isMobile && ':'}
      </dt>

      <dd className={styles.value}>
        {`${
          data?.networkFee && chainId
            ? formatValueToken(
                language,
                Number(
                  formatUnits(data?.networkFee, getNetworkDecimals(chainId)),
                ),
              )
            : '-'
        } ${chainId ? getNetworkSymbol(chainId) : ''}`}
      </dd>
    </dl>
  );
};

export default DHVSwapEstimate;
