import { BigNumber } from '@ethersproject/bignumber';

import ContractFunctions from '@modules/common/types/ContractFunctions';

const handlePoolClaimRewards = (
  poolContract: ContractFunctions<BigNumber>,
  poolId: number,
  gasLimit: BigNumber,
): Promise<BigNumber> => {
  return poolContract.claimRewards(poolId, { gasLimit });
};

export default handlePoolClaimRewards;
