import { FC, useState, useEffect, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import moment from 'moment';
import { Tooltip } from 'antd';
import cn from 'classnames';

import {
  isLoadingSelector,
  listSelector,
} from '@modules/underlying/slices/underlyingSlice';
import getTokenScanLink from '@modules/clusters/helpers/getTokenScanLink';
import useMediaQuery from '@modules/layout/hooks/useMediaQuery';
import { useTypedSelector } from '@utils/store';
import LOADING_IMAGE from '@modules/common/assets/loading.svg';
import UNDERLYING_LIST from '@configs/underlying';
import { Pool } from '@configs/pools';

import styles from '@modules/pools/components/PoolCardRewardTimer/PoolCardRewardTimer.module.scss';

const PoolCardRewardTimer: FC<{
  timestamp: number;
  isClosed?: Pool['closed'];
  list: string[];
}> = ({ timestamp, isClosed, list }) => {
  const [timer, setTimer] = useState({ days: 0, minutes: 0, seconds: 0 });

  const { t } = useTranslation();

  const underlyingList = useTypedSelector(listSelector);
  const isLoading = useTypedSelector(isLoadingSelector);

  const { chainId } = useWeb3React();

  const isMobile = useMediaQuery(991);

  const updateInterval = useCallback(() => {
    if (timestamp > moment().unix()) {
      setTimer({
        days: Math.round(
          moment.duration(timestamp - moment().unix(), 'seconds').asDays(),
        ),
        minutes: moment
          .duration(timestamp - moment().unix(), 'seconds')
          .minutes(),
        seconds: moment
          .duration(timestamp - moment().unix(), 'seconds')
          .seconds(),
      });
    }
  }, [timestamp]);

  useEffect(() => {
    updateInterval();

    const reSetTimer = setInterval(() => updateInterval(), 1000);

    return () => clearInterval(reSetTimer);
  }, [updateInterval]);

  return (
    <aside
      className={cn(styles.container, {
        [styles['is-loading']]: isLoading,
        [styles['is-closed']]: isClosed,
      })}
    >
      {underlyingList && (
        <ul className={styles.list}>
          {list.map(
            (address: string) =>
              UNDERLYING_LIST[underlyingList[address]?.symbol]?.icon && (
                <li className={styles.listItem} key={address}>
                  <a
                    className={styles.listItemLink}
                    target="_blank"
                    href={chainId ? getTokenScanLink(chainId, address) : ''}
                    rel="noreferrer"
                  >
                    <Tooltip
                      overlayClassName={styles.listItemTooltip}
                      placement="topLeft"
                      trigger={isMobile ? 'click' : 'hover'}
                      title={underlyingList[address].symbol}
                    >
                      <img
                        className={styles.listItemImg}
                        height="16"
                        width="16"
                        src={
                          UNDERLYING_LIST[underlyingList[address].symbol].icon
                        }
                        alt={underlyingList[address].symbol}
                      />
                    </Tooltip>
                  </a>
                </li>
              ),
          )}
        </ul>
      )}

      <p className={styles.left}>
        <span className={styles.leftText}>
          {t(
            timestamp > moment().unix()
              ? 'POOLS.POOL_CARD_REWARD_TIMER.EXPIRE'
              : 'POOLS.POOL_CARD_REWARD_TIMER.FINISHED',
          )}
        </span>

        {timestamp > moment().unix() ? (
          <time
            className={styles.leftTimer}
            dateTime={moment.unix(timestamp).format('Y-MM-DD HH:mm')}
          >
            <Trans
              components={[<span className={styles.leftTimerItem} key={0} />]}
              i18nKey="POOLS.POOL_CARD_REWARD_TIMER.TIMER"
              values={{
                days: timer.days,
                minutes: timer.minutes,
                seconds: timer.seconds,
              }}
            />
          </time>
        ) : null}
      </p>

      {isLoading && (
        <img
          className={styles.loading}
          height="16"
          width="16"
          src={LOADING_IMAGE}
          alt=""
        />
      )}
    </aside>
  );
};

PoolCardRewardTimer.defaultProps = { isClosed: undefined };

export default PoolCardRewardTimer;
