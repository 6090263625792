import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import {
  CallEffect,
  PutEffect,
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { ContractInterface, Contract } from '@ethersproject/contracts';
import { JsonRpcProvider } from '@ethersproject/providers';
import { BigNumber } from '@ethersproject/bignumber';

import {
  setIsLoading,
  setPreError,
  setError,
  setList,
} from '@modules/clusters/slices/clustersSlice';
import getTokenName from '@modules/common/actions/getTokenName';
import getTokenSymbol from '@modules/common/actions/getTokenSymbol';
import getTokenDecimals from '@modules/common/actions/getTokenDecimals';
import getClusterUnderlyingList from '@modules/clusters/actions/getClusterUnderlyingList';
import getClusterUnderlyingInCluster from '@modules/clusters/actions/getClusterUnderlyingInCluster';
import getClusterTotalSupply from '@modules/clusters/actions/getClusterTotalSupply';
import StoredClusterList from '@modules/clusters/types/StoredClusterList';
import { ClusterList } from '@configs/clusters';
import getClusterPrice from '@modules/clusters/actions/getClusterPrice';
import { ControllerData } from '@configs/controllers';

type ReturnedCluster = [
  string,
  string,
  string,
  number,
  string[],
  BigNumber[],
  BigNumber,
  BigNumber,
];

const getCluster = (
  clusterAddress: string,
  clusterABI: ContractInterface,
  controller: ControllerData,
  provider: JsonRpcProvider,
): Promise<ReturnedCluster> => {
  const clusterContract = new Contract(clusterAddress, clusterABI, provider);
  const clusterController = new Contract(
    controller.controllerAddress,
    controller.controllerABI,
    provider,
  );

  return Promise.all([
    clusterAddress,
    getTokenName(clusterContract),
    getTokenSymbol(clusterContract),
    getTokenDecimals(clusterContract),
    getClusterUnderlyingList(clusterContract),
    getClusterUnderlyingInCluster(clusterContract),
    getClusterTotalSupply(clusterContract),
    getClusterPrice(clusterController, clusterAddress),
  ]);
};

const getClusterList = (
  clusters: ClusterList,
  controller: ControllerData,
  provider: JsonRpcProvider,
): Promise<ReturnedCluster[]> => {
  const getClustersInfo: Promise<ReturnedCluster>[] = [];

  Object.keys(clusters).map((address: string) => {
    return getClustersInfo.push(
      getCluster(address, clusters[address].tokenABI, controller, provider),
    );
  });

  return Promise.all(getClustersInfo);
};

function* getClusterListWorker({
  payload,
}: PayloadAction<{
  clusters: ClusterList;
  controller: ControllerData;
  library: JsonRpcProvider;
}>): Generator<
  CallEffect<ReturnedCluster[]> | PutEffect<AnyAction>,
  void,
  never
> {
  try {
    yield put(setIsLoading(true));
    yield put(setPreError(null));
    yield put(setError(null));

    const returnedClusterList: ReturnedCluster[] = yield call(
      getClusterList,
      payload.clusters,
      payload.controller,
      payload.library,
    );

    const clustersResult: StoredClusterList = {};

    returnedClusterList.map((value: ReturnedCluster) => {
      clustersResult[value[0]] = {
        name: value[1],
        symbol: value[2],
        decimals: value[3],
        underlyingList: value[4].map((item: string, index: number) => {
          return {
            address: item,
            share: value[5][index],
          };
        }),
        totalSupply: value[6],
        price: value[7],
      };

      return value;
    });

    yield put(setList(clustersResult));
  } catch (error: unknown) {
    yield put(setPreError(error));
  } finally {
    yield put(setIsLoading(false));
  }
}

function* getClusterListSaga(): Generator {
  yield takeLatest('CLUSTERS_GET_CLUSTER_LIST_REQUESTED', getClusterListWorker);
}

export default getClusterListSaga;
