import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { formatUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';

import { useTypedSelector } from '@utils/store';
import StoredAssetList from '@modules/portfolio/types/StoredAssetList';
import { getPrices } from '@modules/prices/slices/tokenPricesSlice';
import formatValuePrice from '@modules/common/helpers/formatValuePrice';
import getTokenPrice from '@modules/common/helpers/getTokenPrice';
import PortfolioDHVModal from '@modules/portfolio/components/PortfolioDHVModal';
import formatValueToken from '@modules/common/helpers/formatValueToken';
import trackGTMActions from '@modules/common/helpers/trackGTMActions';
import ButtonLink from '@modules/common/components/ButtonLink';

import DHV_LIST from '@configs/dhv';
import UNDERLYING_LIST from '@configs/underlying';
import AddToken from '@modules/common/components/AddToken';
import DHV_ICON from '@modules/portfolio/assets/dhv_icon.png';

import styles from './PortfolioDHV.module.scss';

const buyDhvLinkList: { [key: number]: string } = {
  1:
    'https://app.uniswap.org/#/swap?outputCurrency=' +
    '0x62dc4817588d53a056cbbd18231d91ffccd34b2a&use=V2',
  56:
    'https://pancakeswap.finance/swap?inputCurrency=' +
    '0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=' +
    '0x58759dd469ae5631c42cf8a473992335575b58d7',
  100:
    'https://app.honeyswap.org/#/swap?inputCurrency=' +
    '0xFbdd194376de19a88118e84E279b977f165d01b8&outputCurrency=XDAI',
  137:
    'https://quickswap.exchange/#/swap?inputCurrency=' +
    '0x5fcb9de282af6122ce3518cde28b7089c9f97b26&outputCurrency=' +
    '0x831753dd7087cac61ab5644b308642cc1c33dc13',
};

const DHV_SYMBOL = 'DHV';

const PortfolioDHV: FC<{ assets: StoredAssetList | null }> = ({ assets }) => {
  const tokenPrices = useTypedSelector(getPrices);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { chainId, account } = useWeb3React<Web3Provider>();

  const dhvInfo = useMemo(() => {
    let balance = {
      value: BigNumber.from(0),
      decimal: 18,
    };

    let staked = {
      value: BigNumber.from(0),
      decimal: 18,
    };

    if (assets) {
      const dhv = Object.values(assets)
        .map((assetInfo) => Object.values(assetInfo))
        .flat()
        .find((asset) => asset.symbol === DHV_SYMBOL);

      if (dhv) {
        balance = {
          value: dhv.balanceOf,
          decimal: dhv.decimals,
        };

        staked = {
          value: dhv.userPoolAmount,
          decimal: dhv.decimals,
        };
      }
    }

    return { balance, staked };
  }, [assets]);

  return (
    <section className={styles.card}>
      <div className={styles['card--title']}>
        <h3>{t('PORTFOLIO.PORTFOLIO_DHV.TITLE')}</h3>

        {chainId && DHV_LIST[chainId] && (
          <AddToken
            tooltipClassName={styles.titleAddTokenTooltip}
            tokenStandart={DHV_LIST[chainId].tokenStandart}
            tokenDecimals={DHV_LIST[chainId].dedcimals}
            tokenAddress={DHV_LIST[chainId].tokenAddress || ''}
            tokenSymbol={DHV_LIST[chainId].symbol}
            tokenIcon={UNDERLYING_LIST[DHV_LIST[chainId].symbol]?.icon || ''}
            className={styles.titleAddToken}
          />
        )}
      </div>

      <p className={styles['card--description']}>
        {t('PORTFOLIO.PORTFOLIO_DHV.DESCRIPTION')}
      </p>

      <div className={styles.balances}>
        <div className={styles.balance}>
          <h4 className={styles['balance--title']}>
            {t('PORTFOLIO.PORTFOLIO_DHV.BALANCE')}
          </h4>
          <p className={styles['balance--count']}>
            <span className={styles.value}>
              {dhvInfo.balance.value
                ? formatValueToken(
                    language,
                    Number(
                      formatUnits(
                        dhvInfo.balance.value,
                        dhvInfo.balance.decimal,
                      ),
                    ),
                  )
                : '-'}
              <img src={DHV_ICON} alt="Icon" />
            </span>
            <span className={styles.price}>
              {dhvInfo.balance.value && tokenPrices && tokenPrices[DHV_SYMBOL]
                ? formatValuePrice(
                    language,
                    getTokenPrice(
                      dhvInfo.balance.value,
                      dhvInfo.balance.decimal,
                      tokenPrices[DHV_SYMBOL],
                    ),
                  )
                : '-'}
            </span>
          </p>
        </div>

        <div className={styles.balance}>
          <h4 className={styles['balance--title']}>
            {t('PORTFOLIO.PORTFOLIO_DHV.STAKED')}
          </h4>
          <p className={styles['balance--count']}>
            <span className={styles.value}>
              {dhvInfo.staked.value
                ? formatValueToken(
                    language,
                    Number(
                      formatUnits(dhvInfo.staked.value, dhvInfo.staked.decimal),
                    ),
                  )
                : '-'}
              <img src={DHV_ICON} alt="Icon" />
            </span>
            <span className={styles.price}>
              {dhvInfo.staked.value && tokenPrices && tokenPrices[DHV_SYMBOL]
                ? formatValuePrice(
                    language,
                    getTokenPrice(
                      dhvInfo.staked.value,
                      dhvInfo.staked.decimal,
                      tokenPrices[DHV_SYMBOL],
                    ),
                  )
                : '-'}
            </span>
          </p>
        </div>
      </div>

      <nav className={styles['card--nav']}>
        <ul>
          <li>
            <ButtonLink
              size="large"
              text={t('PORTFOLIO.PORTFOLIO_DHV.STAKE')}
              path="/dhv"
            />
          </li>

          <li>
            <ButtonLink
              isExternal
              onClick={() =>
                trackGTMActions(
                  'Buy DHV',
                  'DHV',
                  'undefined',
                  chainId,
                  account,
                  'undefined',
                )
              }
              color="pink"
              size="large"
              text={t('PORTFOLIO.PORTFOLIO_DHV.BUY')}
              path={chainId ? buyDhvLinkList[chainId] : buyDhvLinkList[1]}
            />
          </li>

          <li>
            <ButtonLink
              isExternal
              onClick={() =>
                trackGTMActions(
                  'Claim DHV',
                  'DHV',
                  'undefined',
                  chainId,
                  account,
                  'undefined',
                )
              }
              color="blue"
              size="large"
              text={t('PORTFOLIO.PORTFOLIO_DHV.CLAIM')}
              path="https://dehive.finance/claim"
            />
          </li>

          <li>
            <PortfolioDHVModal />
          </li>
        </ul>
      </nav>
    </section>
  );
};

export default PortfolioDHV;
