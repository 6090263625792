import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { formatUnits } from '@ethersproject/units';
import cn from 'classnames';

import PortfolioMore from '@modules/portfolio/components/PortfolioMore';
import HoldingList from '@modules/common/components/HoldingList';
import { getCurrencySelector } from '@modules/prices/slices/currencySlice';
import {
  preErrorSelector as preErrorSelectorUnderlying,
  listSelector,
} from '@modules/underlying/slices/underlyingSlice';
import {
  poolsApyAprSelector,
  preErrorSelector as preErrorSelectorPoolsStats,
} from '@modules/pools/slices/poolsStatsSlice';
import { listSelector as listSelectorPools } from '@modules/pools/slices/poolsSlice';
import { getPrices } from '@modules/prices/slices/tokenPricesSlice';
import formatValuePercentage from '@modules/common/helpers/formatValuePercentage';
import formatValuePrice from '@modules/common/helpers/formatValuePrice';
import formatValueToken from '@modules/common/helpers/formatValueToken';
import getTokenPrice from '@modules/common/helpers/getTokenPrice';
import getPoolName from '@modules/pools/helpers/getPoolName';
import StoredAssetList from '@modules/portfolio/types/StoredAssetList';
import UnderlyingItem from '@modules/underlying/types/UnderlyingItem';
import { useTypedSelector } from '@utils/store';
import POOLS, { PoolUnderlying } from '@configs/pools';
import constants from '@modules/common/constants';

import styles from './PortfolioEarning.module.scss';

const PortfolioEarningRow: FC<{
  poolAddress: string;
  poolId: number;
  list: StoredAssetList;
}> = ({ poolAddress, poolId, list }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const underlyingList = useTypedSelector(listSelector);
  const poolsStats = useTypedSelector(poolsApyAprSelector);
  const currency = useTypedSelector(getCurrencySelector);
  const prices = useTypedSelector(getPrices);
  const pools = useTypedSelector(listSelectorPools);

  const { chainId } = useWeb3React<Web3Provider>();

  const getPrice = useCallback(
    (address: string, pid: number) => {
      let price = 0;

      if (chainId && list) {
        const { clusterPrice, symbol, decimals } = list[address][Number(pid)];

        if (clusterPrice && currency) {
          price =
            Number(formatUnits(clusterPrice, decimals)) *
            currency[chainId].price;
        }

        if (prices && !clusterPrice) {
          price =
            prices[
              POOLS[chainId][address][pid].asset ||
                POOLS[chainId][address][pid].symbol ||
                symbol
            ];
        }
      }
      return price;
    },
    [currency, chainId, prices, list],
  );

  return (
    <tr className={styles.bodyRow}>
      <td className={styles.bodyCell}>
        {chainId &&
          POOLS[chainId] &&
          POOLS[chainId][poolAddress] &&
          POOLS[chainId][poolAddress][poolId]?.icon && (
            <div
              className={cn(styles.bodyIcon, {
                [styles['bodyIcon--withSustrate']]:
                  POOLS[chainId][poolAddress][poolId].type !== 2 ||
                  POOLS[chainId][poolAddress][poolId].type !== 3,
              })}
            >
              <img
                className={styles.bodyIconImage}
                src={
                  POOLS[chainId][poolAddress][poolId].tokenIcon ||
                  POOLS[chainId][poolAddress][poolId].icon
                }
                alt={list[poolAddress][poolId].symbol}
              />
            </div>
          )}
      </td>

      <td className={cn(styles.bodyCell, styles['bodyCell--bold'])}>
        {list[poolAddress][poolId].userPoolAmount &&
        getPrice(poolAddress, poolId)
          ? formatValuePrice(
              language,
              getTokenPrice(
                list[poolAddress][poolId].userPoolAmount,
                list[poolAddress][poolId].decimals,
                getPrice(poolAddress, poolId),
              ),
            )
          : '-'}
      </td>

      <td className={styles.bodyCell}>
        {chainId &&
        POOLS[chainId] &&
        POOLS[chainId][poolAddress] &&
        POOLS[chainId][poolAddress][poolId]?.type === 4 ? (
          <HoldingList
            userPoolAmountInUnderlying={
              list[poolAddress][poolId].userPoolAmountInUnderlying
            }
            isVisibleHidden
            isHiddenComponent={
              POOLS[chainId][poolAddress][poolId]?.isHoldingListHidden
            }
            poolAddress={poolAddress}
            buttonClass={styles.detailsButton}
            buttonText={t('PORTFOLIO.PORTFOLIO_EARNING.DETAILS')}
            poolId={poolId}
          />
        ) : (
          <>
            {list[poolAddress][poolId].userPoolAmount
              ? formatValueToken(
                  language,
                  Number(
                    formatUnits(
                      list[poolAddress][poolId].userPoolAmount,
                      list[poolAddress][poolId].decimals,
                    ),
                  ),
                )
              : '-'}{' '}
            {pools &&
              pools[poolAddress] &&
              pools[poolAddress][poolId] &&
              getPoolName(
                poolAddress,
                underlyingList,
                poolId,
                pools[poolAddress][poolId],
                chainId,
              )}{' '}
            {chainId &&
              POOLS[chainId] &&
              POOLS[chainId][poolAddress] &&
              POOLS[chainId][poolAddress][poolId]?.type === 1 &&
              t('PORTFOLIO.PORTFOLIO_EARNING.POOL_POSTFIX')}
          </>
        )}
      </td>

      <td className={styles.bodyCell}>
        {chainId &&
        POOLS[chainId] &&
        POOLS[chainId][poolAddress] &&
        POOLS[chainId][poolAddress][poolId]?.type === 4 ? (
          <HoldingList
            poolAddress={poolAddress}
            buttonClass={styles.detailsButton}
            buttonText={t('PORTFOLIO.PORTFOLIO_EARNING.DETAILS')}
            poolId={poolId}
          />
        ) : (
          <>
            {list[poolAddress][poolId].balanceOf
              ? formatValueToken(
                  language,
                  Number(
                    formatUnits(
                      list[poolAddress][poolId].balanceOf,
                      list[poolAddress][poolId].decimals,
                    ),
                  ),
                )
              : '-'}{' '}
            {pools &&
              pools[poolAddress] &&
              pools[poolAddress][poolId] &&
              getPoolName(
                poolAddress,
                underlyingList,
                poolId,
                pools[poolAddress][poolId],
                chainId,
              )}{' '}
            {chainId &&
              POOLS[chainId] &&
              POOLS[chainId][poolAddress] &&
              POOLS[chainId][poolAddress][poolId]?.type === 1 &&
              t('PORTFOLIO.PORTFOLIO_EARNING.POOL_POSTFIX')}
          </>
        )}
      </td>

      <td className={styles.bodyCell}>
        {poolsStats &&
        chainId &&
        POOLS[chainId] &&
        POOLS[chainId][poolAddress] &&
        poolsStats[
          POOLS[chainId][poolAddress][poolId].symbol ||
            list[poolAddress][poolId].symbol
        ]?.apy
          ? formatValuePercentage(
              language,
              poolsStats[
                POOLS[chainId][poolAddress][poolId].symbol ||
                  list[poolAddress][poolId].symbol
              ].apy,
            )
          : '-%'}
      </td>

      <td className={styles.bodyCell}>
        <PortfolioMore poolGroupAddress={poolAddress} poolId={poolId} />
      </td>
    </tr>
  );
};

const PortfolioEarning: FC<{ list: StoredAssetList | null }> = ({ list }) => {
  const [attemptUnderlying, setAttemptUnderlying] = useState<number>(0);
  const [attemptPoolStats, setAttemptPoolStats] = useState(0);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const preErrorUnderlying = useTypedSelector(preErrorSelectorUnderlying);
  const preErrorPoolsStats = useTypedSelector(preErrorSelectorPoolsStats);

  const { chainId, account, library } = useWeb3React<Web3Provider>();

  const getUnderlyingList = useCallback(() => {
    if (chainId && library && POOLS[chainId]) {
      const itemList: UnderlyingItem[] = [];

      Object.keys(POOLS[chainId]).forEach((address: string) => {
        Object.keys(POOLS[chainId][address]).forEach((pid: string) => {
          if (POOLS[chainId][address][Number(pid)].type === 4) {
            POOLS[chainId][address][Number(pid)].underlying?.forEach(
              (item: PoolUnderlying) => {
                itemList.push({
                  underlyingABI: POOLS[chainId][address][Number(pid)].tokenABI,
                  address: item.address,
                });
              },
            );
          }
        });
      });

      dispatch({
        type: 'UNDERLYING_GET_UNDERLYING_LIST_REQUESTED',
        payload: { underlyingList: itemList, library, account },
      });
    }
  }, [dispatch, chainId, account, library]);

  const getPoolsStats = useCallback(() => {
    if (POOLS && chainId) {
      dispatch({
        type: 'POOLS_GET_POOL_STATS',
        payload: {
          pools: Object.values(POOLS[chainId])
            .map((element) => Object.values(element))
            .flat()
            .filter(({ symbol }) => symbol)
            .map(({ symbol }) => symbol),
          chainId,
        },
      });
    }
  }, [dispatch, chainId]);

  useEffect(() => {
    getPoolsStats();

    const reGetPoolsStats = setInterval(
      () => getPoolsStats(),
      constants.GET_ITEMS_INTERVAL,
    );

    return () => clearInterval(reGetPoolsStats);
  }, [getPoolsStats]);

  useEffect(() => {
    if (preErrorPoolsStats && attemptPoolStats < constants.GET_ITEMS_ATTEMPTS) {
      getPoolsStats();

      setAttemptPoolStats(attemptPoolStats + 1);
    }

    if (
      preErrorPoolsStats &&
      attemptPoolStats >= constants.GET_ITEMS_ATTEMPTS
    ) {
      dispatch({
        type: 'POOLS_SET_ERROR_POOLS_STATS',
        payload: { error: preErrorPoolsStats },
      });
    }
  }, [preErrorPoolsStats, getPoolsStats, dispatch, attemptPoolStats]);

  useEffect(() => {
    getUnderlyingList();

    const reGetUnderlyingList = setInterval(
      () => getUnderlyingList(),
      constants.GET_ITEMS_INTERVAL,
    );

    return () => clearInterval(reGetUnderlyingList);
  }, [getUnderlyingList]);

  useEffect(() => {
    if (
      preErrorUnderlying &&
      attemptUnderlying < constants.GET_ITEMS_ATTEMPTS
    ) {
      getUnderlyingList();

      setAttemptUnderlying(attemptUnderlying + 1);
    }

    if (
      preErrorUnderlying &&
      attemptUnderlying >= constants.GET_ITEMS_ATTEMPTS
    ) {
      dispatch({
        type: 'UNDERLYING_SET_ERROR_UNDERLYING_REQUESTED',
        payload: { error: preErrorUnderlying },
      });
    }
  }, [preErrorUnderlying, getUnderlyingList, dispatch, attemptUnderlying]);

  return list && chainId ? (
    <table className={styles.container}>
      <thead>
        <tr>
          <th className={styles.headCell}>
            {t('PORTFOLIO.PORTFOLIO_EARNING.LOGO')}
          </th>
          <th className={styles.headCell}>
            {t('PORTFOLIO.PORTFOLIO_EARNING.STAKED_USD')}
          </th>
          <th className={styles.headCell}>
            {t('PORTFOLIO.PORTFOLIO_EARNING.STAKED')}
          </th>
          <th className={styles.headCell}>
            {t('PORTFOLIO.PORTFOLIO_EARNING.AVAILABLE')}
          </th>
          <th className={styles.headCell}>
            {t('PORTFOLIO.PORTFOLIO_EARNING.APY')}
          </th>
          <th className={styles.headCell}>
            {t('PORTFOLIO.PORTFOLIO_EARNING.MORE')}
          </th>
        </tr>
      </thead>

      <tbody>
        {Object.keys(list)?.map((address: string) => {
          return Object.keys(list[address])
            ?.filter(
              (pid) =>
                (POOLS[chainId] &&
                  POOLS[chainId][address] &&
                  POOLS[chainId][address][Number(pid)]?.type === 0) ||
                false,
            )
            ?.map((pid: string) => (
              <PortfolioEarningRow
                poolAddress={address}
                poolId={Number(pid)}
                list={list}
                key={`${address}_${pid}`}
              />
            ));
        })}

        {Object.keys(list)?.map((address: string) => {
          return Object.keys(list[address])
            ?.filter(
              (pid) =>
                (POOLS[chainId] &&
                  POOLS[chainId][address] &&
                  POOLS[chainId][address][Number(pid)]?.type === 1) ||
                false,
            )
            ?.map((pid: string) => (
              <PortfolioEarningRow
                poolAddress={address}
                poolId={Number(pid)}
                list={list}
                key={`${address}_${pid}`}
              />
            ));
        })}

        {Object.keys(list)?.map((address: string) => {
          return Object.keys(list[address])
            ?.filter(
              (pid) =>
                (POOLS[chainId] &&
                  POOLS[chainId][address] &&
                  POOLS[chainId][address][Number(pid)]?.type === 2) ||
                false,
            )
            ?.map((pid: string) => (
              <PortfolioEarningRow
                poolAddress={address}
                poolId={Number(pid)}
                list={list}
                key={`${address}_${pid}`}
              />
            ));
        })}

        {Object.keys(list)?.map((address: string) => {
          return Object.keys(list[address])
            ?.filter(
              (pid) =>
                (POOLS[chainId] &&
                  POOLS[chainId][address] &&
                  POOLS[chainId][address][Number(pid)]?.type === 4) ||
                false,
            )
            ?.map((pid: string) => (
              <PortfolioEarningRow
                poolAddress={address}
                poolId={Number(pid)}
                list={list}
                key={`${address}_${pid}`}
              />
            ));
        })}

        {Object.keys(list)?.map((address: string) => {
          return Object.keys(list[address])
            ?.filter(
              (pid) =>
                (POOLS[chainId] &&
                  POOLS[chainId][address] &&
                  POOLS[chainId][address][Number(pid)]?.type === 3) ||
                false,
            )
            ?.map((pid: string) => (
              <PortfolioEarningRow
                poolAddress={address}
                poolId={Number(pid)}
                list={list}
                key={`${address}_${pid}`}
              />
            ));
        })}
      </tbody>
    </table>
  ) : null;
};

export default PortfolioEarning;
