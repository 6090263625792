import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { Tooltip } from 'antd';
import cn from 'classnames';

import ClusterCardBalances from '@modules/clusters/components/ClusterCardBalances';
import ClusterCardStats from '@modules/clusters/components/ClusterCardStats';
import ClusterCardChart from '@modules/clusters/components/ClusterCardChart';
import UnderlyingList from '@modules/clusters/components/UnderlyingList';
import CardLabel from '@modules/common/components/CardLabel';
import AddToken from '@modules/common/components/AddToken';
import getActualNetworkName from '@modules/common/helpers/getActualNetworkName';
import useMediaQuery from '@modules/layout/hooks/useMediaQuery';
import StoredCluster from '@modules/clusters/types/StoredCluster';
import ButtonLink from '@modules/common/components/ButtonLink';
import INFO_ICON from '@modules/common/assets/info_icon.svg';
import CLUSTERS from '@configs/clusters';

import styles from '@modules/clusters/components/ClusterCard/ClusterCard.module.scss';

const ClusterCard: FC<{
  clusterAddress: string;
  className: string;
  cluster: StoredCluster;
  isFake?: boolean;
}> = ({ clusterAddress, className, cluster, isFake }) => {
  const { t } = useTranslation();

  const { chainId } = useWeb3React();

  const isMobile = useMediaQuery(991);

  return chainId && CLUSTERS[chainId] && CLUSTERS[chainId][clusterAddress] ? (
    <article
      className={cn(
        styles.container,
        { [styles['is-fake']]: isFake },
        className,
      )}
    >
      <CardLabel
        className={styles.label}
        platform={CLUSTERS[chainId][clusterAddress].platform}
        network={getActualNetworkName(chainId)}
      />

      <div className={styles.containerTop}>
        <Link
          className={styles.logo}
          to={`/clusters/${CLUSTERS[chainId][clusterAddress].path}`}
        >
          <img
            className={styles.logoImage}
            height="90"
            width="90"
            src={CLUSTERS[chainId][clusterAddress].icon}
            alt={cluster.symbol}
          />
        </Link>

        <section>
          <div className={styles.title}>
            <Link
              className={styles.titleLink}
              to={`/clusters/${CLUSTERS[chainId][clusterAddress].path}`}
            >
              <h3 className={styles.titleText}>
                {CLUSTERS[chainId][clusterAddress].name || cluster.name}
              </h3>
            </Link>

            <AddToken
              tooltipClassName={styles.titleAddTokenTooltip}
              tokenStandart={CLUSTERS[chainId][clusterAddress].tokenStandart}
              tokenDecimals={cluster.decimals}
              tokenAddress={clusterAddress}
              tokenSymbol={cluster.symbol}
              tokenIcon={
                window.location.origin +
                CLUSTERS[chainId][clusterAddress].tokenIcon
              }
              className={styles.titleAddToken}
            />
          </div>

          <div className={styles.description}>
            <p className={styles.descriptionText}>
              {CLUSTERS[chainId][clusterAddress].shortDescription}
            </p>

            <Tooltip
              overlayClassName={styles.descriptionTooltip}
              placement="topLeft"
              trigger={isMobile ? 'click' : 'hover'}
              title={CLUSTERS[chainId][clusterAddress].prompt}
            >
              <img
                className={styles.descriptionPrompt}
                height="18"
                width="16"
                src={INFO_ICON}
                alt={CLUSTERS[chainId][clusterAddress].prompt}
              />
            </Tooltip>
          </div>

          <ClusterCardBalances className={styles.balances} cluster={cluster} />

          <ClusterCardStats symbol={cluster.symbol} />
        </section>

        <ClusterCardChart className={styles.chart} name={cluster.name} />
      </div>

      <div className={styles.containerBottom}>
        <UnderlyingList
          clusterUnderlyingList={cluster.underlyingList}
          className={styles.underlying}
        />

        <footer className={styles.footer}>
          <ButtonLink
            className={styles.footerLink}
            text={t('CLUSTERS.CLUSTER_CARD.GET_STARTED')}
            path={`/clusters/${CLUSTERS[chainId][clusterAddress].path}`}
          />
        </footer>
      </div>

      {isFake && (
        <span className={styles.comingSoon}>
          {t('CLUSTERS.CLUSTER_CARD.COMING_SOON')}
        </span>
      )}
    </article>
  ) : null;
};

ClusterCard.defaultProps = { isFake: false };

export default ClusterCard;
