import * as Sentry from '@sentry/react';
import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import {
  CallEffect,
  PutEffect,
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { JsonRpcProvider } from '@ethersproject/providers';
import {
  ContractInterface,
  Contract,
  ContractTransaction,
  ContractReceipt,
} from '@ethersproject/contracts';
import { BigNumber } from '@ethersproject/bignumber';

import {
  setIsLoading,
  setValue,
  setError,
  setIsSuccess,
} from '@modules/dhv/slices/dhvSwapAllowanceSlice';
import approveTokenAction from '@modules/dhv/actions/approveTokenAction';

function* approveTokenWorker({
  payload,
}: PayloadAction<{
  routerAddress: string;
  dhvAddress: string;
  tokenAbi: ContractInterface;
  amountIn: BigNumber;
  library: JsonRpcProvider;
  account: string;
}>): Generator<
  | CallEffect<Promise<ContractReceipt>>
  | CallEffect<unknown>
  | PutEffect<AnyAction>,
  void,
  never
> {
  try {
    yield put(setIsLoading(true));
    yield put(setError(null));
    yield put(setIsSuccess(false));

    const tokenContract = new Contract(
      payload.dhvAddress,
      payload.tokenAbi,
      payload.library.getSigner(payload.account),
    );

    const { wait }: ContractTransaction = yield call(
      approveTokenAction,
      tokenContract,
      payload.routerAddress,
      payload.amountIn,
    );

    yield call(wait, 1);

    yield put(setValue(payload.amountIn));
    yield put(setIsSuccess(true));
  } catch (error: unknown) {
    yield put(setError(error));
    yield put(setIsSuccess(false));
    Sentry.captureException(
      `Approve DHV swap error,
      ' spender address ${payload.routerAddress}',
      ' amount ${Number(payload.amountIn)}',
      ' account ${payload.account}'`,
    );
  } finally {
    yield put(setIsLoading(false));
  }
}

function* approveTokenSaga(): Generator {
  yield takeLatest('SWAP_APPROVE_TOKEN', approveTokenWorker);
}

export default approveTokenSaga;
