import { NetworkConnector } from '@web3-react/network-connector';
import { useWeb3React } from '@web3-react/core';

import Connector from '@modules/connections/types/Connector';
import getRPC from '@modules/connections/helpers/getRPC';

const Network = (chainId: number): (() => Promise<void>) => {
  const { activate } = useWeb3React();

  const network = new NetworkConnector({
    urls: { [chainId]: getRPC(chainId) },
    defaultChainId: chainId,
  });

  return () => activate(network);
};

const NetworkDisconnect = (): (() => void) => {
  const { deactivate } = useWeb3React();

  return () => deactivate();
};

const connector: Connector = {
  type: 'network',
  name: 'Infura',
  icon: '',
  connect: Network,
  disconnect: NetworkDisconnect,
};

export default connector;
