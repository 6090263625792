import PrivacyPolicy from '@modules/info/components/PrivacyPolicy';

const page = {
  path: '/privacy-policy',
  menu: 2,
  order: 8,
  title: 'INFO.PRIVACY_POLICY.NAV_LINK_NAME',
  component: PrivacyPolicy,
};

export default page;
