import ClustersList from '@modules/clusters/components/ClustersList';
import ICON from '@modules/clusters/assets/pages/clusters_logo.svg';

const page = {
  path: '/clusters',
  menu: 1,
  icon: ICON,
  order: 1,
  title: 'CLUSTERS.CLUSTERS_LIST.NAV_LINK_NAME',
  parent: 0,
  component: ClustersList,
};

export default page;
