import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ButtonLink from '@modules/common/components/ButtonLink';
import pages from '@utils/pages';

import styles from '@modules/layout/components/Footer/Footer.module.scss';

const Footer: FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <footer className={className}>
      <ul className={styles.list}>
        {pages.map(({ path, title, menu }) =>
          menu === 2 ? (
            <li className={styles.listItem} key={path}>
              <Link className={styles.listLink} to={path}>
                {t(title)}
              </Link>
            </li>
          ) : null,
        )}

        <li className={styles.listItem}>
          <ButtonLink
            isExternal
            className={styles.listButton}
            color="gray"
            size="small"
            text={t('LAYOUT.FOOTER.GO_TO_WEBSITE')}
            path={process.env.REACT_APP_SITE || ''}
          />
        </li>
      </ul>
    </footer>
  );
};

Footer.defaultProps = { className: undefined };

export default Footer;
