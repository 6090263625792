import NETWORKS from '@configs/networks';

const getActualNetworkName = (id: number): string => {
  const networkName: string | undefined = NETWORKS.find(({ chainId }) => {
    return chainId === id;
  })?.name;

  return networkName || 'Unknown';
};

export default getActualNetworkName;
