import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import cn from 'classnames';

import formatValueToken from '@modules/common/helpers/formatValueToken';
import StoredCluster from '@modules/clusters/types/StoredCluster';

import styles from './ClusterModalFee.module.scss';

const ClusterModalFee: FC<{
  commissionValue: BigNumber | null;
  className?: string;
  decimals: StoredCluster['decimals'];
  symbol: StoredCluster['symbol'];
}> = ({ commissionValue, className, decimals, symbol }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <dl className={cn(styles.container, className)}>
      <dt className={styles.title}>{t('CLUSTERS.CLUSTER_MODAL_FEE.TITLE')}</dt>

      <dd className={styles.value}>
        {commissionValue
          ? formatValueToken(
              language,
              Number(formatUnits(commissionValue, decimals)),
            )
          : '0'}{' '}
        {symbol}
      </dd>
    </dl>
  );
};

ClusterModalFee.defaultProps = { className: undefined };

export default ClusterModalFee;
