import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@utils/store';

import StoredCurrencyList from '@modules/prices/types/StoredCurrencyList';

interface InitialState {
  isLoading: boolean;
  preError: unknown | null;
  error: unknown | null;
  currency: StoredCurrencyList | null;
}

const initialState: InitialState = {
  isLoading: false,
  preError: null,
  error: null,
  currency: null,
};

const reducers = {
  setIsLoading(state: InitialState, action: PayloadAction<boolean>) {
    state.isLoading = action.payload;
  },
  setPreError(state: InitialState, action: PayloadAction<unknown | null>) {
    state.preError = action.payload;
  },
  setError(state: InitialState, action: PayloadAction<unknown | null>) {
    state.error = action.payload;
  },
  setCurrency(state: InitialState, action: PayloadAction<StoredCurrencyList>) {
    state.currency = action.payload;
  },
};

const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers,
});

export const isLoadingSelector = (state: RootState): boolean =>
  state.currency.isLoading;
export const preErrorSelector = (state: RootState): unknown | null =>
  state.currency.preError;
export const errorSelector = (state: RootState): unknown | null =>
  state.currency.error;
export const getCurrencySelector = (
  state: RootState,
): StoredCurrencyList | null => state.currency.currency;

export const { setIsLoading, setPreError, setError, setCurrency } =
  currencySlice.actions;

export default currencySlice.reducer;
