import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatUnits } from '@ethersproject/units';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';

import PoolStakeForm from '@modules/pools/components/PoolStakeForm';
import ActionModal from '@modules/common/components/ActionModal';
import StoredPool from '@modules/pools/types/StoredPool';
import {
  confirmedListSelector,
  pendingListSelector,
  successListSelector,
  valueListSelector,
} from '@modules/pools/slices/stakeSlice';
import { listSelector } from '@modules/underlying/slices/underlyingSlice';
import { listSelector as listSelectorClusters } from '@modules/clusters/slices/clustersSlice';
import { getPrices } from '@modules/prices/slices/tokenPricesSlice';
import { getCurrencySelector } from '@modules/prices/slices/currencySlice';
import formatValueToken from '@modules/common/helpers/formatValueToken';
import formatValuePrice from '@modules/common/helpers/formatValuePrice';
import getTokenPrice from '@modules/common/helpers/getTokenPrice';
import trackGTMActions from '@modules/common/helpers/trackGTMActions';
import getPoolSymbol from '@modules/pools/helpers/getPoolSymbol';
import getPoolName from '@modules/pools/helpers/getPoolName';
import UnderlyingFormItem from '@modules/pools/types/UnderlyingFormItem';
import { useTypedSelector } from '@utils/store';
import constants from '@modules/pools/constants';
import STAKE_ICON from '@modules/pools/assets/stake_icon.svg';
import POOLS, { PoolUnderlying, Pool } from '@configs/pools';
import useMediaQuery from '@modules/layout/hooks/useMediaQuery';

const defineModalWidth = (isMultyPool: boolean, isMobile: boolean): number => {
  if (isMultyPool && isMobile) {
    return 290;
  }

  if (isMultyPool && !isMobile) {
    return 586;
  }

  if (isMobile) {
    return 290;
  }

  return 455;
};

const getDefaultTitle = (poolType: number): string => {
  if (poolType === 1) {
    return 'POOLS.POOL_STAKE.DEFAULT_TITLE_CLUSTER';
  }

  if (poolType === 4) {
    return 'POOLS.POOL_STAKE.DEFAULT_TITLE_STABLE';
  }

  return 'POOLS.POOL_STAKE.DEFAULT_TITLE';
};

const PoolStake: FC<{
  isClosed: Pool['closed'];
  className?: string;
  buttonSize?: 'big' | 'small' | 'medium';
  underlying?: PoolUnderlying[];
  poolAddress: string;
  poolId: number;
  pool: StoredPool;
}> = ({
  isClosed,
  className,
  buttonSize,
  poolAddress,
  underlying,
  poolId,
  pool,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const isMobile = useMediaQuery(991);

  const underlyingList = useTypedSelector(listSelector);
  const confirmedList = useTypedSelector(confirmedListSelector);
  const pendingList = useTypedSelector(pendingListSelector);
  const successList = useTypedSelector(successListSelector);
  const valueList = useTypedSelector(valueListSelector);
  const clusters = useTypedSelector(listSelectorClusters);
  const currency = useTypedSelector(getCurrencySelector);
  const priceList = useTypedSelector(getPrices);

  const { chainId, account } = useWeb3React<Web3Provider>();

  const underlyingFormList: UnderlyingFormItem[] = useMemo(() => {
    if (underlying) {
      const list: UnderlyingFormItem[] = [];

      underlying?.forEach(({ address, isHidden }: PoolUnderlying) => {
        if (underlyingList && underlyingList[address]) {
          list.push({
            tokenAddress: address,
            userBalance: underlyingList[address].userBalance,
            isHidden: isHidden || false,
            decimals: underlyingList[address].decimals,
            symbol: underlyingList[address].symbol,
          });
        }
      });

      return list;
    }

    return [
      {
        tokenAddress: pool.tokenAddress,
        userBalance: pool.userBalance,
        isHidden: false,
        decimals: pool.decimals,
        symbol: pool.symbol,
      },
    ];
  }, [pool, underlying, underlyingList]);

  const successValue: string = useMemo(() => {
    let value = 0;

    valueList[poolAddress][poolId]?.forEach((item, index: number) => {
      if (item) {
        value += Number(formatUnits(item, underlyingFormList[index].decimals));
      }
    });

    return formatValueToken(language, value);
  }, [poolAddress, poolId, language, valueList, underlyingFormList]);

  const successPrice: string = useMemo(() => {
    let value = 0;

    valueList[poolAddress][poolId]?.forEach((item, index: number) => {
      if (
        item &&
        chainId &&
        priceList &&
        POOLS[chainId][poolAddress][poolId] &&
        POOLS[chainId][poolAddress][poolId].type === 4 &&
        !underlyingFormList[index].isHidden &&
        underlyingFormList[index].decimals &&
        priceList[
          // TODO: temporary hot fix for MAI
          underlyingFormList[index].symbol === 'miMATIC'
            ? 'MAI'
            : underlyingFormList[index].symbol
        ]
      ) {
        value += getTokenPrice(
          item,
          underlyingFormList[index].decimals,
          priceList[
            // TODO: temporary hot fix for MAI
            underlyingFormList[index].symbol === 'miMATIC'
              ? 'MAI'
              : underlyingFormList[index].symbol
          ],
        );
      } else if (
        item &&
        chainId &&
        priceList &&
        POOLS[chainId][poolAddress][poolId] &&
        POOLS[chainId][poolAddress][poolId].type !== 4 &&
        priceList[
          POOLS[chainId][poolAddress][poolId].asset ||
            POOLS[chainId][poolAddress][poolId].symbol
        ]
      ) {
        value += getTokenPrice(
          item,
          underlyingFormList[index].decimals,
          priceList[
            POOLS[chainId][poolAddress][poolId].asset ||
              POOLS[chainId][poolAddress][poolId].symbol
          ],
        );
      } else if (
        clusters &&
        clusters[underlyingFormList[0].tokenAddress] &&
        chainId &&
        currency
      ) {
        value += getTokenPrice(
          item,
          underlyingFormList[index].decimals,
          getTokenPrice(
            clusters[underlyingFormList[0].tokenAddress].price,
            clusters[underlyingFormList[0].tokenAddress].decimals,
            currency[chainId].price,
          ),
        );
      }
    });

    return formatValuePrice(language, value);
  }, [
    valueList,
    poolAddress,
    poolId,
    underlyingFormList,
    priceList,
    clusters,
    language,
    chainId,
    currency,
  ]);

  useEffect(() => {
    if (successList[poolAddress][poolId] && chainId) {
      trackGTMActions(
        'Stake',
        POOLS[chainId][poolAddress][poolId].symbol || '',
        POOLS[chainId][poolAddress][poolId].type || 1,
        chainId,
        account,
        'fact',
      );
    }
  }, [account, chainId, poolAddress, poolId, successList]);

  return (
    <ActionModal
      dispatchObject={{
        type: 'POOLS_RESET_STAKE_POOL_REQUESTED',
        payload: { poolAddress, poolId },
      }}
      defaultTitle={t(getDefaultTitle(pool.type || 0), {
        name: getPoolName(poolAddress, underlyingList, poolId, pool, chainId),
      })}
      successTitle={
        pool.type === 4
          ? `${t('POOLS.POOL_STAKE.SUCCESS_FULL_INFO_TITLE', {
              poolName: getPoolName(
                poolAddress,
                underlyingList,
                poolId,
                pool,
                chainId,
              ),
            })}`
          : t('POOLS.POOL_STAKE.SUCCESS_TITLE', {
              name: getPoolName(
                poolAddress,
                underlyingList,
                poolId,
                pool,
                chainId,
              ),
            })
      }
      successValue={pool.type === 4 ? undefined : successValue}
      successPrice={successPrice}
      successSymbol={getPoolSymbol(
        poolAddress,
        underlyingList,
        pool.symbol,
        poolId,
        chainId,
      )}
      buttonClassName={className}
      buttonTitle={t('POOLS.POOL_STAKE.BUTTON_TITLE')}
      buttonSize={buttonSize}
      isConfirmed={confirmedList[poolAddress][poolId]}
      isDisabled={isClosed}
      isPending={pendingList[poolAddress][poolId]}
      isSuccess={successList[poolAddress][poolId]}
      modalAddress={`${poolId}_${poolAddress}`}
      modalPath={constants.MODAL_PATH_STAKE}
      modalIcon={STAKE_ICON}
      isEllipse
      modalWidth={defineModalWidth(pool.type === 4, isMobile)}
      platformIcon={
        (chainId && POOLS[chainId]?.[poolAddress]?.[poolId]?.platformIcon) ||
        undefined
      }
    >
      <PoolStakeForm
        userTotalLockedDHV={pool.userTotalLockedDHV}
        userPoolAmountDHV={pool.userPoolAmountDHV}
        userBalanceDHV={pool.userBalanceDHV}
        decimalsDHV={pool.decimalsDHV}
        symbolDHV={pool.symbolDHV}
        сlusterRateAccuracy={pool.сlusterRateAccuracy}
        clusterRate={pool.clusterRate}
        allowance={pool.allowance}
        underlyingFormList={underlyingFormList}
        poolAddress={poolAddress}
        poolName={getPoolName(
          poolAddress,
          underlyingList,
          poolId,
          pool,
          chainId,
        )}
        poolType={pool.type}
        poolId={poolId}
        isDisabled={isClosed}
        isLoading={
          !!(
            confirmedList[poolAddress][poolId] ||
            pendingList[poolAddress][poolId]
          )
        }
      />
    </ActionModal>
  );
};

PoolStake.defaultProps = {
  className: undefined,
  buttonSize: undefined,
  underlying: undefined,
};

export default PoolStake;
