import { FC } from 'react';

// Main pages:
import main from '@modules/main/pages/main';
// Cluster pages:
import clusterDetails from '@modules/clusters/pages/clusterDetails';
import clustersList from '@modules/clusters/pages/clustersList';
// DHV pages:
import dhv from '@modules/dhv/pages/dhv';
// Pool pages:
import impulseList from '@modules/pools/pages/impulseList';
import stableList from '@modules/pools/pages/stableList';
// Portfolio pages:
import portfolio from '@modules/portfolio/pages/portfolio';
// Info pages:
import privacyPolicy from '@modules/info/pages/privacyPolicy';
import cookieNotice from '@modules/info/pages/cookieNotice';
import termsOfUse from '@modules/info/pages/termsOfUse';
// Error pages:
import pageNotFound from '@modules/static/pages/pageNotFound';

export interface Page {
  path: string;
  menu?: number;
  icon?: string;
  order: number;
  title: string;
  parent?: number;
  component: FC;
}

const pages: Page[] = [
  // Main pages:
  main,
  // Cluster pages:
  clusterDetails,
  clustersList,
  // DHV pages:
  dhv,
  // Pool pages:
  impulseList,
  stableList,
  // Portfolio pages:
  portfolio,
  // Info pages:
  privacyPolicy,
  cookieNotice,
  termsOfUse,
  // Error pages:
  pageNotFound,
].sort((a: Page, b: Page) => a.order - b.order);

export default pages;
