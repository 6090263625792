const GET_ITEMS_ATTEMPTS = 5;

const GET_ITEMS_INTERVAL = 30000;

const INPUT_MINIMAL_STEP = '0.000001';
const INPUT_MINIMAL_UNSTAKE_STEP = '0.000000000000000001';

export default {
  GET_ITEMS_ATTEMPTS,
  GET_ITEMS_INTERVAL,
  INPUT_MINIMAL_STEP,
  INPUT_MINIMAL_UNSTAKE_STEP,
};
