import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import { InputNumber, Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import cn from 'classnames';

import onInputFormatter from '@modules/common/helpers/onInputFormatter';
import checkMaxValue from '@modules/common/helpers/checkMaxValue';
import constants from '@modules/common/constants';
import UNDERLYING_LIST from '@configs/underlying';

import styles from './FormInput.module.scss';

type AdditionalValidation = (value: string) => void;

const { Item } = Form;

const onInputValidator = (
  isNoValidateMax: boolean | undefined,
  value: string,
  decimals: number | undefined,
  minValue: string,
  maxValue?: BigNumber | null,
  additionalValidation?: AdditionalValidation,
): Promise<string> => {
  return new Promise((resolve) => {
    const valueDecimals = Number(value) ? value.split('.')[1]?.length : null;

    if (valueDecimals && decimals && valueDecimals > decimals) {
      throw new Error(`Decimals of the amount must be less than ${decimals}!`);
    }

    if (
      value &&
      BigNumber.from(parseUnits(value, decimals)).lt(
        BigNumber.from(parseUnits(minValue, decimals)),
      )
    ) {
      throw new Error(`Amount must be more than ${minValue}`);
    }

    if (!isNoValidateMax && checkMaxValue(value, decimals, maxValue)) {
      throw new Error(
        `Amount must be less than ${
          maxValue ? formatUnits(maxValue, decimals) : 0
        }`,
      );
    }

    if (additionalValidation) {
      additionalValidation(value);
    }

    resolve('success');
  });
};

const FormInput: FC<{
  minValueForValidation?: string;
  additionalValidation?: AdditionalValidation;
  isNoValidateMax?: boolean;
  isHasMaxButton?: boolean;
  tokenSymbol?: string;
  isDisabled: boolean;
  className?: string;
  inputName: string;
  maxValue?: BigNumber | null;
  minValue?: string;
  decimals?: number;
  isShort?: boolean;
  isDark?: boolean;
  form: FormInstance;
}> = ({
  minValueForValidation,
  additionalValidation,
  isNoValidateMax,
  isHasMaxButton,
  tokenSymbol,
  isDisabled,
  className,
  inputName,
  maxValue,
  minValue,
  decimals,
  isShort,
  isDark,
  form,
}) => {
  const { t } = useTranslation();

  const handleMaxButton = useCallback(() => {
    form.setFieldsValue({
      [inputName]: maxValue ? formatUnits(maxValue, decimals) : '0',
    });

    form.validateFields().catch(() => false);
  }, [inputName, maxValue, decimals, form]);

  return (
    <div className={cn(styles.container, className)}>
      {tokenSymbol && (
        <div
          className={cn(styles.token, {
            [styles['is-disabled']]: isDisabled,
            [styles['token--dark']]: isDark,
          })}
          style={{
            backgroundColor:
              (!isDark && !isDisabled && UNDERLYING_LIST[tokenSymbol].color) ||
              undefined,
          }}
        >
          <img
            className={styles.tokenIcon}
            src={UNDERLYING_LIST[tokenSymbol].icon}
            alt={tokenSymbol}
          />

          {!isDark && <span className={styles.tokenText}>{tokenSymbol}</span>}
        </div>
      )}

      <Item
        className={styles.item}
        rules={[
          {
            required: true,
            message: t('COMMON.FORM_INPUT.VALIDATION_ERROR_EMPTY'),
          },
          {
            validator: (rule, value: string) =>
              onInputValidator(
                isNoValidateMax,
                value,
                decimals,
                Number(minValueForValidation || minValue) >= 0
                  ? minValueForValidation || minValue || '0'
                  : constants.INPUT_MINIMAL_STEP,
                maxValue,
                additionalValidation,
              ),
          },
        ]}
        name={inputName}
      >
        <InputNumber
          stringMode
          className={cn(styles.input, {
            [styles['is-disabled']]: isDisabled,
            [styles['input--dark']]: isDark,
          })}
          formatter={onInputFormatter}
          bordered={false}
          disabled={isDisabled || !decimals}
          step={constants.INPUT_MINIMAL_STEP}
          min={
            Number(minValue) >= 0
              ? Number(minValue).toString()
              : constants.INPUT_MINIMAL_STEP
          }
        />
      </Item>

      {isHasMaxButton && (
        <button
          className={cn(styles.button, {
            [styles['button--short']]: isShort,
            [styles['button--dark']]: isDark,
          })}
          disabled={isDisabled || !decimals}
          onClick={handleMaxButton}
          type="button"
        >
          {t('COMMON.FORM_INPUT.BUTTON_MAX')}
        </button>
      )}
    </div>
  );
};

FormInput.defaultProps = {
  minValueForValidation: undefined,
  additionalValidation: undefined,
  isNoValidateMax: undefined,
  isHasMaxButton: true,
  tokenSymbol: undefined,
  className: undefined,
  maxValue: undefined,
  minValue: undefined,
  decimals: undefined,
  isShort: false,
  isDark: false,
};

export default FormInput;
