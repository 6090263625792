import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';

import WalletConnections from '@modules/connections/components/WalletConnections';
import Button from '@modules/common/components/Button';

const FormSubmit: FC<{
  isDisabled: boolean;
  isLoading: boolean;
  className?: string;
  color?: 'gradient' | 'pink' | 'polygon' | 'azure' | 'blue';
  name: string;
  size?: 'large' | 'big' | 'sbig' | 'medium' | 'small';
}> = ({ isDisabled, isLoading, className, color, name, size }) => {
  const { t } = useTranslation();

  const { account } = useWeb3React();

  return account ? (
    <Button
      className={className}
      isLoading={isLoading}
      isSubmit
      disabled={isDisabled || isLoading}
      color={color}
      text={name}
      size={size}
    />
  ) : (
    <WalletConnections
      textButton={t('COMMON.FORM_SUBMIT.CONNECT_WALLET')}
      className={className}
    />
  );
};

FormSubmit.defaultProps = {
  className: undefined,
  color: 'gradient',
  size: 'big',
};

export default FormSubmit;
