import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';

import { getPrices } from '@modules/prices/slices/tokenPricesSlice';
import formatValueToken from '@modules/common/helpers/formatValueToken';
import formatValuePrice from '@modules/common/helpers/formatValuePrice';
import getTokenPrice from '@modules/common/helpers/getTokenPrice';
import StoredPool from '@modules/pools/types/StoredPool';
import { useTypedSelector } from '@utils/store';

import styles from './PoolStakeFormDHV.module.scss';

const PoolStakeFormDHV: FC<{
  userPoolAmountDHV: StoredPool['userPoolAmountDHV'];
  clusterRate: StoredPool['clusterRate'];
  decimalsDHV: StoredPool['decimalsDHV'];
  symbolDHV: StoredPool['symbolDHV'];
  poolName: StoredPool['name'];
}> = ({ userPoolAmountDHV, clusterRate, decimalsDHV, symbolDHV, poolName }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const prices = useTypedSelector(getPrices);

  return clusterRate?.gt(BigNumber.from('0')) ? (
    <dl className={styles.balance}>
      <div className={styles.balanceItem}>
        <dt className={styles.title}>
          {t('POOLS.POOL_STAKE_FORM_DHV.IN_WALLET_BALANCE', {
            symbol: symbolDHV,
          })}
        </dt>
        <dd className={styles.value}>
          {userPoolAmountDHV && decimalsDHV
            ? formatValueToken(
                language,
                Number(formatUnits(userPoolAmountDHV, decimalsDHV)),
              )
            : '-'}{' '}
          {symbolDHV}
        </dd>
      </div>

      <div className={styles.balanceItem}>
        <dt className={styles.title}>
          {t('POOLS.POOL_STAKE_FORM_DHV.STAKED_BALANCE', { symbol: symbolDHV })}
        </dt>
        <dd className={styles.value}>
          {prices &&
          symbolDHV &&
          prices[symbolDHV] &&
          userPoolAmountDHV &&
          decimalsDHV
            ? formatValuePrice(
                language,
                getTokenPrice(
                  userPoolAmountDHV,
                  decimalsDHV,
                  Number(prices[symbolDHV]),
                ),
              )
            : '-'}
        </dd>
      </div>
    </dl>
  ) : (
    <p className={styles.text}>
      {t('POOLS.POOL_STAKE_FORM_DHV.NOT_NEED_TO_STAKE', { name: poolName })}
    </p>
  );
};

export default PoolStakeFormDHV;
