import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';

import { getCurrencySelector } from '@modules/prices/slices/currencySlice';
import formatValuePrice from '@modules/common/helpers/formatValuePrice';
import formatValueToken from '@modules/common/helpers/formatValueToken';
import getTokenPrice from '@modules/common/helpers/getTokenPrice';
import { useTypedSelector } from '@utils/store';

import styles from './ClusterModalEstimate.module.scss';

const getEstimatedValue = (
  decimals: number,
  inputValue: string | null,
  clusterAmount?: BigNumber,
  commissionValue?: BigNumber | null,
): BigNumber | null => {
  if (inputValue) {
    if (clusterAmount) {
      let value = clusterAmount
        .mul(BigNumber.from(parseUnits(inputValue, decimals)))
        .div(BigNumber.from(Number(`1e${decimals}`).toString()));

      if (commissionValue) {
        value = value.sub(commissionValue);
      }

      return value;
    }

    return null;
  }

  return BigNumber.from(0);
};

const ClusterModalEstimate: FC<{
  commissionValue: BigNumber | null;
  clusterAmount?: BigNumber;
  isAssemble?: boolean;
  inputValue: string | null;
  decimals: number;
  symbol: string;
  price: BigNumber | null;
}> = ({
  commissionValue,
  clusterAmount,
  isAssemble,
  inputValue,
  decimals,
  symbol,
  price,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { chainId } = useWeb3React();

  const currency = useTypedSelector(getCurrencySelector);

  const estimatedValue: BigNumber | null = useMemo(() => {
    return getEstimatedValue(
      decimals,
      inputValue,
      clusterAmount,
      commissionValue,
    );
  }, [commissionValue, clusterAmount, inputValue, decimals]);

  return (
    <dl className={styles.container}>
      <dt className={styles.title}>
        {t('CLUSTERS.CLUSTER_MODAL_ESTIMATE.TITLE', { symbol })}
      </dt>

      <dd className={styles.value}>
        {estimatedValue
          ? formatValueToken(
              language,
              Number(formatUnits(estimatedValue, decimals)),
            )
          : '-'}{' '}
        {symbol} (
        {estimatedValue && price && chainId && currency && currency[chainId]
          ? formatValuePrice(
              language,
              isAssemble
                ? getTokenPrice(
                    estimatedValue.mul(price),
                    decimals * 2,
                    currency[chainId].price,
                  )
                : getTokenPrice(
                    estimatedValue,
                    decimals,
                    currency[chainId].price,
                  ),
            )
          : '-'}
        )
      </dd>
    </dl>
  );
};

ClusterModalEstimate.defaultProps = {
  clusterAmount: undefined,
  isAssemble: false,
};

export default ClusterModalEstimate;
