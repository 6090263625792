import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { formatUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';

import UnderlyingDetails from '@modules/clusters/components/UnderlyingDetails';
import ValueChanges from '@modules/common/components/ValueChanges';
import formatValuePercentage from '@modules/common/helpers/formatValuePercentage';
import getTokenScanLink from '@modules/clusters/helpers/getTokenScanLink';
import formatValuePrice from '@modules/common/helpers/formatValuePrice';
import formatValueToken from '@modules/common/helpers/formatValueToken';
import getTokenPrice from '@modules/common/helpers/getTokenPrice';
import StoredClusterUnderlying from '@modules/clusters/types/StoredClusterUnderlying';
import StoredUnderlyingList from '@modules/underlying/types/StoredUnderlyingList';
import StoredTokenPrice from '@modules/prices/types/TokenPrice';
import LINK_ICON from '@modules/clusters/assets/link_icon.svg';
import UNDERLYING_LIST from '@configs/underlying';
import CLUSTERS from '@configs/clusters';
import { getPrices } from '@modules/prices/slices/tokenPricesSlice';
import { useTypedSelector } from '@utils/store';

import styles from '@modules/clusters/components/UnderlyingTable/UnderlyingTable.module.scss';

const UNDERLYING_PRICE_DAY_AGO = 0.5;

const getAllocation = (
  clusterUnderlyingList: StoredClusterUnderlying[],
  underlyingList: StoredUnderlyingList,
  decimal: number,
  share: BigNumber,
  price: number,
  prices: StoredTokenPrice,
): number => {
  const totalUnderlyingPrice = clusterUnderlyingList
    .map(({ address, share: tokenShare }) => {
      return getTokenPrice(
        tokenShare,
        decimal,
        prices[
          underlyingList[address]?.symbol
            .toUpperCase()
            // TODO need to fix this on BE
            .replaceAll(/[^A-Za-z0-9]/g, '')
        ],
      );
    })
    .reduce((a: number, b: number) => a + b);

  return (getTokenPrice(share, decimal, price) / totalUnderlyingPrice) * 100;
};

const getUserShare = (
  share: BigNumber,
  shareDecimal: number,
  balance: BigNumber,
  balanceDecimal: number,
  stakedBalance: BigNumber,
  stakedBalanceDecimals: number,
) => {
  return (
    Number(formatUnits(share, shareDecimal)) *
    (Number(formatUnits(balance, balanceDecimal)) +
      Number(formatUnits(stakedBalance, stakedBalanceDecimals)))
  );
};

const UnderlyingTable: FC<{
  clusterUnderlyingList: StoredClusterUnderlying[];
  clusterUserBalance?: BigNumber | null;
  clusterDecimals: number;
  clusterAddress: string;
  stakedUserBalance?: BigNumber | null;
  stakedDecimals: number;
  underlyingList: StoredUnderlyingList;
}> = ({
  clusterUnderlyingList,
  clusterUserBalance,
  clusterDecimals,
  clusterAddress,
  stakedUserBalance,
  stakedDecimals,
  underlyingList,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { chainId, account } = useWeb3React();

  const tokenPrices = useTypedSelector(getPrices);

  return chainId ? (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead className={styles.head}>
          <tr>
            <th className={styles.headCell}>
              {t('CLUSTERS.UNDERLYING_TABLE.TABLE_HEAD_UNDERLYING')}
            </th>
            <th className={styles.headCell}>
              {t('CLUSTERS.UNDERLYING_TABLE.TABLE_HEAD_ALLOCATION')}
            </th>
            <th className={styles.headCell}>
              {t('CLUSTERS.UNDERLYING_TABLE.TABLE_HEAD_PRICE')}
            </th>
            <th className={styles.headCell} style={{ display: 'none' }}>
              {t('CLUSTERS.UNDERLYING_TABLE.TABLE_HEAD_24H_CHANGE')}
            </th>
            <th className={styles.headCell}>
              {t('CLUSTERS.UNDERLYING_TABLE.TABLE_HEAD_YOUR_SHARE')}
            </th>
            <th className={styles.headCell}>
              {t('CLUSTERS.UNDERLYING_TABLE.TABLE_HEAD_DETAILS')}
            </th>
          </tr>
        </thead>

        <tbody>
          {clusterUnderlyingList?.map(
            ({ address, share }) =>
              share.gt(BigNumber.from('0')) && (
                <tr className={styles.bodyRow} key={address}>
                  <td className={styles.bodyCell}>
                    <a
                      className={styles.bodyLink}
                      target="_blank"
                      href={getTokenScanLink(chainId, address)}
                      rel="noreferrer"
                    >
                      {underlyingList[address]?.symbol &&
                        UNDERLYING_LIST[underlyingList[address].symbol]
                          .icon && (
                          <div className={styles.bodyLinkIcon}>
                            <img
                              className={styles.bodyLinkIconImage}
                              height="17"
                              width="17"
                              src={
                                UNDERLYING_LIST[underlyingList[address].symbol]
                                  .icon
                              }
                              alt={underlyingList[address].symbol}
                            />
                          </div>
                        )}

                      <span className={styles.bodyLinkText}>
                        {underlyingList[address]?.name} (
                        {underlyingList[address]?.symbol})
                      </span>

                      <img
                        className={styles.bodyLinkDots}
                        height="26"
                        width="24"
                        src={LINK_ICON}
                        alt={underlyingList[address]?.symbol}
                      />
                    </a>
                  </td>

                  <td className={styles.bodyCell}>
                    {CLUSTERS[chainId][clusterAddress] &&
                    tokenPrices &&
                    underlyingList[address] &&
                    tokenPrices[
                      underlyingList[address]?.symbol
                        .toUpperCase()
                        .replaceAll(/[^A-Za-z0-9]/g, '')
                    ] >= 0
                      ? formatValuePercentage(
                          language,
                          getAllocation(
                            clusterUnderlyingList,
                            underlyingList,
                            CLUSTERS[chainId][clusterAddress]
                              .underlyingDecimals,
                            share,
                            tokenPrices[
                              underlyingList[address]?.symbol
                                .toUpperCase()
                                .replaceAll(/[^A-Za-z0-9]/g, '')
                            ],
                            tokenPrices,
                          ),
                        )
                      : '-'}
                  </td>

                  <td className={styles.bodyCell}>
                    {tokenPrices &&
                    underlyingList[address] &&
                    tokenPrices[
                      underlyingList[address]?.symbol
                        .toUpperCase()
                        .replaceAll(/[^A-Za-z0-9]/g, '')
                    ] >= 0
                      ? formatValuePrice(
                          language,
                          tokenPrices[
                            underlyingList[address]?.symbol
                              .toUpperCase()
                              .replaceAll(/[^A-Za-z0-9]/g, '')
                          ],
                        )
                      : '-'}
                  </td>

                  <td className={styles.bodyCell} style={{ display: 'none' }}>
                    {tokenPrices &&
                    underlyingList[address] &&
                    tokenPrices[
                      underlyingList[address]?.symbol
                        .toUpperCase()
                        .replaceAll(/[^A-Za-z0-9]/g, '')
                    ] &&
                    UNDERLYING_PRICE_DAY_AGO ? (
                      <ValueChanges
                        valuePrevious={UNDERLYING_PRICE_DAY_AGO}
                        valueCurrent={
                          tokenPrices[
                            underlyingList[address]?.symbol
                              .toUpperCase()
                              .replaceAll(/[^A-Za-z0-9]/g, '')
                          ]
                        }
                      />
                    ) : (
                      '-'
                    )}
                  </td>

                  <td className={styles.bodyCell}>
                    {CLUSTERS[chainId][clusterAddress] &&
                    account &&
                    clusterUserBalance &&
                    stakedUserBalance
                      ? formatValueToken(
                          language,
                          getUserShare(
                            share,
                            CLUSTERS[chainId][clusterAddress]
                              .underlyingDecimals,
                            clusterUserBalance,
                            clusterDecimals,
                            stakedUserBalance,
                            stakedDecimals,
                          ),
                        )
                      : '-'}{' '}
                    {underlyingList[address]?.symbol}
                  </td>

                  <td className={styles.bodyCell}>
                    {underlyingList[address]?.symbol &&
                    UNDERLYING_LIST[underlyingList[address].symbol]
                      ?.description ? (
                      <UnderlyingDetails
                        description={
                          UNDERLYING_LIST[underlyingList[address].symbol]
                            .description
                        }
                        symbol={underlyingList[address].symbol}
                        name={underlyingList[address].name}
                        icon={
                          UNDERLYING_LIST[underlyingList[address].symbol].icon
                        }
                      />
                    ) : (
                      '-'
                    )}
                  </td>
                </tr>
              ),
          )}
        </tbody>
      </table>
    </div>
  ) : null;
};

UnderlyingTable.defaultProps = {
  clusterUserBalance: null,
  stakedUserBalance: null,
};

export default UnderlyingTable;
