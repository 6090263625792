import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { PutEffect, takeLatest, put } from 'redux-saga/effects';

import {
  setSuccessItem,
  setErrorItem,
} from '@modules/clusters/slices/assembleSlice';

function* resetAssembleClusterWorker({
  payload,
}: PayloadAction<{
  clusterAddress: string;
}>): Generator<PutEffect<AnyAction>, void, never> {
  yield put(
    setSuccessItem({
      clusterAddress: payload.clusterAddress,
      isSuccess: false,
    }),
  );
  yield put(
    setErrorItem({
      clusterAddress: payload.clusterAddress,
      error: null,
    }),
  );
}

function* resetAssembleClusterSaga(): Generator {
  yield takeLatest(
    'CLUSTERS_RESET_ASSEMBLE_CLUSTER_REQUESTED',
    resetAssembleClusterWorker,
  );
}

export default resetAssembleClusterSaga;
