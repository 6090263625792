import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import styles from '@modules/info/assets/styles/info.module.scss';

const CookieNotice: FC = () => {
  const { t } = useTranslation();

  return (
    <article className={styles.container}>
      <hgroup>
        <h1 className={styles.title}>{t('INFO.COOKIE_NOTICE.PAGE_TITLE')}</h1>
      </hgroup>
      <aside>
        <ul className={styles.created}>
          <li className={styles.createdVersion}>
            {t('INFO.COOKIE_NOTICE.PAGE_VERSION')} 1.0.1.2022
          </li>
          <li>
            {t('INFO.COOKIE_NOTICE.PAGE_LAST_UPDATED')}{' '}
            <time dateTime="2021-10-07">October 7, 2021</time>
          </li>
        </ul>
      </aside>

      <section className={styles.section}>
        <p className={styles.mb}>
          <Trans
            i18nKey="INFO.COOKIE_NOTICE.DOCUMENT_DESCRIBE.ABOUT_THIS_DOCUMENT"
            values={{
              link: 'https://dehive.finance/',
              abbrLLC: 'LLC',
            }}
            tOptions={{ interpolation: { escapeValue: false } }}
            components={[
              <abbr key={0} title={`${t('INFO.ABBR_DECRYPTION.LLC')}`} />,
              <a
                key={1}
                className={styles.primaryLink}
                href="https://dehive.finance/"
              >
                {' '}
              </a>,
            ]}
          />
        </p>
        <p>{t('INFO.COOKIE_NOTICE.DOCUMENT_DESCRIBE.INTEGRAL_PART')}</p>
        <p>{t('INFO.COOKIE_NOTICE.DOCUMENT_DESCRIBE.DATA_PROTECTION')}</p>
      </section>

      <address className={styles.section}>
        <h3 className={styles.subtitle}>
          {t('INFO.COOKIE_NOTICE.PAGE_SUB_TITLE')}
        </h3>

        <p>{t('INFO.COOKIE_NOTICE.ADDRESS.REGISTERED')}</p>
        <p>
          <Trans
            i18nKey="INFO.COOKIE_NOTICE.ADDRESS.CONTACT_EMAIL_ADDRESS"
            values={{ link: 'dehive_foundation@ukr.net' }}
            components={[
              <a
                key={0}
                className={styles.primaryLink}
                href="mailto:dehive_foundation@ukr.net"
              >
                {' '}
              </a>,
            ]}
          />
        </p>
      </address>
      <section className={styles.section}>
        <h3 className={styles.subtitle}>
          {t('INFO.COOKIE_NOTICE.WHAT_IS_COOKIE.TITLE')}
        </h3>

        <p className={styles.mb}>
          {t('INFO.COOKIE_NOTICE.WHAT_IS_COOKIE.DEFINITION')}
        </p>
        <p className={styles.mb}>
          <Trans
            i18nKey="INFO.COOKIE_NOTICE.WHAT_IS_COOKIE.HOW_IT_WORK"
            components={[<strong key={0} />]}
          />
        </p>
        <p>{t('INFO.COOKIE_NOTICE.WHAT_IS_COOKIE.TWO_MAIN_KINDS')}</p>
        <p className={styles.mb}>
          <Trans
            i18nKey="INFO.COOKIE_NOTICE.WHAT_IS_COOKIE.WHAT_KINDS_WE_USE"
            components={[<strong key={0} />]}
          />
        </p>
      </section>
      <section className={styles.section}>
        <h3 className={styles.subtitle}>
          {t('INFO.COOKIE_NOTICE.WHY_WE_USE_COOKIES.TITLE')}
        </h3>

        <h4 className={styles.subSubtitle}>
          {t('INFO.COOKIE_NOTICE.WHY_WE_USE_COOKIES.STRICTLY_NECESSARY')}
        </h4>
        <p className={styles.mb}>
          <Trans
            i18nKey="INFO.COOKIE_NOTICE.WHY_WE_USE_COOKIES.NECESSARY_DESC"
            components={[<strong key={0} />]}
          />
        </p>
        <h4 className={styles.subSubtitle}>
          {t('INFO.COOKIE_NOTICE.WHY_WE_USE_COOKIES.STATISTICS_COOKIES')}
        </h4>
        <p className={styles.mb}>
          <Trans
            i18nKey="INFO.COOKIE_NOTICE.WHY_WE_USE_COOKIES.ANALYZE_STATS"
            components={[<strong key={0} />]}
          />
        </p>
        <p>
          <Trans
            i18nKey="INFO.COOKIE_NOTICE.WHY_WE_USE_COOKIES.WE_PLACE_COOKIES"
            values={{ link: 'www.google.com/analytics/policies/' }}
            tOptions={{ interpolation: { escapeValue: false } }}
            components={[
              <a
                key={0}
                className={styles.primaryLink}
                href="https://www.google.com/analytics/policies/"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
              </a>,
            ]}
          />
        </p>
        <p>{t('INFO.COOKIE_NOTICE.WHY_WE_USE_COOKIES.THIRD_PROVIDES')}</p>
        <p>
          {t('INFO.COOKIE_NOTICE.WHY_WE_USE_COOKIES.COOKIES_OF_CLOUDFLARE')}
        </p>
        <p>
          <Trans
            i18nKey="INFO.COOKIE_NOTICE.WHY_WE_USE_COOKIES.WE_WILL_STORE"
            components={[<strong key={0} />]}
          />
        </p>
      </section>
      <section className={styles.section}>
        <h3 className={styles.subtitle}>
          {t('INFO.COOKIE_NOTICE.MANAGING_COOKIES.TITLE')}
        </h3>

        <p className={styles.mb}>
          {t('INFO.COOKIE_NOTICE.MANAGING_COOKIES.LAW_STATES')}
        </p>
        <p className={styles.mb}>
          {t('INFO.COOKIE_NOTICE.MANAGING_COOKIES.UNLESS_YOU_CHOOSE')}
        </p>
        <p className={styles.mb}>
          <Trans
            i18nKey="INFO.COOKIE_NOTICE.MANAGING_COOKIES.CHECK_SPECIFIC"
            components={[<strong key={0} />]}
          />
        </p>
        <p className={styles.mb}>
          {t('INFO.COOKIE_NOTICE.MANAGING_COOKIES.DECLINING')}
        </p>
        <p className={styles.mb}>
          {t('INFO.COOKIE_NOTICE.MANAGING_COOKIES.CHANGE_OR_WITHDRAW')}
        </p>
        <p>{t('INFO.COOKIE_NOTICE.MANAGING_COOKIES.KEEP_IN_MIND')}</p>
      </section>
      <section className={styles.contacts}>
        <h3 className={styles.subtitle}>
          {t('INFO.COOKIE_NOTICE.CONTACT_US.TITLE')}
        </h3>

        <address className={styles.contactsAddress}>
          <Trans
            i18nKey="INFO.COOKIE_NOTICE.CONTACT_US.ADDRESS"
            values={{ link: 'dehive_foundation@ukr.net' }}
            components={[
              <a
                key={0}
                className={styles.primaryLink}
                href="mailto:dehive_foundation@ukr.net"
              >
                {' '}
              </a>,
            ]}
          />
        </address>
      </section>
    </article>
  );
};

export default CookieNotice;
