import { FC, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import CloseButton from '@modules/layout/components/CloseButton';
import Button from '@modules/common/components/Button';
import DISCLAIMER_ICON from '@modules/layout/assets/disclaimer_icon.svg';

import styles from './Disclaimer.module.scss';

const Disclaimer: FC<{ className?: string }> = ({ className }) => {
  const [isDisclaimerHidden, setIsDisclaimerHidden] = useState<boolean>(
    JSON.parse(
      localStorage.getItem(
        `settings-disclaimer-${
          process.env.REACT_APP_NETWORK_ENVIRONMENT || 'test'
        }`,
      ) || 'false',
    ),
  );

  const { t } = useTranslation();

  const handleHideDisclaimer = useCallback(() => {
    setIsDisclaimerHidden(true);

    localStorage.setItem(
      `settings-disclaimer-${
        process.env.REACT_APP_NETWORK_ENVIRONMENT || 'test'
      }`,
      'true',
    );
  }, []);

  return isDisclaimerHidden ? null : (
    <aside className={cn(styles.container, className)}>
      <div className={styles.innerBlock}>
        <div className={styles.header}>
          <img
            className={styles.headerIcon}
            src={DISCLAIMER_ICON}
            alt={t('LAYOUT.DISCLAIMER.TITLE')}
          />

          <h4 className={styles.headerTitle}>{t('LAYOUT.DISCLAIMER.TITLE')}</h4>
        </div>

        <p className={styles.description}>
          {t('LAYOUT.DISCLAIMER.DESCRIPTION')}
        </p>

        <Button
          className={styles.buttonAgree}
          onClick={handleHideDisclaimer}
          color="polygon"
          text={t('LAYOUT.DISCLAIMER.BUTTON_AGREE')}
        />

        <CloseButton
          className={styles.buttonClose}
          onClick={handleHideDisclaimer}
        />
      </div>
    </aside>
  );
};

Disclaimer.defaultProps = { className: undefined };

export default Disclaimer;
