import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { RootState } from '@utils/store';

interface InitialState {
  isLoading: boolean;
  preError: unknown | null;
  error: unknown | null;
  value: number | null;
}

const initialState: InitialState = {
  isLoading: false,
  preError: null,
  error: null,
  value: null,
};

const reducers = {
  setIsLoading(state: InitialState, action: PayloadAction<boolean>) {
    state.isLoading = action.payload;
  },
  setPreError(state: InitialState, action: PayloadAction<unknown | null>) {
    state.preError = action.payload;
  },
  setError(state: InitialState, action: PayloadAction<unknown | null>) {
    state.error = action.payload;
  },
  setValue(state: InitialState, action: PayloadAction<number>) {
    state.value = action.payload;
  },
};

const tvlSlice = createSlice({
  name: 'tvl',
  initialState,
  reducers,
});

export const isLoadingSelector = (state: RootState): boolean =>
  state.tvl.isLoading;
export const preErrorSelector = (state: RootState): unknown | null =>
  state.tvl.preError;
export const errorSelector = (state: RootState): unknown | null =>
  state.tvl.error;
export const valueSelector = (state: RootState): number | null =>
  state.tvl.value;

export const { setIsLoading, setValue, setPreError, setError } =
  tvlSlice.actions;

export default tvlSlice.reducer;
