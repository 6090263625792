import ImpulseList from '@modules/pools/components/ImpulseList';
import ICON from '@modules/pools/assets/pages/impulses_logo.svg';

const page = {
  path: '/impulses',
  menu: 1,
  icon: ICON,
  order: 4,
  title: 'POOLS.IMPULSE_LIST.NAV_LINK_NAME',
  parent: 0,
  component: ImpulseList,
};

export default page;
