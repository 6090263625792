import { BigNumber } from '@ethersproject/bignumber';

import ContractFunctions from '@modules/common/types/ContractFunctions';

const getUserPoolAmount = (
  poolContract: ContractFunctions<BigNumber>,
  poolId: number,
  account: string,
): Promise<BigNumber> => {
  return poolContract.userPoolAmount(poolId, account);
};

export default getUserPoolAmount;
