import { createContext } from 'react';

export interface NetworkSelectorContextType {
  handleChangeChainId: (value: number) => void;
  chainId: number;
}

export const NetworkSelectorContext = createContext<NetworkSelectorContextType>(
  {
    handleChangeChainId: () => {},
    chainId: 1,
  },
);
