import { JsonRpcProvider } from '@ethersproject/providers';
import { ContractInterface, Contract } from '@ethersproject/contracts';
import { BigNumber } from '@ethersproject/bignumber';

import getUserPoolAmountInUnderlying from '@modules/pools/actions/getUserPoolAmountInUnderlying';
import getClusterYieldBalance from '@modules/pools/actions/getClusterYieldBalance';
import getImpulseYieldBalance from '@modules/pools/actions/getImpulseYieldBalance';
import getUserPoolAmount from '@modules/pools/actions/getUserPoolAmount';
import getPendingRewards from '@modules/pools/actions/getPendingRewards';
import getPoolInfo from '@modules/pools/actions/getPoolInfo';
import PoolInfo from '@modules/pools/types/PoolInfo';

const getPoolData = (
  poolAddress: string,
  poolABI: ContractInterface,
  poolId: number,
  isMulti: boolean,
  isCluster: boolean,
  isImpulse: boolean,
  provider: JsonRpcProvider,
  account?: string | null,
): Promise<
  [
    PoolInfo,
    [BigNumber] | null,
    BigNumber | null,
    BigNumber[] | null,
    BigNumber | null,
    BigNumber | null,
  ]
> => {
  const poolContract = new Contract(
    poolAddress,
    poolABI,
    account ? provider.getSigner(account) : provider,
  );

  return Promise.all([
    getPoolInfo(poolContract, poolId),
    getPendingRewards(poolContract, poolId, account),
    account ? getUserPoolAmount(poolContract, poolId, account) : null,
    isMulti && account
      ? getUserPoolAmountInUnderlying(poolContract, poolId, account)
      : null,
    isCluster ? getClusterYieldBalance(poolContract, poolId) : null,
    isImpulse && account
      ? getImpulseYieldBalance(poolContract, poolId, account)
      : null,
  ]);
};

export default getPoolData;
