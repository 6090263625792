import { ContractInterface } from '@ethersproject/contracts';

import ClusterToken from '@abis/ClusterToken.json';
import IERC20 from '@abis/IERC20.json';

import DBSC_TOKEN_ICON from '@assets/images/clusters/DBSC_token.svg';
import DBSC_ICON from '@assets/images/clusters/DBSC.svg';
import DECR_TOKEN_ICON from '@assets/images/clusters/DECR_token.svg';
import DECR_ICON from '@assets/images/clusters/DECR.svg';
import DGAME_TOKEN_ICON from '@assets/images/clusters/DGAME_token.svg';
import DGAME_ICON from '@assets/images/clusters/DGAME.svg';
import DPOL_TOKEN_ICON from '@assets/images/clusters/DPOL_token.svg';
import DPOL_ICON from '@assets/images/clusters/DPOL.svg';
import DXIN_TOKEN_ICON from '@assets/images/clusters/DXIN_token.svg';
import DXIN_ICON from '@assets/images/clusters/DXIN.svg';
import DXDC_ICON from '@assets/images/clusters/DXDC.svg';
import DXDC_TOKEN_ICON from '@assets/images/clusters/DXDC_token.svg';

export interface Cluster {
  underlyingDecimals: number;
  underlyingABI: ContractInterface;
  tokenStandart: string;
  tokenABI: ContractInterface;
  poolAddress?: string;
  poolId?: number;
  tokenIcon: string;
  icon: string;
  shortDescription: string;
  description: string;
  platform: string;
  prompt: string;
  name?: string;
  path: string;
}

export interface ClusterList {
  [key: string]: Cluster;
}

interface ClusterListByChain {
  [key: number]: ClusterList;
}

const getCluster = (
  clusterObject: Cluster,
  poolAddress?: string,
  poolId?: number,
): Cluster => {
  return {
    ...clusterObject,
    ...(poolAddress !== undefined && { poolAddress }),
    ...(poolId !== undefined && { poolId }),
  };
};

const DECR: Cluster = {
  underlyingDecimals: 6,
  underlyingABI: IERC20,
  tokenStandart: 'ERC20',
  tokenABI: ClusterToken,
  tokenIcon: DECR_TOKEN_ICON,
  icon: DECR_ICON,
  shortDescription: 'Crypto Index',
  description:
    'The first Cluster introduced by DeHive analytics team. It is the one that consists\nof the core protocols of the Ethereum DeFi ecosystem. These fundamental protocols\nform the foundation layer of all decentralized financial instruments. It is the\nheart of the ecosystem - a set of protocols which on the average perform and react\nto the market in a similar way.', // eslint-disable-line
  platform: 'DeHive platform',
  prompt:
    'Crypto Index is a defined portfolio of selected cryptocurrencies that you can buy or sell with just one click', // eslint-disable-line
  path: 'DECR',
};

const DBSC: Cluster = {
  underlyingDecimals: 6,
  underlyingABI: IERC20,
  tokenStandart: 'ERC20',
  tokenABI: ClusterToken,
  tokenIcon: DBSC_TOKEN_ICON,
  icon: DBSC_ICON,
  shortDescription: 'Yield Generating Index',
  description:
    'BSC-deCluster contains the most promising and popular Binance Smart Chain protocols that are united into one profitable Cluster. The underlyings complement each other, bringing their own value to the ecosystem and creating a perfect combination for efficient portfolio management.', // eslint-disable-line
  platform: 'DeHive platform',
  prompt: 'Yield generating Index is an index with yield farming strategies',
  name: 'De-BSC',
  path: 'DBSC',
};

const DPOL: Cluster = {
  underlyingDecimals: 6,
  underlyingABI: IERC20,
  tokenStandart: 'ERC20',
  tokenABI: ClusterToken,
  tokenIcon: DPOL_TOKEN_ICON,
  icon: DPOL_ICON,
  shortDescription: 'Yield Generating Index',
  description:
    'The first Polygon (Matic) Cluster contains typical Polygon protocols. Those are endemics that reflect the state of the DeFi ecosystem at this chain and apply only to Matic. All these protocols bring their own value into the ecosystem, covering the most significant segments of the market. Though, all of them are closely connected since they represent some of the core elements of expanding the Matic universe.', // eslint-disable-line
  platform: 'DeHive platform',
  prompt: 'Yield generating Index is an index with yield farming strategies',
  name: 'Poly',
  path: 'DPOL',
};

const DGAME: Cluster = {
  underlyingDecimals: 6,
  underlyingABI: IERC20,
  tokenStandart: 'ERC20',
  tokenABI: ClusterToken,
  tokenIcon: DGAME_TOKEN_ICON,
  icon: DGAME_ICON,
  shortDescription: 'Incentive index',
  description:
    'DeHive Poly Gaming Cluster is composed of seven successfully performing tokens used in Play2Earn NFT games. The Poly Gaming Cluster is reflecting the state of Polygon-based NFT projects and is aimed to facilitate the enterrance to the NFT space of DeFi while facilitating the trading processes.', // eslint-disable-line
  platform: 'DeHive platform',
  prompt:
    'An incentive Index is an index with opportunities to get  temporary rewards from DeHive partners', // eslint-disable-line
  path: 'DGAME',
};

const DXIN: Cluster = {
  underlyingDecimals: 6,
  underlyingABI: IERC20,
  tokenStandart: 'ERC20',
  tokenABI: ClusterToken,
  tokenIcon: DXIN_TOKEN_ICON,
  icon: DXIN_ICON,
  shortDescription: 'Incentive index',
  description:
    'xInfra Cluster was explicitly developed to represent the community and infrastructure sectors of DeFi. These are the areas surrounding DeFi and supporting the whole ecosystem growth, thus, bringing extra value to the industry. The Cluster consists of fee DAO tokens, infrastructure creators, and protocols connected to the outer world.', // eslint-disable-line
  platform: 'DeHive platform',
  prompt:
    'An incentive Index is an index with opportunities to get  temporary rewards from DeHive partners', // eslint-disable-line
  name: 'xInfra',
  path: 'DXIN',
};

const DXDC: Cluster = {
  underlyingDecimals: 6,
  underlyingABI: IERC20,
  tokenStandart: 'ERC20',
  tokenABI: ClusterToken,
  tokenIcon: DXDC_TOKEN_ICON,
  icon: DXDC_ICON,
  shortDescription: 'Incentive index',
  description:
    'Our De-xDai Cluster represents the DeFi part of the market, including a native token as a fuel for DeFi protocols, a core swap aggregator, a streaming farm, an oracle, an insurance protocol, and the largest farm aggregator. Altogether, they represent the market as a whole, allowing users to benefit from the most promising assets and trends within xDai financial sector.', // eslint-disable-line
  platform: 'DeHive platform',
  prompt:
    'An incentive Index is an index with opportunities to get  temporary rewards from DeHive partners', // eslint-disable-line
  name: 'De-Gnosis',
  path: 'DXDC',
};

const CLUSTERS: ClusterListByChain = {
  1: {
    '0x6Bc3F65Fc50E49060e21eD6996be96ee4B404752': getCluster(DECR),
  },
  4: {
    '0x5Ec005db72E18ee4d1E9B454Ff4C37533c7786b2': getCluster(DECR),
  },
  56: {
    '0x0a684421ef48b431803BFd75F38675EAb1e38Ed5': getCluster(
      DBSC,
      '0x7cDA416c096768971c0b7605F5aaABD8fA713818',
      0,
    ),
  },
  137: {
    '0x4964B3B599B82C3FdDC56e3A9Ffd77d48c6AF0f0': getCluster(
      DPOL,
      '0x8271529b62c82b4d30a2eFdB3ec89D7abA60897E',
      0,
    ),
    '0x589Ea336092184d9eD74b8263c4eecA73Ed0cE7a': getCluster(
      DGAME,
      '0x8271529b62c82b4d30a2eFdB3ec89D7abA60897E',
      1,
    ),
  },
  100: {
    '0xA6C090c5572f54d529B0839b8fd2D50a4afB1E6B': getCluster(
      DXIN,
      '0xdC005EbbA14C3C01ab26B06D4e981FC81e2DF2FF',
      0,
    ),
    '0xF557B2B73b872E6d2F43826f9D77B7402A363Bc0': getCluster(
      DXDC,
      '0xdC005EbbA14C3C01ab26B06D4e981FC81e2DF2FF',
      1,
    ),
  },
  80001: {
    '0xe08229E2aBF47C4838a92C26E5663Cb4FBFe48fa': getCluster(
      DPOL,
      '0xA805494ac5F1079929870f8fD37ACd5ebf4B988e',
      0,
    ),
    '0xC02A26c5f6395F12916BE2429e88DfC94057872f': getCluster(DGAME),
  },
};

export default CLUSTERS;
