import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';

const getPoolGasPriceHarvest = (
  poolContract: Contract,
  poolId: number,
): Promise<BigNumber> => {
  return poolContract.estimateGas.harvest(poolId);
};

export default getPoolGasPriceHarvest;
