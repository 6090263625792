import { FC, useState, useCallback } from 'react';
import WalletConnectProvider from '@walletconnect/web3-provider';

import {
  WalletConnectContextType,
  WalletConnectContext,
} from '@modules/connections/context/WalletConnectContext';

const { Provider } = WalletConnectContext;

const WalletConnectWrapper: FC = ({ children }) => {
  const [provider, setProvider] = useState<WalletConnectProvider | null>(null);

  const handleChangeProvider = useCallback(
    (value: WalletConnectProvider | null) => setProvider(value),
    [],
  );

  const context: WalletConnectContextType = { handleChangeProvider, provider };

  return <Provider value={context}>{children}</Provider>;
};

export default WalletConnectWrapper;
