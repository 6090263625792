import { FC } from 'react';
import cn from 'classnames';

import { listSelector } from '@modules/underlying/slices/underlyingSlice';
import { useTypedSelector } from '@utils/store';
import { PoolUnderlying, Pool } from '@configs/pools';

import styles from '@modules/pools/components/PoolCardUderlyingList/PoolCardUderlyingList.module.scss';

const PoolCardUderlyingList: FC<{
  underlying: PoolUnderlying[];
  isClosed?: Pool['closed'];
}> = ({ underlying, isClosed }) => {
  const underlyingList = useTypedSelector(listSelector);

  return (
    <h4 className={cn(styles.container, { [styles['is-closed']]: isClosed })}>
      {underlyingList &&
        underlying?.map(({ address }: PoolUnderlying, index: number) => {
          return underlyingList[address] && index !== 0
            ? ` + ${underlyingList[address].symbol}`
            : underlyingList[address]?.symbol;
        })}
    </h4>
  );
};

PoolCardUderlyingList.defaultProps = { isClosed: undefined };

export default PoolCardUderlyingList;
