import {
  all,
  call,
  CallEffect,
  put,
  PutEffect,
  takeLatest,
  AllEffect,
} from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import { AnyAction } from '@reduxjs/toolkit';
import {
  setError,
  setIsLoading,
  setPreError,
  setPrices,
} from '@modules/prices/slices/tokenPricesSlice';
import StoredTokenPrice from '@modules/prices/types/TokenPrice';
import ResponseData from '@modules/prices/types/ResponseData';

const API = process.env.REACT_APP_API || 'http://localhost:1234/api';

const getTokenPrices = (): Promise<AxiosResponse<ResponseData[]>> => {
  return axios.get<ResponseData[]>(`${API}/prices/token`);
};
const getLpTokenPrices = (): Promise<AxiosResponse<ResponseData[]>> => {
  return axios.get<ResponseData[]>(`${API}/lpToken/prices`);
};

function* getTokensPricesWorker(): Generator<
  | CallEffect<AxiosResponse<ResponseData[]>>
  | PutEffect<AnyAction>
  | AllEffect<AnyAction>,
  void,
  never
> {
  try {
    yield put(setIsLoading(true));
    yield put(setPreError(null));
    yield put(setError(null));

    const [response, responseLpTokens]: AxiosResponse<ResponseData[]>[] =
      yield all([call(getTokenPrices), call(getLpTokenPrices)]);

    const storedData: StoredTokenPrice = {};
    response.data.forEach(({ symbol, priceUSD }) => {
      storedData[symbol] = Number(priceUSD);
    });
    responseLpTokens.data.forEach(({ symbol, priceUSD }) => {
      storedData[symbol] = Number(priceUSD);
    });

    yield put(setPrices(storedData));
  } catch (error: unknown) {
    yield put(setPreError(error));
  } finally {
    yield put(setIsLoading(false));
  }
}

function* getTokensPricesSaga(): Generator {
  yield takeLatest('DHV_GET_TOKENS_PRICES_REQUESTED', getTokensPricesWorker);
}

export default getTokensPricesSaga;
