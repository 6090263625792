import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import { FormInstance } from 'antd/lib/form';
import cn from 'classnames';

import FormInput from '@modules/common/components/FormInput';
import maxValueWithCommission from '@modules/common/helpers/maxValueWithCommission';
import formatValueToken from '@modules/common/helpers/formatValueToken';

import styles from './DHVSwapField.module.scss';

const DHVSwapField: FC<{
  commissionValue?: BigNumber | null;
  isNoValidateMax?: boolean;
  isHasMaxButton?: boolean;
  tokenSymbol: string;
  isDisabled: boolean;
  className?: string;
  inputName: string;
  formName: string;
  minValue?: string;
  decimals: number;
  balance: BigNumber | null;
  title: string;
  form: FormInstance;
}> = ({
  commissionValue,
  isNoValidateMax,
  isHasMaxButton,
  tokenSymbol,
  isDisabled,
  className,
  inputName,
  formName,
  minValue,
  decimals,
  balance,
  title,
  form,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.head}>
        <label className={styles.title} htmlFor={`${formName}_${inputName}`}>
          {title}
        </label>

        <dl className={styles.balance}>
          <dt className={styles.balanceTitle}>
            {t('DHV.DHV_SWAP_FIELD.BALANCE_TITLE')}
          </dt>

          <dd className={styles.balanceValue}>
            {balance && decimals
              ? formatValueToken(
                  language,
                  Number(formatUnits(balance, decimals)),
                )
              : '-'}
          </dd>
        </dl>
      </div>

      <FormInput
        isNoValidateMax={isNoValidateMax}
        isHasMaxButton={isHasMaxButton}
        tokenSymbol={tokenSymbol}
        isDisabled={isDisabled}
        inputName={inputName}
        maxValue={maxValueWithCommission(commissionValue, balance)}
        minValue={minValue}
        decimals={decimals}
        isDark
        form={form}
      />
    </div>
  );
};

DHVSwapField.defaultProps = {
  commissionValue: null,
  isNoValidateMax: undefined,
  isHasMaxButton: false,
  className: undefined,
  minValue: undefined,
};

export default DHVSwapField;
