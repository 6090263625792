import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { formatUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import cn from 'classnames';

import HoldingList from '@modules/common/components/HoldingList';
import { poolsApyAprSelector } from '@modules/pools/slices/poolsStatsSlice';
import { getCurrencySelector } from '@modules/prices/slices/currencySlice';
import { listSelector } from '@modules/underlying/slices/underlyingSlice';
import { getPoolsTvl } from '@modules/pools/slices/poolsTvlSlices';
import { getPrices } from '@modules/prices/slices/tokenPricesSlice';
import formatValuePercentage from '@modules/common/helpers/formatValuePercentage';
import formatValueToken from '@modules/common/helpers/formatValueToken';
import formatValuePrice from '@modules/common/helpers/formatValuePrice';
import getTokenPrice from '@modules/common/helpers/getTokenPrice';
import StoredPool from '@modules/pools/types/StoredPool';
import { useTypedSelector } from '@utils/store';
import POOLS, { Pool } from '@configs/pools';

import styles from '@modules/pools/components/PoolCardStats/PoolCardStats.module.scss';

const PoolCardStats: FC<{
  userPoolAmountInUnderlying: StoredPool['userPoolAmountInUnderlying'];
  totalPoolSupply: StoredPool['poolSupply'];
  userPoolAmount: StoredPool['userPoolAmount'];
  clusterPrice: StoredPool['clusterPrice'];
  poolDecimals: StoredPool['decimals'];
  poolAddress: string;
  poolSymbol: StoredPool['symbol'];
  poolAsset: Pool['asset'];
  poolId: number;
}> = ({
  userPoolAmountInUnderlying,
  totalPoolSupply,
  userPoolAmount,
  clusterPrice,
  poolDecimals,
  poolAddress,
  poolSymbol,
  poolAsset,
  poolId,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const underlyingList = useTypedSelector(listSelector);
  const poolsStats = useTypedSelector(poolsApyAprSelector);
  const currency = useTypedSelector(getCurrencySelector);
  const poolsTvl = useTypedSelector(getPoolsTvl);
  const prices = useTypedSelector(getPrices);

  const { chainId } = useWeb3React();

  const displayiedPoolSymbol = useMemo(() => {
    if (
      underlyingList &&
      chainId &&
      POOLS[chainId] &&
      POOLS[chainId][poolAddress] &&
      (POOLS[chainId][poolAddress][poolId]?.type === 2 ||
        POOLS[chainId][poolAddress][poolId]?.type === 3) &&
      POOLS[chainId][poolAddress][poolId]?.underlying
    ) {
      return POOLS[chainId][poolAddress][poolId].underlying?.map(
        ({ address }, index: number) =>
          index !==
          (POOLS[chainId][poolAddress][poolId].underlying?.length || 0) - 1
            ? `${underlyingList[address]?.symbol || ''}/`
            : underlyingList[address]?.symbol || '',
      );
    }

    return poolSymbol;
  }, [underlyingList, poolAddress, chainId, poolId, poolSymbol]);

  return (
    <dl className={styles.container}>
      <dt className={styles.title}>{t('POOLS.POOL_CARD_STATS.TITLE_APY')}</dt>
      <dd className={styles.value}>
        <span className={styles.valueBig}>
          {poolsStats && poolsStats[poolSymbol]?.apy
            ? formatValuePercentage(language, poolsStats[poolSymbol]?.apy)
            : '- %'}
        </span>
        <small className={styles.valueSmall}>
          {t('POOLS.POOL_CARD_STATS.TITLE_APR')}:{' '}
          {poolsStats && poolsStats[poolSymbol]?.apr
            ? formatValuePercentage(language, poolsStats[poolSymbol]?.apr)
            : '- %'}
        </small>
      </dd>

      <dt className={styles.title}>
        {t('POOLS.POOL_CARD_STATS.TOTAL_VALUE_SHORT')}
      </dt>
      <dd className={styles.value}>
        <span className={styles.valueBig}>
          {poolsTvl && poolsTvl[poolSymbol] >= 0
            ? formatValuePrice(language, poolsTvl[poolSymbol])
            : ''}

          {!(poolsTvl && poolsTvl[poolSymbol]) &&
          totalPoolSupply &&
          prices &&
          prices[poolSymbol] >= 0
            ? formatValuePrice(
                language,
                getTokenPrice(
                  totalPoolSupply,
                  poolDecimals,
                  prices[poolSymbol],
                ),
              )
            : ''}

          {!(poolsTvl && poolsTvl[poolSymbol]) && !totalPoolSupply && '-'}
        </span>
        <small className={styles.valueSmall}>
          {t('POOLS.POOL_CARD_STATS.TOTAL_VALUE')}
        </small>
      </dd>

      <dt
        className={cn(styles.title, {
          [styles['is-empty']]:
            !userPoolAmount || userPoolAmount.lte(BigNumber.from('0')),
        })}
      >
        {t('POOLS.POOL_CARD_STATS.YOUR_DEPOSIT')}
      </dt>
      <dd className={styles.value}>
        <span
          className={cn(styles.valueBig, {
            [styles['is-empty']]:
              !userPoolAmount || userPoolAmount.lte(BigNumber.from('0')),
          })}
        >
          {chainId &&
            POOLS[chainId] &&
            POOLS[chainId][poolAddress] &&
            POOLS[chainId][poolAddress][poolId]?.type === 1 &&
            userPoolAmount &&
            chainId &&
            currency &&
            currency[chainId] &&
            formatValuePrice(
              language,
              getTokenPrice(
                userPoolAmount.mul(clusterPrice || BigNumber.from('0')),
                poolDecimals * 2,
                currency[chainId].price,
              ),
            )}

          {chainId &&
            POOLS[chainId] &&
            POOLS[chainId][poolAddress] &&
            POOLS[chainId][poolAddress][poolId]?.type !== 1 &&
            userPoolAmount &&
            prices &&
            prices[poolAsset || poolSymbol] &&
            formatValuePrice(
              language,
              getTokenPrice(
                userPoolAmount,
                poolDecimals,
                prices[poolAsset || poolSymbol],
              ),
            )}

          {!(
            (chainId &&
              POOLS[chainId] &&
              POOLS[chainId][poolAddress] &&
              POOLS[chainId][poolAddress][poolId]?.type === 1 &&
              userPoolAmount &&
              currency &&
              currency[chainId]) ||
            (chainId &&
              POOLS[chainId] &&
              POOLS[chainId][poolAddress] &&
              POOLS[chainId][poolAddress][poolId]?.type !== 1 &&
              userPoolAmount &&
              prices &&
              prices[poolAsset || poolSymbol])
          ) && '-'}
        </span>
        <small
          className={cn(styles.valueSmall, {
            [styles['is-empty']]:
              !userPoolAmount || userPoolAmount.lte(BigNumber.from('0')),
          })}
        >
          {chainId &&
          POOLS[chainId] &&
          POOLS[chainId][poolAddress] &&
          POOLS[chainId][poolAddress][poolId]?.type === 4 ? (
            <HoldingList
              userPoolAmountInUnderlying={userPoolAmountInUnderlying}
              isVisibleHidden
              isHiddenComponent={
                POOLS[chainId][poolAddress][poolId]?.isHoldingListHidden
              }
              poolAddress={poolAddress}
              buttonClass={styles.holdingButton}
              buttonText={t('POOLS.POOL_CARD_STATS.YOUR_HOLDINGS')}
              poolId={poolId}
            />
          ) : (
            <>
              {userPoolAmount
                ? formatValueToken(
                    language,
                    Number(formatUnits(userPoolAmount, poolDecimals)),
                  )
                : '-'}{' '}
              {displayiedPoolSymbol}
            </>
          )}
        </small>
      </dd>
    </dl>
  );
};

export default PoolCardStats;
