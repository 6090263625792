import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import cn from 'classnames';

import { getCurrencySelector } from '@modules/prices/slices/currencySlice';
import formatValuePrice from '@modules/common/helpers/formatValuePrice';
import getTokenPrice from '@modules/common/helpers/getTokenPrice';
import StoredCluster from '@modules/clusters/types/StoredCluster';
import { useTypedSelector } from '@utils/store';

import styles from '@modules/clusters/components/ClusterCardBalances/ClusterCardBalances.module.scss';

const ClusterCardBalances: FC<{
  className: string;
  cluster: StoredCluster;
}> = ({ className, cluster }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { chainId } = useWeb3React();

  const currency = useTypedSelector(getCurrencySelector);

  return (
    <dl className={cn(styles.container, className)}>
      <div className={styles.containerItem}>
        <dt className={styles.title}>
          {t('CLUSTERS.CLUSTER_CARD_BALANCES.TOTAL_VALUE')}
        </dt>
        <dd className={styles.value}>
          {cluster.totalSupply &&
          cluster.price &&
          currency &&
          chainId &&
          currency[chainId]
            ? formatValuePrice(
                language,
                getTokenPrice(
                  cluster.totalSupply.mul(cluster.price),
                  cluster.decimals * 2,
                  currency[chainId].price,
                ),
              )
            : '-'}
        </dd>
      </div>

      <div className={styles.containerItem}>
        <dt className={styles.title}>
          {t('CLUSTERS.CLUSTER_CARD_BALANCES.PRICE')}
        </dt>
        <dd className={styles.value}>
          {cluster.price && currency && chainId && currency[chainId]
            ? formatValuePrice(
                language,
                getTokenPrice(
                  cluster.price,
                  cluster.decimals,
                  currency[chainId].price,
                ),
              )
            : '-'}
        </dd>
      </div>
    </dl>
  );
};

export default ClusterCardBalances;
