import { BigNumber } from '@ethersproject/bignumber';

import ContractFunctions from '@modules/common/types/ContractFunctions';

const getAmountsOut = (
  routerContract: ContractFunctions<BigNumber[]>,
  amountIn: BigNumber,
  path: string[],
): Promise<BigNumber[]> => {
  return routerContract.getAmountsOut(amountIn, path);
};

export default getAmountsOut;
