import { BigNumber } from '@ethersproject/bignumber';

import ContractFunctions from '@modules/common/types/ContractFunctions';

const getAmountsIn = (
  routerContract: ContractFunctions<BigNumber[]>,
  amountOut: BigNumber,
  path: string[],
): Promise<BigNumber[]> => {
  return routerContract.getAmountsIn(amountOut, path);
};

export default getAmountsIn;
