import {
  call,
  CallEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import { AnyAction, PayloadAction } from '@reduxjs/toolkit';

import {
  setPoolsApyApr,
  setError,
  setIsLoading,
  setPreError,
} from '@modules/pools/slices/poolsStatsSlice';
import PoolsStatsListStored from '@modules/pools/types/PoolStatsList';

interface ResponseData {
  apr: string;
  apy: string;
}

const API = process.env.REACT_APP_API || 'http://localhost:1234/api';

const getPoolStats = (
  pools: string[],
  chainId: number,
): Promise<AxiosResponse<ResponseData>[]> => {
  return Promise.all(
    pools.map((symbol) =>
      axios.get<ResponseData>(
        `${API}/staking/stats?stakingSymbol=${symbol}&network=${chainId}`,
      ),
    ),
  );
};

function* getPoolStatsWorker({
  payload,
}: PayloadAction<{
  pools: string[];
  chainId: number;
}>): Generator<
  CallEffect<AxiosResponse<ResponseData>[]> | PutEffect<AnyAction>,
  void,
  never
> {
  try {
    yield put(setIsLoading(true));
    yield put(setPreError(null));
    yield put(setError(null));

    const response: AxiosResponse<ResponseData>[] = yield call(
      getPoolStats,
      payload.pools,
      payload.chainId,
    );

    const poolsStats: PoolsStatsListStored = {};

    response.forEach(({ data }: { data: ResponseData }, index) => {
      poolsStats[payload.pools[index]] = {
        apr: Number(data.apr),
        apy: Number(data.apy),
      };
    });

    yield put(setPoolsApyApr(poolsStats));
  } catch (error: unknown) {
    yield put(setPreError(error));
  } finally {
    yield put(setIsLoading(false));
  }
}

function* getPoolStatsSaga(): Generator {
  yield takeLatest('POOLS_GET_POOL_STATS', getPoolStatsWorker);
}

export default getPoolStatsSaga;
