import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';

const getPoolGasLimitUnStake = (
  poolContract: Contract,
  poolId: number,
  amount: BigNumber,
): Promise<BigNumber> => {
  return poolContract.estimateGas.withdraw(poolId, amount);
};

export default getPoolGasLimitUnStake;
