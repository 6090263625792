import { ContractInterface } from '@ethersproject/contracts';

import IERC20 from '@abis/IERC20.json';
import swapRouterAbi from '@abis/Swap.json';
import UNI_SWAP_ICON from '@assets/images/supporters/UniSwap.png';
import PANCAKE_SWAP_ICON from '@assets/images/supporters/PancakeSwap.png';
import HONEY_SWAP_ICON from '@assets/images/supporters/HoneySwap.png';
import QUICK_SWAP_ICON from '@assets/images/supporters/QuickSwap.png';

export interface Swap {
  transactionFee: number; // 0 to 100
  supporter: string;
  slippage: number; // 0 to 100
  icon: string;
  tokenAbi: ContractInterface;
  dhvAddress: string;
  swapRouter: string;
  swapPath: string[];
  swapAbi: ContractInterface;
}

interface SwapByChain {
  [key: number]: Swap;
}

const UNI_SWAP: Swap = {
  transactionFee: 3,
  supporter: 'UniSwap',
  slippage: 4,
  icon: UNI_SWAP_ICON,
  tokenAbi: IERC20,
  dhvAddress: '0x62Dc4817588d53a056cBbD18231d91ffCcd34b2A',
  swapRouter: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  swapPath: [
    '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    '0x62Dc4817588d53a056cBbD18231d91ffCcd34b2A',
  ],
  swapAbi: swapRouterAbi,
};

const PANCAKE_SWAP: Swap = {
  transactionFee: 3,
  supporter: 'PancakeSwap',
  slippage: 4,
  icon: PANCAKE_SWAP_ICON,
  tokenAbi: IERC20,
  dhvAddress: '0x58759dd469ae5631c42cf8a473992335575b58d7',
  swapRouter: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
  swapPath: [
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    '0x58759dd469ae5631c42cf8a473992335575b58d7',
  ],
  swapAbi: swapRouterAbi,
};

const HONEY_SWAP: Swap = {
  transactionFee: 3,
  supporter: 'HoneySwap',
  slippage: 4,
  icon: HONEY_SWAP_ICON,
  tokenAbi: IERC20,
  dhvAddress: '0xFbdd194376de19a88118e84E279b977f165d01b8',
  swapRouter: '0x1C232F01118CB8B424793ae03F870aa7D0ac7f77',
  swapPath: [
    '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d',
    '0xFbdd194376de19a88118e84E279b977f165d01b8',
  ],
  swapAbi: swapRouterAbi,
};

const QUICK_SWAP: Swap = {
  transactionFee: 3,
  supporter: 'QuickSwap',
  slippage: 4,
  icon: QUICK_SWAP_ICON,
  tokenAbi: IERC20,
  dhvAddress: '0x5fCB9de282Af6122ce3518CDe28B7089c9F97b26',
  swapRouter: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
  swapPath: [
    '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    '0x831753DD7087CaC61aB5644b308642cc1c33Dc13',
    '0x5fCB9de282Af6122ce3518CDe28B7089c9F97b26',
  ],
  swapAbi: swapRouterAbi,
};

const SWAP_LIST: SwapByChain = {
  1: UNI_SWAP,
  56: PANCAKE_SWAP,
  100: HONEY_SWAP,
  137: QUICK_SWAP,
};

export default SWAP_LIST;
