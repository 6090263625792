import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import getInitialObject from '@modules/pools/helpers/getInitialObject';
import BooleanList from '@modules/pools/types/BooleanList';
import ErrorList from '@modules/pools/types/ErrorList';
import type { RootState } from '@utils/store';

interface InitialState {
  confirmedList: BooleanList;
  pendingList: BooleanList;
  successList: BooleanList;
  errorList: ErrorList;
}

const initialState: InitialState = {
  confirmedList: getInitialObject(),
  pendingList: getInitialObject(),
  successList: getInitialObject(),
  errorList: getInitialObject(),
};

const reducers = {
  setConfirmedItem(
    state: InitialState,
    action: PayloadAction<{
      poolAddress: string;
      poolId: number;
      isConfirmed: boolean;
    }>,
  ) {
    if (state.confirmedList[action.payload.poolAddress]) {
      state.confirmedList[action.payload.poolAddress][action.payload.poolId] =
        action.payload.isConfirmed;
    } else {
      state.confirmedList[action.payload.poolAddress] = {
        [action.payload.poolId]: action.payload.isConfirmed,
      };
    }
  },
  setPendingItem(
    state: InitialState,
    action: PayloadAction<{
      poolAddress: string;
      poolId: number;
      isPending: boolean;
    }>,
  ) {
    if (state.pendingList[action.payload.poolAddress]) {
      state.pendingList[action.payload.poolAddress][action.payload.poolId] =
        action.payload.isPending;
    } else {
      state.pendingList[action.payload.poolAddress] = {
        [action.payload.poolId]: action.payload.isPending,
      };
    }
  },
  setSuccessItem(
    state: InitialState,
    action: PayloadAction<{
      poolAddress: string;
      poolId: number;
      isSuccess: boolean;
    }>,
  ) {
    if (state.successList[action.payload.poolAddress]) {
      state.successList[action.payload.poolAddress][action.payload.poolId] =
        action.payload.isSuccess;
    } else {
      state.successList[action.payload.poolAddress] = {
        [action.payload.poolId]: action.payload.isSuccess,
      };
    }
  },
  setErrorItem(
    state: InitialState,
    action: PayloadAction<{
      poolAddress: string;
      poolId: number;
      error: unknown | null;
    }>,
  ) {
    if (state.errorList[action.payload.poolAddress]) {
      state.errorList[action.payload.poolAddress][action.payload.poolId] =
        action.payload.error;
    } else {
      state.errorList[action.payload.poolAddress] = {
        [action.payload.poolId]: action.payload.error,
      };
    }
  },
};

const rewardSlice = createSlice({
  name: 'reward',
  initialState,
  reducers,
});

export const confirmedListSelector = (state: RootState): BooleanList =>
  state.reward.confirmedList;
export const pendingListSelector = (state: RootState): BooleanList =>
  state.reward.pendingList;
export const successListSelector = (state: RootState): BooleanList =>
  state.reward.successList;
export const errorListSelector = (state: RootState): ErrorList =>
  state.reward.errorList;

export const {
  setConfirmedItem,
  setPendingItem,
  setSuccessItem,
  setErrorItem,
} = rewardSlice.actions;

export default rewardSlice.reducer;
