import { InjectedConnector } from '@web3-react/injected-connector';
import { useWeb3React } from '@web3-react/core';

import NETWORKS from '@configs/networks';
import Connector from '@modules/connections/types/Connector';
import icon from '@modules/connections/assets/metamask_logo.svg';

const Injected = (): (() => Promise<void>) => {
  const { activate } = useWeb3React();

  const network = new InjectedConnector({
    supportedChainIds: NETWORKS.map(({ chainId }) => chainId),
  });

  return () => activate(network);
};

const InjectedDisconnect = (): (() => void) => {
  const { deactivate } = useWeb3React();

  return () => deactivate();
};

const connector: Connector = {
  type: 'injected',
  name: 'MetaMask',
  icon,
  connect: Injected,
  disconnect: InjectedDisconnect,
};

export default connector;
