import ClusterDetails from '@modules/clusters/components/ClusterDetails';

const page = {
  path: '/clusters/:path',
  order: 2,
  title: 'CLUSTERS.CLUSTERS_DETAILS.NAV_LINK_NAME',
  component: ClusterDetails,
};

export default page;
