import Main from '@modules/main/components/Main';
import ICON from '@modules/main/assets/pages/main_logo.svg';

const page = {
  path: '/',
  menu: 1,
  icon: ICON,
  order: 0,
  title: 'MAIN.MAIN.NAV_LINK_NAME',
  component: Main,
};

export default page;
