import { AnyAction } from '@reduxjs/toolkit';
import {
  call,
  CallEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';

import {
  setIsLoading,
  setPreError,
  setError,
  setList,
} from '@modules/main/slices/productsSlice';
import StoredProducts from '@modules/main/types/StoredProducts';

const API = process.env.REACT_APP_API || 'http://localhost:1234/api';

const getProducts = (): Promise<AxiosResponse<StoredProducts>> => {
  return axios.get<StoredProducts>(`${API}/tvl/products`);
};

function* getProductsWorker(): Generator<
  CallEffect<AxiosResponse<StoredProducts>> | PutEffect<AnyAction>,
  void,
  never
> {
  try {
    yield put(setIsLoading(true));
    yield put(setPreError(null));
    yield put(setError(null));

    const { data }: { data: StoredProducts } = yield call(getProducts);

    yield put(setList(data));
  } catch (error: unknown) {
    yield put(setPreError(error));
  } finally {
    yield put(setIsLoading(false));
  }
}

function* getProductsSaga(): Generator {
  yield takeLatest('MAIN_GET_PRODUCTS', getProductsWorker);
}

export default getProductsSaga;
