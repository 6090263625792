import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { BigNumber } from '@ethersproject/bignumber';
import { Tooltip } from 'antd';
import cn from 'classnames';

import getTokenScanLink from '@modules/clusters/helpers/getTokenScanLink';
import {
  isLoadingSelector,
  listSelector,
} from '@modules/underlying/slices/underlyingSlice';
import { useTypedSelector } from '@utils/store';
import StoredClusterUnderlying from '@modules/clusters/types/StoredClusterUnderlying';
import LOADING_IMAGE from '@modules/common/assets/loading.svg';
import UNDERLYING_LIST from '@configs/underlying';

import styles from '@modules/clusters/components/UnderlyingList/UnderlyingList.module.scss';

const UnderlyingList: FC<{
  clusterUnderlyingList: StoredClusterUnderlying[];
  className: string;
}> = ({ clusterUnderlyingList, className }) => {
  const { t } = useTranslation();

  const underlyingList = useTypedSelector(listSelector);
  const isLoading = useTypedSelector(isLoadingSelector);

  const { chainId } = useWeb3React();

  return chainId ? (
    <aside
      className={cn(
        styles.container,
        { [styles['is-loading']]: isLoading },
        className,
      )}
    >
      <h4 className={styles.title}>{t('CLUSTERS.UNDERLYING_LIST.TITLE')}</h4>

      {underlyingList && (
        <ul className={styles.list}>
          {clusterUnderlyingList.map(
            ({ address, share }) =>
              UNDERLYING_LIST[underlyingList[address]?.symbol]?.icon &&
              share.gt(BigNumber.from('0')) && (
                <li className={styles.listItem} key={address}>
                  <a
                    className={styles.listLink}
                    target="_blank"
                    href={getTokenScanLink(chainId, address)}
                    rel="noreferrer"
                  >
                    <Tooltip
                      overlayClassName={styles.listTooltip}
                      placement="topLeft"
                      trigger="hover"
                      title={underlyingList[address].symbol}
                    >
                      <img
                        className={styles.listImage}
                        height="19"
                        width="19"
                        src={
                          UNDERLYING_LIST[underlyingList[address].symbol].icon
                        }
                        alt={underlyingList[address].symbol}
                      />
                    </Tooltip>
                  </a>
                </li>
              ),
          )}
        </ul>
      )}

      {isLoading && (
        <img
          className={styles.loading}
          height="19"
          width="19"
          src={LOADING_IMAGE}
          alt=""
        />
      )}
    </aside>
  ) : null;
};

export default UnderlyingList;
