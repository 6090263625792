import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import {
  CallEffect,
  PutEffect,
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { JsonRpcProvider } from '@ethersproject/providers';
import { ContractInterface } from '@ethersproject/contracts';

import {
  setIsLoading,
  setListItem,
  setPreError,
  setError,
} from '@modules/pools/slices/poolsSlice';
import getPoolAllowance from '@modules/pools/helpers/getPoolAllowance';
import getPoolAsset from '@modules/pools/helpers/getPoolAsset';
import getPoolData from '@modules/pools/helpers/getPoolData';
import getPoolDHV from '@modules/pools/helpers/getPoolDHV';
import getClusterPriceHelper from '@modules/pools/helpers/getClusterPrice';
import StoredPool from '@modules/pools/types/StoredPool';
import { PoolUnderlying, PoolList } from '@configs/pools';
import { ControllerData } from '@configs/controllers';

const getPool = async ({
  poolTokenABI,
  poolAddress,
  poolDHVAddress,
  poolDHVABI,
  underlying,
  poolType,
  poolABI,
  poolId,
  isMulti,
  isCluster,
  isImpulse,
  provider,
  controller,
  account,
}: {
  poolTokenABI: ContractInterface;
  poolAddress: string;
  poolDHVAddress?: string;
  poolDHVABI?: ContractInterface;
  underlying?: PoolUnderlying[];
  poolType: number | null;
  poolABI: ContractInterface;
  poolId: number;
  isMulti: boolean;
  isCluster: boolean;
  isImpulse: boolean;
  provider: JsonRpcProvider;
  controller: ControllerData;
  account?: string;
}): Promise<StoredPool> => {
  const poolInfo = await getPoolData(
    poolAddress,
    poolABI,
    poolId,
    isMulti,
    isCluster,
    isImpulse,
    provider,
    account,
  );

  const poolAsset = await getPoolAsset(
    poolInfo[0].assetToken,
    poolTokenABI,
    provider,
    account,
  );

  const poolAllowance = await getPoolAllowance(
    isMulti && underlying ? underlying : [{ address: poolInfo[0].assetToken }],
    poolTokenABI,
    poolAddress,
    provider,
    account,
  );

  let result: StoredPool = {
    name: poolAsset[0],
    type: poolType,
    symbol: poolAsset[1],
    decimals: poolAsset[2],
    paused: poolInfo[0].paused,
    tokenAddress: poolInfo[0].assetToken,
    allowance: poolAllowance,
    poolSupply: poolInfo[0].poolSupply,
    userBalance: poolAsset[3],
    yieldBalance: poolInfo[4] || poolInfo[5],
    pendingRewards: poolInfo[1],
    userPoolAmount: poolInfo[2],
    userPoolAmountInUnderlying: poolInfo[3],
  };

  if (poolType === 1) {
    const clusterPrice = await getClusterPriceHelper(
      poolInfo[0].assetToken,
      provider,
      controller,
    );

    result = {
      ...result,
      clusterPrice,
    };
  }

  if (poolDHVAddress && poolDHVABI) {
    const poolDHV = await getPoolDHV(
      poolInfo[0].assetToken,
      poolDHVAddress,
      poolDHVABI,
      poolId,
      provider,
      account,
    );

    if (
      poolDHV[4].assetToken !== '0x0000000000000000000000000000000000000000'
    ) {
      const poolDHVAsset = await getPoolAsset(
        poolDHV[4].assetToken,
        poolTokenABI,
        provider,
        account,
      );
      result = {
        ...result,
        сlusterRateAccuracy: poolDHV[2],
        clusterRate: poolDHV[3],
        userPoolAmountDHV: poolDHV[1],
        userTotalLockedDHV: poolDHV[0],
        symbolDHV: poolDHVAsset[1],
        decimalsDHV: poolDHVAsset[2],
        userBalanceDHV: poolDHVAsset[3],
      };
    }
  }

  return result;
};

function* getPoolWorker({
  payload,
}: PayloadAction<{
  poolAddress: string;
  poolId: number;
  pools: PoolList;
  controller: ControllerData;
  library: JsonRpcProvider;
  account?: string;
}>): Generator<CallEffect<StoredPool> | PutEffect<AnyAction>, void, never> {
  try {
    yield put(setIsLoading(true));
    yield put(setPreError(null));
    yield put(setError(null));

    const poolResult: StoredPool = yield call(getPool, {
      poolTokenABI: payload.pools[payload.poolAddress][payload.poolId].tokenABI,
      poolAddress: payload.poolAddress,
      poolDHVAddress:
        payload.pools[payload.poolAddress][payload.poolId].dhvAddress,
      poolDHVABI: payload.pools[payload.poolAddress][payload.poolId].dhvABI,
      underlying: payload.pools[payload.poolAddress][payload.poolId].underlying,
      poolType: payload.pools[payload.poolAddress][payload.poolId].type,
      poolABI: payload.pools[payload.poolAddress][payload.poolId].poolABI,
      poolId: payload.poolId,
      isMulti: payload.pools[payload.poolAddress][payload.poolId].type === 4,
      isCluster: payload.pools[payload.poolAddress][payload.poolId].type === 1,
      isImpulse:
        payload.pools[payload.poolAddress][payload.poolId].type === 3 ||
        payload.pools[payload.poolAddress][payload.poolId].type === 4,
      provider: payload.library,
      controller: payload.controller,
      account: payload.account,
    });

    yield put(
      setListItem({
        poolAddress: payload.poolAddress,
        poolId: payload.poolId,
        pool: poolResult,
      }),
    );
  } catch (error: unknown) {
    yield put(setPreError(error));
  } finally {
    yield put(setIsLoading(false));
  }
}

function* getPoolSaga(): Generator {
  yield takeLatest('POOLS_GET_POOL_REQUESTED', getPoolWorker);
}

export default getPoolSaga;
