import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '@modules/layout/hooks/useMediaQuery';
import { Pool } from '@configs/pools';

import styles from './PoolStakeFormDesc.module.scss';

const PoolStakeFormDesc: FC<{
  tokensCount: number;
  text: Pool['platformDescription'];
}> = ({ tokensCount, text }) => {
  const [isLessText, setIsLessText] = useState<boolean>(true);

  const { t } = useTranslation();

  const isMobile = useMediaQuery(991);

  const descriptionText: string = useMemo(
    () => t(text || '', { count: tokensCount }),
    [t, text, tokensCount],
  );

  return (
    <p className={styles.text}>
      {descriptionText.slice(
        0,
        isLessText && isMobile ? 78 : descriptionText.length,
      )}
      {isLessText && isMobile && '... '}
      {isLessText && isMobile && (
        <button
          className={styles.textMore}
          onClick={() => setIsLessText(false)}
          type="button"
        >
          {t('POOLS.POOL_STAKE_FORM_DESC.READ_MORE')}
        </button>
      )}
    </p>
  );
};

export default PoolStakeFormDesc;
