import { notification } from 'antd';

const errorOpenModalUnstake = (): void => {
  notification.error({
    message: 'Unstake pool',
    description: 'Here is not available a pool that you try to unstake.',
  });
};

export default errorOpenModalUnstake;
