import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import formatValuePrice from '@modules/common/helpers/formatValuePrice';
import {
  isLoadingSelector,
  listSelector,
} from '@modules/main/slices/productsSlice';
import ButtonLink from '@modules/common/components/ButtonLink';
import { useTypedSelector } from '@utils/store';
import LOADING_IMAGE from '@modules/common/assets/loading.svg';

import styles from './ProductCard.module.scss';

const ProductCard: FC<{
  type: string;
  link: string;
  image: string;
  title: string;
  subtitle: string;
  description: string;
}> = ({ type, link, image, title, subtitle, description }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const isLoading = useTypedSelector(isLoadingSelector);
  const list = useTypedSelector(listSelector);

  return (
    <article className={styles.container}>
      <Link className={styles.logo} to={link}>
        <img className={styles.logoImage} src={image} alt={title} />
      </Link>

      <Link className={styles.title} to={link}>
        <h3 className={styles.titleText}>{title}</h3>
      </Link>

      <dl className={styles.deposit}>
        <dt className={styles.depositTitle}>
          {t('MAIN.PRODUCT_CARD.DEPOSIT')}
        </dt>

        <dd
          className={cn(styles.depositDescription, {
            [styles['is-loading']]: isLoading,
          })}
        >
          <strong className={styles.depositDescriptionValue}>
            {list
              ? formatValuePrice(
                  language,
                  type === 'dhv'
                    ? Number(list[type]) + Number(list.lp)
                    : Number(list[type]),
                )
              : '-'}
          </strong>

          {isLoading && (
            <img
              className={styles.depositLoading}
              height="14"
              width="14"
              src={LOADING_IMAGE}
              alt="Loading"
            />
          )}
        </dd>
      </dl>

      <h4 className={styles.subtitle}>{subtitle}</h4>

      <p className={styles.description}>{description}</p>

      <ButtonLink
        className={styles.link}
        size="big"
        text={t('MAIN.PRODUCT_CARD.LINK')}
        path={link}
      />
    </article>
  );
};

export default ProductCard;
