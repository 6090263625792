import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import 'antd/dist/antd.css';

import store from '@utils/store';
import pages from '@utils/pages';
import '@utils/i18next';
import '@utils/types';

import '@assets/styles/fonts.scss';
import '@assets/styles/modals.scss';
import '@assets/styles/tooltips.scss';
import '@assets/styles/notification.scss';

import NetworkSelectorWrapper from '@modules/networks/components/NetworkSelectorWrapper';
import Web3ReactWrapper from '@modules/connections/components/Web3ReactWrapper';
import IntercomWrapper from '@modules/intercom/components/IntercomWrapper';
import Layout from '@modules/layout/components/Layout';

function App(): JSX.Element {
  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_TAG_MANAGER) {
      TagManager.initialize({
        gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER,
      });
    }
  }, []);

  return (
    <Provider store={store}>
      <NetworkSelectorWrapper>
        <Web3ReactWrapper>
          <IntercomWrapper>
            <BrowserRouter>
              <Layout>
                <Switch>
                  {pages.map(({ path, title, component }) => (
                    <Route
                      component={component}
                      path={path}
                      exact
                      key={title}
                    />
                  ))}
                </Switch>
              </Layout>
            </BrowserRouter>
          </IntercomWrapper>
        </Web3ReactWrapper>
      </NetworkSelectorWrapper>
    </Provider>
  );
}

export default App;
