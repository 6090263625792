import { ContractInterface } from '@ethersproject/contracts';

import ImpulseStakingMulti from '@abis/ImpulseStakingMulti.json';
import ImpulseStaking from '@abis/ImpulseStaking.json';
import StakingPools from '@abis/StakingPools.json';
import ClustersLock from '@abis/ClustersLock.json';
import StakingDHV from '@abis/StakingDHV.json';
import IERC20 from '@abis/IERC20.json';

// DHV pool icons
import DHV_ICON from '@assets/images/pools/DHV.png';
import DHV_ICON_TOKEN from '@assets/images/pools/DHV_token.png';
// Cluster pool icons
import DBSC_TOKEN_ICON from '@assets/images/clusters/DBSC_token.svg';
import DBSC_ICON from '@assets/images/clusters/DBSC.svg';
import DGAME_TOKEN_ICON from '@assets/images/clusters/DGAME_token.svg';
import DGAME_ICON from '@assets/images/clusters/DGAME.svg';
import DPOL_TOKEN_ICON from '@assets/images/clusters/DPOL_token.svg';
import DPOL_ICON from '@assets/images/clusters/DPOL.svg';
import DXIN_TOKEN_ICON from '@assets/images/clusters/DXIN_token.svg';
import DXIN_ICON from '@assets/images/clusters/DXIN.svg';
import DXDC_TOKEN_ICON from '@assets/images/clusters/DXDC_token.svg';
import DXDC_ICON from '@assets/images/clusters/DXDC.svg';
// LP pool icons
import UNI_DHV_WETH_ICON from '@assets/images/pools/UNI-DHV-WETH.png';
import PANCAKE_DHV_BUSD_ICON from '@assets/images/pools/PANCAKE-DHV-BUSD.png';
import HONEY_DHV_WXDAI_ICON from '@assets/images/pools/HONEY-DHV-WXDAI.png';
// Impulse pool icons
import DHV_QUICK_ICON from '@assets/images/pools/DHV-QUICK-IMPULSE.png';
import WETH_DAI_ICON from '@assets/images/pools/WETH-DAI-IMPULSE.png';
import USDC_MATIC_ICON from '@assets/images/pools/USDC-MATIC-IMPULSE.png';
import MAI_USDT_ICON from '@assets/images/pools/MAI-USDT-IMPULSE.png';
import WMATIC_WETH_ICON from '@assets/images/pools/WMATIC-WETH-IMPULSE.png';
import CRV_WETH_ICON from '@assets/images/pools/CRV-WETH-IMPULSE.png';
import SNX_WETH_ICON from '@assets/images/pools/SNX-WETH-IMPULSE.png';
import WMATIC_GHST_ICON from '@assets/images/pools/WMATIC-GHST-IMPULSE.png';
import CAKE_BUSD_ICON from '@assets/images/pools/CAKE-BUSD-IMPULSE.png';
import BUSDT_USDC_ICON from '@assets/images/pools/BUSDT-USDC-IMPULSE.png';
import BUSDT_WBNB_ICON from '@assets/images/pools/BUSDT-WBNB-IMPULSE.png';
import XVS_WBNB_ICON from '@assets/images/pools/XVS-WBNB-IMPULSE.png';
import ALPACA_BUSD_ICON from '@assets/images/pools/ALPACA-BUSD-IMPULSE.png';
import LINK_WBNB_ICON from '@assets/images/pools/LINK-WBNB-IMPULSE.png';
import CAKE_BUSDT_ICON from '@assets/images/pools/CAKE-BUSDT-IMPULSE.png';
import DODO_WBNB_ICON from '@assets/images/pools/DODO-WBNB-IMPULSE.png';
import BANANA_WBNB_ICON from '@assets/images/pools/BANANA-WBNB-IMPULSE.png';
import BANANA_BUSD_ICON from '@assets/images/pools/BANANA-BUSD-IMPULSE.png';
import TWT_WBNB_ICON from '@assets/images/pools/TWT-WBNB-IMPULSE.png';
import AVAX_WBNB_ICON from '@assets/images/pools/AVAX-WBNB-IMPULSE.png';
import USDC_QUICK_ICON from '@assets/images/pools/USDC-QUICK-IMPULSE.png';
import AVAX_WETH_ICON from '@assets/images/pools/AVAX-WETH-IMPULSE.png';
// Impulse-multi pool icons
import CRV_EURTUSD_IMPULSE_ICON from '@assets/images/pools/CRV-EURTUSD-IMPULSE.png';
import CRV_IMPULSE_ICON from '@assets/images/pools/CRV-IMPULSE.png';
import CRV_AAVE_ICON from '@assets/images/pools/CRV-AAVE-IMPULSE.png';
import CRV_3_ICON from '@assets/images/pools/CRV-3-IMPULSE.png';
import NOMO_ICON from '@assets/images/pools/NOMO-IMPULSE.png';
// Platform icons
import QUICK_SWAP_ICON from '@assets/images/platforms/QuickSwap.png';
import CURVE_ICON from '@assets/images/platforms/Curve.png';
// Supporter icons
import POWERED_NOMO_ICON from '@assets/images/supporters/LeagueDAO.png';

export interface PoolUnderlying {
  address: string;
  isHidden?: boolean;
}

export interface TimerRewards {
  timestamp: number;
  list: string[];
}

export interface IPoweredBy {
  name: string;
  icon: string;
  link: string;
}

export interface Pool {
  tokenABI: ContractInterface;
  poolABI: ContractInterface;
  dhvAddress?: string;
  dhvABI?: ContractInterface;
  description: string;
  underlying?: PoolUnderlying[];
  pendingRewards?: string[];
  timerRewards?: TimerRewards;
  isAccumulated?: boolean;
  isClaimed?: boolean;
  platformDescription?: string;
  platformIcon?: string;
  platform: string;
  getLink?: string;
  closed: boolean;
  symbol: string;
  asset?: string;
  name?: string;
  type: number | null;
  icon: string;
  tokenIcon?: string;
  poweredBy?: IPoweredBy;
  isHoldingListHidden?: boolean;
}

export interface PoolList {
  [key: string]: {
    [key: number]: Pool;
  };
}

interface PoolListByChain {
  [key: number]: PoolList;
}

// Solo pools
const DHV: Pool = {
  tokenABI: IERC20,
  poolABI: StakingDHV,
  tokenIcon: DHV_ICON_TOKEN,
  icon: DHV_ICON,
  description:
    'Core staking program of the DeHive protocol. Stake DHV to cover the ability for Cluster activation for yield farming. And be incentivized with more DHV.', // eslint-disable-line
  platform: 'DeHive platform',
  type: 0,
  closed: false,
  symbol: 'DHV',
  name: 'DHV',
  isClaimed: true,
};

// Cluster pools
const DBSC: Pool = {
  tokenABI: IERC20,
  poolABI: ClustersLock,
  tokenIcon: DBSC_TOKEN_ICON,
  icon: DBSC_ICON,
  description:
    'Cluster is the yield generating index. Stake the BSC-deCluster to activate yield farming and earn on the index underlyings with just holding a Cluster.', // eslint-disable-line
  platform: 'DeHive platform',
  type: 1,
  closed: false,
  symbol: 'DBSC',
  name: 'De-BSC',
  isClaimed: true,
};

const DPOL: Pool = {
  tokenABI: IERC20,
  poolABI: ClustersLock,
  tokenIcon: DPOL_TOKEN_ICON,
  icon: DPOL_ICON,
  description:
    'Cluster is the yield generating index. Stake the Poly Cluster to activate yield farming and earn on the index underlyings with just holding a Cluster.', // eslint-disable-line
  platform: 'DeHive platform',
  type: 1,
  closed: false,
  symbol: 'DPOL',
  name: 'Poly',
  isClaimed: true,
};

const DGAME: Pool = {
  tokenABI: IERC20,
  poolABI: ClustersLock,
  tokenIcon: DGAME_TOKEN_ICON,
  icon: DGAME_ICON,
  description:
    'Cluster is the yield generating index. Stake the Gaming Cluster to get incentivisation and earn on the index underlyings with just holding a Cluster.', // eslint-disable-line
  platform: 'DeHive platform',
  type: 1,
  closed: false,
  symbol: 'DGAME',
  isClaimed: true,
};

const DXIN: Pool = {
  tokenABI: IERC20,
  poolABI: ClustersLock,
  tokenIcon: DXIN_TOKEN_ICON,
  icon: DXIN_ICON,
  description:
    'Cluster is the yield generating index. Stake the xInfra Cluster to activate yield farming and earn on the index underlyings with just holding a Cluster.', // eslint-disable-line
  platform: 'DeHive platform',
  type: 1,
  closed: false,
  symbol: 'DXIN',
  name: 'xInfra',
  isClaimed: true,
};

const DXDC: Pool = {
  tokenABI: IERC20,
  poolABI: ClustersLock,
  tokenIcon: DXDC_TOKEN_ICON,
  icon: DXDC_ICON,
  description:
    'Cluster is the yield generating index. Stake the de-xDai Cluster to activate yield farming and earn on the index underlyings with just holding a Cluster.', // eslint-disable-line
  platform: 'DeHive platform',
  type: 1,
  closed: false,
  symbol: 'DXDC',
  name: 'De-Gnosis',
  isClaimed: true,
};

// LP pools
const UNI_DHV_WETH: Pool = {
  tokenABI: IERC20,
  poolABI: StakingPools,
  icon: UNI_DHV_WETH_ICON,
  description:
    'Liquidity mining program for the Uniswap DHV and ETH liquidity pair. Provide liquidity and stake LPs to get incentivization in DHV.', // eslint-disable-line
  platform: 'Uniswap',
  type: 2,
  closed: false,
  symbol: 'UNI-DHV-WETH',
  isClaimed: true,
};

const PANCAKE_DHV_BUSD: Pool = {
  tokenABI: IERC20,
  poolABI: StakingPools,
  icon: PANCAKE_DHV_BUSD_ICON,
  description:
    'Liquidity mining program for the PancakeSwap DHV and BUSD liquidity pair. Provide liquidity and stake LPs to get incentivization in DHV.', // eslint-disable-line
  platform: 'DeHive platform',
  type: 2,
  closed: false,
  symbol: 'PANCAKE-DHV-BUSD',
  isClaimed: true,
};

const HONEY_DHV_WXDAI: Pool = {
  tokenABI: IERC20,
  poolABI: StakingPools,
  icon: HONEY_DHV_WXDAI_ICON,
  description:
    'Liquidity mining program for the HoneySwap DHV and XDAI liquidity pair. Provide liquidity and stake LPs to get incentivization in DHV.', // eslint-disable-line
  platform: 'DeHive platform',
  type: 2,
  closed: false,
  symbol: 'HONEY-DHV-WXDAI',
  isClaimed: true,
};

// Impulse pools
const CAKE_BUSD_IMPULSE: Pool = {
  tokenABI: IERC20,
  poolABI: ImpulseStaking,
  icon: CAKE_BUSD_ICON,
  description:
    'Impulse is an automatic compounding tool that enables hourly compound interest on your LP tokens. Get more LP tokens in return and increase your APY. CAKE-BUSD Impulse works on the PancakeSwap farm, compounding CAKE for CAKE and BUSD liquidity pair.', // eslint-disable-line
  platform: 'PancakeSwap',
  type: 3,
  closed: true,
  symbol: 'CAKE-BUSD-IMPULSE',
  asset: 'PANCAKE-CAKE-BUSD',
};

const BUSDT_USDC_IMPULSE: Pool = {
  tokenABI: IERC20,
  poolABI: ImpulseStaking,
  icon: BUSDT_USDC_ICON,
  description:
    'Impulse is an automatic compounding tool that enables hourly compound interest on your LP tokens. Get more LP tokens in return and increase your APY. USDT-USDC Impulse works on the PancakeSwap farm, compounding CAKE for USDT and USDC liquidity pair.', // eslint-disable-line
  platform: 'PancakeSwap',
  type: 3,
  closed: true,
  symbol: 'BUSDT-USDC-IMPULSE',
  asset: 'PANCAKE-BUSDT-USDC',
};

const BUSDT_WBNB_IMPULSE: Pool = {
  tokenABI: IERC20,
  poolABI: ImpulseStaking,
  icon: BUSDT_WBNB_ICON,
  description:
    'Impulse is an automatic compounding tool that enables hourly compound interest on your LP tokens. Get more LP tokens in return and increase your APY. USDT-WBNB Impulse works on the PancakeSwap farm, compounding CAKE for USDT and WBNB liquidity pair.', // eslint-disable-line
  platform: 'PancakeSwap',
  type: 3,
  closed: true,
  symbol: 'BUSDT-WBNB-IMPULSE',
  asset: 'PANCAKE-BUSDT-WBNB',
};

const XVS_WBNB_IMPULSE: Pool = {
  tokenABI: IERC20,
  poolABI: ImpulseStaking,
  icon: XVS_WBNB_ICON,
  description:
    'Impulse is an automatic compounding tool that enables hourly compound interest on your LP tokens. Get more LP tokens in return and increase your APY. XVS-WBNB Impulse works on the PancakeSwap farm, compounding CAKE for XVS and WBNB liquidity pair.', // eslint-disable-line
  platform: 'PancakeSwap',
  type: 3,
  closed: true,
  symbol: 'XVS-WBNB-IMPULSE',
  asset: 'PANCAKE-XVS-WBNB',
};

const ALPACA_BUSD_IMPULSE: Pool = {
  tokenABI: IERC20,
  poolABI: ImpulseStaking,
  icon: ALPACA_BUSD_ICON,
  description:
    'Impulse is an automatic compounding tool that enables hourly compound interest on your LP tokens. Get more LP tokens in return and increase your APY. ALPACA-BUSD Impulse works on the PancakeSwap farm, compounding CAKE for ALPACA and BUSD liquidity pair.', // eslint-disable-line
  platform: 'PancakeSwap',
  type: 3,
  closed: true,
  symbol: 'ALPACA-BUSD-IMPULSE',
  asset: 'PANCAKE-ALPACA-BUSD',
};

const LINK_WBNB_IMPULSE: Pool = {
  tokenABI: IERC20,
  poolABI: ImpulseStaking,
  icon: LINK_WBNB_ICON,
  description:
    'Impulse is an automatic compounding tool that enables hourly compound interest on your LP tokens. Get more LP tokens in return and increase your APY. LINK-WBNB Impulse works on the PancakeSwap farm, compounding CAKE for LINK and WBNB liquidity pair.', // eslint-disable-line
  platform: 'PancakeSwap',
  type: 3,
  closed: true,
  symbol: 'LINK-WBNB-IMPULSE',
  asset: 'PANCAKE-LINK-WBNB',
};

const CAKE_BUSDT_IMPULSE: Pool = {
  tokenABI: IERC20,
  poolABI: ImpulseStaking,
  icon: CAKE_BUSDT_ICON,
  description:
    'Impulse is an automatic compounding tool that enables hourly compound interest on your LP tokens. Get more LP tokens in return and increase your APY. CAKE-USDT Impulse works on the PancakeSwap farm, compounding CAKE for CAKE and USDT liquidity pair.', // eslint-disable-line
  platform: 'PancakeSwap',
  type: 3,
  closed: true,
  symbol: 'CAKE-BUSDT-IMPULSE',
  asset: 'PANCAKE-CAKE-BUSDT',
};

const DODO_WBNB_IMPULSE: Pool = {
  tokenABI: IERC20,
  poolABI: ImpulseStaking,
  icon: DODO_WBNB_ICON,
  description:
    'Impulse is an automatic compounding tool that enables hourly compound interest on your LP tokens. Get more LP tokens in return and increase your APY. DODO-WBNB Impulse works on the PancakeSwap farm, compounding CAKE for DODO and WBNB liquidity pair.', // eslint-disable-line
  platform: 'PancakeSwap',
  type: 3,
  closed: true,
  symbol: 'DODO-WBNB-IMPULSE',
  asset: 'PANCAKE-DODO-WBNB',
};

const BANANA_WBNB_IMPULSE: Pool = {
  tokenABI: IERC20,
  poolABI: ImpulseStaking,
  icon: BANANA_WBNB_ICON,
  description:
    'Impulse is an automatic compounding tool that enables hourly compound interest on your LP tokens. Get more LP tokens in return and increase your APY. BANANA-WBNB Impulse works on the ApeSwap farm, compounding BANANA for BANANA and WBNB liquidity pair.', // eslint-disable-line
  platform: 'ApeSwap',
  type: 3,
  closed: true,
  symbol: 'BANANA-WBNB-IMPULSE',
  asset: 'APE-BANANA-WBNB',
};

const BANANA_BUSD_IMPULSE: Pool = {
  tokenABI: IERC20,
  poolABI: ImpulseStaking,
  icon: BANANA_BUSD_ICON,
  description:
    'Impulse is an automatic compounding tool that enables hourly compound interest on your LP tokens. Get more LP tokens in return and increase your APY. BANANA-BUSD Impulse works on the ApeSwap farm, compounding BANANA for BANANA and BUSD liquidity pair.', // eslint-disable-line
  platform: 'ApeSwap',
  type: 3,
  closed: true,
  symbol: 'BANANA-BUSD-IMPULSE',
  asset: 'APE-BANANA-BUSD',
};

const TWT_WBNB_IMPULSE: Pool = {
  tokenABI: IERC20,
  poolABI: ImpulseStaking,
  icon: TWT_WBNB_ICON,
  description:
    'Impulse is an automatic compounding tool that enables hourly compound interest on your LP tokens. Get more LP tokens in return and increase your APY. TWT-WBNB Impulse works on the ApeSwap farm, compounding BANANA for TWT and WBNB liquidity pair.', // eslint-disable-line
  platform: 'ApeSwap',
  type: 3,
  closed: true,
  symbol: 'TWT-WBNB-IMPULSE',
  asset: 'APE-TWT-WBNB',
};

const AVAX_WBNB_IMPULSE: Pool = {
  tokenABI: IERC20,
  poolABI: ImpulseStaking,
  icon: AVAX_WBNB_ICON,
  description:
    'Impulse is an automatic compounding tool that enables hourly compound interest on your LP tokens. Get more LP tokens in return and increase your APY. AVAX-WBNB Impulse works on the ApeSwap farm, compounding BANANA for AVAX and WBNB liquidity pair.', // eslint-disable-line
  platform: 'ApeSwap',
  type: 3,
  closed: true,
  symbol: 'AVAX-WBNB-IMPULSE',
  asset: 'APE-AVAX-WBNB',
};

const DHV_QUICK_IMPULSE: Pool = {
  tokenABI: IERC20,
  poolABI: ImpulseStaking,
  icon: DHV_QUICK_ICON,
  description:
    'Impulse is an automatic compounding tool that enables hourly compound interest on your LP tokens. Get more LP tokens in return and increase your APY. DHV-QUICK Impulse works on the QuickSwap liquidity pool, compounding dQuick for DHV and Quick liquidity pair.', // eslint-disable-line
  platform: 'QuickSwap',
  type: 3,
  closed: true,
  symbol: 'DHV-QUICK-IMPULSE',
  asset: 'QCK-DHV-QUICK',
};

const WETH_DAI_IMPULSE: Pool = {
  tokenABI: IERC20,
  poolABI: ImpulseStaking,
  icon: WETH_DAI_ICON,
  description:
    'Impulse is an automatic compounding tool that enables hourly compound interest on your LP tokens. Get more LP tokens in return and increase your APY. WETH-DAI Impulse works on the QuickSwap liquidity pool, compounding dQuick for Weth and DAI liquidity pair.', // eslint-disable-line
  platform: 'QuickSwap',
  type: 3,
  closed: false,
  symbol: 'WETH-DAI-IMPULSE',
  asset: 'QCK-WETH-DAI',
};

const USDC_QUICK_IMPULSE: Pool = {
  tokenABI: IERC20,
  poolABI: ImpulseStaking,
  icon: USDC_QUICK_ICON,
  description:
    'Impulse is an automatic compounding tool that enables hourly compound interest on your LP tokens. Get more LP tokens in return and increase your APY. USDC-QUICK Impulse works on the QuickSwap liquidity pool, compounding dQuick for USDC and Quick liquidity pair.', // eslint-disable-line
  platform: 'QuickSwap',
  type: 3,
  closed: false,
  symbol: 'USDC-QUICK-IMPULSE',
  asset: 'QCK-USDC-QUICK',
};

const USDC_MATIC_IMPULSE: Pool = {
  tokenABI: IERC20,
  poolABI: ImpulseStaking,
  icon: USDC_MATIC_ICON,
  description:
    'Impulse is an automatic compounding tool that enables hourly compound interest on your LP tokens. Get more LP tokens in return and increase your APY. USDC-MATIC Impulse works on the QuickSwap Dual Mining pool, compounding both WMatic and dQuick for USDC and Matic liquidity pair.', // eslint-disable-line
  platform: 'QuickSwap',
  type: 3,
  closed: false,
  symbol: 'USDC-MATIC-IMPULSE',
  asset: 'QCK-USDC-WMATIC',
};

const MAI_USDT_IMPULSE: Pool = {
  tokenABI: IERC20,
  poolABI: ImpulseStaking,
  icon: MAI_USDT_ICON,
  description:
    'Impulse is an automatic compounding tool that enables hourly compound interest on your LP tokens. Get more LP tokens in return and increase your APY. USDT-MAI Impulse works on the QuickSwap liquidity pool, compounding dQuick for USDT and MAI liquidity pair.', // eslint-disable-line
  platform: 'QuickSwap',
  type: 3,
  closed: false,
  symbol: 'MAI-USDT-IMPULSE',
  asset: 'QCK-MAI-USDT',
};

const AVAX_WETH_IMPULSE: Pool = {
  tokenABI: IERC20,
  poolABI: ImpulseStaking,
  icon: AVAX_WETH_ICON,
  description:
    'Impulse is an automatic compounding tool that enables hourly compound interest on your LP tokens. Get more LP tokens in return and increase your APY. AVAX-WETH Impulse works on the SushiSwap farm, compounding Matic for Avax and Weth liquidity pair.', // eslint-disable-line
  platform: 'SushiSwap',
  type: 3,
  closed: false,
  symbol: 'AVAX-WETH-IMPULSE',
  asset: 'SUSHI-AVAX-WETH',
};

const WMATIC_WETH_IMPULSE: Pool = {
  tokenABI: IERC20,
  poolABI: ImpulseStaking,
  icon: WMATIC_WETH_ICON,
  description:
    'Impulse is an automatic compounding tool that enables hourly compound interest on your LP tokens. Get more LP tokens in return and increase your APY. WMATIC-WETH Impulse works on the SushiSwap farm, compounding Matic for WMatic and Weth liquidity pair.', // eslint-disable-line
  platform: 'SushiSwap',
  type: 3,
  closed: false,
  symbol: 'WMATIC-WETH-IMPULSE',
  asset: 'SUSHI-WMATIC-WETH',
};

const CRV_WETH_IMPULSE: Pool = {
  tokenABI: IERC20,
  poolABI: ImpulseStaking,
  icon: CRV_WETH_ICON,
  description:
    'Impulse is an automatic compounding tool that enables hourly compound interest on your LP tokens. Get more LP tokens in return and increase your APY. CRV-WETH Impulse works on the SushiSwap farm, compounding Matic for CRV and Weth liquidity pair.', // eslint-disable-line
  platform: 'SushiSwap',
  type: 3,
  closed: false,
  symbol: 'CRV-WETH-IMPULSE',
  asset: 'SUSHI-CRV-WETH',
};

const SNX_WETH_IMPULSE: Pool = {
  tokenABI: IERC20,
  poolABI: ImpulseStaking,
  icon: SNX_WETH_ICON,
  description:
    'Impulse is an automatic compounding tool that enables hourly compound interest on your LP tokens. Get more LP tokens in return and increase your APY. SNX-WETH Impulse works on the SushiSwap farm, compounding SNX for Avax and Weth liquidity pair.', // eslint-disable-line
  platform: 'SushiSwap',
  type: 3,
  closed: true,
  symbol: 'SNX-WETH-IMPULSE',
  asset: 'SUSHI-SNX-WETH',
};

const WMATIC_GHST_IMPULSE: Pool = {
  tokenABI: IERC20,
  poolABI: ImpulseStaking,
  icon: WMATIC_GHST_ICON,
  description:
    'Impulse is an automatic compounding tool that enables hourly compound interest on your LP tokens. Get more LP tokens in return and increase your APY. WMATIC-GHST Impulse works on the SushiSwap farm, compounding Matic for WMatic and GHST liquidity pair.', // eslint-disable-line
  platform: 'SushiSwap',
  type: 3,
  closed: false,
  symbol: 'WMATIC-GHST-IMPULSE',
  asset: 'SUSHI-WMATIC-GHST',
};

// Impulse-multi pools
const CRV_EURTUSD_IMPULSE: Pool = {
  tokenABI: IERC20,
  poolABI: ImpulseStakingMulti,
  icon: CRV_EURTUSD_IMPULSE_ICON,
  description:
    'Impulse for Curve pools allows you to deposit stablecoins through DeHive with one-click interface and earn autocompounded interest. Use all benefits from Curve Stable pools with DeHive autocompounding Impulse and one-click stablecoin deposit.', // eslint-disable-line
  platformDescription:
    'Curve Impulse is an automatic compounding tool that enables hourly compound interest on your LP tokens. The Impulse automatically gets Curve LPs and compounds CRV and Matic earnings in the Curve Gauge. You can stake all {{count}} coins at once, or choose only one of them (or any pair or combination of them).', // eslint-disable-line
  platformIcon: CURVE_ICON,
  platform: 'Curve.fi protocol',
  type: 4,
  name: 'Europool',
  closed: false,
  symbol: 'CRV-EURTUSD-IMPULSE',
  asset: 'CRV-EURT-DAI-USDT-USDC',
  isClaimed: true,
  isAccumulated: true,
};

const CRV_IMPULSE: Pool = {
  tokenABI: IERC20,
  poolABI: ImpulseStakingMulti,
  icon: CRV_IMPULSE_ICON,
  description:
    'Curve Impulse is an automatic compounding tool that enables hourly compound interest on your assets. The first Polygon interface to the Curve which has input in all pool coins. Automatically gets Curve LPs and compounds CRV and Matic earnings in the Curve Gauge.', // eslint-disable-line
  platformDescription:
    'Curve Impulse is an automatic compounding tool that enables hourly compound interest on your LP tokens. The Impulse automatically gets Curve LPs and compounds CRV and Matic earnings in the Curve Gauge. You can stake all {{count}} coins at once, or choose only one of them (or any pair or combination of them).', // eslint-disable-line
  platformIcon: CURVE_ICON,
  platform: 'Curve.fi protocol',
  type: 4,
  name: 'Curve 5-Pool',
  closed: false,
  symbol: 'CRV-IMPULSE',
  asset: 'CRV-DAI-USDC-USDT-WBTC-WETH',
};

const NOMO_IMPULSE: Pool = {
  tokenABI: IERC20,
  poolABI: ImpulseStakingMulti,
  icon: NOMO_ICON,
  description:
    'Balanced pool is based on 3 Quickswap stablecoin pairs. It allows you to deposit stablecoins through DeHive with one-click interface and earn autocompounded interest. Diversify risks within 3 stable pairs and keep your yield on stablecoins with DeHive Impulse and one-click stablecoin deposit. Powered by League DAO.', // eslint-disable-line
  platformDescription:
    'Impulse for Balanced pool is an automatic compounding tool that enables compound interest on your LP tokens. The Impulse automatically gets LPs and compounds rewards, and than distributes income between several pairs to diversify portfolio. You can stake all {{count}} coins at once, or choose only one of them (or any pair or combination of them).', // eslint-disable-line
  platformIcon: QUICK_SWAP_ICON,
  platform: 'QuickSwap',
  type: 4,
  name: 'Balanced Stable Pool',
  closed: false,
  symbol: 'NOMO-IMPULSE',
  asset: 'QCK-MAI-USDC-USDT',
  poweredBy: {
    name: 'League DAO',
    icon: POWERED_NOMO_ICON,
    link: 'https://leaguedao.com/',
  },
  isHoldingListHidden: true,
};

const CRV_AAVE_IMPULSE: Pool = {
  tokenABI: IERC20,
  poolABI: ImpulseStakingMulti,
  icon: CRV_AAVE_ICON,
  description:
    'Impulse for Curve pools allows you to deposit stablecoins through DeHive with one-click interface and earn autocompounded interest. Use all benefits from Curve Stable pools with DeHive autocompounding Impulse and one-click stablecoin deposit.', // eslint-disable-line
  platformDescription:
    'Curve Impulse is an automatic compounding tool that enables hourly compound interest on your LP tokens. The Impulse automatically gets Curve LPs and compounds CRV and Matic earnings in the Curve Gauge. You can stake all {{count}} coins at once, or choose only one of them (or any pair or combination of them).', // eslint-disable-line
  platformIcon: CURVE_ICON,
  platform: 'Curve.fi protocol',
  type: 4,
  name: 'Stable Curve 3-Pool',
  closed: false,
  symbol: 'CRV-AAVE-IMPULSE',
  asset: 'CRV-DAI-USDC-USDT',
};

const CRV_3_IMPULSE: Pool = {
  tokenABI: IERC20,
  poolABI: ImpulseStakingMulti,
  icon: CRV_3_ICON,
  description:
    'Impulse for Curve pools allows you to deposit stablecoins through DeHive with one-click interface and earn autocompounded interest. Use all benefits from Curve Stable pools with DeHive autocompounding Impulse and one-click stablecoin deposit.', // eslint-disable-line
  platformDescription:
    'Curve Impulse is an automatic compounding tool that enables hourly compound interest on your LP tokens. The Impulse automatically gets Curve LPs and compounds CRV and Matic earnings in the Curve Gauge. You can stake all {{count}} coins at once, or choose only one of them (or any pair or combination of them).', // eslint-disable-line
  platformIcon: CURVE_ICON,
  platform: 'Curve.fi protocol',
  type: 4,
  name: 'Stable Curve 3-Pool',
  closed: false,
  symbol: 'CRV-3-IMPULSE',
  asset: 'CRV-WXDAI-USDC-USDT',
};

const POOLS: PoolListByChain = {
  1: {
    // Solo pools
    '0x04595f9010F79422a9b411ef963e4dd1F7107704': {
      0: {
        ...DHV,
        pendingRewards: ['0x62Dc4817588d53a056cBbD18231d91ffCcd34b2A'], // DHV
      },
    },
    // LP pools
    '0x4964B3B599B82C3FdDC56e3A9Ffd77d48c6AF0f0': {
      0: {
        ...UNI_DHV_WETH,
        pendingRewards: ['0x62Dc4817588d53a056cBbD18231d91ffCcd34b2A'], // DHV
        underlying: [
          {
            address: '0x62Dc4817588d53a056cBbD18231d91ffCcd34b2A', // DHV
          },
          {
            address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2', // WETH
          },
        ],
        getLink:
          'https://app.uniswap.org/#/add/v2/' +
          '0x62Dc4817588d53a056cBbD18231d91ffCcd34b2A/' +
          '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      },
    },
  },
  4: {
    // Solo pools
    '0x8B03FB5daF155B55343219FbADe7a27b15546Ad2': {
      0: {
        ...DHV,
        pendingRewards: ['0xED377aCA060a0b1Dab10911C8DFf98D24fcF4118'], // DHV
      },
    },
    // LP pools
    '0x652D9095030c5d6f3323c1f2EfC760f15c19f091': {
      0: {
        ...UNI_DHV_WETH,
        pendingRewards: ['0xED377aCA060a0b1Dab10911C8DFf98D24fcF4118'], // DHV
        underlying: [
          {
            address: '0xED377aCA060a0b1Dab10911C8DFf98D24fcF4118', // DHV
          },
          {
            address: '0xdf032bc4b9dc2782bb09352007d4c57b75160b15', // WETH
          },
        ],
      },
    },
  },
  56: {
    // Solo pools
    '0x35f28aA0B2F34eFF17d2830135312ab2a777De36': {
      0: {
        ...DHV,
        pendingRewards: ['0x58759dd469ae5631c42cf8a473992335575b58d7'], // DHV
      },
    },
    // Cluster pools
    '0x7cDA416c096768971c0b7605F5aaABD8fA713818': {
      0: {
        ...DBSC,
        pendingRewards: ['0x58759dd469ae5631c42cf8a473992335575b58d7'], // DHV
        timerRewards: {
          timestamp: 1642845600,
          list: ['0x58759dd469ae5631c42cf8a473992335575b58d7'], // DHV
        },
        dhvAddress: '0x35f28aA0B2F34eFF17d2830135312ab2a777De36',
        dhvABI: StakingDHV,
      },
    },
    // LP pools
    '0xF2e8CD1c40C766FEe73f56607fDffa526Ba8fa6c': {
      0: {
        ...PANCAKE_DHV_BUSD,
        pendingRewards: ['0x58759dd469ae5631c42cf8a473992335575b58d7'], // DHV
        underlying: [
          {
            address: '0x58759dd469ae5631c42cf8a473992335575b58d7', // DHV
          },
          {
            address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', // BUSD
          },
        ],
        getLink:
          'https://pancakeswap.finance/add/' +
          '0x58759Dd469ae5631C42cf8a473992335575b58D7/' +
          '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      },
    },
    // Impulse pools
    '0xA9c97Ff825dB9dd53056d65aE704031B4959d99a': {
      0: {
        ...CAKE_BUSD_IMPULSE,
        underlying: [
          {
            address: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82', // CAKE
          },
          {
            address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', // BUSD
          },
        ],
        getLink:
          'https://pancakeswap.finance/add/' +
          '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/' +
          '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      },
      1: {
        ...BUSDT_USDC_IMPULSE,
        underlying: [
          {
            address: '0x55d398326f99059fF775485246999027B3197955', // BUSDT
          },
          {
            address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d', // BUSDC
          },
        ],
        getLink:
          'https://pancakeswap.finance/add/' +
          '0x55d398326f99059fF775485246999027B3197955/' +
          '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      },
      2: {
        ...BUSDT_WBNB_IMPULSE,
        underlying: [
          {
            address: '0x55d398326f99059fF775485246999027B3197955', // BUSDT
          },
          {
            address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', // WBNB
          },
        ],
        getLink:
          'https://pancakeswap.finance/add/' +
          '0x55d398326f99059fF775485246999027B3197955/' +
          '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      },
      3: {
        ...XVS_WBNB_IMPULSE,
        underlying: [
          {
            address: '0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63', // XVS
          },
          {
            address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', // WBNB
          },
        ],
        getLink:
          'https://pancakeswap.finance/add/' +
          '0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63/' +
          '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      },
      4: {
        ...ALPACA_BUSD_IMPULSE,
        underlying: [
          {
            address: '0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F', // ALPACA
          },
          {
            address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', // BUSD
          },
        ],
        getLink:
          'https://pancakeswap.finance/add/' +
          '0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F/' +
          '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      },
      5: {
        ...LINK_WBNB_IMPULSE,
        underlying: [
          {
            address: '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD', // LINK
          },
          {
            address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', // WBNB
          },
        ],
        getLink:
          'https://pancakeswap.finance/add/' +
          '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD/' +
          '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      },
      6: {
        ...CAKE_BUSDT_IMPULSE,
        underlying: [
          {
            address: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82', // CAKE
          },
          {
            address: '0x55d398326f99059fF775485246999027B3197955', // BUSDT
          },
        ],
        getLink:
          'https://pancakeswap.finance/add/' +
          '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/' +
          '0x55d398326f99059fF775485246999027B3197955',
      },
      7: {
        ...DODO_WBNB_IMPULSE,
        underlying: [
          {
            address: '0x67ee3Cb086F8a16f34beE3ca72FAD36F7Db929e2', // DODO
          },
          {
            address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', // WBNB
          },
        ],
        getLink:
          'https://pancakeswap.finance/add/' +
          '0x67ee3Cb086F8a16f34beE3ca72FAD36F7Db929e2/' +
          '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      },
      8: {
        ...BANANA_WBNB_IMPULSE,
        underlying: [
          {
            address: '0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95', // BANANA
          },
          {
            address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', // WBNB
          },
        ],
        getLink:
          'https://app.apeswap.finance/add/ETH/' +
          '0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
      },
      9: {
        ...BANANA_BUSD_IMPULSE,
        underlying: [
          {
            address: '0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95', // BANANA
          },
          {
            address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', // BUSD
          },
        ],
        getLink:
          'https://app.apeswap.finance/add/' +
          '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/' +
          '0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
      },
      10: {
        ...TWT_WBNB_IMPULSE,
        underlying: [
          {
            address: '0x4B0F1812e5Df2A09796481Ff14017e6005508003', // TWT
          },
          {
            address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', // WBNB
          },
        ],
        getLink:
          'https://app.apeswap.finance/add/' +
          '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/' +
          '0x4B0F1812e5Df2A09796481Ff14017e6005508003',
      },
      11: {
        ...AVAX_WBNB_IMPULSE,
        underlying: [
          {
            address: '0x1CE0c2827e2eF14D5C4f29a091d735A204794041', // AVAX
          },
          {
            address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', // WBNB
          },
        ],
        getLink:
          'https://app.apeswap.finance/add/' +
          '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/' +
          '0x1CE0c2827e2eF14D5C4f29a091d735A204794041',
      },
    },
  },
  137: {
    // Solo pools
    '0x88cFC1bc9aEb80f6C8f5d310d6C3761c2a646Df7': {
      0: {
        ...DHV,
        pendingRewards: ['0x5fCB9de282Af6122ce3518CDe28B7089c9F97b26'], // DHV
      },
    },
    // Cluster pools
    '0x8271529b62c82b4d30a2eFdB3ec89D7abA60897E': {
      0: {
        ...DPOL,
        pendingRewards: ['0x5fCB9de282Af6122ce3518CDe28B7089c9F97b26'], // DHV
        timerRewards: {
          timestamp: 1642845600,
          list: ['0x5fCB9de282Af6122ce3518CDe28B7089c9F97b26'], // DHV
        },
        dhvAddress: '0x88cFC1bc9aEb80f6C8f5d310d6C3761c2a646Df7',
        dhvABI: StakingDHV,
      },
      1: {
        ...DGAME,
        pendingRewards: ['0x5fCB9de282Af6122ce3518CDe28B7089c9F97b26'], // DHV
        timerRewards: {
          timestamp: 1642845600,
          list: ['0x5fCB9de282Af6122ce3518CDe28B7089c9F97b26'], // DHV
        },
        dhvAddress: '0x88cFC1bc9aEb80f6C8f5d310d6C3761c2a646Df7',
        dhvABI: StakingDHV,
      },
    },
    // Impulse pools
    '0xf4feb23531EdBe471a4493D432f8BB29Bf0A3868': {
      0: {
        ...DHV_QUICK_IMPULSE,
        underlying: [
          {
            address: '0x5fCB9de282Af6122ce3518CDe28B7089c9F97b26', // DHV
          },
          {
            address: '0x831753DD7087CaC61aB5644b308642cc1c33Dc13', // QUICK
          },
        ],
        getLink:
          'https://quickswap.exchange/#/add/' +
          '0x5fCB9de282Af6122ce3518CDe28B7089c9F97b26/' +
          '0x831753DD7087CaC61aB5644b308642cc1c33Dc13',
      },
      1: {
        ...WETH_DAI_IMPULSE,
        underlying: [
          {
            address: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619', // WETH
          },
          {
            address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063', // DAI
          },
        ],
        getLink:
          'https://quickswap.exchange/#/add/' +
          '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/' +
          '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
      },
      2: {
        ...USDC_QUICK_IMPULSE,
        underlying: [
          {
            address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174', // USDC
          },
          {
            address: '0x831753DD7087CaC61aB5644b308642cc1c33Dc13', // QUICK
          },
        ],
        getLink:
          'https://quickswap.exchange/#/add/' +
          '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/' +
          '0x831753DD7087CaC61aB5644b308642cc1c33Dc13',
      },
      3: {
        ...USDC_MATIC_IMPULSE,
        underlying: [
          {
            address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174', // USDC
          },
          {
            address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270', // WMATIC
          },
        ],
        getLink:
          'https://quickswap.exchange/#/add/' +
          '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/ETH',
      },
      4: {
        ...MAI_USDT_IMPULSE,
        underlying: [
          {
            address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F', // USDT
          },
          {
            address: '0xa3fa99a148fa48d14ed51d610c367c61876997f1', // MAI
          },
        ],
        getLink:
          'https://quickswap.exchange/#/add/' +
          '0xc2132D05D31c914a87C6611C10748AEb04B58e8F/' +
          '0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
      },
      5: {
        ...AVAX_WETH_IMPULSE,
        underlying: [
          {
            address: '0x2C89bbc92BD86F8075d1DEcc58C7F4E0107f286b', // AVAX
          },
          {
            address: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619', // WETH
          },
        ],
        getLink:
          'https://app.sushi.com/add/' +
          '0x2C89bbc92BD86F8075d1DEcc58C7F4E0107f286b/' +
          '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
      },
      6: {
        ...WMATIC_WETH_IMPULSE,
        underlying: [
          {
            address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270', // WMATIC
          },
          {
            address: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619', // WETH
          },
        ],
        getLink:
          'https://app.sushi.com/add/' +
          '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/' +
          '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
      },
      7: {
        ...CRV_WETH_IMPULSE,
        underlying: [
          {
            address: '0x172370d5Cd63279eFa6d502DAB29171933a610AF', // CRV
          },
          {
            address: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619', // WETH
          },
        ],
        getLink:
          'https://app.sushi.com/add/' +
          '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/' +
          '0x172370d5Cd63279eFa6d502DAB29171933a610AF',
      },
      8: {
        ...SNX_WETH_IMPULSE,
        underlying: [
          {
            address: '0x50B728D8D964fd00C2d0AAD81718b71311feF68a', // SNX
          },
          {
            address: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619', // WETH
          },
        ],
        getLink:
          'https://app.sushi.com/add/' +
          '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/' +
          '0x50B728D8D964fd00C2d0AAD81718b71311feF68a',
      },
      9: {
        ...WMATIC_GHST_IMPULSE,
        underlying: [
          {
            address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270', // WMATIC
          },
          {
            address: '0x385eeac5cb85a38a9a07a70c73e0a3271cfb54a7', // GHST
          },
        ],
        getLink:
          'https://app.sushi.com/add/' +
          '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/' +
          '0x385Eeac5cB85A38A9a07A70c73e0a3271CfB54A7',
      },
    },
    // Impulse-multi pools
    '0xE6E6982fb5dDF4fcc74cCCe4e4eea774E002D17F': {
      0: {
        ...CRV_IMPULSE,
        underlying: [
          {
            address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063', // DAI
          },
          {
            address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174', // USDC
          },
          {
            address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F', // USDT
          },
          {
            address: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6', // WBTC
          },
          {
            address: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619', // WETH
          },
        ],
      },
      1: {
        ...CRV_AAVE_IMPULSE,
        underlying: [
          {
            address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063', // DAI
          },
          {
            address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174', // USDC
          },
          {
            address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F', // USDT
          },
        ],
      },
      2: {
        ...CRV_EURTUSD_IMPULSE,
        pendingRewards: ['0x5fCB9de282Af6122ce3518CDe28B7089c9F97b26'], // DHV
        timerRewards: {
          timestamp: 1652432400,
          list: ['0x5fCB9de282Af6122ce3518CDe28B7089c9F97b26'], // DHV
        },
        underlying: [
          {
            address: '0x7BDF330f423Ea880FF95fC41A280fD5eCFD3D09f', // EURT
            isHidden: true,
          },
          {
            address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063', // DAI
          },
          {
            address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174', // USDC
          },
          {
            address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F', // USDT
          },
        ],
      },
      3: {
        ...NOMO_IMPULSE,
        underlying: [
          {
            address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174', // USDC
          },
          {
            address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F', // USDT
          },
          {
            address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063', // DAI
          },
          {
            address: '0xa3Fa99A148fA48D14Ed51d610c367C61876997F1', // MAI
          },
        ],
      },
    },
  },
  100: {
    // Solo pools
    '0x589Ea336092184d9eD74b8263c4eecA73Ed0cE7a': {
      0: {
        ...DHV,
        pendingRewards: ['0xFbdd194376de19a88118e84E279b977f165d01b8'], // DHV
      },
    },
    // Cluster pools
    '0xdC005EbbA14C3C01ab26B06D4e981FC81e2DF2FF': {
      0: {
        ...DXIN,
        pendingRewards: [
          '0xFbdd194376de19a88118e84E279b977f165d01b8', // DHV
          '0x9c58bacc331c9aa871afd802db6379a98e80cedb', // GNO
        ],
        timerRewards: {
          timestamp: 1645264800,
          list: [
            '0xFbdd194376de19a88118e84E279b977f165d01b8', // DHV
            '0x9c58bacc331c9aa871afd802db6379a98e80cedb', // GNO
          ],
        },
        dhvAddress: '0x589Ea336092184d9eD74b8263c4eecA73Ed0cE7a',
        dhvABI: StakingDHV,
      },
      1: {
        ...DXDC,
        pendingRewards: [
          '0xFbdd194376de19a88118e84E279b977f165d01b8', // DHV
          '0x9c58bacc331c9aa871afd802db6379a98e80cedb', // GNO
          '0x71850b7E9Ee3f13Ab46d67167341E4bDc905Eef9', // HNY
          '0x63e62989D9EB2d37dfDB1F93A22f063635b07d51', // MIVA
          '0xc45b3c1c24d5f54e7a2cf288ac668c74dd507a84', // SYMM
          '0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C', // ELK
          '0x3a97704a1b25F08aa230ae53B352e2e72ef52843', // AGVE
        ],
        timerRewards: {
          timestamp: 1645264800,
          list: [
            '0x71850b7E9Ee3f13Ab46d67167341E4bDc905Eef9', // HNY
            '0x63e62989D9EB2d37dfDB1F93A22f063635b07d51', // MIVA
            '0xc45b3c1c24d5f54e7a2cf288ac668c74dd507a84', // SYMM
            '0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C', // ELK
            '0x3a97704a1b25F08aa230ae53B352e2e72ef52843', // AGVE
          ],
        },
        dhvAddress: '0x589Ea336092184d9eD74b8263c4eecA73Ed0cE7a',
        dhvABI: StakingDHV,
      },
    },
    // LP pools
    '0xa4E7BE054000603B82B79208aC3eE5428554CaF6': {
      1: {
        ...HONEY_DHV_WXDAI,
        pendingRewards: [
          '0xFbdd194376de19a88118e84E279b977f165d01b8', // DHV
          '0xb7D311E2Eb55F2f68a9440da38e7989210b9A05e', // STAKE
        ],
        timerRewards: {
          timestamp: 1645264800,
          list: [
            '0xb7D311E2Eb55F2f68a9440da38e7989210b9A05e', // STAKE
          ],
        },
        underlying: [
          {
            address: '0xFbdd194376de19a88118e84E279b977f165d01b8', // DHV
          },
          {
            address: '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d', // WXDAI
          },
        ],
        getLink:
          'https://app.honeyswap.org/#/add/' +
          '0xFbdd194376de19a88118e84E279b977f165d01b8/XDAI',
      },
    },
    // Impulse-multi pools
    '0xfa7Ca14a28CD419a69E45e8416cA4FA87457aCE8': {
      0: {
        ...CRV_3_IMPULSE,
        underlying: [
          {
            address: '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d', // WXDAI
          },
          {
            address: '0xDDAfbb505ad214D7b80b1f830fcCc89B60fb7A83', // USDC
          },
          {
            address: '0x4ECaBa5870353805a9F068101A40E0f32ed605C6', // USDT
          },
        ],
      },
    },
  },
  80001: {
    // Solo pools
    '0xffDD71cc9a8f69E976813a887920d77ba2d3e1Df': {
      0: {
        ...DHV,
        pendingRewards: ['0x19B30Ab83641745Bd445c8dB21A520f2971257e0'], // DHV
      },
    },
    // Cluster pools
    '0xA805494ac5F1079929870f8fD37ACd5ebf4B988e': {
      0: {
        ...DPOL,
        pendingRewards: ['0x19B30Ab83641745Bd445c8dB21A520f2971257e0'], // DHV
        timerRewards: {
          timestamp: 1642845600,
          list: ['0x19B30Ab83641745Bd445c8dB21A520f2971257e0'], // DHV
        },
        dhvAddress: '0xffDD71cc9a8f69E976813a887920d77ba2d3e1Df',
        dhvABI: StakingDHV,
      },
    },
    // Impulse pools
    '0x57B33da15d86876905f3f5C730195a0b8Ce2B807': {
      242: {
        ...DHV_QUICK_IMPULSE,
        underlying: [
          {
            address: '0x19B30Ab83641745Bd445c8dB21A520f2971257e0', // DHV
          },
          {
            address: '0x5186f787c24280040371ba594C6EFc087b98788e', // QUICK
          },
        ],
      },
      678: {
        ...WMATIC_WETH_IMPULSE,
        underlying: [
          {
            address: '0x19B30Ab83641745Bd445c8dB21A520f2971257e0', // DHV
          },
          {
            address: '0x5186f787c24280040371ba594C6EFc087b98788e', // QUICK
          },
        ],
      },
    },
    // Impulse-multi pools
    '0xB3A6FB059E360e5225fe8c2cACaB6687191726Ac': {
      0: {
        ...CRV_IMPULSE,
        underlying: [
          {
            address: '0x6ef9bB1907D0a630B650db97b7aE8eB364220989', // DAI
          },
          {
            address: '0xCE82a02139ACe6feE401d05F6202e31ADc21AD26', // USDC
          },
          {
            address: '0xDE24389b784fE9A3ECa9DDF95FB5738c2fc16b63', // USDT
          },
          {
            address: '0x52d6020A124B0030870136975C3114D5B3d07901', // WBTC
          },
          {
            address: '0x6311fA8e0BD5B92d19a90CA091377Ec425F3731d', // WETH
          },
        ],
      },
    },
  },
};

export default POOLS;
