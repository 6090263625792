import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import cn from 'classnames';

import WalletConnections from '@modules/connections/components/WalletConnections';
import TotalValueLocked from '@modules/layout/components/TotalValueLocked';
import NetworkSelector from '@modules/networks/components/NetworkSelector';
import Logo from '@modules/layout/components/Logo';
import useMediaQuery from '@modules/layout/hooks/useMediaQuery';
import MENU_ICON from '@modules/layout/assets/menu_icon.svg';

import styles from './Header.module.scss';

const Header: FC<{ className?: string; onClick: () => void }> = ({
  className,
  onClick,
}) => {
  const { t } = useTranslation();

  const isMobile = useMediaQuery(991);

  const { account } = useWeb3React();

  return (
    <header className={cn(styles.container, className)}>
      <button className={styles.menu} onClick={onClick} type="button">
        <img
          className={styles.menuIcon}
          src={MENU_ICON}
          alt={t('LAYOUT.HEADER.MENU_ICON')}
        />
      </button>

      <Logo className={styles.logo} />

      <TotalValueLocked className={styles.tvl} />

      <NetworkSelector className={styles.networkSelector} />

      <WalletConnections
        textButton={t(
          isMobile
            ? 'LAYOUT.HEADER.WALLET_CONNECTIONS_MOBILE_TEXT_BUTTON'
            : 'LAYOUT.HEADER.WALLET_CONNECTIONS_TEXT_BUTTON',
        )}
        className={styles.walletConnect}
        isBlue={!!(isMobile && account)}
        size={isMobile ? 'small' : 'big'}
      />
    </header>
  );
};

Header.defaultProps = { className: undefined };

export default Header;
