import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatUnits } from '@ethersproject/units';
import cn from 'classnames';

import formatValueToken from '@modules/common/helpers/formatValueToken';
import StoredCluster from '@modules/clusters/types/StoredCluster';
import { Cluster } from '@configs/clusters';

import styles from './ClusterModalBalance.module.scss';

const ClusterModalBalance: FC<{
  className?: string;
  decimals: StoredCluster['decimals'];
  balance: StoredCluster['userBalance'];
  symbol: StoredCluster['symbol'];
  name: StoredCluster['name'] | Cluster['name'];
}> = ({ className, decimals, balance, symbol, name }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <dl className={cn(styles.container, className)}>
      <dt className={styles.title}>
        {t('CLUSTERS.CLUSTER_MODAL_BALANCE.TITLE', { name })}
      </dt>

      <dd className={styles.value}>
        {balance
          ? formatValueToken(language, Number(formatUnits(balance, decimals)))
          : '-'}{' '}
        {symbol}
      </dd>
    </dl>
  );
};

ClusterModalBalance.defaultProps = { className: undefined };

export default ClusterModalBalance;
