import { PayloadAction, AnyAction } from '@reduxjs/toolkit';
import {
  CallEffect,
  PutEffect,
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { JsonRpcProvider } from '@ethersproject/providers';
import { ContractInterface, Contract } from '@ethersproject/contracts';
import { parseUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';

import {
  setIsLoadingAssemble,
  setErrorAssemble,
  setAssemble,
} from '@modules/clusters/slices/commissionSlice';
import getClusterGasPriceAssemble from '@modules/clusters/actions/getClusterGasPriceAssemble';
import getProviderFeeData from '@modules/clusters/helpers/getProviderFeeData';
import ProviderFeeData from '@modules/clusters/types/ProviderFeeData';
import { ClusterList } from '@configs/clusters';

const getMethodGasPrice = (
  clusterAddress: string,
  clusterABI: ContractInterface,
  provider: JsonRpcProvider,
  account?: string,
): Promise<BigNumber> => {
  const clusterContract = new Contract(
    clusterAddress,
    clusterABI,
    account ? provider.getSigner(account) : provider,
  );

  return getClusterGasPriceAssemble(
    clusterContract,
    BigNumber.from('100'),
    BigNumber.from('100000000000000'),
  );
};

function* getClusterCommissionAssembleWorker({
  payload,
}: PayloadAction<{
  clusterDecimals: number;
  clusterAddress: string;
  clusters: ClusterList;
  library: JsonRpcProvider;
  account?: string;
}>): Generator<
  CallEffect<ProviderFeeData> | CallEffect<BigNumber> | PutEffect<AnyAction>,
  void,
  never
> {
  try {
    yield put(setIsLoadingAssemble(true));
    yield put(setErrorAssemble(null));

    const providerFeeData: ProviderFeeData = yield call(
      getProviderFeeData,
      payload.library,
    );

    const methodGasPrice: BigNumber = yield call(
      getMethodGasPrice,
      payload.clusterAddress,
      payload.clusters[payload.clusterAddress].tokenABI,
      payload.library,
      payload.account,
    );

    const commissionValue =
      providerFeeData.maxFeePerGas && providerFeeData.maxPriorityFeePerGas
        ? providerFeeData.maxFeePerGas
            .add(providerFeeData.maxPriorityFeePerGas)
            .mul(methodGasPrice)
        : BigNumber.from(parseUnits('0.01', payload.clusterDecimals));

    yield put(setAssemble(commissionValue));
  } catch (error: unknown) {
    yield put(setErrorAssemble(error));
  } finally {
    yield put(setIsLoadingAssemble(false));
  }
}

function* getClusterCommissionAssembleSaga(): Generator {
  yield takeLatest(
    'CLUSTERS_GET_CLUSTER_COMMISSION_ASSEMBLE_REQUESTED',
    getClusterCommissionAssembleWorker,
  );
}

export default getClusterCommissionAssembleSaga;
