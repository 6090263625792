import { FC } from 'react';
import { useWeb3React } from '@web3-react/core';

import ClusterDisassemble from '@modules/clusters/components/ClusterDisassemble';
import ClusterAssemble from '@modules/clusters/components/ClusterAssemble';
import ClusterBalances from '@modules/clusters/components/ClusterBalances';
import StoredCluster from '@modules/clusters/types/StoredCluster';
import CLUSTERS from '@configs/clusters';

import styles from '@modules/clusters/components/ClusterInfo/ClusterInfo.module.scss';

const ClusterInfo: FC<{ clusterAddress: string; cluster: StoredCluster }> = ({
  clusterAddress,
  cluster,
}) => {
  const { chainId } = useWeb3React();

  return chainId && CLUSTERS[chainId] ? (
    <div className={styles.container}>
      <img
        className={styles.icon}
        height="103"
        width="103"
        src={CLUSTERS[chainId][clusterAddress].icon}
        alt={cluster.symbol}
      />

      <ClusterBalances
        clusterAddress={clusterAddress}
        className={styles.balances}
        cluster={cluster}
      />

      <p className={styles.description}>
        {CLUSTERS[chainId][clusterAddress].description}
      </p>

      <div className={styles.buttons}>
        <ClusterAssemble
          clusterAddress={clusterAddress}
          className={styles.buttonsItem}
          cluster={cluster}
        />

        <ClusterDisassemble
          clusterAddress={clusterAddress}
          className={styles.buttonsItem}
          cluster={cluster}
        />
      </div>
    </div>
  ) : null;
};

export default ClusterInfo;
