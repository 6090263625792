import {
  call,
  CallEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import { AnyAction } from '@reduxjs/toolkit';

import {
  setValue,
  setError,
  setIsLoading,
  setPreError,
} from '@modules/layout/slices/tvlSlice';

const API = process.env.REACT_APP_API || 'http://localhost:1234/api';

const getTvl = (): Promise<AxiosResponse<string>> => {
  return axios.get<string>(`${API}/tvl/total`);
};

function* getTvlWorker(): Generator<
  CallEffect<AxiosResponse<string>> | PutEffect<AnyAction>,
  void,
  never
> {
  try {
    yield put(setIsLoading(true));
    yield put(setPreError(null));
    yield put(setError(null));

    const { data }: { data: AxiosResponse<string> } = yield call(getTvl);

    yield put(setValue(Number(data)));
  } catch (error: unknown) {
    yield put(setPreError(error));
  } finally {
    yield put(setIsLoading(false));
  }
}

function* getTvlSaga(): Generator {
  yield takeLatest('LAYOUT_GET_TVL', getTvlWorker);
}

export default getTvlSaga;
