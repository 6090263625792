import { FC, useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import cn from 'classnames';

import ClusterCard from '@modules/clusters/components/ClusterCard';
import ErrorBlock from '@modules/common/components/ErrorBlock';
import NotFound from '@modules/common/components/NotFound';
import { preErrorSelector as preErrorSelectorUnderlying } from '@modules/underlying/slices/underlyingSlice';
import {
  isLoadingSelector as isLoadingSelectorClusters,
  preErrorSelector as preErrorSelectorClusters,
  errorSelector as errorSelectorClusters,
  listSelector as listSelectorClusters,
} from '@modules/clusters/slices/clustersSlice';
import { successListSelector as successListSelectorDisassemble } from '@modules/clusters/slices/disassembleSlice';
import { successListSelector as successListSelectorAssemble } from '@modules/clusters/slices/assembleSlice';
import { preErrorSelector as currencyPreError } from '@modules/prices/slices/currencySlice';
import { preErrorSelector as preErrorSelectorPoolsStats } from '@modules/pools/slices/poolsStatsSlice';
import { useTypedSelector } from '@utils/store';
import StoredClusterUnderlying from '@modules/clusters/types/StoredClusterUnderlying';
import UnderlyingItem from '@modules/underlying/types/UnderlyingItem';
import constants from '@modules/common/constants';
import LOADING_IMAGE from '@modules/common/assets/loading.svg';
import CLUSTERS from '@configs/clusters';
import controllers from '@configs/controllers';

import styles from '@modules/clusters/components/ClustersList/ClustersList.module.scss';
import POOLS from '@configs/pools';

const ClustersList: FC = () => {
  const [attemptUnderlying, setAttemptUnderlying] = useState<number>(0);
  const [attemptCluster, setAttemptCluster] = useState<number>(0);
  const [currencyAttempts, setCurrencyAttempts] = useState(0);
  const [attemptPoolStats, setAttemptPoolStats] = useState<number>(0);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { chainId, library } = useWeb3React<Web3Provider>();

  const successListDisassemble = useTypedSelector(
    successListSelectorDisassemble,
  );
  const successListAssemble = useTypedSelector(successListSelectorAssemble);
  const preErrorUnderlying = useTypedSelector(preErrorSelectorUnderlying);
  const isLoadingClusters = useTypedSelector(isLoadingSelectorClusters);
  const preErrorClusters = useTypedSelector(preErrorSelectorClusters);
  const errorClusters = useTypedSelector(errorSelectorClusters);
  const clusters = useTypedSelector(listSelectorClusters);
  const preErrorCurrency = useTypedSelector(currencyPreError);
  const preErrorPoolsStats = useTypedSelector(preErrorSelectorPoolsStats);

  const getPoolsStats = useCallback(() => {
    if (clusters && chainId) {
      dispatch({
        type: 'POOLS_GET_POOL_STATS',
        payload: {
          pools: Object.values(clusters)
            .filter((cluster) =>
              Object.values(POOLS[chainId])
                .map((pool) => Object.values(pool))
                .flat()
                .map(({ symbol }) => symbol)
                .includes(cluster.symbol),
            )
            .map((cluster) => cluster.symbol),
          chainId,
        },
      });
    }
  }, [dispatch, clusters, chainId]);

  useEffect(() => {
    getPoolsStats();

    const reGetPoolsStats = setInterval(
      () => getPoolsStats(),
      constants.GET_ITEMS_INTERVAL,
    );

    return () => clearInterval(reGetPoolsStats);
  }, [getPoolsStats]);

  useEffect(() => {
    if (preErrorPoolsStats && attemptPoolStats < constants.GET_ITEMS_ATTEMPTS) {
      getPoolsStats();

      setAttemptPoolStats(attemptPoolStats + 1);
    }

    if (
      preErrorPoolsStats &&
      attemptPoolStats >= constants.GET_ITEMS_ATTEMPTS
    ) {
      dispatch({
        type: 'POOLS_SET_ERROR_POOLS_STATS',
        payload: { error: preErrorPoolsStats },
      });
    }
  }, [preErrorPoolsStats, getPoolsStats, dispatch, attemptPoolStats]);

  const getCurrencyPrice = useCallback(() => {
    dispatch({
      type: 'PRICES_GET_CURRENCY',
    });
  }, [dispatch]);

  useEffect(() => {
    getCurrencyPrice();

    const reGetCurrencyPrice = setInterval(
      () => getCurrencyPrice(),
      constants.GET_ITEMS_INTERVAL,
    );
    return clearInterval(reGetCurrencyPrice);
  }, [getCurrencyPrice]);

  useEffect(() => {
    if (preErrorCurrency && currencyAttempts < constants.GET_ITEMS_ATTEMPTS) {
      getCurrencyPrice();
      setCurrencyAttempts(currencyAttempts + 1);
    }
    if (preErrorCurrency && currencyAttempts >= constants.GET_ITEMS_ATTEMPTS) {
      dispatch({
        type: 'PRICE_SET_ERROR_CURRENCY_PRICE',
        payload: { error: preErrorCurrency },
      });
    }
  }, [currencyAttempts, dispatch, getCurrencyPrice, preErrorCurrency]);

  const getUnderlyingList = useCallback(() => {
    if (clusters && chainId && library && CLUSTERS[chainId]) {
      const underlyingList: UnderlyingItem[] = [];

      Object.keys(clusters).map((token: string) => {
        return clusters[token].underlyingList.map(
          ({ address }: StoredClusterUnderlying) => {
            if (CLUSTERS[chainId][token]) {
              return underlyingList.push({
                underlyingABI: CLUSTERS[chainId][token].underlyingABI,
                address,
              });
            }

            return null;
          },
        );
      });

      dispatch({
        type: 'UNDERLYING_GET_UNDERLYING_LIST_REQUESTED',
        payload: { underlyingList, library },
      });
    }
  }, [dispatch, clusters, chainId, library]);

  const getClusterList = useCallback(() => {
    if (chainId && library && CLUSTERS[chainId]) {
      dispatch({
        type: 'CLUSTERS_GET_CLUSTER_LIST_REQUESTED',
        payload: {
          clusters: CLUSTERS[chainId],
          controller: controllers[chainId],
          library,
        },
      });
    }
  }, [dispatch, chainId, library]);

  useEffect(() => {
    getClusterList();

    const reGetClusterList = setInterval(
      () => getClusterList(),
      constants.GET_ITEMS_INTERVAL,
    );

    return () => clearInterval(reGetClusterList);
  }, [getClusterList]);

  useEffect(() => {
    const success: string | undefined = Object.keys(successListAssemble).find(
      (item) => (successListAssemble[item] ? item : null),
    );

    if (success) {
      getClusterList();
    }
  }, [successListAssemble, getClusterList]);

  useEffect(() => {
    const success: string | undefined = Object.keys(
      successListDisassemble,
    ).find((item) => (successListDisassemble[item] ? item : null));

    if (success) {
      getClusterList();
    }
  }, [successListDisassemble, getClusterList]);

  useEffect(() => {
    getUnderlyingList();

    const reGetUnderlyingList = setInterval(
      () => getUnderlyingList(),
      constants.GET_ITEMS_INTERVAL,
    );

    return () => clearInterval(reGetUnderlyingList);
  }, [getUnderlyingList]);

  useEffect(() => {
    if (preErrorClusters && attemptCluster < constants.GET_ITEMS_ATTEMPTS) {
      getClusterList();

      setAttemptCluster(attemptCluster + 1);
    }

    if (preErrorClusters && attemptCluster >= constants.GET_ITEMS_ATTEMPTS) {
      dispatch({
        type: 'CLUSTERS_SET_ERROR_CLUSTER_REQUESTED',
        payload: { error: preErrorClusters },
      });
    }
  }, [preErrorClusters, getClusterList, dispatch, attemptCluster]);

  useEffect(() => {
    if (
      preErrorUnderlying &&
      attemptUnderlying < constants.GET_ITEMS_ATTEMPTS
    ) {
      getUnderlyingList();

      setAttemptUnderlying(attemptUnderlying + 1);
    }

    if (
      preErrorUnderlying &&
      attemptUnderlying >= constants.GET_ITEMS_ATTEMPTS
    ) {
      dispatch({
        type: 'UNDERLYING_SET_ERROR_UNDERLYING_REQUESTED',
        payload: { error: preErrorUnderlying },
      });
    }
  }, [preErrorUnderlying, getUnderlyingList, dispatch, attemptUnderlying]);

  return (
    <article className={styles.container}>
      <h1 className={styles.title}>{t('CLUSTERS.CLUSTERS_LIST.PAGE_TITLE')}</h1>

      <p className={styles.description}>
        {t('CLUSTERS.CLUSTERS_LIST.PAGE_DESCRIPTION')}
      </p>

      {chainId && CLUSTERS[chainId] && (
        <section
          className={cn(styles.list, {
            [styles['is-loading']]: isLoadingClusters,
          })}
        >
          {clusters &&
            Object.keys(clusters)?.map((item) => (
              <ClusterCard
                clusterAddress={item}
                className={styles.listItem}
                cluster={clusters[item]}
                key={item}
              />
            ))}

          {clusters && Object.keys(clusters).length === 1 && (
            <ClusterCard
              clusterAddress={Object.keys(clusters)[0]}
              className={styles.listItem}
              cluster={{
                name: clusters[Object.keys(clusters)[0]].name,
                price: clusters[Object.keys(clusters)[0]].price,
                symbol: clusters[Object.keys(clusters)[0]].symbol,
                decimals: clusters[Object.keys(clusters)[0]].decimals,
                underlyingList:
                  clusters[Object.keys(clusters)[0]].underlyingList,
                totalSupply: null,
              }}
              isFake
            />
          )}

          {isLoadingClusters && (
            <img
              className={styles.loading}
              height="100"
              width="100"
              src={LOADING_IMAGE}
              alt=""
            />
          )}
        </section>
      )}

      {chainId && !CLUSTERS[chainId] && (
        <NotFound title={t('CLUSTERS.NOT_FOUND.BLOCK_TITLE')} />
      )}

      {chainId && CLUSTERS[chainId] && !clusters && errorClusters && (
        <ErrorBlock title={t('CLUSTERS.ERROR_BLOCK.BLOCK_TITLE')} />
      )}
    </article>
  );
};

export default ClustersList;
