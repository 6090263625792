import { BigNumber } from '@ethersproject/bignumber';

import ContractFunctions from '@modules/common/types/ContractFunctions';

const handleClusterAssemble = (
  clusterContract: ContractFunctions<BigNumber>,
  clusterAmount: BigNumber,
  ethAmount: BigNumber,
  gasLimit: BigNumber,
): Promise<BigNumber> => {
  return clusterContract.assemble(clusterAmount, true, {
    value: ethAmount,
    gasLimit,
  });
};

export default handleClusterAssemble;
