import { parseUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';

import ContractFunctions from '@modules/common/types/ContractFunctions';

const getClusterAmountFromEth = (
  controllerContract: ContractFunctions<BigNumber>,
  clusterDecimals: number,
  clusterAddress: string,
  amount: string,
): Promise<BigNumber> => {
  return controllerContract.getClusterAmountFromEth(
    parseUnits(amount, clusterDecimals),
    clusterAddress,
  );
};

export default getClusterAmountFromEth;
