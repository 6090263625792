import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '@utils/store';
import StoredTokenPrice from '@modules/prices/types/TokenPrice';

interface InitialState {
  isLoading: boolean;
  preError: unknown | null;
  error: unknown | null;
  prices: StoredTokenPrice | null;
}

const initialState: InitialState = {
  isLoading: false,
  preError: null,
  error: null,
  prices: null,
};

const reducers = {
  setIsLoading(state: InitialState, action: PayloadAction<boolean>) {
    state.isLoading = action.payload;
  },
  setPreError(state: InitialState, action: PayloadAction<unknown | null>) {
    state.preError = action.payload;
  },
  setError(state: InitialState, action: PayloadAction<unknown | null>) {
    state.error = action.payload;
  },
  setPrices(
    state: InitialState,
    action: PayloadAction<StoredTokenPrice | null>,
  ) {
    state.prices = action.payload;
  },
};

const tokensPrices = createSlice({
  name: 'tokensPrices',
  initialState,
  reducers,
});

export const isLoadingSelector = (state: RootState): boolean =>
  state.tokensPrices.isLoading;
export const preErrorSelector = (state: RootState): unknown | null =>
  state.tokensPrices.preError;
export const errorSelector = (state: RootState): unknown | null =>
  state.tokensPrices.error;
export const getPrices = (state: RootState): StoredTokenPrice | null =>
  state.tokensPrices.prices;

export const { setIsLoading, setPreError, setError, setPrices } =
  tokensPrices.actions;

export default tokensPrices.reducer;
