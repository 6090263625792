import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { RootState } from '@utils/store';
import StoredPoolList from '@modules/pools/types/StoredPoolList';
import StoredPool from '@modules/pools/types/StoredPool';

interface InitialState {
  isLoading: boolean;
  preError: unknown | null;
  error: unknown | null;
  list: StoredPoolList | null;
}

const initialState: InitialState = {
  isLoading: false,
  preError: null,
  error: null,
  list: null,
};

const reducers = {
  setIsLoading(state: InitialState, action: PayloadAction<boolean>) {
    state.isLoading = action.payload;
  },
  setListItem(
    state: InitialState,
    action: PayloadAction<{
      poolAddress: string;
      poolId: number;
      pool: StoredPool;
    }>,
  ) {
    if (state.list) {
      if (state.list[action.payload.poolAddress]) {
        state.list[action.payload.poolAddress][action.payload.poolId] =
          action.payload.pool;
      } else {
        state.list[action.payload.poolAddress] = {
          [action.payload.poolId]: action.payload.pool,
        };
      }
    } else {
      state.list = {
        [action.payload.poolAddress]: {
          [action.payload.poolId]: action.payload.pool,
        },
      };
    }
  },
  setPreError(state: InitialState, action: PayloadAction<unknown | null>) {
    state.preError = action.payload;
  },
  setError(state: InitialState, action: PayloadAction<unknown | null>) {
    state.error = action.payload;
  },
  setList(state: InitialState, action: PayloadAction<StoredPoolList>) {
    state.list = {
      ...state.list,
      ...action.payload,
    };
  },
};

const poolsSlice = createSlice({
  name: 'pools',
  initialState,
  reducers,
});

export const isLoadingSelector = (state: RootState): boolean =>
  state.pools.isLoading;
export const preErrorSelector = (state: RootState): unknown | null =>
  state.pools.preError;
export const errorSelector = (state: RootState): unknown | null =>
  state.pools.error;
export const listSelector = (state: RootState): StoredPoolList | null =>
  state.pools.list;

export const { setIsLoading, setListItem, setPreError, setError, setList } =
  poolsSlice.actions;

export default poolsSlice.reducer;
