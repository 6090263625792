import { notification } from 'antd';

const errorOpenModalAssemble = (): void => {
  notification.error({
    message: 'Join cluster error',
    description: 'Here is not available a cluster that you try to join.',
  });
};

export default errorOpenModalAssemble;
